// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, AfterViewInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';

// ***__***_________  MODELOS _________ ***__***
import { ChooseModalParam } from 'src/app/models/choose-modal-param';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { ALIAS, SERVICE_URL, DATE_FORMAT, LANGUAGE } from '../../../constants/global';

// ***__***_________  VARIAVEIS JAVASCRIPT _________ ***__***
import { Filter, FiltersType, SelectElementData, FilterColumnTable } from '../../../modules/dockmodal.module';
import { TranslateValueService } from '../../../services/translate-value.service';
import { DatatableParameters } from '../../../models/datatable-parameters';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModelValidators } from 'src/app/models/validators/validators';

declare var Functions: any;

@Component({
  templateUrl: './choose-modal.html'
})
export class ChooseModalComponent implements AfterViewInit {

  list: Array<any> = new Array<any>(); // listagem de dados
  actionTable: string = null; // listagem de dados
  table: any = null;
  idSelec: number;
  indexSelec: number;
  modalTitle: string; // titulo da modal
  aoColumnsList: Array<any> = new Array<any>(); // colunas para a datatable
  columnDefsList: Array<any> = new Array<any>(); // definição colunas para a datatable
  filters: Array<Filter> = new Array<Filter>(); // array com o filtros dos dados para colocar os inputs - pode ir a null caso nao tenha filtros
  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  filtersColumns: Array<FilterColumnTable> = new Array<FilterColumnTable>(); // array com o filtros das colunas, caso nao sejam inputs normais
  actionParams: Array<Filter> = null;
  checkID = null;
  allowNone = null;
  filterExpanded: boolean = false;
  filterDate: boolean = false;
  form: UntypedFormGroup;


  columnID: string = 'ID';

  constructor(public dialogRef: MatDialogRef<ChooseModalComponent>, @Inject(MAT_DIALOG_DATA) data: ChooseModalParam, private _translateService: TranslateService,
    private _translateValueService: TranslateValueService, private formBuilder: UntypedFormBuilder) {

    // parametros que vai receber
    this.list = data.List;
    this.actionTable = data.ActionTable;
    this.modalTitle = data.ModalTitle;
    this.aoColumnsList = data.Columns;
    if (data.ColumnsDefs) {
      this.columnDefsList = data.ColumnsDefs;
    }
    this.filters = data.Filters;
    if (data.OrderColumnIndex != null && data.orderBy != null) {
      this.datatableParameters.order = [[data.OrderColumnIndex, data.orderBy]];
    }
    if (data.FilterColumns) {
      this.filtersColumns = data.FilterColumns;
    }

    if (data.ActionParams) {
      this.actionParams = data.ActionParams;
    }
    if (data.CheckID) {
      this.checkID = data.CheckID;
    }
    if (data.AllowNone) {
      this.allowNone = data.AllowNone;
    }
    if (data.OtherID) {
      this.columnID = data.OtherID;
    }
    if (data.filtersExpanded) {
      this.filterExpanded = data.filtersExpanded;
    }
    if(data.filterDate){
      this.filterDate = data.filterDate;
      let auxDI = new Date();
      let auxDF = new Date();
      auxDI.setDate(auxDI.getDate()-7)
      this.form = this.formBuilder.group({
        'dataInicio': [auxDI.toLocaleDateString(), [Validators.required, ModelValidators.validDate]],
        'dataFim': [auxDF.toLocaleDateString(), [Validators.required, ModelValidators.validDate]],
      }, {
        validator: (formgroup: UntypedFormGroup) => {
          return ModelValidators.validIntervalDates(formgroup, 'dataInicio', 'dataFim', true);
        }
      });
    }
  }

  ngAfterViewInit() {
    let idTable = '#tableChoose';

    // adicionar na tabela no header, tantas linhas quantas colunas enviadas, +1 que a length por causa da linha de botoes
    let headers = document.querySelectorAll(idTable + ' thead tr');
    if (headers.length === 2) {
      let inputFind = '';
      for (let index = 0; index < this.aoColumnsList.length + 1; index++) {
        inputFind = '<input type="text" class="findInput materialStyle" /><span class="materialStyle barra"></span>';
        headers[0].appendChild(document.createElement('th'));
        let headerFind = document.createElement('th');
        headerFind.setAttribute('style', 'padding-top:3px');
        if (index !== this.aoColumnsList.length) {
          if (this.filtersColumns) { // se os filtros das colunas tiverem outro tipo de dados (datas ou select)
            let filterC: FilterColumnTable = this.filtersColumns.find(fc => fc.Index === index); // vai procurar no array o que corresponde a este index
            if (filterC && filterC.Type === FiltersType.Datetime) {
              inputFind = inputFind.replace('class="findInput materialStyle"', 'class="findInput materialStyle datetimeFindInput"'); // acrescenta a classe que mais tarde leva o datepicker
            } else if (filterC && filterC.Type === FiltersType.Select) {
              inputFind = '<select class = "materialStyleFilterCol"><option value=""></option>';
              let options = '';
              filterC.SelectElements.forEach((selectEl: SelectElementData) => {
                options += '<option value="' + selectEl.Value + '">' + selectEl.Text + '</option>';
              });
              inputFind += options + '</select>';
            }
          }
          headerFind.insertAdjacentHTML('beforeend', inputFind);
        }
        headers[1].appendChild(headerFind);
      }
    }

    if (this.filters) {
      let divContainer = document.createElement('div');
      divContainer.className = 'col-sm-12';

      let divPanel = document.createElement('div');
      divPanel.className = 'panel panel-default';

      let divHeading = document.createElement('div');
      divHeading.className = 'panel-heading clearfix pointer';
      divHeading.setAttribute('data-toggle', 'collapse');
      divHeading.setAttribute('data-target', '#FilterSearchModalChoose');
      divHeading.insertAdjacentHTML('beforeend', '<h5 class="panel-title"><i class="fas fa-filter"></i><strong id="filterTitle"></strong><i class="collapse-icon fas fa-caret-down"></i></h5>');

      let divBody = document.createElement('div');
      divBody.id = 'FilterSearchModalChoose';
      divBody.setAttribute('style', 'padding: 5px 0 0;');
      divBody.className = 'panel-body no-horizontal-padding collapse';
      if (this.filterExpanded) {
        divBody.className += ' in';
      }

      let divFormGroup = document.createElement('div');
      divFormGroup.className = 'form-group';

      let divContainerInputs = document.createElement('div');
      divContainerInputs.className = 'col-lg-11 col-md-11 col-sm-12';

      let arrayDate = [];

      let barra = document.createElement('span');
      barra.className = 'materialStyle barra';

      let linhas = 0;
      this.filters.forEach((filter: Filter) => {
        // criar a label
        let label = document.createElement('label');
        label.innerHTML = filter.Name;
        // criar div onde a label e o input vao inserir
        let divFormLine = document.createElement('div');
        // se for um select
        if (filter.Type === FiltersType.Select) {
          // criar elemento select
          let select = document.createElement('select');
          select.id = filter.ID;
          select.className = 'form-control materialStyle';
          divFormLine.className = 'form-inline';
          // criar option vazia
          let option = document.createElement('option');
          option.text = '';
          option.value = '';
          select.appendChild(option);
          // criar as restantes option
          filter.SelectElements.forEach((selectEl: SelectElementData) => {
            option = document.createElement('option');
            option.text = selectEl.Text;
            option.value = selectEl.Value;

            select.appendChild(option);
          });
          label.className = 'materialStyleDiv';

          let divEx = document.createElement('div');
          divEx.className = 'col-lg-11 col-md-11 col-sm-12';
          divEx.setAttribute('style', 'display:flex; height:24px');
          divFormLine.appendChild(divEx);
          divEx.appendChild(label);
          divEx.appendChild(select);

          linhas = linhas + 12;
          // valor inicial
          if (filter.InitialValue) {
            select.value = <string>filter.InitialValue;
          }

        } else {
          // criar o input
          let input = document.createElement('input');
          input.id = filter.ID;
          label.htmlFor = filter.ID;
          if (filter.Type === FiltersType.Boolean) {
            // é um checkbox, tem o div tem primeiro o input e depois a label
            divFormLine.className = 'md-checkbox';
            input.type = 'checkbox';
            // label.setAttribute('style', 'font-weight: bold');
            divFormLine.appendChild(input);
            divFormLine.appendChild(label);
            if (filter.InitialValue) {
              input.checked = <boolean>filter.InitialValue;
            }
          } else {
            // nao é um checkbox, a label é inserida primeiro que o input
            input.className = 'form-control materialStyle';
            divFormLine.className = 'form-inline materialStyle';
            if (filter.Type === FiltersType.Datetime) {
              // é um datetime, vai adicionar o id a um array
              arrayDate.push(filter.ID);
              // se é datetime,
              input.setAttribute('data-date-container', '#choose-modal');
            }
            input.addEventListener('focus', function () {
              if (!this.parentElement.classList.contains('withText')) {
                this.parentElement.classList.add('withText');
              }
            });
            input.addEventListener('blur', function () {
              if ((<HTMLInputElement>this).value !== '') {
                document.querySelector('label[for="' + this.id + '"]').className = 'materialStyle withText';
              } else {
                document.querySelector('label[for="' + this.id + '"]').className = 'materialStyle';
                this.parentElement.classList.remove('withText');
              }
            });
            label.className += ' materialStyle';
            divFormLine.appendChild(input);
            divFormLine.appendChild(barra);
            divFormLine.appendChild(label);

            if (filter.InitialValue) {
              input.value = <string>filter.InitialValue;
              input.parentElement.classList.add('withText');
              label.classList.add('withText');
            }
          }
          linhas = linhas + 6;

        }

        divContainerInputs.appendChild(divFormLine);
        // if (Math.abs(linhas % 12) === 0) {
        //   // terminou uma linha (necessario por causa de mexer nas margens quando tem valor no input)
        //   divBody.appendChild(divFormGroup);
        // }
      });
      // adicionar botao de procura
      let button = document.createElement('button');
      button.className = 'btn btn-primary btn-flat pull-right';
      button.id = idTable.replace('#', '') + 'btn_search_filter';
      button.insertAdjacentHTML('beforeend', '<i class="fas fa-search" aria-hidden="true"></i>');
      let that = this;
      button.addEventListener('click', function _filterData() {
        if (that.list) { // os dados estao num array
          let listFilter = that.list;
          that.filters.forEach((filterData: Filter) => {

            let inputData = filterData.Type === FiltersType.Boolean ?
              (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
              (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;
            listFilter = listFilter.filter(l => l[filterData.IDToFind] === inputData);
          });
          that.table.clear(); // limpar a tabela
          that.table.rows.add(listFilter).draw(); //
        } else { // os dados são para ir buscar no servidor
          let result = {};
          that.filters.forEach((filterData: Filter) => {
            let inputData = filterData.Type === FiltersType.Boolean ?
              (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
              (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;
            result[filterData.IDToFind] = inputData;
          });
          that.table.reloadTableParameters(result);
          // Functions.datatables_reload(idTable, null);
        }

      });

      let divContainerButton = document.createElement('div');
      divContainerButton.className = 'col-lg-1 col-md-1 col-sm-12 pull-right';
      divContainerButton.appendChild(button);
      // divContainer2.appendChild(divContainerButton);

      divFormGroup.appendChild(divContainerInputs);
      divFormGroup.appendChild(divContainerButton);
      divBody.appendChild(divFormGroup);

      divPanel.appendChild(divHeading);
      divPanel.appendChild(divBody);
      divContainer.appendChild(divPanel);
      document.querySelector(idTable).parentElement.parentElement.insertBefore(divContainer, document.querySelector(idTable).parentElement);

      // se existem inputs do formato data
      if (arrayDate.length > 0) {
        arrayDate.forEach(inputID => {
          Functions.dataTimePicker('#' + inputID, this._translateValueService.translateLanguage, 1, DATE_FORMAT, 0, false, this._translateValueService.datepickerTranslates, document.getElementById(inputID).getAttribute('disabled') !== '');
        });
      }
    }

    this._translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'BUTTON.UPDATE', 'BUTTON.ALL', 'SERVER_TIMEOUT',
      'SERVER_ERROR', 'FILTER', 'FILTERS'])
      .toPromise().then((response: string[]) => {

        if (this.filters) {
          document.getElementById('filterTitle').innerHTML = response['FILTERS'];
        }

        let that = this;

        let buttons = function (id: string): string {
          let checked = (that.checkID && id.toString() === that.checkID.toString()) ? 'checked' : '';
          let chooseThis = '<div style="text-align:center" class="md-radio btnTable-choose"><input id="' + id + '" type="radio" name="radioLocation" ' + checked + '><label for="' + id + '" class="" ></label></div>';
          return chooseThis;
        };
        // adicionar a ultima coluna com o checkbox para selecionar linha
        this.aoColumnsList.push({ 'data': that.columnID, 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data); } });
        this.columnDefsList.push({ 'targets': [-1], 'orderable': false });
        this.columnDefsList.push({ 'targets': [-1], 'width': '110px' });

        let translates = {
          clear: response['BUTTON.CLEAR'],
          update: response['BUTTON.UPDATE'],
          all: response['BUTTON.ALL'],
          serverTimeout: response['SERVER_TIMEOUT'],
          serverError: response['SERVER_ERROR'],
          search: response['FILTER']
        };

        if (this.list) { // envia já a lista de dados
          let listFilter = that.list;
          if (this.filters) {
            that.filters.forEach((filterData: Filter) => {
              let inputData = filterData.Type === FiltersType.Boolean ?
                (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
                (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;
              listFilter = listFilter.filter(l => l[filterData.IDToFind] === inputData);
            });
          }
          this.table = Functions.datatablesWithDataSet(idTable, listFilter, translates, this.aoColumnsList, this.columnDefsList,
            ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', this.datatableParameters.order[0][0], true, false, true, false, this.datatableParameters.order[0][1]); // ultimo campo para alterar DOM

        } else { // tem de ir fazer o pedido ao controlador para receber os dados
          let result = null;
          if (this.filters) { // se tiver inputs de filtros
            result = {};
            that.filters.forEach((filterData: Filter) => {
              let inputData = filterData.Type === FiltersType.Boolean ?
                (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
                (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;
              result[filterData.IDToFind] = inputData;
            });
          }
          if (this.actionParams) {
            if (!result) {
              result = {};
            }
            that.actionParams.forEach((filterData: Filter) => {
              let inputData = filterData.InitialValue;
              result[filterData.IDToFind] = inputData;
            });
          }
          if(this.filterDate && this.form.valid){
            if (!result) {
              result = {};
            }
            result.startDate = this.form.get("dataInicio").value;
            result.endDate = this.form.get("dataFim").value;
          }
          this.table = Functions.datatables(idTable, SERVICE_URL + this.actionTable, translates, this.aoColumnsList, this.columnDefsList,
            ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 'POST', result, true, false, false, false, this.datatableParameters);
        }

        this.table.on('draw', function () {
          if (!that.list && that.table.ajax.json()) {
            let autenticado = that.table.ajax.json().IsAuthenticated;
            if (!autenticado) {
              // se tiver perdido a sessao tem de fechar a modal
              that.dialogRef.close(null);
            }
          }

          Functions.dataTimePicker('th>.datetimeFindInput', that._translateValueService.translateLanguage, 1, DATE_FORMAT, 0, false, that._translateValueService.datepickerTranslates, true);

          let prevDOM = document.getElementById('tableChoose_previous');
          let nextDOM = document.getElementById('tableChoose_next');
          if (prevDOM && nextDOM) {
            prevDOM.children[0].innerHTML = '<';
            nextDOM.children[0].innerHTML = '>';
          }
          let lengthDOM = document.getElementById('tableChoose_length');
          let infoDOM = document.getElementById('tableChoose_info');
          if (lengthDOM && infoDOM) {
            // diminuir a letra do "mostrar[]registos" e do "a mostrar... de .."
            lengthDOM.style.fontSize = '10px';
            infoDOM.style.fontSize = '10px';

            // retirar palavra "a mostrar"
            lengthDOM.children[0].childNodes[0].nodeValue = '';
          }

          let btnsChoose = document.getElementsByClassName('btnTable-choose');
          for (let chooseThis of Array.from(btnsChoose)) {
            chooseThis.addEventListener('click', function (ev: any) {
              let linha = this.parentNode.parentNode;
              let row = that.table.row(linha);
              let rowData = row.data();
              let rowIndex = row.index();
              if (ev.target.tagName === 'LABEL') { // necessario por o check vai clicar tambem no input
                that.selecItem(rowData[that.columnID], rowIndex, linha);
              }
            });
          }
          // quando faz click no botao de procura dos filtros
          let btnFilter = document.getElementById(idTable.replace('#', '') + 'btn_search');
          if (btnFilter) {
            btnFilter.addEventListener('click', function _filterData() {
              if (that.filters) { // se tiver inputs de filtros
                if (that.list) { // os dados estao num array
                  let listFilter = that.list;
                  that.filters.forEach((filterData: Filter) => {

                    let inputData = filterData.Type === FiltersType.Boolean ?
                      (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
                      (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;
                    listFilter = listFilter.filter(l => l[filterData.IDToFind] === inputData);
                  });
                  that.table.clear(); // limpar a tabela
                  that.table.rows.add(listFilter).draw(); //
                } else { // os dados são para ir buscar no servidor
                  let result = {};
                  that.filters.forEach((filterData: Filter) => {
                    let inputData = filterData.Type === FiltersType.Boolean ?
                      (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
                      (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;
                    result[filterData.IDToFind] = inputData;
                  });
                  that.table.reloadTableParameters(result);
                  // Functions.datatables_reload(idTable, null);
                }
              }
              // tem de se remover porque se nao duplica o evento
              this.removeEventListener('click', _filterData);
            });
          }

        });
      });
  }

  selecItem(idSelecionado: number, index: number, linha: any) {
    let rows = document.getElementsByClassName('btnTable-choose'); // todos os 'a' dos botoes
    if (rows.length > 0) {
      // retirar linha que possa estar selecionada, porque só pode selecionar uma
      [].forEach.call(rows, function (el: any) {
        let row = el.parentNode.parentNode; // linha "mãe" do botão
        row.classList.remove('td-selected');
      });
    }
    linha.classList.add('td-selected');
    this.idSelec = idSelecionado;
    if (!this.list) { // se os dados forem do controlador
      this.indexSelec = index; // vai colocar o index
    }

    this.save(); // se for para fechar a modal logo que escolhe uma opção
  }

  filterValues() {
    // ir buscar, caso existam os valores dos filtros alem dos filtros das coluna da datatable
    let filterValues = null;
    if (this.filters) { // se tiver inputs de filtros
      this.filters.forEach((filterData: Filter) => {
        let inputData = filterData.Type === FiltersType.Boolean ?
          (<HTMLInputElement>document.querySelector('#' + filterData.ID)).checked :
          (<HTMLInputElement>document.querySelector('#' + filterData.ID)).value;

        if ((filterData.Type === FiltersType.Boolean && inputData) || (filterData.Type !== FiltersType.Boolean && inputData.toString().length > 0)) {
          // se tiver valor é que vai adicionar ao objecto de filtros
          if (!filterValues) { // se ainda estiver a null
            filterValues = {};
          }
          filterValues[filterData.IDToFind] = inputData;
        }
      });
    }
    return filterValues;

  }

  onClickFilterDates()
  {
    let result = null
    if (this.actionParams) {
      if (!result) {
        result = {};
      }
      this.actionParams.forEach((filterData: Filter) => {
        let inputData = filterData.InitialValue;
        result[filterData.IDToFind] = inputData;
      });
    }
    if(this.filterDate && this.form.valid){
      if (!result) {
        result = {};
      }
      result.startDate = this.form.get("dataInicio").value;
      result.endDate = this.form.get("dataFim").value;
    }
    if(result)
      this.table.reloadTableParameters(result);
  }

  save() {
    let filterValues = this.filterValues();
    if (this.list) { // se for lista apenas vai devolver o id do selecionado
      if (this.idSelec != null || typeof this.idSelec === 'undefined') {
        // neste momento os valores dos filtros ainda nao sao necessarios por isso nao esta ser utilizado
        // let returnV = filterValues ? new ChooseModalReturn(this.idSelec, filterValues) : this.idSelec;
        let returnV = this.idSelec;
        this.dialogRef.close(returnV);
      }
    } else { // se tiver a ir buscar os dados da tabela ao controlador, vai enviar todos os dados da linha
      // validar se for selecionado algum, senao se fizer guardar sem selecionar, vai carregar o index 0 da lista
      if (typeof (this.indexSelec) !== 'undefined' && this.indexSelec >= 0) {
        // let returnV = filterValues ? new ChooseModalReturn(this.table.row(this.indexSelec).data(), filterValues) : this.table.row(this.indexSelec).data();

        let returnV = this.table.row(this.indexSelec).data();
        if (filterValues) {
          returnV.FiltersValues = filterValues;
        }
        if(this.filterDate && this.form.valid)
        {
          returnV.startDate = this.form.get("dataInicio").value;
          returnV.endDate = this.form.get("dataFim").value;
        }
        this.dialogRef.close(returnV);
      } else {
        // é para ignorar e não fazer nada
        // this.dialogRef.close(null);
      }
    }
  }

  cancel() {
    let returnV = this.filterValues() ? { FiltersValues: this.filterValues() } : null;
    this.dialogRef.close(returnV);
  }

  removerSeleccao() {
    // se permitir que nao esteja nenhum selecionado, vai apagar o que estiver selecionado
    if (this.allowNone) {
      this.checkID = null;
      this.idSelec = null;
      //vai enviar para o pai o valor -1 para indicar que não foi nada selecionado
      this.dialogRef.close(-1);
      // so ia precisar desta parte se não fosse fechar a janela e quissesse mostrar que nenhum fica selecionado
      // let radios = document.querySelectorAll('[name="radioLocation"]');
      // for (let i = 0; i < radios.length; i++) {
      //   let radio = <HTMLInputElement>radios[i];
      //   if (radio.checked) {
      //     radio.checked = false;
      //   }
      // }
    }

  }
}
