import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, NgZone, OnDestroy } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorTreatmentFunctions } from '../../modules/treatments.module';

// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';
import { TranslateValueService } from '../../services/translate-value.service';
import { CompanyDeliveryScheduleService } from '../../services/companyDeliverySchedule.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CommonService } from '../../services/common.service';

// ***__***_________  MODELOS _________ ***__***
import { OrderDetail, OrderTax, OrderAllowance, OrderDiscount, OrderNote, OrderReference, TransformModel, OrderDocumentType, OrderDocumentState } from '../../models/order';
import { CostCenterConfig, TransformModelCC } from '../../models/costCenterConfig';
import { Dropdown } from '../../models/dropdown';
import { ModelValidators } from '../../models/validators/validators';
import { ReturnStatusHtml } from '../../models/returnStatus';
import { ValueAddedTax } from '../../models/value-added-tax';

// ***__***_________  MODALS _________ ***__***
import { ChooseModalComponent } from '../shared/choose-modal/choose-modal.component';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { CURRENCY_DECIMAL, CURRENCY_SYMBOL, UNITARY_PRICE_DECIMAL, STEP_MIN, DATE_HOUR_FORMAT, QUANTITY_DECIMAL } from '../../constants/global';

import { DistributionModalComponent } from '../shared/distribution-modal/distribution-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { DistributionModalParam } from 'src/app/models/distribution-modal-param';
import { CADistributionModalComponent } from '../shared/distribution-modal/ca/ca-distribution-modal.component';
import { AccountingConfig, TransformModelAC } from 'src/app/models/accountingConfig';
import { TipologiaProdutoServico } from 'src/app/models/tipologia-produto-servico';
import { OrderService } from 'src/app/services/order.service';
import { GenericFieldExtension } from 'src/app/models/generic-field-extension';
import { ProductService } from 'src/app/services/product.service';
import { Subject } from 'rxjs';
import { CostCenter } from 'src/app/models/costCenter';

declare var Functions: any;

@Component({
  templateUrl: './orderDetails-modal.html'
})
export class OrderDetailModalComponent implements OnInit, OnDestroy {
  collPnPopup1: boolean = true;
  collPnPopup2: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public detailForm: UntypedFormGroup;

  noError: boolean = true;
  submitted: boolean = false;
  formErrors: Array<string> = new Array<string>(); // erros do formulario, array para campos
  formErrorsParam: Array<string> = new Array<string>(); // parametros para passar para a string de erro do campo
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  allowEdit: boolean = false; // se tem permissoes de edicao
  model: OrderDetail = null;
  tranfModelCC = new TransformModelCC(); // necessario para usar as funcoes de configuração de numeros
  tranfModelAC = new TransformModelAC();
  decimalPlaces3: number = 3;

  // arrays para options dos selects
  orderTaxes: Array<any> = new Array<any>();
  orderAllowances: Array<any> = new Array<any>();
  orderDiscounts: Array<any> = new Array<any>();
  valueAddedTaxes: ValueAddedTax[] = [];
  otherReferenceTypes: Dropdown[] = [];

  detailTaxes: Array<any> = new Array<any>();
  detailUnitsG: Array<any> = new Array<any>();
  detailUnitsW: Array<any> = new Array<any>();
  detailUnitsV: Array<any> = new Array<any>();

  listaMoradasLocaisEntrega: Array<any> = new Array<any>();
  allCountries: Array<any> = new Array<any>();
  providers: Array<any> = new Array<any>();

  familiesList: Array<any> = new Array<any>();
  familiesListLevel1: Array<any> = new Array<any>();
  familiesListLevel2: Array<any> = new Array<any>();

  forceEdit: boolean = false;
  showPackagingPanel: boolean = false;
  showTaxesPanel: boolean = false;
  showNotesPanel: boolean = false;
  showAllowancesPanel: boolean = false;
  showDiscountsPanel: boolean = false;
  showReferencesPanel: boolean = false;
  showStatePanel: boolean = false;
  showSupplierPanel: boolean = false;
  showDeliveryPanel: boolean = false;
  productQuantityValidations: boolean;

  showFamily: boolean = false;
  showOrderedQuantityUOMCode: boolean = false;
  showBonusQuantity: boolean = false;
  showBonusQuantityUOM: boolean = false;
  showNetUnitPrice: boolean = false;
  showTotalNetAmountDetail: boolean = false;
  showGrossUnitPrice: boolean = false;
  showTotalGrossAmount: boolean = false;
  //showDistributionButton: boolean = false; //GM 2022-02-24 aparentemente não é usado para nada, passou a ser usado o showDistributionDetails
  showPackQuantityUOM: boolean = false;
  showPackUnitsPerLayer: boolean = false;
  showLatestAcceptableDate: boolean = false;
  showTotalNetAmount: boolean = false;
  disableTaxes: boolean = false;
  showOthersReferencesTypes: boolean = false;
  showTaxTypeCodeOther: boolean = false;
  showShippingDate: boolean = false;
  showPriceUnit: boolean = false;
  hideCodeSupplier: boolean = false;
  hideCodeClient: boolean = false;


  isEditable: boolean; // serve para distinguir entre detalhes purchases / detalhes sales
  formDisabled: boolean; // para saber se os detalhes purchases se podem editar ou sao so de consulta

  familyNameLevel1: string;
  familyNameLevel2: string;

  familiesLv1: Array<any> = new Array<any>();
  familiesLv2: Array<any> = new Array<any>();
  familiesLv3: Array<any> = new Array<any>();

  familyLv1: number;
  familyLv2: number;
  familyLv3: number;

  hasDistribution: boolean;
  saveOnServer: boolean = false;
  context: string = '';
  currentLanguage: string;

  isServiceProvider: boolean;

  // listas a enviar p/ distribuição
  typeList: Array<Dropdown> = new Array<Dropdown>(); // tipo (centro de custo/eixo de analise)
  debitCreditList: Array<Dropdown> = new Array<Dropdown>(); // debito ou credito
  incidenceList: Array<Dropdown> = new Array<Dropdown>(); // listagem de incidencias
  analisysAxesList: Array<any> = new Array<any>(); // listagem de eixos de análise
  divisionKeysList: Array<any> = new Array<any>(); // listagem de chaves de divisão
  allCostCentersList: Array<any> = new Array<any>(); // listagem de todos os centros de custo
  documentType: string = ''; // tipo de documento
  // ** fim listas distribuição
  userCostCentersFiltered: CostCenter[] = [];

  private _cur_symbol: string = CURRENCY_SYMBOL;
  // private _decimalPipe: DecimalPipe;

  useDeliveryDateSchedule: boolean = false;
  minDays: number = 0;
  minHour: number = 0;
  maxHour: number = 23;
  weekDays: Array<boolean> = new Array<boolean>(false, false, false, false, false, false, false);

  deliveryDateRequiredNonSP: boolean = false;
  isRequiredDeliveryPlaceGroupDetailNonSP: boolean = false;
  showDeliveryDateOnBasePanel: boolean = false;

  showDetailDeliveryPanel: boolean = false; // variavel global p/ definir se panel de entrega é para aparecer ou nao

  tranfModel = new TransformModel(); // necessario para usar as funcoes de configuração de hora e numeros

  showReturnedQuantity: boolean = false;
  useAccountingConfig: boolean = false;

  showDistributionExpenseAccount: boolean = true;
  showDistributionInitiativeCode: boolean = true;
  showDistributionActivityCode: boolean = true;
  showDistributionFixedAssetRecordType: boolean = true;
  showDistributionAmortizationBookCode: boolean = true;
  showDistributionVATaccountingGroup: boolean = true;
  showDistributionRefactor: boolean = true;
  showDistributionAccountingType: boolean = true;
  showAccountingVatGroup: boolean = true;
  hideEanCode: boolean = false;

  accountTypeList: Array<Dropdown> = new Array<Dropdown>();

  accountProductTypes: Array<TipologiaProdutoServico> = new Array<TipologiaProdutoServico>();
  accountProductSubTypes: Array<TipologiaProdutoServico> = new Array<TipologiaProdutoServico>();
  vatTreatmentTypes: Array<Dropdown> = new Array<Dropdown>();

  showDistributionDetails: boolean = false;
  getProductServiceType: boolean = false;

  enableSaveButton: boolean = false;

  //setting para colocar uma label específica para o botão da contabilização/distribuição
  distributionButtonLabel: string = 'DISTRIBUTION';

  netUnitPriceLabel: string = 'NET_UNIT_PRICE';
  showDescriptionAsTextArea: boolean = false;

  showProductServiceTypeFields: boolean = false;
  showDistributionVatTreatmentType: boolean = true;
  showDistributionCodMensualizacao: boolean = true;

  useProductCostCenter: boolean = false; //Setting que define se o Campo Opcional 'CostCenter' é utilizado
  useProductReclassificationCode: boolean = false; // Setting que define se o Campo Opcional 'ReclassificationCode' é utilizado
  showCostCenterDetail: boolean = false; //Setting para mostrar custo de centro no detalhe encomenda

  costCenterList: any[] = []; //Armazena os Centros de Custo
  reclassificationCodeList: any[] = []; //Armazena os Códigos de Reclassificação

  costCenter: GenericFieldExtension; //Centro de Custo selecionado
  reclassificationCode: GenericFieldExtension; //Código de Reclassificação selecionado

  supplierIdentifier: string; // Identifier do Fornecedor
  product: any; //Produto associado ao Detalhe de Encomenda
  showAccountingCode: boolean = false;
  userCostCentersList: Array<any> = new Array<any>(); // listagem dos centros de custo
  parentForm: UntypedFormGroup;
  validationMessagesTaxes = {};
  validationMessagesAllowance = {};
  validationMessagesDiscount = {};
  validationMessagesOtherReferences = {};
  validationMessagesNotes = {};
  maxNotesLength: number = 0;

  constructor(public dialogRef: MatDialogRef<OrderDetailModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private zone: NgZone,
    private dialog: MatDialog,
    private translateValueService: TranslateValueService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private companyDeliveryScheduleService: CompanyDeliveryScheduleService,
    private authenticationService: AuthenticationService,
    private _errorTreat: ErrorTreatmentFunctions,
    private commonService: CommonService,
    private productService: ProductService,
    private orderService: OrderService) {

    this.context = data.context;

    this.currentLanguage = translateValueService.translateLanguage;

    this.model = data.detailModel;
    this.detailUnitsG = data.detailUnitsG;
    this.detailUnitsW = data.detailUnitsW;
    this.detailUnitsV = data.detailUnitsV;

    this.showPackagingPanel = data.showPackagingPanel;
    this.showTaxesPanel = data.showTaxesPanel;
    this.showNotesPanel = data.showNotesPanel;
    this.showAllowancesPanel = data.showAllowancesPanel;
    this.showDiscountsPanel = data.showDiscountsPanel;
    this.showReferencesPanel = data.showReferencesPanel;
    this.showStatePanel = data.showStatePanel;
    this.showTaxTypeCodeOther = data.showTaxTypeCodeOther;

    this.showFamily = data.showFamily;
    this.showOrderedQuantityUOMCode = data.showOrderedQuantityUOMCode;
    this.showBonusQuantity = data.showBonusQuantity;
    this.showBonusQuantityUOM = data.showBonusQuantityUOM;
    this.showNetUnitPrice = data.showNetUnitPrice;
    this.showTotalNetAmountDetail = data.showTotalNetAmountDetail;
    this.showGrossUnitPrice = data.showGrossUnitPrice;
    this.showTotalGrossAmount = data.showTotalGrossAmount;
    this.showTotalNetAmount = data.showTotalNetAmount;
    this.showLatestAcceptableDate = data.showLatestAcceptableDate;
    this.showOthersReferencesTypes = data.showOthersReferencesTypes;
    this.hideEanCode = data.hideEanCode;

    this.familiesList = data.familiesList;
    this.useAccountingConfig = data.useAccountingConfig;

    //Configurações das colunas do painel de contabilização
    this.showDistributionExpenseAccount = data.showDistributionExpenseAccount;
    this.showDistributionInitiativeCode = data.showDistributionInitiativeCode;
    this.showDistributionActivityCode = data.showDistributionActivityCode;
    this.showDistributionFixedAssetRecordType = data.showDistributionFixedAssetRecordType;
    this.showDistributionAmortizationBookCode = data.showDistributionAmortizationBookCode;
    this.showDistributionVATaccountingGroup = data.showDistributionVATaccountingGroup;
    this.showDistributionRefactor = data.showDistributionRefactor;
    this.showDistributionAccountingType = data.showDistributionAccountingType;
    this.showAccountingVatGroup = data.showAccountingVatGroup;
    this.showDistributionVatTreatmentType = data.showDistributionVatTreatmentType;
    this.showDistributionCodMensualizacao = data.showDistributionCodMensualizacao;

    //Listas para o painel da contabilização
    this.accountTypeList = data.accountTypeList;

    //Listas para o Tipo de Produto/Serviço
    this.accountProductTypes = data.accountProductTypes;
    this.accountProductSubTypes = data.accountProductSubTypes;
    this.vatTreatmentTypes = data.vatTreatmentTypes;

    //Para saber se é para mostar os campos relativos ao tipo Produto/Serviço
    this.getProductServiceType = data.getProductServiceType;

    this.showDistributionDetails = data.showDistributionDetails;

    this.showShippingDate = data.showShippingDate;

    this.showPriceUnit = data.showPriceUnit;
    this.hideCodeSupplier = data.hideCodeSupplier;
    this.hideCodeClient = data.hideCodeClient;

    //Para poder (ou não) editar a contabilização
    this.enableSaveButton = data.enableSaveButton;

    this.distributionButtonLabel = data.distributionButtonLabel ? data.distributionButtonLabel : this.distributionButtonLabel;

    this.netUnitPriceLabel = data.netUnitPriceLabel;
    this.showDescriptionAsTextArea = data.showDescriptionAsTextArea;
    this.parentForm = data.parentForm;

    if ((this.orderService.showProcess && this.orderService.showFraction) || this.orderService.showRequestData_Sale)
    {
      this.documentType = data.documentType;
    }

    // Verifica se é para mostrar os tipos de outras referências
    if (this.showOthersReferencesTypes) {
      // Tipos de Outras Referências
      this.commonService.otherReferenceTypes.pipe(takeUntil(this.destroy$)).subscribe(response => this.otherReferenceTypes = response);
    }

    if (this.showTaxesPanel) {
      // Listas de dados
      this.commonService.valueAddedTaxes.subscribe((response: Array<ValueAddedTax>) => this.valueAddedTaxes = response);
    }

    this.showAccountingCode = data.showAccountingCode;
    this.forceEdit = data.forceEdit;

    if (data.context === 'encomendaCompra') { // encomenda de compra (as editaveis)

      this.listaMoradasLocaisEntrega = data.locations;
      this.allCountries = data.allCountries;
      this.providers = data.providers == null ? [] : data.providers;

      this.showDeliveryPanel = data.showDeliveryPanel;
      this.showSupplierPanel = data.showSupplierPanel;

      this.productQuantityValidations = data.productQuantityValidations;
      //this.showDistributionButton = data.showDistributionButton;
      this.showPackQuantityUOM = data.showPackQuantityUOM;
      this.showPackUnitsPerLayer = data.showPackUnitsPerLayer;

      this.disableTaxes = data.disableTaxes;
      this.showDeliveryDateOnBasePanel = data.showDeliveryDateOnBasePanel;

      this.isServiceProvider = data.isServiceProvider;

      this.useDeliveryDateSchedule = data.useDeliveryDateSchedule;
      this.deliveryDateRequiredNonSP = data.deliveryDateRequiredNonSP;
      this.isRequiredDeliveryPlaceGroupDetailNonSP = data.isRequiredDeliveryPlaceGroupDetailNonS;
      this.maxNotesLength = data.maxNotesLengh;
      
      if(this.showCostCenterDetail)
      {
        this.costCenterList = data.costCenterList;
      }

      if (this.useAccountingConfig) {
        if (this.model && this.model.AccountingConfigList && this.model.AccountingConfigList.length > 0) {
          this.hasDistribution = true;
        }
      } else {
        if (this.model && this.model.CostCentersConfig && this.model.CostCentersConfig.length > 0) {
          this.hasDistribution = true;
        }
      }

      this.showReturnedQuantity = data.showReturnedQuantity;

    } else if (data.context === 'requisicao') { // para alterar o nome de quantidade encomendada para quantidade requisitada

      this.listaMoradasLocaisEntrega = data.locations;
      this.allCountries = data.allCountries;
      this.providers = data.providers == null ? [] : data.providers;

      this.showDeliveryPanel = data.showDeliveryPanel == null ? true : data.showDeliveryPanel;
      this.showSupplierPanel = data.showSupplierPanel == null ? false : data.showSupplierPanel;
      //this.showDistributionButton = data.showDistributionButton;
      this.showPackQuantityUOM = data.showPackQuantityUOM;
      this.showPackUnitsPerLayer = data.showPackUnitsPerLayer;
      this.maxNotesLength = data.maxNotesLengh;


      if ((this.model && this.model.CostCentersConfig && this.model.CostCentersConfig.length > 0)
        || (this.detailForm && this.detailForm.controls != null && this.detailForm.controls['CostCentersConfig'] != null
          && this.detailForm.controls['CostCentersConfig'].value.length > 0)) {
        this.hasDistribution = true;
      }

    } else {
      // encomenda de venda
      this.isEditable = false;
      if(this.forceEdit)this.isEditable = this.forceEdit;
      this.showDeliveryPanel = data.showDeliveryPanel;

      this.showDeliveryDateOnBasePanel = data.showDeliveryDateOnBasePanel;

      this.disableTaxes = data.disableTaxes;

      // fazer o form
      let noteValue: string = '';
      let otherNotes = [];
      if (this.model && this.model.Notes && this.model.Notes.length > 0) {
        if (this.model.Notes.find((x: any) => x.NoteType === 'PortalObs')) {
          noteValue = this.model.Notes.find((x: any) => x.NoteType === 'PortalObs').NoteValue;
        }
        this.model.Notes.forEach(element => {
          if (element.NoteType !== 'PortalObs') {
            otherNotes.push(element);
          }
        });
      }

      let totalNetAmountWithTaxes = 0;
      if (this.showTaxesPanel) {
        totalNetAmountWithTaxes = this.model.Taxes.reduce((acc, tax) => {
          if (tax.TaxRate)
            return acc + this.getPercentageValue(this.model.TotalNetAmount, tax.TaxRate)

          if (tax.TaxAmount)
            return acc + tax.TaxAmount;

          return acc;
        }, this.model.TotalNetAmount)
      }

      this.detailForm = this.formBuilder.group({
        'ID': [this.model.ID],
        // Identificação da linha
        'LineNumber': [this.model.LineNumber],
        'FamilyID': [this.model.FamilyID],
        'FamilyLv1': [null],
        'FamilyLv2': [null],
        'FamilyLv3': [null],
        'BuyerProductCode': [this.model.BuyerProductCode],
        'SupplierProductCode': [this.model.SupplierProductCode],
        'StandardProductCode': [this.model.StandardProductCode],
        'ProductDescription': [this.model.ProductDescription],
        'ProductID': [this.model.ProductID],
        // Quantidades e preços
        'OrderedQuantity': [this.model.OrderedQuantity_form],


        'OrderedQuantityUOMCode': [this.model.OrderedQuantityUOMCode],
        'OrderedQuantityUOMCodeOther': [this.model.OrderedQuantityUOMCodeOther],
        'BonusQuantity': [this.model.BonusQuantity_form],
        'BonusQuantityUOMCode': [this.model.BonusQuantityUOMCode],

        'NetUnitPrice': [this.model.NetUnitPrice_form],
        'GrossUnitPrice': [this.model.GrossUnitPrice_form],
        'TotalNetAmount': [this.model.TotalNetAmount_form],
        'TotalGrossAmount': [this.model.TotalGrossAmount_form],

        // Local de entrega por linha
        'DeliverLocationID': [this.model.DeliverLocationID],
        'DeliveryLocationIdentifier': [this.model.DeliveryLocationIdentifier],
        'DeliveryLocationName': [this.model.DeliveryLocationName],
        'DeliveryLocationStreet': [this.model.DeliveryLocationStreet],
        'DeliveryLocationPostalCode': [this.model.DeliveryLocationPostalCode],
        'DeliveryLocationCity': [this.model.DeliveryLocationCity],
        'DeliveryLocationCountryCode': [this.model.DeliveryLocationCountryCode],
        'DeliveryEarliestAcceptableDate': [this.model.DeliveryEarliestAcceptableDate],
        'DeliveryLatestAcceptableDate': [this.model.DeliveryLatestAcceptableDate],
        'OrderedBy': [this.model.OrderedBy],

        // Embalagens
        'PackQuantity': [this.model.PackQuantity_form],
        'PackQuantityUOM': [this.model.PackQuantityUOM],
        'PackSize': [this.model.PackSize_form],
        'PackUnitsPerLayer': [this.model.PackUnitsPerLayer_form],
        'PackAverageGrossWeight': [this.model.PackAverageGrossWeight_form],

        // Taxas
        'Taxes': this.formBuilder.array([]),
        // Abonos
        'Allowances': this.formBuilder.array([]),
        // Descontos
        'Discounts': this.formBuilder.array([]),
        // Referências
        'OtherReferences': this.formBuilder.array([]),
        // Observações
        'Notes': this.formBuilder.array([]),
        // Variável que espera no servidor para transformar em NotesCSV ****************************
        'NoteToShow': [noteValue],
        'OtherNotes': this.initOtherNotes(otherNotes),
        // Estado
        'LineState': [this.model.LineState],
        'RevisedQuantity': [this.model.RevisedQuantity_form],
        'DespatchedQuantity': [this.model.DespatchedQuantity_form],
        'SatisfiedQuantity': [this.model.SatisfiedQuantity_form],
        'ReceivedQuantity': [this.model.ReceivedQuantity_form],
        'InvoiceToQuantity': [this.model.InvoiceToQuantity_form],
        'ReturnedQuantity': [this.model.ReturnedQuantity_form],

        'Quantity': [this.model.Quantity_form],

        // P/a distribuição
        'CostCentersConfig': this.formBuilder.array([]),
        // 'ID_unic': [this.idUnic],
        'OrderDetailID': [this.model.OrderDetailID],
        'LineType': [this.model.LineType],
        'LineTypeOther': [this.model.LineTypeOther],
        'BuyersGlobalOrderNumber': [this.model.BuyersGlobalOrderNumber],
        'BuyersGlobalOrderType': [this.model.BuyersGlobalOrderType],
        'MasterOrderDetailID': [this.model.MasterOrderDetailID],
        'ChildCreatedBy': [this.model.ChildCreatedBy],
        'CostCenterID': [this.model.CostCenterID],
        'ProductPackID': [this.model.ProductPackID],
        'PriceListProductStepID': [this.model.PriceListProductStepID],
        'UnitAmountMaxDiference': [this.model.UnitAmountMaxDiference],
        'PercentageMaxDiference': [this.model.PercentageMaxDiference],
        'TotalWeight': [this.model.TotalWeight],
        'TotalVolume': [this.model.TotalVolume],
        'HandlerUserID': [this.model.HandlerUserID],
        'AccountProductType': [this.model.TipologiaProdutoServico ? this.model.TipologiaProdutoServico.ParentID : null],
        'AccountProductSubtype': [this.model.TipologiaProdutoServico ? this.model.TipologiaProdutoServico.ID : null],
        'VatTreatmentType': [this.model.TipologiaProdutoServico ? this.model.TipologiaProdutoServico.TipoTratamentoIva : null],
        'TipologiaProdutoServicoID': [this.model.TipologiaProdutoServicoID ? this.model.TipologiaProdutoServicoID : null],
        'PriceUnit': [this.model.PriceUnit],
        'AccountingCode': [this.model.AccountingCode],
        'CostCenterName': [this.model.CostCenterName],

        'TotalNetAmountWithTaxes': [totalNetAmountWithTaxes]
      });

      this.addTax(false);
      this.addAllowance(false);
      this.addDiscount(false);
      this.addOtherReference(false);
      this.addNote(false);

      // so vai buscar os extended fields caso o setting esteja a true e o tipo de documento for (PropertiesOrder ou NCPropOrder) e o contexto for do tipo compra
      if((this.documentType === OrderDocumentType.PROPERTIES_ORDER || OrderDocumentType.ORDER_CREDIT_NOTICE_PROPERTIES) && this.context != 'encomendaCompra'){
        // se for null instanciar
        if(!this.model.FieldsExtensions)
        {
          this.model.FieldsExtensions = new Array<GenericFieldExtension>();
        }
        if(this.orderService.showProcess) {
          // campo de processo
          this.commonService.getGenericFieldExtension('OrderDetail', this.model.ID, 'Process').pipe(takeUntil(this.destroy$)).subscribe(response => {
            let fieldExtension: GenericFieldExtension = response.ReturnStatus.ReturnObject;
            if(fieldExtension){
              this.model.FieldsExtensions.push(fieldExtension);
              // adicionar o formControl com os valores
              this.detailForm.addControl('Process', new UntypedFormControl({ value: fieldExtension.FieldValue, disabled: true }, Validators.compose([ModelValidators.lengthVal({ max: 30 }), Validators.compose([Validators.required])])));
            }
          });
        }
        
        if(this.orderService.showFraction) {
          // campo de fração
          this.commonService.getGenericFieldExtension('OrderDetail', this.model.ID, 'Fraction').pipe(takeUntil(this.destroy$)).subscribe(response => {
            let fieldExtension: GenericFieldExtension = response.ReturnStatus.ReturnObject;
            if(fieldExtension){
              this.model.FieldsExtensions.push(fieldExtension);
              this.detailForm.addControl('Fraction', new UntypedFormControl({ value: fieldExtension.FieldValue, disabled: true }, Validators.compose([ModelValidators.lengthVal({ max: 30 })])));
            }
          });
        } 
      }

      // validar se tem o setting se e do tipo request e se esta no contexto venda
      // como no contexto venda nao ha form tem de se criar aqui os form controls
      if (this.orderService.showRequestData_Sale 
        && this.documentType !== 'Request' 
        && this.model.RequestDocumentNumber 
        && this.model.RequestLineNumber)
      {
        this.detailForm.addControl('RequestDocumentNumber', new UntypedFormControl({ value: this.model.RequestDocumentNumber, disabled: true }));
        this.detailForm.addControl('RequestLineNumber', new UntypedFormControl({ value: this.model.RequestLineNumber, disabled: true }));
      }

      this.detailForm.disable();
      this.formDisabled = true;
    }

    if (this.showDeliveryPanel) {
      this.showDetailDeliveryPanel = true;
    }



    if (data.context === 'encomendaCompra' || data.context === 'requisicao') {
      this.isEditable = true;
      this.allowEdit = data.allowEdit;
      this.typeList = data.typeList;
      this.debitCreditList = data.debitCreditList;
      this.incidenceList = data.incidenceList;
      this.analisysAxesList = data.analisysAxesList;
      this.divisionKeysList = data.divisionKeysList;
      this.allCostCentersList = data.allCostCentersList;
      this.documentType = data.documentType == null ? '' : data.documentType;
      this.saveOnServer = data.saveOnServer;
      this.formDisabled = data.formDisabled;

      this.validationMessages = data.validationMessagesDetails;
      this.detailForm = data.detailForm;
      this.userCostCentersList = data.userCostCentersList;
    }
    else{
      if(this.forceEdit)this.isEditable = this.forceEdit;
      if(data.allowEdit != false && this.forceEdit){
        this.allowEdit = this.forceEdit;
        this.formDisabled = false;
        this.detailForm.enable();
      }
    }

    //Se as taxas são para apresentar preciso dos tipos de taxas
    if (this.showTaxesPanel) {
      this.detailTaxes = data.detailTaxes;
    }

    this.getFamilyInformation();

    if (this.showFamily && this.familiesLv1 != null && this.familiesLv1.length > 0) { // verificar se existe list lvl 1
      this.detailForm.controls['FamilyLv1'].setValidators([Validators.required]); // add validador
      this.detailForm.controls['FamilyLv1'].updateValueAndValidity(); // atualizar validador
    }

    if(this.showNotesPanel && this.maxNotesLength > 0)
    {
      this.detailForm.controls['NoteToShow'].setValidators([ModelValidators.lengthVal({max: this.maxNotesLength})]); // add validador
      this.detailForm.controls['NoteToShow'].updateValueAndValidity(); // atualizar validador
    }

    this.useProductCostCenter = data.useProductCostCenter;
    this.userCostCentersFiltered = data.userCostCentersFiltered;
    this.showCostCenterDetail = data.showCostCenterDetail;
    this.useProductReclassificationCode = data.useProductReclassificationCode;

    this.supplierIdentifier = data.supplierIdentifier;

    this.validationMessagesTaxes = {
      
    // 'AmountCurrencyCode': '',
    // 'AmountCurrencyCodeOther': '',
    'TaxRate': {
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    },
    'TaxAmount': {
      'numberVal': 'NUMBER_INVALID',
      'numberMin': 'NUMBER_MIN_ERROR',
      'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
    },
    // 'TaxTypeCode': '',
    // 'TaxTypeCodeOther': ''
  
    }

    this.validationMessagesAllowance = {
      'Percentage ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'Amount ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      }
    }

    this.validationMessagesDiscount = {
      'Percentage ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'Amount ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      }
    }

    this.validationMessagesOtherReferences = {
      'ReferenceDate ': {
        'invalidDate': 'INVALID_DATE'
      },
    }

    this.validationMessagesNotes = {}

    
  }

  ngOnInit() {
    let that = this;

    if (this.isEditable) {
      // let isReadonly = this.detailForm.get('id').disabled;

      this.detailForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDetails(value);
      });

      this.onValueChangedDetails(); // p/ 1ªx
      this.zone.onStable.pipe(first()).subscribe(() => {
        // that.addTax(false);
        // that.addAllowance(false);
        // that.addDiscount(false);
        // that.addOtherReference(false);
        // that.addNote(false);

        if (that.formDisabled) {
          that.detailForm.disable();
        } else if (that.disableTaxes && !this.orderService.OrderDetailPurchaseSettings.calculatePricesFromGrossUnitPrice) {
          that.detailForm.get('Taxes').disable();
        }
      });

      if (!this.formDisabled) {
        this.getDeliveryScheduleForDeliveryDate(this.useDeliveryDateSchedule, this.showDeliveryDateOnBasePanel, this.isServiceProvider);
      }

      // Se as setting estão ativas, buscar o nome de centro de custo do detalhe pelo ID
      // senão buscar centro de custo da encomenda e passar para detalhe da encomenda
      if(this.showCostCenterDetail)
      {

        if(this.detailForm.get('CostCenterID').value && this.userCostCentersList.find(x => x.ID == this.detailForm.get('CostCenterID').value))
        {
          this.detailForm.get('CostCenterName').setValue(this.userCostCentersList.find(x => x.ID == this.detailForm.get('CostCenterID').value).Name);
        }
        else if(this.parentForm.get('CostCenterID').value && this.parentForm.get('CostCenterName').value)
        {
            this.detailForm.get('CostCenterID').setValue(this.parentForm.get('CostCenterID').value);
            this.detailForm.get('CostCenterName').setValue(this.parentForm.get('CostCenterName').value);
        }
      }
    }

    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    //se for para editar e o campo TipologiaProdutoServicoID estiver preenchido, vamos buscar o filho com base no pai e no tratamento de IVA
    if (this.detailForm.controls['ID'].value !== 0 && this.getProductServiceType && this.model != null && this.detailForm.controls['TipologiaProdutoServicoID'].value != null) {
      let accountProductType = this.detailForm.controls['AccountProductType'].value;
      let vatTreatmentType = this.detailForm.controls['VatTreatmentType'].value;
      this.getChildsList(accountProductType, vatTreatmentType);
      //se o valor do tipo de produto/serviço vier preenchido, vamos buscar a descrição (o nome)
      let accountProductTypeEntity = this.accountProductTypes.find(x => x.ID === accountProductType);
      let accountProductTypeName = accountProductTypeEntity ? accountProductTypeEntity.Name : null;
      this.detailForm.controls['AccountProductTypeDescription'].setValue(accountProductTypeName);
    }

    if (this.getProductServiceType && this.model && this.model.ProductID == null) {
      this.showProductServiceTypeFields = true;
    }

    if (this.useProductCostCenter || this.showCostCenterDetail) {
      this.detailForm.addControl('CostCenter', new UntypedFormControl());
      this.detailForm.addControl('CostCenterDescription', new UntypedFormControl());
      if (this.useProductReclassificationCode) {
        this.detailForm.addControl('ReclassificationCode', new UntypedFormControl());
        this.detailForm.addControl('ReclassificationDescription', new UntypedFormControl());
      }
    }

    if (this.showShippingDate) {
      this.detailForm.addControl('ShippingDate', new UntypedFormControl());
    }
    if (this.showPriceUnit) {
      this.detailForm.addControl('PriceUnit', new UntypedFormControl());
    }
    if (this.showAccountingCode) {
      this.detailForm.addControl('AccountingCode', new UntypedFormControl());
    }

    this.getProductInfo();

    if (this.orderService.OrderDetailPurchaseSettings.calculatePricesFromGrossUnitPrice){
      (<FormArray>this.detailForm.get('Discounts')).controls.forEach(control => {
        control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.updateValuesDiscount(control as UntypedFormGroup)
        });
      });
  
      (<FormArray>this.detailForm.get('Taxes')).controls.forEach((control: UntypedFormGroup) => {
        control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.updateValuesTaxes(control as UntypedFormGroup)
        });
      });
    }
  }

  getFamilyInformation() {
    this.familiesLv1 = this.familiesList.filter(r => r.Level === 1);

    if (this.model && this.model.FamilyID !== 0 && this.model.FamilyID !== null && typeof (this.model.FamilyID) !== 'undefined') {
      let family = this.familiesList.filter(r => r.ID === this.model.FamilyID)[0];
      if (family != null) {
        switch (family.Level) {
          case 1:
            this.familyLv1 = family.ID;
            this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === family.ID);
            this.familiesLv3 = [];
            break;
          case 2:
            this.familyLv1 = family.ParentID;
            this.familyLv2 = family.ID;
            this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === family.ParentID);
            this.familiesLv3 = this.familiesList.filter(r => r.Level === 3 && r.ParentID === family.ID);
            break;
          case 3:
            this.familyLv1 = this.familiesList.filter(r => r.ID === family.ParentID)[0].ParentID;
            this.familyLv2 = family.ParentID;
            this.familyLv3 = family.ID;
            this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === this.familyLv1);
            this.familiesLv3 = this.familiesList.filter(r => r.Level === 3 && r.ParentID === family.ParentID);
            break;
        }
      }
    }
  }

  onChangeFamilyLv1($event: any) {
    this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === +$event.value);
    this.detailForm.get('FamilyLv2').setValue(null);
    this.familiesLv3 = [];
    this.detailForm.get('FamilyLv3').setValue(null);
    if (this.showFamily && this.familiesLv2 != null && this.familiesLv2.length > 0) { // verificar se existe list lvl 2
      this.detailForm.controls['FamilyLv2'].setValidators([Validators.required]); // add validador
      this.detailForm.controls['FamilyLv2'].updateValueAndValidity(); // atualizar validador
      this.detailForm.controls['FamilyLv3'].setValidators(null); // remover validador lvl 3
      this.detailForm.controls['FamilyLv3'].updateValueAndValidity(); // atualizar validador
    }
  }

  onChangeFamilyLv2($event: any) {
    this.familiesLv3 = this.familiesList.filter(r => r.Level === 3 && r.ParentID === +$event.value);
    this.detailForm.get('FamilyLv3').setValue(null);
    if (this.showFamily && this.familiesLv3 != null && this.familiesLv3.length > 0) { // verificar se existe list lvl 3
      this.detailForm.controls['FamilyLv3'].setValidators([Validators.required]); // add validador
      this.detailForm.controls['FamilyLv3'].updateValueAndValidity(); // atualizar validador
    }
  }

  // #region Detail
  onValueChangedDetails(value?: any) {

    if (!this.detailForm) { return; }
    const form = this.detailForm;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {
          this.noError = false;
          const messages = this.validationMessages[field];

          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  save() {
    this.detailForm.markAllAsTouched(); // para mostrar todos os form controls com erro
    if(this.detailForm.valid){
      if (this.allowEdit) { // verificar se permissoes
        this.submitted = true;
        this.noError = true;
        this.onValueChangedDetails();
  
        if ((this.detailForm.controls['AccountProductSubtype'] !== undefined && this.detailForm.controls['AccountProductSubtype'] !== null)) {
          if (this.detailForm.controls['AccountProductSubtype'].value !== null) {
            this.detailForm.controls['TipologiaProdutoServicoID'].setValue(this.detailForm.controls['AccountProductSubtype'].value);
          }
        }
  
        if (this.detailForm.controls['CheckPopupOpened']) {
          this.detailForm.controls['CheckPopupOpened'].setValue(true)
        }
  
  
        if (this.useProductCostCenter) {
          //Validar se o centro de custo foi preenchido e pertence à lista 
          if (this.costCenterList && this.costCenterList.length > 0)
          {
            let costCenterFormControl = this.detailForm.get("CostCenter");
            if (!costCenterFormControl || !costCenterFormControl.value) {
              //Centro de custo é obrigatório
              Functions.gritter('O centro de custo é obrigatório!', 'danger');
              this.noError = false;
            }
            else if (!this.costCenterList.find(x => x.code == costCenterFormControl.value))
            {
              //Centro de custo inválido
              Functions.gritter('O centro de custo é inválido!', 'danger');
              this.noError = false;
            }
          }
        }
  
        if (this.useProductReclassificationCode) {
          //Validar se o código de reclassificaçãofoi preenchido e pertence à lista 
          if (this.reclassificationCodeList && this.reclassificationCodeList.length > 0)
          {
            let reclassificationFormControl = this.detailForm.get("ReclassificationCode");
            if (!reclassificationFormControl || !reclassificationFormControl.value) {
              //Centro de custo é obrigatório
              Functions.gritter('O código de reclassificação é obrigatório!', 'danger');
              this.noError = false;
            }
            else if (!this.reclassificationCodeList.find(x => x.code == reclassificationFormControl.value))
            {
              //Centro de custo inválido
              Functions.gritter('O código de reclassificação é inválido!', 'danger');
              this.noError = false;
            }
          }
        }
  
        if (this.detailForm.controls['ID'].value != 0) {
          if (this.useProductCostCenter) {
            let costCenterFormControl = this.detailForm.get("CostCenter");
            if (costCenterFormControl) {
              this.costCenter.FieldValue = costCenterFormControl.value;
              if (this.costCenter.FieldValue == null) {
                this.commonService.createOrUpdateGenericFieldExtension(this.costCenter).pipe(takeUntil(this.destroy$))
                  .subscribe(response => {
                    if (!response.ReturnStatus.Successfull) {
                      this._errorTreat.treatErrorResponse(response);
                    }
                  });
              }
            }
          }
  
          if (this.useProductReclassificationCode) {
            let reclassificationCodeFormControl = this.detailForm.get("ReclassificationCode");
            if (reclassificationCodeFormControl) {
              this.reclassificationCode.FieldValue = reclassificationCodeFormControl.value;
              if (this.reclassificationCode.FieldValue == null) {
                this.commonService.createOrUpdateGenericFieldExtension(this.reclassificationCode).pipe(takeUntil(this.destroy$))
                  .subscribe(response => {
                    if (!response.ReturnStatus.Successfull) {
                      this._errorTreat.treatErrorResponse(response);
                    }
                  });
              }
            }
          }
        }
  
        if (this.noError) {
          this.dialogRef.close(this.detailForm); // nao pode enviar so o value por causa dos campos disabled
        }
        this.submitted = false;
      }
    }
  }

  // obter info locais de entrega (moradas)
  selectLocalDeliveryDetails() {
    this.translateService.get(['SELECT_DELIVERY_LOCAL', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.listaMoradasLocaisEntrega, null, response['SELECT_DELIVERY_LOCAL'], aoColumns, columnDefs, null, 1, null),
          disableClose: true, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.listaMoradasLocaisEntrega.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['DeliverLocationID'].setValue(this.listaMoradasLocaisEntrega[index].ID);
            this.detailForm.controls['DeliveryLocationIdentifier'].setValue(this.listaMoradasLocaisEntrega[index].IntegrationID);
            this.detailForm.controls['DeliveryLocationName'].setValue(this.listaMoradasLocaisEntrega[index].Name);
            this.detailForm.controls['DeliveryLocationStreet'].setValue(this.listaMoradasLocaisEntrega[index].Street);
            this.detailForm.controls['DeliveryLocationPostalCode'].setValue(this.listaMoradasLocaisEntrega[index].PostalCode);
            this.detailForm.controls['DeliveryLocationCity'].setValue(this.listaMoradasLocaisEntrega[index].City);
            this.detailForm.controls['DeliveryLocationCountryCode'].setValue(this.listaMoradasLocaisEntrega[index].CountryCode);
          }
        }
      });

    });

  }

  selectProvider() {
    this.translateService.get(['SELECT_SUPPLIER_TITLE', 'CODE', 'TAX_NUMBER', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
        { 'data': 'TaxNumber', 'class': 'verticalMiddle', 'title': response['TAX_NUMBER'] }, // 2 - nif
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.providers, null, response['SELECT_SUPPLIER_TITLE'], aoColumns, columnDefs, null, 1, null),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.providers.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['SupplierID'].setValue(this.providers[index].ID);
            this.detailForm.controls['SupplierIdentifier'].setValue(this.providers[index].InternalID);
            this.detailForm.controls['SupplierName'].setValue(this.providers[index].Name);
          }
        }
      });
    });
  }

  // distribuição dos detalhes da encomenda
  openDistribution() {
    if (this.useAccountingConfig) {
      this.openDistributionAccountConfig();
    } else {

      const control = <UntypedFormArray>this.detailForm.controls['CostCentersConfig'];
      // converter incidência para string para fazer match com o select
      control.controls.forEach((costCenter: any) => {
        costCenter.get('Incidence').setValue((costCenter.get('Incidence').value).toString());
      });

      let valor = this.detailForm.controls['TotalNetAmount'].value !== 0 && this.detailForm.controls['TotalNetAmount'].value ? (this.detailForm.controls['TotalNetAmount'].value).toString().revertDecimal() : 0;

      let id = (this.model) ? this.model.ID : 0;

      let dialogRef = this.dialog.open(DistributionModalComponent, {
        data: // dados que vai enviar para o componente da modal
          new DistributionModalParam(this.formDisabled, 'OrderDetail', id, control.getRawValue(),
            this.saveOnServer, valor, this.documentType, this.typeList, this.debitCreditList,
            this.incidenceList, this.analisysAxesList, this.divisionKeysList,
            this.allCostCentersList),
        disableClose: false, // nao permitir fechar modal com escape ou clique fora
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          // limpar formArray original p/ substituir por novo
          this.detailForm.controls['CostCentersConfig'] = this.formBuilder.array([]);

          for (let cost of result) {
            const newControl = <UntypedFormArray>this.detailForm.controls['CostCentersConfig'];
            const addrCtrl = this.initOrderCostCenterConfig(cost);
            newControl.push(addrCtrl);
          }
          this.hasDistribution = this.detailForm.controls['CostCentersConfig'].value.length > 0;
        }
      });
    }
  }

  openDistributionAccountConfig() {
    const control = <UntypedFormArray>this.detailForm.controls['AccountingConfigList'];

    let valor = this.detailForm.controls['TotalNetAmount'].value !== 0 && this.detailForm.controls['TotalNetAmount'].value ? (this.detailForm.controls['TotalNetAmount'].value).toString().revertDecimal() : 0;

    let id = (this.model) ? this.model.ID : 0;

    let dialogRef = this.dialog.open(CADistributionModalComponent, {
      data: {// dados que vai enviar para o componente da modal
        distributionModalParam: new DistributionModalParam(this.formDisabled, 'OrderDetail', id, null,
          this.saveOnServer, valor, this.documentType, null, null, null, this.analisysAxesList, this.divisionKeysList,
          this.allCostCentersList, control.getRawValue(), this.showDistributionExpenseAccount,
          this.showDistributionInitiativeCode, this.showDistributionActivityCode, this.showDistributionFixedAssetRecordType, this.showDistributionAmortizationBookCode,
          this.showDistributionVATaccountingGroup, this.showDistributionRefactor, this.showDistributionAccountingType, this.showDistributionVatTreatmentType,
          this.showDistributionCodMensualizacao, this.enableSaveButton, false, false, this.showAccountingVatGroup),
      },
      disableClose: false, // nao permitir fechar modal com escape ou clique fora
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        // limpar formArray original p/ substituir por novo
        this.detailForm.controls['AccountingConfigList'] = this.formBuilder.array([]);

        for (let config of result) {
          const newControl = <UntypedFormArray>this.detailForm.controls['AccountingConfigList'];
          const addrCtrl = this.initOrderAccountingConfig(config);
          newControl.push(addrCtrl);
        }
        this.hasDistribution = this.detailForm.controls['AccountingConfigList'].value.length > 0;
      }
    });
  }

  // quando a quantidade encomendada ou o preço unitario sao alterados
  quantityOrNetUnitPriceChange(val: any) {
    let value = val ? (val).toString().revertDecimal() : null;

    if (!value)
      return;

    if (this.orderService.OrderDetailPurchaseSettings.calculatePricesFromGrossUnitPrice)
      this.calculatePrices();
    else {
      let quantity = this.revert(this.detailForm.get('OrderedQuantity').value);
      let price = this.revert(this.detailForm.get('NetUnitPrice').value);
      let result = quantity * price;
      this.detailForm.get('TotalNetAmount').setValue(this.format(result, +CURRENCY_DECIMAL));
    }
  }

  // #region OrderQtdMethods
  /* Atenção!!!
  Aqui (modal), quando clica no increaseOrderedQtd() ou no reduceOrderedQtd() vai sempre fazer o checkQuantityIncrement()
  porque clicar nos dois primeiros desencadeia o (change) do input

  Ou entao nao...
  */
  increaseOrderedQtd() {
    let quantity = this.detailForm.get('OrderedQuantity').value.toString().revertDecimal();
    let minimumQuantity = this.detailForm.get('MinimumQuantity').value;
    let quantityIncrement = this.detailForm.get('QuantityIncrement').value;

    if (!isNaN(quantity) && !isNaN(minimumQuantity) && !isNaN(quantityIncrement)) {
      let val = quantity + quantityIncrement;
      this.detailForm.controls['OrderedQuantity'].setValue(val);

      this.quantityOrNetUnitPriceChange(val);
      this.recalcDetail();
    }
  }

  reduceOrderedQtd() {
    let quantity = this.detailForm.get('OrderedQuantity').value.toString().revertDecimal();
    let minimumQuantity = this.detailForm.get('MinimumQuantity').value;
    let quantityIncrement = this.detailForm.get('QuantityIncrement').value;

    if (!isNaN(quantity) && !isNaN(minimumQuantity) && !isNaN(quantityIncrement)) {
      let val = quantity - quantityIncrement;
      if (val >= minimumQuantity) {
        this.detailForm.controls['OrderedQuantity'].setValue(val);

        this.quantityOrNetUnitPriceChange(val);
        this.recalcDetail();
      }
    }
  }

  // altera valor da quantidade encomendada caso nao seja divisivel pela qauntidade incrmentável. Ex: se só puder fazer incrementos de 5 em 5;
  checkQuantityIncrement() {
    if (this.productQuantityValidations) {
      let quantity = this.detailForm.get('OrderedQuantity').value.revertDecimal();
      let minimumQuantity = this.detailForm.get('MinimumQuantity').value;
      let quantityIncrement = this.detailForm.get('QuantityIncrement').value;

      if (!isNaN(quantity) && !isNaN(minimumQuantity) && !isNaN(quantityIncrement)) {
        let currentValue = quantity - minimumQuantity;

        if (currentValue <= 0) { // valida se valor <= 0
          currentValue = minimumQuantity;

        } else { // valida se valor > 0

          let isInteger = currentValue % quantityIncrement === 0 ? true : false;

          if (!isInteger) {
            currentValue = (Math.ceil(currentValue / quantityIncrement) * quantityIncrement);
          }
          currentValue = currentValue + minimumQuantity;
        }
        this.detailForm.controls['OrderedQuantity'].setValue(currentValue);
      }
    }

    if(this.orderService.OrderDetailPurchaseSettings.calculatePricesFromGrossUnitPrice)
      this.calculatePrices();
    else
      this.recalcDetail();
  }

  recalcDetail() {
    let detailValues = this.tranfModel.revertObjectDetail(this.detailForm.getRawValue());

    let orderedQuantity = detailValues.OrderedQuantity !== null ? detailValues.OrderedQuantity : 0;
    let packSize = detailValues.PackSize !== null ? detailValues.PackSize : 0;
    let netUnitPrice = detailValues.NetUnitPrice !== null ? detailValues.NetUnitPrice : 0;

    // atualizar o PackQuantity
    if (packSize > 0) {
      this.detailForm.controls['PackQuantity'].setValue((Math.ceil(orderedQuantity / packSize)).formatDecimal(this.currentLanguage, +QUANTITY_DECIMAL));
    } else {
      this.detailForm.controls['PackQuantity'].setValue(1);
    }

    // GM 2018-09-10 - O valor bruto = liq. + descontos e não liq. + taxas
    this.detailForm.controls['TotalNetAmount'].setValue((netUnitPrice * orderedQuantity).formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));
    let totalNetAmount = ((this.detailForm.controls['TotalNetAmount'].value).toString()).revertDecimal();

    // Recalcular taxas
    let environmentalTaxTotal = 0;
    const taxes = <UntypedFormArray>this.detailForm.get('Taxes');
    if (taxes != null && taxes.controls.length > 0) {

      // Eco Taxa
      let environmentalTax = taxes.controls.find(r => r.get('TaxTypeCode').value === 'EnvironmentalTax');
      if (environmentalTax) {
        // Valor da Eco taxa
        environmentalTaxTotal = ((environmentalTax.get('TaxRate').value.toString().revertDecimal() / 100) * totalNetAmount).round(2);
        environmentalTax.get('TaxAmount').setValue(environmentalTaxTotal.formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));
      }

      // IVA e outras taxas (incide também sobre eco taxas)
      taxes.controls.filter(r => r.get('TaxTypeCode').value !== 'EnvironmentalTax').forEach((tax: UntypedFormGroup) => {
        // Valor da taxa
        let taxAmount = (totalNetAmount + environmentalTaxTotal) * (tax.get('TaxRate').value.toString().revertDecimal() / 100);
        tax.get('TaxAmount').setValue(taxAmount.formatDecimal(this.currentLanguage, +UNITARY_PRICE_DECIMAL));
      });
    }

    // Recalcular descontos
    let discountsTotal = 0;
    let discountsUnit = 0;
    const discounts = <UntypedFormArray>this.detailForm.get('Discounts');
    if (discounts != null && discounts.controls.length > 0) {
      discounts.controls.forEach((discount: UntypedFormGroup) => {
        let percentage = discount.get('Percentage').value;

        if (percentage && percentage > 0) { // Se tiver percentagem, recalcula o valor
          // Valor do desconto
          let discountAmount = ((totalNetAmount - discountsTotal) * (discount.get('Percentage').value / 100));
          discount.get('Amount').setValue(discountAmount.formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));
          discountsTotal += discountAmount.round(CURRENCY_DECIMAL);

          // Valor de desconto unitário
          discountsUnit += ((netUnitPrice - discountsTotal) * (discount.get('Percentage').value / 100));
        } else if (discount.get('Amount').value) { // Senão se tiver valor fixo, usa esse valor
          discountsTotal += discount.get('Amount').value;
        }
      });
    }

    this.detailForm.get('GrossUnitPrice').setValue((netUnitPrice + discountsTotal).formatDecimal(this.currentLanguage, +UNITARY_PRICE_DECIMAL));
    this.detailForm.get('TotalGrossAmount').setValue((totalNetAmount + discountsUnit).formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));

  }
  // #endregion OrderQtdMethods

  // obter horario de entrega possivel para a data de entrega do detalhe da encomenda
  getDeliveryScheduleForDeliveryDate(useDeliveryDateSchedule: boolean, showDeliveryDateOnBasePanel: boolean, isServiceProvider: boolean) {

    if ((useDeliveryDateSchedule && this.model.ProductID && !showDeliveryDateOnBasePanel) ||
      (useDeliveryDateSchedule && this.model.ProductID && showDeliveryDateOnBasePanel && !isServiceProvider)) {
      this.companyDeliveryScheduleService.getByProductAndBuyer(this.model.ProductID, this.authenticationService.session.company.ID).pipe(takeUntil(this.destroy$)).subscribe((resp: ReturnStatusHtml) => {
        if (resp.ReturnStatus.Successfull) {
          let schedule = resp.ReturnStatus.ReturnObject.CompanyDeliverySchedule;

          if (schedule) {

            this.weekDays = []; // colapse das divs
            this.weekDays.push(schedule.Sunday, schedule.Monday, schedule.Tuesday, schedule.Wednesday, schedule.Thursday,
              schedule.Friday, schedule.Saturday); // tem de começar pelo domingo. domingo: 0, (...), sabado: 6

            this.minDays = schedule.RequestMinDays;
            this.minHour = schedule.DeliveryHourStart;
            this.maxHour = schedule.DeliveryHourEnd;

            let element = document.getElementById('datePickerScheduleDetail');
            let lang = this.translateValueService.translateLanguage;
            let type = 3;
            let formatData = DATE_HOUR_FORMAT;
            let stepMinutes = STEP_MIN;
            let tooltipsTrans = this.translateValueService.datepickerTranslates;

            // para voltar atualizar minDays, minHour, maxHour do dataTimePicker
            Functions.dataTimePicker(element, lang, type, formatData, stepMinutes, false, tooltipsTrans, null, null,
              this.minDays, this.minHour, this.maxHour, this.weekDays, element.getAttribute('disabled') !== '');

          }

        } else { // o que acontece se der erro
          this._errorTreat.treatErrorResponse(resp);
        }
      });
    }
  }

  // #endregion Detail

  // #region Distribution
  initOrderCostCenterConfig(costCenterConfig: CostCenterConfig = null) {
    if (costCenterConfig) {

      costCenterConfig = this.tranfModelCC.transformObject(costCenterConfig);
      return this.formBuilder.group({
        'ID': [costCenterConfig.ID],
        'AnalisysAxeID': [costCenterConfig.AnalisysAxeID],
        'Context': [costCenterConfig.Context],
        'CostCenterID': [costCenterConfig.CostCenterID],
        'DebitOrCredit': [costCenterConfig.DebitOrCredit],
        'DivisionKeyID': [costCenterConfig.DivisionKeyID],
        'EntityID': [costCenterConfig.EntityID],
        'Percentage': [costCenterConfig.Percentage_form ? costCenterConfig.Percentage_form : 0],
        'FixedValue': [costCenterConfig.FixedValue_form ? costCenterConfig.FixedValue_form : 0],
        'Incidence': [costCenterConfig.Incidence],
        'IntegrationID': [costCenterConfig.IntegrationID],
        'Type': [costCenterConfig.Type],
        'CostCenterName': [{ value: '', disabled: true }]
      });
    }
  }

  initOrderAccountingConfig(accountingConfig: AccountingConfig = null) {
    if (accountingConfig) {

      let costCenterName: string = null;
      if (accountingConfig.CentroCusto !== null && this.allCostCentersList !== null && this.allCostCentersList.length > 0) {
        let costCenter = this.allCostCentersList.find(x => x.IntegrationID === accountingConfig.CentroCusto);
        costCenterName = costCenter != null ? costCenter.Name : null;
      }

      accountingConfig = this.tranfModelAC.transformObject(accountingConfig);
      return this.formBuilder.group({
        'ID': [accountingConfig.ID],
        'Context': [accountingConfig.Context],
        'EntityID': [accountingConfig.EntityID],
        'Percentagem': [accountingConfig.Percentagem, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'ValorFixo': [accountingConfig.ValorFixo, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'CentroCusto': [accountingConfig.CentroCusto],
        'Refaturar': [accountingConfig.Refaturar],
        'Tipo': [accountingConfig.Tipo],
        'ContaGasto': [accountingConfig.ContaGasto],
        'GrupoIVA': [accountingConfig.GrupoIVA],
        'CodAtividade': [accountingConfig.CodAtividade],
        'CodIniciativa': [accountingConfig.CodIniciativa],
        'TipoRegistoImob': [accountingConfig.TipoRegistoImob],
        'CodLivroAmort': [accountingConfig.CodLivroAmort],
        'NomeCentroCusto': [costCenterName],
        'Observacoes': [accountingConfig.Observacoes],
        'CodGrupoContabilizacao': [accountingConfig.CodGrupoContabilizacao],
        'CodRetencao': [accountingConfig.CodRetencao],
        'TipoTratamentoIva': [accountingConfig.TipoTratamentoIva],
        'CodMensualizacao': [accountingConfig.CodMensualizacao]
      });
    }
  }
  // #endregion Distribution

  // #region Taxes
  initTax(tax: OrderTax = null) {
    if (tax) {
      tax = this.tranfModel.transformObjectTax(tax, this.currentLanguage);
      return this.formBuilder.group({
        'AmountCurrencyCode': [tax.AmountCurrencyCode],
        'AmountCurrencyCodeOther': [tax.AmountCurrencyCodeOther],
        'TaxAmount': [tax.TaxAmount_form, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL }), Validators.required])],
        // 'TaxRate': [tax.TaxRate, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: CURRENCY_DECIMAL })])],
        'TaxRate': [tax.TaxTypeCode !== "ValueAddedTax" ? tax.TaxRate_form : tax.TaxRate, [ModelValidators.numberVal({min: 0, max: 100})]],
        'TaxTypeCode': [tax.TaxTypeCode],
        'TaxTypeCodeOther': [tax.TaxTypeCodeOther]
      });
    } else {
      return this.formBuilder.group({
        'AmountCurrencyCode': [null],
        'AmountCurrencyCodeOther': [null],
        'TaxAmount': [null, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL }), Validators.required])],
        // 'TaxRate': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: CURRENCY_DECIMAL })])],
        'TaxRate': [null, [ModelValidators.numberVal({min: 0, max: 100})]],
        'TaxTypeCode': [null],
        'TaxTypeCodeOther': [null]
      });
    }
  }

  addTax(newInsert: boolean, objectInsert: OrderTax = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
      const taxCtrl = this.initTax();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(taxCtrl);
      taxCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedTax(value);
        this.updateValuesTaxes(taxCtrl);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
      if (objectInsert) { // usado quando faz reset
        const taxCtrl = this.initTax(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(taxCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedTax(value);
            this.updateValuesTaxes(control.controls[i] as FormGroup);
          });
        }
      }
      if (this.model && this.model.Taxes && this.model.Taxes.length > 0) {
        for (objectInsert of this.model.Taxes) {
          const taxCtrl = this.initTax(objectInsert);
          control.push(taxCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado      
    }
  }

  removeTaxe(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
    control.removeAt(i);

    if(this.orderService.OrderDetailPurchaseSettings.calculatePricesFromGrossUnitPrice)
      this.calculatePrices();
    // this.checkIfExemption();
  }

  /* tslint:disable:member-ordering */
  formErrorsTaxes: Array<string> = new Array<string>();
  formErrorsTaxesParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedTax(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsTaxes = new Array<string>();
    this.formErrorsTaxes = new Array<string>();
    for (const field in this.validationMessagesTaxes) {
      if (this.validationMessagesTaxes.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Taxes');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesTaxes[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsTaxes.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsTaxesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsTaxesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Taxes

  // #region Allowance
  initAllowance(allowance: OrderAllowance = null) {
    if (allowance) {
      return this.formBuilder.group({
        'AllowanceType': [allowance.AllowanceType],
        'Percentage': [allowance.Percentage, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
        'Amount': [allowance.Amount, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
      });
    } else {
      return this.formBuilder.group({
        'AllowanceType': [''],
        'Percentage': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
        'Amount': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
      });
    }
  }

  addAllowance(newInsert: boolean, objectInsert: OrderAllowance = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
      const allowCtrl = this.initAllowance();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(allowCtrl);
      allowCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedTAllowance(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
      if (objectInsert) { // usado quando faz reset
        const allowCtrl = this.initAllowance(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(allowCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedTAllowance(value);
          });
        }
      }

      if (this.model && this.model.Allowances && this.model.Allowances.length > 0) {
        for (objectInsert of this.model.Allowances) {
          const allowCtrl = this.initAllowance(objectInsert);
          control.push(allowCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeAllowance(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
    control.removeAt(i);
    // this.checkIfExemption();
  }

  /* tslint:disable:member-ordering */
  formErrorsAllowance: Array<string> = new Array<string>();

  formErrorsAllowanceParam: Array<string> = new Array<string>();


  /* tslint:enable:member-ordering */

  onValueChangedTAllowance(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsAllowance = new Array<string>();
    this.formErrorsAllowanceParam = new Array<string>();
    for (const field in this.validationMessagesAllowance) {
      if (this.validationMessagesAllowance.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Allowances');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesAllowance[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsAllowance.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsAllowance.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsAllowance.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Allowance

  // #region Discount
  initDiscount(discount: OrderDiscount = null) {
    let min = 1 / Math.pow(10, +CURRENCY_DECIMAL); // cacular o minimo consoante o setting, ex:  se tiver 3 casas decimais -> 0.001

    let discountForm: FormGroup;

    if (discount) {
      discount = this.tranfModel.transformObjectDiscount(discount, this.currentLanguage);

      discountForm = this.formBuilder.group({
        'DiscountType': [discount.DiscountType],
        'Percentage'  : [discount.Percentage_form, Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: this.decimalPlaces3 })])],
        'Amount'      : [discount.Amount_form, Validators.compose([ModelValidators.numberVal({ min, decimalPlaces: +CURRENCY_DECIMAL }), Validators.required])],
      });     
    } else {
      discountForm = this.formBuilder.group({
        'DiscountType': [''],
        'Percentage'  : [null, Validators.compose([ModelValidators.numberVal({ min: 0, max: 100, decimalPlaces: this.decimalPlaces3 })])],
        'Amount'      : [null, Validators.compose([ModelValidators.numberVal({ min, decimalPlaces: +CURRENCY_DECIMAL }), Validators.required])],
      });

      discountForm.get('Amount').addValidators(this.validateControl.bind(this));
    }

    return discountForm;
  }

  private validateControl(control: AbstractControl) {
    const totalNetAmount = this.revert(this.detailForm.get('TotalNetAmountWithTaxes').value);
    const amount = this.revert(control.value);

    if (totalNetAmount < amount) {
      return { amountInvalid: true };
    }
    return null; 
    
  }

  addDiscount(newInsert: boolean, objectInsert: OrderDiscount = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
      const discountCtrl = this.initDiscount();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(discountCtrl);
      discountCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDiscount(value);
        this.updateValuesDiscount(discountCtrl);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
      if (objectInsert) { // usado quando faz reset
        const discountCtrl = this.initDiscount(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(discountCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedDiscount(value);
            this.updateValuesDiscount(control.controls[i] as FormGroup);
          });
        }
      }

      if (this.model && this.model.Discounts && this.model.Discounts.length > 0) {
        for (objectInsert of this.model.Discounts) {
          const discountCtrl = this.initDiscount(objectInsert);
          control.push(discountCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  updateValuesDiscount(discountCtrl: UntypedFormGroup) {
    let percentage = this.revert(discountCtrl.get('Percentage').value);
    let amount = this.revert(discountCtrl.get('Amount').value);

    if (amount && !percentage) {
      discountCtrl.get('Percentage').setValue('', { emitEvent: false});
    }

    this.calculatePrices();
  }

  updateValuesTaxes(taxesCtrl: UntypedFormGroup) {
    let percentage = this.revert(taxesCtrl.get('TaxRate').value);
    let amount = this.revert(taxesCtrl.get('TaxAmount').value);

    if (amount && !percentage) {
      taxesCtrl.get('TaxRate').setValue('', { emitEvent: false});
    }

    this.calculatePrices();
  }

  removeDiscount(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
    control.removeAt(i);

    if(this.orderService.OrderDetailPurchaseSettings.calculatePricesFromGrossUnitPrice)
      this.calculatePrices();
    // this.checkIfExemption();
  }

  /* tslint:disable:member-ordering */
  formErrorsDiscount: Array<string> = new Array<string>();
  formErrorsDiscountParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedDiscount(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsDiscount = new Array<string>();
    this.formErrorsDiscountParam = new Array<string>();
    for (const field in this.validationMessagesDiscount) {
      if (this.validationMessagesDiscount.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Discounts');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesDiscount[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsDiscount.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsDiscountParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsDiscountParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Discount

  // #region OtherReferences
  initOtherReference(reference: OrderReference = null) {
    if (reference) {
      return this.formBuilder.group({
        'ReferenceType': [reference.ReferenceType],
        'ReferenceValue': [reference.ReferenceValue],
        'ReferenceDate': [reference.ReferenceDate]
      });
    } else {
      return this.formBuilder.group({
        'ReferenceType': [''],
        'ReferenceValue': [''],
        'ReferenceDate': ['']
      });
    }
  }

  addOtherReference(newInsert: boolean, objectInsert: OrderReference = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      const refeCtrl = this.initOtherReference();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(refeCtrl);
      refeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedOtherReferences(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      if (objectInsert) { // usado quando faz reset
        const refeCtrl = this.initOtherReference(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(refeCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedOtherReferences(value);
          });
        }
      }
      if (this.model && (<any>this.model).References && (<any>this.model).References.length > 0) {
        for (objectInsert of (<any>this.model).References) {
          const refeCtrl = this.initOtherReference(objectInsert);
          control.push(refeCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeOtherReference(i: number) {
    // se remover uma nota
    const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
    control.removeAt(i);
  }

  /* tslint:disable:member-ordering */
  formErrorsOtherReferences: Array<string> = new Array<string>();
  formErrorsOtherReferencesParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedOtherReferences(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsOtherReferences = new Array<string>();
    this.formErrorsOtherReferencesParam = new Array<string>();
    for (const field in this.validationMessagesOtherReferences) {
      if (this.validationMessagesOtherReferences.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('OtherReferences');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesOtherReferences[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsOtherReferences.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsOtherReferencesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsOtherReferencesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion OtherReferences

  // #region Notes

  initOtherNotes(otherNotes: any[]) {
    let formArray: UntypedFormArray = this.formBuilder.array([]);

    // iniciar validacao do formulario de notas
    if (otherNotes && otherNotes.length > 0) {
      for (let i = 0; i < otherNotes.length; i++) {
        formArray.push(this.formBuilder.group({
          'NoteType': [otherNotes[i].NoteType],
          'NoteValue': [otherNotes[i].NoteValue]
        }));
      }
    }

    if (!this.allowEdit || !this.isEditable) { // colocar todos como disabled se não tem permissões de edição
      formArray.disable();
    }

    return formArray;
  }

  initNote(note: OrderNote = null) {
    // iniciar validacao do formulario de notsa
    if (note) {
      return this.formBuilder.group({
        'NoteType': [note.NoteType],
        'NoteValue': [note.NoteValue]
      });

    }
    return this.formBuilder.group({
      'NoteType': [''],
      'NoteValue': ['']
    });
  }

  addNote(newInsert: boolean, objectInsert: OrderNote = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Notes'];
      const noteCtrl = this.initNote();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(noteCtrl);
      noteCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedNotes(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Notes'];
      if (objectInsert) { // usado quando faz reset
        const noteCtrl = this.initNote(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(noteCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedNotes(value);
          });
        }
      }
      if (this.model && this.model.Notes && this.model.Notes.length > 0) {
        for (objectInsert of this.model.Notes) {
          const noteCtrl = this.initNote(objectInsert);
          control.push(noteCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeNote(i: number) {
    // se remover uma nota
    const control = <UntypedFormArray>this.detailForm.controls['Notes'];
    control.removeAt(i);
  }
  /* tslint:disable:member-ordering */
  formErrorsNotes: Array<string> = new Array<string>();

  formErrorsNotesParam: Array<string> = new Array<string>();


  /* tslint:enable:member-ordering */

  onValueChangedNotes(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsNotes = new Array<string>();
    this.formErrorsNotesParam = new Array<string>();
    for (const field in this.validationMessagesNotes) {
      if (this.validationMessagesNotes.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Notes');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesNotes[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsNotes.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsNotesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsNotesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Notes

  checkSupplier(): void {

    let index = this.providers.findIndex((r: any) => r.InternalID + '' === this.detailForm.controls['SupplierIdentifier'].value + '');
    if (index > -1) {
      this.detailForm.controls['SupplierID'].setValue(this.providers[index].ID);
      this.detailForm.controls['SupplierName'].setValue(this.providers[index].Name);
    } else {
      if (this.detailForm.controls['SupplierIdentifier'].value !== '') {
        Functions.gritter(this.translateValueService.get('PROVIDER_IDENTIFIER_NOT_FOUND', { value: this.detailForm.controls['SupplierIdentifier'].value }), 'danger');
      }
      this.detailForm.controls['SupplierIdentifier'].setValue('');
      this.detailForm.controls['SupplierName'].setValue('');
      this.detailForm.controls['SupplierID'].setValue('');
    }
  }

  // #region Métodos Gerais e Comuns
  resetForm() { // reset do form (quando faz cancelar mantém valores da BD + criados não guardados)
    if (this.model) {
      this.detailForm.reset(this.model);

      // colocar os arrays com os valores iniciais
      // taxas
      const controlsT = <UntypedFormArray>this.detailForm.get('Taxes');
      while (controlsT.length) { // remover todos(as)
        controlsT.removeAt(controlsT.length - 1);
      }
      if (this.model.Taxes != null) { // adicionar
        this.model.Taxes.forEach((tax: OrderTax) => {
          this.addTax(false, tax);
        });
      }

      // abonos
      const controlsA = <UntypedFormArray>this.detailForm.get('Allowances');
      while (controlsA.length) { // remover todos(as)
        controlsA.removeAt(controlsA.length - 1);
      }
      if (this.model.Allowances != null) { // adicionar
        this.model.Allowances.forEach((allowance: OrderAllowance) => {
          this.addAllowance(false, allowance);
        });
      }

      // descontos
      const controlsD = <UntypedFormArray>this.detailForm.get('Discounts');
      while (controlsD.length) { // remover todos(as)
        controlsD.removeAt(controlsD.length - 1);
      }
      if (this.model.Discounts != null) { // adicionar
        this.model.Discounts.forEach((discount: OrderDiscount) => {
          this.addDiscount(false, discount);
        });
      }

      // outras referências
      const controlsO = <UntypedFormArray>this.detailForm.get('OtherReferences');
      while (controlsO.length) { // remover todos(as)
        controlsO.removeAt(controlsO.length - 1);
      }
      if (this.model.OtherReferences != null) { // adicionar
        this.model.OtherReferences.forEach((reference: OrderReference) => {
          this.addOtherReference(false, reference);
        });
      }

      // notas
      const controlsN = <UntypedFormArray>this.detailForm.get('Notes');
      while (controlsN.length) { // remover todos(as)
        controlsN.removeAt(controlsN.length - 1);
      }
      if (this.model.Notes != null) { // adicionar
        this.model.Notes.forEach((note: OrderNote) => {
          this.addNote(false, note);
        });
      }
    }
  }
  // #endregion Métodos Gerais e Comuns

  getChildsList(parentID: number, tipoTratamentoIVA: string) {
    let accountProductSubtype: number = 0;
    let accountProductSubtypeName: string = null;
    let accountProductSubtypeEntity: TipologiaProdutoServico = null;

    //vamos buscar a lista de subtipos consoante o pai e o tipo de tratamento de inva selecionados
    this.orderService.GetChildsList(parentID, tipoTratamentoIVA).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject != null) {
        this.accountProductSubTypes = response.ReturnStatus.ReturnObject;
        //se a lista de subtipos for diferente de null ou undefined
        if (this.accountProductSubTypes) {
          //vamos obter o id do subtipo (filho)
          accountProductSubtype = this.detailForm.controls['TipologiaProdutoServicoID'].value ? this.detailForm.controls['TipologiaProdutoServicoID'].value : 0;
          //vamos tentar obter a entidade através do id do filho
          accountProductSubtypeEntity = this.accountProductSubTypes.find(x => x.ID === accountProductSubtype);
          //se for diferente de null
          if (accountProductSubtypeEntity) {
            //obtemos o nome (descrição) do subtipo
            accountProductSubtypeName = accountProductSubtypeEntity.Name;
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(accountProductSubtypeName ? accountProductSubtypeName : null);
          }
        }
      }
    });
  }

  //Quando se muda o tipo de Produto/Serviço
  onChangeAccountProductType(value: number) {
    this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
    this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
    let tipoTratamentoIVA = this.detailForm.controls['VatTreatmentType'].value;

    //se foi selecionado algum tipo de produto/serviço e um tipo de tratameno de iva
    if (value != null && tipoTratamentoIVA != null) {
      //temos de ir buscar os subtipos para estes dois tipos
      this.getChildsList(value, tipoTratamentoIVA);
    }
  }

  //Quando se muda o tipo de tratamento de IVA
  onChangeVatTreatmentType($event: any) {
    this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
    this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
    let accountProductType = this.detailForm.controls['AccountProductType'].value;

    //se foi selecionado algum tipo de produto/serviço e um tipo de tratameno de iva
    if ($event.value != null && accountProductType != null) {
      //vamos buscar a lista de subtipos com base nos dois tipos selecionados
      this.getChildsList(accountProductType, $event.value);
    }
  }

  setAccountProductType() {

    this.translateService.get(['SELECT_ACCOUNT_PRODUCT_TYPE', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.accountProductTypes, null, response['SELECT_ACCOUNT_PRODUCT_TYPE'], aoColumns, columnDefs, null, 1, null, null, null, true),
          disableClose: false, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          let resultID = parseInt(result, null);
          if ((resultID != null || resultID.toString().length > 0) && resultID > 0) {
            let index = this.accountProductTypes.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['AccountProductType'].setValue(this.accountProductTypes[index].ID);
            this.detailForm.controls['AccountProductTypeDescription'].setValue(this.accountProductTypes[index].Name);
            this.onChangeAccountProductType(this.accountProductTypes[index].ID);
          } else if (resultID != null && resultID === -1) {
            this.detailForm.controls['AccountProductType'].setValue(null);
            this.detailForm.controls['AccountProductTypeDescription'].setValue(null);
            this.onChangeAccountProductType(null);
          }
        }
      });

    });
  }


  setAccountProductSubtype() {
    this.translateService.get(['SELECT_ACCOUNT_PRODUCT_SUBTYPE', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.accountProductSubTypes, null, response['SELECT_ACCOUNT_PRODUCT_SUBTYPE'], aoColumns, columnDefs, null, 1, null, null, null, true),
          disableClose: false, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          let resultID = parseInt(result, null);
          if ((resultID != null || resultID.toString().length > 0) && resultID > 0) {
            let index = this.accountProductSubTypes.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['AccountProductSubtype'].setValue(this.accountProductSubTypes[index].ID);
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(this.accountProductSubTypes[index].Name);
            this.detailForm.controls['TipologiaProdutoServicoID'].setValue(this.accountProductSubTypes[index].ID);
          } else if (resultID != null && resultID === -1) {
            this.detailForm.controls['AccountProductSubtype'].setValue(null);
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
            this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
          }
        }
      });

    });
  }



  /**
   * Selector de Centros de Custo, apenas se encontra disponível se for devolvido mais que um Centro de Custo
   */
  selectExternalCostCenter() {
    this.translateService.get(['SELECT_COST_CENTER', 'CODE', 'DESCRIPTION']).subscribe(response => {

      let aoColumns = [
        { 'data': 'code', 'class': 'verticalMiddle', 'title': response['CODE'] },
        { 'data': 'description', 'class': 'verticalMiddle', 'title': response['DESCRIPTION'] }
      ];

      let columnDefs = [
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.costCenterList, null, response['SELECT_COST_CENTER'],
              aoColumns, columnDefs, null, 1, null, null, null, null, 'code'),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const chosenCostCenter = this.costCenterList.find(x => x.code == result);
          if (chosenCostCenter) {
            this.detailForm.get('CostCenter').setValue(chosenCostCenter.code);
            this.detailForm.get('CostCenterDescription').setValue(chosenCostCenter.description);
            
            //Atualizar os códigos de reclassificação
            this.getReclassificationCodeClassification(chosenCostCenter.code);
          }
        }
      });
    });
  }

  selectCostCenter() {
    this.translateService.get(['SELECT_COST_CENTER', 'CODE', 'NAME']).subscribe(response => {

      let aoColumns = [
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'] },
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }
      ];

      let columnDefs = [
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.costCenterList, null, response['SELECT_COST_CENTER'],
              aoColumns, columnDefs, null, 1, null, null, null, null, 'ID'),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const chosenCostCenter = this.costCenterList.find(x => x.ID == result);
          if (chosenCostCenter) {
            this.detailForm.get('CostCenterID').setValue(chosenCostCenter.ID);
            this.detailForm.get('CostCenterName').setValue(chosenCostCenter.Name);
          }
        }
      });
    });
  }
    selectCostCenterDetail() {
    this.translateService.get(['SELECT_COST_CENTER', 'CODE', 'TAX_NUMBER', 'NAME']).subscribe(response => {
      let that = this;

      that.filterCostCenters();

      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.userCostCentersFiltered, null, response['SELECT_COST_CENTER'],
              aoColumns, columnDefs, null, 1, null),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {

          this.detailForm.get('CostCenterID').setValue(result);
          this.detailForm.get('CostCenterID').updateValueAndValidity

          this.detailForm.get('CostCenterName').setValue(this.userCostCentersFiltered.find(r => r.ID === result).Name);
          this.detailForm.get('CostCenterName').updateValueAndValidity();

          // Consoante a mudança no detalhe, vai alterar também respetivamente na encomenda
          this.changeOrderCostCenter();

        }
      });
    });
  }

  // Filtra a lista de centros de custo por tipo de documento e morada de entrega
  filterCostCenters() {

    if (!this.showCostCenterDetail) {
      return;
    }

    // Carregar os centros de custo
    if (this.formDisabled) {
      //Se o form estiver disable é consulta e a lista tem de ter todos os CCs possiveis
      this.userCostCentersFiltered = this.userCostCentersList;
    } else {
      //Se for edição/criação apenas podem usar CCs de acordo com o tipo e morada e user
      if (this.userCostCentersList) {
        this.userCostCentersFiltered = this.userCostCentersList.filter(r =>
          (this.parentForm.get('DocumentType').value ? r.DocumentTypes.length === 0 || r.DocumentTypes.findIndex(r2 => r2.DocumentType === this.parentForm.get('DocumentType').value) !== -1 : true) &&
          (this.parentForm.get('DeliveryLocationID').value ? r.Locations.length === 0 || r.Locations.findIndex(r2 => r2.LocationID === this.parentForm.get('DeliveryLocationID').value) !== -1 : true)
        );
      } else {
        this.userCostCentersFiltered = [];
      }

        // Se o centro de custo do cabeçalho selecionado não existir na lista filtrada
        if (this.detailForm.value && this.detailForm.value.CostCenterID && +this.detailForm.value.CostCenterID > 0 &&
          this.userCostCentersFiltered.findIndex(r => r.ID === this.detailForm.get('CostCenterID').value) === -1) {
          // Se criação e só existe 1 centro de custo na lista filtrada --> seleciona por defeito esse
          if (this.detailForm.get('ID').value === 0) { // Se for criação, então seleciona o primeiro se só existir 1 centro de custo
            this.detailForm.get('CostCenterID').setValue(this.userCostCentersFiltered && this.userCostCentersFiltered.length === 1 ? this.userCostCentersFiltered[0].ID : null);
            this.detailForm.get('CostCenterName').setValue(this.userCostCentersFiltered && this.userCostCentersFiltered.length === 1 ? this.userCostCentersFiltered[0].Name : null);
          } else {  // Senão limpa o centro de custo
            this.detailForm.get('CostCenterID').setValue(null);
            this.detailForm.get('CostCenterName').setValue(null);
          }
        } else {
          //Se não estiver nenhum CC selecionado e a lista apenas tiver um registo --> selecionar esse registo
          if (this.userCostCentersFiltered && this.userCostCentersFiltered.length === 1 && !this.detailForm.get('CostCenterID').value) {
            this.detailForm.get('CostCenterID').setValue(this.userCostCentersFiltered[0].ID);
            this.detailForm.get('CostCenterName').setValue(this.userCostCentersFiltered[0].Name);
          }
        }
       
    }
  }

  /**
   * Selector de Códigos de Reclassificação, apenas se encontra disponível se for devolvido mais que um Código de Reclassificação
   */
  selectRecCode() {
    this.translateService.get(['SELECT_RECLASSIFICATION_CODE', 'CODE', 'DESCRIPTION']).subscribe(response => {

      let aoColumns = [
        { 'data': 'code', 'class': 'verticalMiddle', 'title': response['CODE'] },
        { 'data': 'description', 'class': 'verticalMiddle', 'title': response['DESCRIPTION'] }
      ];

      let columnDefs = [
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.reclassificationCodeList, null, response['SELECT_RECLASSIFICATION_CODE'],
              aoColumns, columnDefs, null, 1, null, null, null, null, 'code'),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const chosenRecCode = this.reclassificationCodeList.find(x => x.code == result);
          if (chosenRecCode)
          {
            this.detailForm.get('ReclassificationCode').setValue(chosenRecCode.code);
            this.detailForm.get('ReclassificationDescription').setValue(chosenRecCode.description);
          }
        }
      });
    });
  }


  /**
   * Obtém a Listagem de Centros de Custo
   */
  getCostCenterClassification() {
    if (this.product)
    {
      //por defeito desabilitar o campo
      this.detailForm.get('CostCenterDescription').disable();

      this.orderService.getCostCenterClassification(this.product.CodGrupoContabilizacao, this.product.VATRate, this.supplierIdentifier).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {

          //Obtém a Listagem devolvida
          let costCenterJSONObject = response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'costCenterList').Value;

          //Adiciona os Centros de Custo obtidos pelo Web Service ao Array de Centros de Custo
          costCenterJSONObject.forEach(jsonObj => {
            this.costCenterList.push({
              code: jsonObj.find(costCenter => costCenter.Key == 'code').Value,
              description: jsonObj.find(costCenter => costCenter.Key == 'description').Value
            })
          });

          //Se a Listagem devolvida vier vazia, bloqueia-se o FormControl e notifica o utilizador
          if (this.costCenterList.length == 0) {
            //Functions.gritter(response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'message').Value, 'error');
            this.detailForm.get('CostCenter').setValue(null);
            this.detailForm.get('CostCenterDescription').setValue(null);
            this.detailForm.get('CostCenterDescription').disable();
          }

          //Caso devolva registos
          else if (this.costCenterList.length >= 1)
          {
            if (this.allowEdit)
            {
              this.detailForm.get('CostCenterDescription').enable();
            }

            //Se existir valor guardado na BD
            if (this.detailForm.get('CostCenter') && this.detailForm.get('CostCenter').value) 
            {
              //Validar se o valor da BD é válido
              let selectedCostCenter: any = this.costCenterList.find(x => x.code == this.detailForm.get('CostCenter').value);
              if (selectedCostCenter) {
                //Carregar a descrição no form se for válido
                this.detailForm.get('CostCenterDescription').setValue(selectedCostCenter.description);
              }
              else {
                this.detailForm.get('CostCenter').setValue(null);
                this.detailForm.get('CostCenterDescription').setValue(null);
                Functions.gritter(this.translateValueService.get('COST_CENTER_NOT_FOUND', 'error'));
              }
            }
            else if (this.costCenterList.length == 1)
            {
              //Se não existe valor na BD e apenas existe 1 registo, carregar o valor por defeito
              this.detailForm.get('CostCenter').setValue(this.costCenterList[0].code);
              this.detailForm.get('CostCenterDescription').setValue(this.costCenterList[0].description);
            }
          }

          //Atualizar o código de reclassificação de acordo com o centro de custo selecionado
          this.getReclassificationCodeClassification(this.detailForm.get('CostCenter').value);
        } 
        else 
        { // o que acontece se der erro
          this._errorTreat.treatErrorResponse(response);
          //this.detailForm.get('CostCenter').setValue(null);
          //this.detailForm.get('CostCenterDescription').setValue(null);
          this.detailForm.get('CostCenterDescription').disable();
          if (this.useProductReclassificationCode) {
            //this.detailForm.get('ReclassificationCode').setValue(null);
            //this.detailForm.get('ReclassificationDescription').setValue(null);
            this.detailForm.get('ReclassificationDescription').disable();
          }
        }
      });
    }
  }


  /**
   * Obtém a Listagem de Códigos de Reclassificação consoante o ID do Centro de Custo
   * @param  {number} index - ID do Centro de Custo
   */
  getReclassificationCodeClassification(costCenterCode: string) {

    //desabilitar o campo por defeito, só deve estar habilitado se for uma lista
    this.detailForm.get('ReclassificationDescription').disable();

    if (!costCenterCode)
    {
      this.detailForm.get('ReclassificationCode').setValue(null);
      this.detailForm.get('ReclassificationDescription').setValue(null);
    }
    else
    {
      this.orderService.getReclassificationCodeClassification(this.product.CodGrupoContabilizacao, this.product.VATRate,
        costCenterCode, this.supplierIdentifier).subscribe((response: ReturnStatusHtml) => {
          if (response.ReturnStatus.Successfull) {

            //Obtém a Listagem devolvida
            let reclassificationCodeJSONObject = response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'reclassificationCodeList').Value;

            //Adiciona os Códigos de Reclassificação obtidos pelo Web Service ao Array de Códs de Reclassificação
            reclassificationCodeJSONObject.forEach(jsonObj => {
              //Apenas incluir lista de valores com dados
              if (jsonObj.find(recCode => recCode.Key == 'code').Value && jsonObj.find(recCode => recCode.Key == 'description').Value)
              {
                this.reclassificationCodeList.push({
                  code: jsonObj.find(recCode => recCode.Key == 'code').Value,
                  description: jsonObj.find(recCode => recCode.Key == 'description').Value
                });
              }
            });

            //Se a Listagem devolvida vier vazia, bloqueia-se o FormControl e notifica o utilizador
            if (this.reclassificationCodeList.length == 0) {
              //Functions.gritter(response.ReturnStatus.ReturnObject.find(obj => obj.Key == 'message').Value, 'error');
              this.detailForm.get('ReclassificationCode').setValue(null);
              this.detailForm.get('ReclassificationDescription').setValue(null);
            }
            else if (this.reclassificationCodeList.length >= 1)
            {
              //apenas se puder editar
              if (this.allowEdit)
              {
                this.detailForm.get('ReclassificationDescription').enable();
              }

              // Se o codigo de reclassificação já estiver carregado na BD, validar
              if (this.detailForm.get('ReclassificationCode') && this.detailForm.get('ReclassificationCode').value) 
              {
                let selectedReclassification: any = this.reclassificationCodeList.find(x => x.code == this.detailForm.get('ReclassificationCode').value);
                if (selectedReclassification) {
                  //Carregar a descrição no form
                  this.detailForm.get('ReclassificationDescription').setValue(selectedReclassification.description);
                }
                else {
                  this.detailForm.get('ReclassificationCode').setValue(null);
                  this.detailForm.get('ReclassificationDescription').setValue(null);
                  Functions.gritter(this.translateValueService.get('RECLASSIFICATION_CODE_NOT_FOUND', 'error'));
                }
              }
              //Se não estiver carregado na BD, carregar por defeito se possivel
              else if (this.reclassificationCodeList.length == 1)
              {
                this.detailForm.get('ReclassificationCode').setValue(this.reclassificationCodeList[0].code);
                this.detailForm.get('ReclassificationDescription').setValue(this.reclassificationCodeList[0].description);
              }
            }
          } 
          else 
          { // o que acontece se der erro
            this._errorTreat.treatErrorResponse(response);
            this.detailForm.get('ReclassificationCode').disable();
            this.detailForm.get('ReclassificationDescription').disable();
          }
        });
    }
  }

   async getProductInfo() 
   {
    // Se for para carregar os centros de custo e códigos de reclassificação a partir do código de dados do produto
    // Estes campos só são apresentados nas compras
    if (this.useProductCostCenter && this.context == 'encomendaCompra') 
    {
      //Se for uma edição/consulta
      if (this.detailForm.get('ID').value !== 0) 
      {
        //Obter os dados guardados na BD desta encomenda
        await this.getOrderDetailExtendedFields();
      }

      //Se o produto foi defenido e é edição --> obter info, caso contrário inabilitar os campos
      if (!this.formDisabled && this.detailForm.get("ProductID").value) 
      {
        this.productService.get(this.detailForm.get("ProductID").value, true).subscribe((response: ReturnStatusHtml) => {
          if (response.ReturnStatus.Successfull) {
            if (response.ReturnStatus.ReturnObject.Product) {
              this.product = response.ReturnStatus.ReturnObject.Product;
              
              //Obter a lista de centros de custo a apresentar 
              //Se não existirem --> não deixar carregar e alertar o utilizador
              //Se existir 1 --> Carregar automáticamente esse
              //Se existirem vário --> permitir ao utilizador selecionar
              this.getCostCenterClassification();
            }
          } else { // o que acontece que der erro
            this._errorTreat.treatErrorResponse(response);
          }
        });
      }
      else 
      {
        //Se não tem produto, inabilitar os campos, inabilitar os campos
        this.detailForm.get('CostCenterDescription').disable();
        if (this.useProductReclassificationCode) {
          this.detailForm.get('ReclassificationDescription').disable();
        }
      }
    } 
    else 
    {
      //Obter os fieldsextensions dos detalhes se for necessário
      if (this.showShippingDate) {
        //Obter os campos extendidos à BD e carregar no form
        this.getOrderDetailExtendedFields();

        if (this.showShippingDate) {
          this.detailForm.get('ShippingDate').disable();
        }
      }
    }
  }

  async getOrderDetailExtendedFields() {
    //Obtém os Campos Genéricos no Contexto 'OrderDetail' com base no ID do Detail selecionado
    let response = await this.commonService.getGenericFieldsExtension("OrderDetail", this.detailForm.get('ID').value).pipe(takeUntil(this.destroy$)).toPromise()
    if (response.ReturnStatus.Successfull) {
      let receivedList = response.ReturnStatus.ReturnObject;

      //Se o OrderDetail tiver o Campo Genérico 'CostCenter'
      if (this.useProductCostCenter) {
        let costCenterField = receivedList.find(genField => genField.FieldCode == 'CostCenter');
        this.costCenter = costCenterField != null ? costCenterField :
          new GenericFieldExtension(0, this.detailForm.get('ID').value, "OrderDetail", "", null, "CostCenter", "", null);

        if (this.costCenter.FieldValue) {
          this.detailForm.get("CostCenter").setValue(this.costCenter.FieldValue);
        }
      }

      //Se o OrderDetail tiver o Campo Genérico 'ReclassificationCode'
      if (this.useProductReclassificationCode) {
        let reclassificationCodeField = receivedList.find(genField => genField.FieldCode == 'ReclassificationCode');

        this.reclassificationCode = reclassificationCodeField != null ? reclassificationCodeField :
          new GenericFieldExtension(0, this.detailForm.get('ID').value, "OrderDetail", "", null, "ReclassificationCode", "", null);

        if (this.reclassificationCode.FieldValue) {
          this.detailForm.get("ReclassificationCode").setValue(this.reclassificationCode.FieldValue);
        }
      }


      if (this.showShippingDate) {
        //apenas para consulta
        let shippingDateField = receivedList.find(genField => genField.FieldCode == 'ShippingDate');

        if (shippingDateField) {
          if (shippingDateField.FieldValue)
            this.detailForm.get("ShippingDate").setValue(shippingDateField.FieldValue);
        }
      }
    }
  }
  // Método para alterar centro de custo da encomenda, quando o centro de custo do detalhe da encomenda sofre alterações
  changeOrderCostCenter() {
    
    if(this.showCostCenterDetail)
    {

      // Se tiver apenas um detalhe, altera na encomenda diretamente
      if(this.parentForm.get('Details').value.length == 1)
      {
        this.parentForm.get('CostCenterID').setValue(this.detailForm.get('CostCenterID').value);
        this.parentForm.get('CostCenterName').setValue(this.detailForm.get('CostCenterName').value);
      }
      //Mais do que um detalhe
      else
      {
        //Buscar todos os detalhes
        const details = this.parentForm.get('Details').value;
        //Buscar todos os centros de custo dos detalhes
        const costcenterids = details.map(detail => detail.CostCenterID);

        //Validar se todos os centros de custo são iguais, se sim colocar o centro de custo na encomenda
        if(costcenterids.every(id => id === costcenterids[0]))
        {
          this.parentForm.get('CostCenterID').setValue(this.detailForm.get('CostCenterID').value);
          this.parentForm.get('CostCenterName').setValue(this.detailForm.get('CostCenterName').value);
        }
        //Se não, colocar centro de custo da encomenda sem valor
        else
        {
          this.parentForm.get('CostCenterID').setValue('');
          this.parentForm.get('CostCenterName').setValue('');
        }

      }

        
    }
  }

  calculatePrices(): void {
    if (!this.orderService.OrderDetailPurchaseSettings.calculatePricesFromGrossUnitPrice)
      return;

    // validar se a quantidade e valor unitario brueot estao a zero se sim nao vale a pena continuar as contas
    let quantity = this.revert(this.detailForm.get('OrderedQuantity').value);
    let grossUnitPrice = this.revert(this.detailForm.get('GrossUnitPrice').value);

    if (!quantity || !grossUnitPrice) {
      this.detailForm.patchValue({
        TotalGrossAmount: this.format(0, +CURRENCY_DECIMAL),
        TotalNetAmount: this.format(0, +CURRENCY_DECIMAL),
        NetUnitPrice: this.format(0, +UNITARY_PRICE_DECIMAL)
      });
      return;
    }

    // calcular valores 
    let totalGrossAmount = quantity * grossUnitPrice;
    let totalNetAmount = totalGrossAmount;

    let taxes = this.detailForm.get('Taxes') as FormArray;
    
    // calcular valores das taxas (se tiver valor na taxRate usa essa percentagem para calcular caso não tenta com a taxAmount caso nao tenha nenhum deste nao faz nada)
    taxes.controls.forEach((tax: FormGroup) => {
      let taxRate = this.revert(tax.get('TaxRate').value);
      let taxAmount = this.revert(tax.get('TaxAmount').value);

      if (taxRate && tax.get('TaxRate').valid) {
        let percentageValue = this.getPercentageValue(totalGrossAmount, taxRate);
        totalNetAmount += percentageValue;

        tax.get('TaxAmount').setValue(this.format(percentageValue, +CURRENCY_DECIMAL), { emitEvent: false });
      }
      else if (taxAmount && tax.get('TaxAmount').valid && totalGrossAmount >= taxAmount) {
        totalNetAmount += taxAmount;
      }
    });

    this.detailForm.get('TotalNetAmountWithTaxes').setValue(totalNetAmount);

    let discounts = this.detailForm.get('Discounts') as FormArray;

    totalNetAmount = discounts.controls.reduce((totalWithoutDiscouts: number, discount: FormGroup) => {
      let percentage = this.revert(discount.get('Percentage').value);
      let amount = this.revert(discount.get('Amount').value);

      if (percentage && discount.get('Percentage').valid) {
        let percentageValue = this.getPercentageValue(totalWithoutDiscouts, percentage);
        discount.get('Amount').setValue(this.format(percentageValue, +CURRENCY_DECIMAL), { emitEvent: false });
        
        return totalWithoutDiscouts -= percentageValue;
      }
      else if (amount && discount.get('Amount').valid && totalNetAmount >= amount) {
        return totalWithoutDiscouts -= amount;
      }

      return totalWithoutDiscouts;
    }, totalNetAmount);

    discounts.updateValueAndValidity();

    let netUnitPrice = totalNetAmount / quantity;

    this.detailForm.patchValue({
      TotalGrossAmount: this.format(totalGrossAmount, +CURRENCY_DECIMAL),
      TotalNetAmount: this.format(totalNetAmount, +CURRENCY_DECIMAL),
      NetUnitPrice: this.format(netUnitPrice, +UNITARY_PRICE_DECIMAL)
    });
  }

  getPercentageValue(value: number, percentage: number): number {
    if(!value || !percentage)
      return 0;

    return value * (percentage / 100);
  }

  revert(value: string): number {
    return value ? value.toString().revertDecimal() : 0;
  }

  format(value: number, decimalCases: number): string {
    return value.formatDecimal(this.currentLanguage, decimalCases);
  }

  formatCurrency(control: AbstractControl)
  {
    control.setValue(this.format(this.revert(control.value), +CURRENCY_DECIMAL));
  }

  formatPercentage(control: AbstractControl)
  {
    control.setValue(this.format(this.revert(control.value), this.decimalPlaces3));
  }

  ngOnDestroy() { }
}


