export class Dropdown {
  /// Chaves das várias listas
  public static readonly INCOTERM: string = 'Incoterm';
  public static readonly BAR_CODE_TYPE: string = 'BarCodeType';
  public static readonly DEBIT_CREDIT: string = 'DebitOrCredit';
  public static readonly DELIVERY_DATE_EVAL: string = 'DeliveryDateEval';
  public static readonly RECEIVED_QUANTITY_EVAL: string = 'ReceivedQuantityEval';
  public static readonly PRODUCT_EVAL: string = 'ProductEval';
  public static readonly EVALUATION_EVAL: string = 'Evaluation';
  public static readonly COST_CENTER_CONFIG_TYPE: string = 'CostCenterConfigType';
  public static readonly COST_CENTER_CONFIG_INCIDENCE: string = 'CostCenterConfigIncidence';
  public static readonly PROMOTION_TYPE: string = 'PromotionType';
  public static readonly NUTRIENTS_OTHER: string = 'OtherNutrients';
  public static readonly NUTRIENTS_OR_ENERGY: string = 'EnergyOrNutrients';
  public static readonly NUTRIENTS_VITAMINS_MINERALS: string = 'VitaminesAndMinerals';
  public static readonly HEALTH_CLAIM: string = 'HealthClaim';
  public static readonly IMAGE_INFO_TYPE_LIST: string = 'ImageInfoType';
  public static readonly IMAGE_IN_OR_OUT_LIST: string = 'ImageInOrOut';
  public static readonly IMAGE_ANGLE_LIST: string = 'ImageAngle';
  public static readonly IMAGE_DIRECTION_LIST: string = 'ImageDirection';
  public static readonly GENDERS_LIST: string = 'Gender';
  public static readonly FAMILY_LIST: string = 'Family';
  public static readonly PERSON_TYPE_LIST: string = 'PersonType';
  public static readonly LANGUAGE_LIST: string = 'Language';
  public static readonly CURRENCY_LIST: string = 'Currency';
  public static readonly EXCHANGE_RATE_CATEGORY_LIST: string = 'ExchangeRateCategory';
  public static readonly SPECIAL_VAT_SCHEME_LIST: string = 'SpecialVATScheme';
  public static readonly SHIPPING_CONDITION_LIST: string = 'ShippingCondition';
  public static readonly PACKAGING_LIST: string = 'Packaging';
  public static readonly PALLET_TYPE_LIST: string = 'PalletType';
  public static readonly PACKAGE_PELLICLE_LIST: string = 'PackagePellicle';
  public static readonly PACKAGE_CORNER_LIST: string = 'PackageCorner';
  public static readonly SALES_ORGANIZATION_LIST: string = 'SalesOrganization'; // 18
  public static readonly DISTRIBUTION_CHANNEL_LIST: string = 'DistributionChannel'; // 19
  public static readonly SALES_SYSTEM_LIST: string = 'SalesSystem'; // 22
  public static readonly OPERATIONAL_ZONE_LIST: string = 'OperationalZone'; // 23
  public static readonly CUSTOMER_CLASSIFICATION_LIST: string = 'CustomerClassification'; // 95
  public static readonly CARDEX_ATTRIBUTE_LIST: string = 'AtributoCardex'; // Não sei o numero meter no back provavelmente
  public static readonly ROUTE_LIST: string = 'Route'; // 170
  public static readonly PAYMENT_TERMS_LIST: string = 'PaymentTerms'; // 190
  public static readonly CLIENT_TYPE_LIST: string = 'ClientType'; // 335
  public static readonly FREE_AT_LIST: string = 'FreeAT'; // 336
  public static readonly INSIGNIA_LIST: string = 'Insignia'; // 337
  public static readonly CUSTOMER_ORIGIN_LIST: string = 'CustomerOrigin'; // 343
  public static readonly CUSTOMER_DESTINATION_LIST: string = 'CustomerDestination'; // 344
  public static readonly CLOSING_DAY_LIST: string = 'ClosingDay'; // 345
  public static readonly CUSTOMER_TYPOLOGY_LIST: string = 'CustomerTypology'; // 346 // 350
  public static readonly CUSTOMER_SUBTYPOLOGY_LIST: string = 'CustomerSubtypology';
  public static readonly AVERAGE_MONTHLY_COFFEE_CONSUMPTION_LIST: string = 'AverageMonthlyCoffeeConsumption'; // 347
  public static readonly SEASONALITY_LIST: string = 'Seasonality'; // 349
  public static readonly ACCESSIBILITY_LIST: string = 'Accessibility'; // 352
  public static readonly PAYMENT_TYPE_LIST: string = 'PaymentType';
  public static readonly SPECIAL_CONDITION_LIST: string = 'SpecialCondition';
  public static readonly CUSTOMER_GROUP_LIST: string = 'CustomerGroup';
  public static readonly NATIONAL_CUSTOMER_LIST: string = 'NationalCustomer';
  public static readonly DOCUMENT_TYPE_LIST: string = 'DocumentType';
  public static readonly SALES_OFFICE_LIST: string = 'SalesOffice';
  public static readonly DELTA_Q_PRO_CUSTOMER_LIST: string = 'DeltaQProCustomer';
  public static readonly PRICE_GROUP_LIST: string = 'PriceGroup';
  public static readonly PRICE_LIST_LIST: string = 'PriceList';
  public static readonly SCRIPT_TYPE: string = 'ScriptType';
  public static readonly GPC_BRICK_CODE_1: string = 'GPCBrickCode1';
  public static readonly GPC_BRICK_CODE_2: string = 'GPCBrickCode2';
  public static readonly GPC_BRICK_CODE_3: string = 'GPCBrickCode3';
  public static readonly GPC_BRICK_CODE_4: string = 'GPCBrickCode4';
  public static readonly PRODUCT_TAX_TYPE: string = 'ProductTaxType';
  public static readonly PACKAGE_TYPE: string = 'PackageType';
  public static readonly CONTRACT_TYPE_TYPE: string = 'ContractTypeType';
  public static readonly CONTRACT_ERROR_NOTIFICATION_TYPE: string = 'ContractErrorNotificationType';
  public static readonly CONTRACT_BILLING_CYCLE: string = 'ContractBillingCycleType';
  public static readonly CONTRACT_DETAIL_TAX_TYPE: string = 'ContractDetailTaxType';
  public static readonly IMPORTATION_PROFILE_FILE_TYPE: string = 'ImpProfileFileType';
  public static readonly TAXES_VAT_LIST: string = 'TaxesVAT';
  public static readonly FOOD_CONTACT_TYPE_LIST: string = 'FoodContactType';
  public static readonly NUTRICIONAL_CLAIM: string = 'NutricionalClaim';
  public static readonly INGREDIENT_FREE_INFO: string = 'IngredientFreeInfo';
  public static readonly PACKAGE_MATERIALS: string = 'PackageMaterials';
  public static readonly PACKAGE_ALLERGENS_INFO: string = 'PackageAllergensInfo';
  public static readonly PACKAGE_ENVIRONMENT_INFO: string = 'PackageEnvironmentInfo';
  public static readonly PACKAGE_ETHICAL_INFO: string = 'PackageEthicalInfo';
  public static readonly FOOD_PRODUCTION_METHOD: string = 'FoodProductionMethod';
  public static readonly FOOD_CATCH_METHOD: string = 'FoodCatchMethod';
  public static readonly FOOD_FROZEN: string = 'FoodFrozen';
  public static readonly FOOD_CATCH_AREA: string = 'FoodCatchArea';
  public static readonly FOOD_SUB_CATCH_AREA: string = 'FoodSubCatchArea';
  public static readonly CONTAINMENT_LEVEL: string = 'ContainmentLevel';
  public static readonly ALLERGENS: string = 'Allergens';
  public static readonly FOOD_PREPARATION_STATE: string = 'FoodPreparationState';
  public static readonly ENERGY_OR_NUTRIENTS: string = 'EnergyOrNutrients';
  public static readonly NUTRIENTS_PRECISION: string = 'NutrientsPrecision';
  public static readonly VITAMINES_AND_MINERALS: string = 'VitaminesAndMinerals';
  public static readonly NUTRIENTS_PERCENTAGE_TYPE: string = 'NutrientsPercentageType';
  public static readonly OTHER_NUTRIENTS: string = 'OtherNutrients';
  public static readonly FOOD_DATE_INFO: string = 'FoodDateInfo';
  public static readonly PACKAGE_RECYCLE_SCHEME: string = 'PackageRecycleScheme';
  public static readonly PACKAGE_TERMS_AND_CONDITIONS: string = 'PackageTermsAndConditions';
  public static readonly PACKAGE_FUNCTION: string = 'PackageFunction';
  public static readonly WAY_NEXT_TYPE: string = 'WayNextType';
  public static readonly VALUE_ADDED_TAX_TYPE: string = 'ValueAddedTaxType';
  public static readonly INCIDENCE_TYPE: string = 'IncidenceType';
  public static readonly OTHER_REFERENCE_TYPE: string = 'OtherReferenceType';
  public static readonly LOCATION_ASSOCIATION_TYPE: string = 'LocationAssociationType';
  public static readonly QUOTATION_BRAND: string = 'QuotationBrand';
  public static readonly TRANSPORT_CIRCUITS: string = 'TransportCircuits';
  public static readonly ACCOUNT_PRODUCT_TYPE: string = 'AccountProductType';
  public static readonly ACCOUNT_PRODUCT_SUBTYPE: string = 'AccountProductSubType';
  public static readonly ACCOUNTING_CONFIG_TYPE: string = 'AccountingConfigType';
  public static readonly EXPENSE_ACCOUNT: string = 'ExpenseAccount';
  public static readonly ACTIVITY_CODE: string = 'ActivityCode';
  public static readonly INITIATIVE_CODE: string = 'InitiativeCode';
  public static readonly AMORTIZATION_BOOK_CODE: string = 'AmortizationBookCode';
  public static readonly VAT_GROUP: string = 'VatGroup';
  public static readonly FIXED_ASSET_RECORD_TYPE: string = 'FixedAssetRecordType';
  public static readonly VAT_TREATMENT_TYPE: string = 'VatTreatmentType';
  public static readonly PAYMENT_METHOD: string = 'PaymentMethod';
  public static readonly CAES: string = 'CAES';
  public static readonly CONCILIATION_ACCOUNT: string = 'ConciliationAccount';
  public static readonly SORT_KEY: string = 'SortKey';
  public static readonly CASH_MANAGEMENT_GROUP: string = 'CashManagementGroup';
  public static readonly IRF_COUNTRY: string = 'IRFCountry';
  public static readonly PAYMENT_CONDITIONS: string = 'PaymentConditions';
  public static readonly PAYMENT_FORMS: string = 'PaymentForms';
  public static readonly SUPPLIER_GROUP_TYPE: string = 'SupplierGroupType';

  constructor(
    /// ID único e PK da entidade.
    /// Incrementado automáticamente.
    public ID: number = 0,

    /// ID da dropdown
    public DropDownID: string = null,

    /// Codigo do item da dropdown.
    /// Este campo está do tipo ANY para poder converter de STRING em NUMBER em algumas situações específicas
    public ItemCode: any = null,

    /// Denominação do item da dropdown
    public ItemName: string = null,

    /// Ordem de apresentação do item na dropdown
    public ItemOrder: number = null,

    /// Descrição do item da dropdown
    public ItemDescription: string = null,

    /// Codigo do item pai deste valor da dropdown. (null se não tiver)
    public ParentCode: string = null,

    /// Inativo
    public Inactive: boolean = false,

    //Grupo de Empresas
    public CompanyGroup: string = null,

    /** ID da empresa ao qual esta dropdown vai se associar (se tiver null faz para todas) */
    public CompanyID: number = null
  ) { }
}
