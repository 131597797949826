
import { first } from 'rxjs/operators';
import { Component, Optional, NgZone, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgControl } from '@angular/forms';

import { DATE_FORMAT, DATE_HOUR_FORMAT, STEP_MIN, TIME_ZONE } from '../constants/global';

// **__**________  SERVICOS ________ **__**
import { TranslateService } from '@ngx-translate/core';
import { TranslateValueService } from '../services/translate-value.service';

// **__**________  JAVASCRIPT ________ **__**
// import * as moment from 'moment';
import moment from 'moment-timezone';

declare var Functions: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: '.date-picker',
  // host: {
  //     '(blur)': 'onBlur($event)',
  //     '(focus)': 'onFocus($event)'
  // },
  template: `<button type="button" (click)="changeClick($event)" class="dataChange" hidden>
    <ng-content> </ng-content>`
})
// tslint:disable-next-line:component-class-suffix
export class DateTimePickerDirective {
  /// <summary>
  // -Para colocar o datetimepicker e mudar o controlform
  // -É necessario adicionar um button hidden que no evento change é clicado (no init.js)
  /// </summary>

  identificador: string = null;
  @Input() tipoDateTime: number;
  @Input() stepMinutes: number;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() minDays: number;
  @Input() minHour: number;
  @Input() maxHour: number;
  @Input() weekDays: Array<boolean>;
  @Input() orderHourLimit: number;
  @Output() public onChangeDatePicker: EventEmitter<any> = new EventEmitter();
  
 

  static convertToString(date: any, includeTime: boolean, format?: string): string {
    if (!date) {
      return null;
    }

    // Define qual o formato da data que vai usar
    let dateFormat = format ? format : DATE_FORMAT;

    if (includeTime) {
      if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
        return moment(date.replace('/Date(', '').replace(')/', ''), 'x').tz(TIME_ZONE).format(DATE_HOUR_FORMAT);
      } else {
        return moment(date, DATE_HOUR_FORMAT).tz(TIME_ZONE).format(DATE_HOUR_FORMAT);
      }
    } else {
      if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
        return moment(date.replace('/Date(', '').replace(')/', ''), 'x').tz(TIME_ZONE).format(dateFormat);
      } else {
        return moment(date, dateFormat).tz(TIME_ZONE).format(dateFormat);
      }
    }
  }

  static convertToFormat(date: any, includeTime: boolean): string {
    if (!date) {
      return null;
    }

    if (includeTime) {
      if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
        return moment(date.replace('/Date(', '').replace(')/', ''), 'x').format();
      } else {
        return moment(date, DATE_HOUR_FORMAT).format();
      }
    } else {
      if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
        return moment(date.replace('/Date(', '').replace(')/', ''), 'x').format();
      } else {
        return moment(date, DATE_FORMAT).format();
      }
    }
  }

  static convertToDate(date: any, includeTime: boolean): Date {
    if (!date) {
      return null;
    }

    if (includeTime) {
      if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
        return moment(date.replace('/Date(', '').replace(')/', ''), 'x').toDate();
      } else {
        return moment(date, DATE_HOUR_FORMAT).toDate();
      }
    } else {
      if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
        return moment(date.replace('/Date(', '').replace(')/', ''), 'x').toDate();
      } else {
        return moment(date, DATE_FORMAT).toDate();
      }
    }
  }

  static convertHourToString(date: any, format: string = null): string {
    if (!date) {
      return null;
    }

    const HOUR_FORMAT = format ? format : DATE_HOUR_FORMAT.replace(DATE_FORMAT + ' ', '');
    if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
      return moment(date.replace('/Date(', '').replace(')/', ''), 'x').tz(TIME_ZONE).format(HOUR_FORMAT);
    } else {
      return moment(date, DATE_HOUR_FORMAT).tz(TIME_ZONE).format(HOUR_FORMAT);
    }
  }

  static getYear(date: any): number {
    if (typeof date === 'string' && date.indexOf('/Date(') !== -1) {
      return moment(date.replace('/Date(', '').replace(')/', ''), 'x').year();
    } else {
      return moment(date, DATE_FORMAT).year();
    }
  }

  static getTimeDifferenceDates(startDateString: string, endDateString: string): string {
    let startDate = this.convertToDate(startDateString, true);
    let endDate = this.convertToDate(endDateString, true);
    let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
    let timeString = '';

    // Hours
    let hh = Math.floor(timeDiff / 1000 / 60 / 60);
    if (hh < 10) {
      timeString = '0' + hh;
    } else {
      timeString = '' + hh;
    }

    // Minutes
    timeDiff -= hh * 1000 * 60 * 60;
    let mm = Math.floor(timeDiff / 1000 / 60);
    if (mm < 10) {
      timeString += 'h0' + mm;
    } else {
      timeString += 'h' + mm;
    }

    return timeString;
  }

  static getTimeDifferenceSeconds(seconds: number): string {
    let timeDiff = seconds * 1000;
    let timeString = '';

    // Hours
    let hh = Math.floor(timeDiff / 1000 / 60 / 60);
    if (hh < 10) {
      timeString = '0' + hh;
    } else {
      timeString = '' + hh;
    }

    // Minutes
    timeDiff -= hh * 1000 * 60 * 60;
    let mm = Math.floor(timeDiff / 1000 / 60);
    if (mm < 10) {
      timeString += 'h0' + mm;
    } else {
      timeString += 'h' + mm;
    }

    return timeString;
  }

  constructor(@Optional() public formControl: NgControl, public el: ElementRef,
    private _zone: NgZone, private _translate: TranslateValueService,
    private _translate2: TranslateService,
    private _cdr: ChangeDetectorRef) {
    this._zone.onStable.pipe(first()).subscribe(() => {
      this.setFunction();
    });
  }

  changeClick($event: Event) {
    /// <summary>
    /// quando o botao e clicado, o force do click é feito quando o evento change.dt existe no javascript
    /// </summary>
    /// <param name="$event" type="type"></param>

    if (this.formControl && this.formControl.control) {
      let inputEl = null;
      if ($event) {
        let button = <HTMLButtonElement>($event.currentTarget);
        inputEl = button.parentElement;
      } else {
        inputEl = <HTMLInputElement>this.el.nativeElement;
      }

      let valueEl = inputEl.value;

      if (this.formControl) {
        if (this.tipoDateTime === 1) {
          if (valueEl !== '' && valueEl !== '__/__/____' && valueEl !== '31/12/1899') { // verificar se foi introduzida alguma data
            this.formControl.control.setValue(valueEl);
            this.onChangeDatePicker.emit(valueEl);
          } else {
            this.formControl.control.setValue('');
            this.onChangeDatePicker.emit('');
            inputEl.value = '';
          }
        } else {
          this.formControl.control.setValue(valueEl);
          this.onChangeDatePicker.emit(valueEl);
        }
      }
    }
  }


  @HostListener('blur', ['$event'])
  onBlur($event: Event) { // forcar a remoção do datepicker quando sai do campo
    if (this.formControl) {
      let inputEl = <HTMLButtonElement>($event.currentTarget);

      // verificar se o input tem o mesmo valor que o form,
      // porque o utilizador pode ter inserido sem o picker
      //DS: Verificar se é diferente de null porque é necessario nos botões que só gravam quando houver alterações
      if (this.formControl.control.value !== inputEl.value && this.formControl.control.value !== null) {
        this.formControl.control.setValue(inputEl.value);
        this.onChangeDatePicker.emit(inputEl.value);
      }
      

      
      // colocar vazio se so tiver a mascara
      if (this.formControl.control.value === '__/__/____') {
        this.formControl.control.setValue('');
        this.onChangeDatePicker.emit('');
        inputEl.value = '';
      }
    }
  }

  @HostListener('focus', ['$event'])
  onFocus($event: Event) {
    // testar para ver se ja tem o picker, por causa das tabs (se estiver numa tab que nao esta ativa) pode ter sido retirado
    if (!(<HTMLInputElement>this.el.nativeElement).classList.contains('hasDatepicker')) {
      this.setFunction(true);
    }
  }

  setFunction(show: boolean = false) {
    let podeMostar = this.el.nativeElement.getAttribute('disabled') !== ''; // CG os campos disabled tem de entrar na funcao na mesma para formatar a data, mas depois nao faz o show do datepicker
    this.tipoDateTime = this.tipoDateTime ? this.tipoDateTime : 1;
    let formData = this.tipoDateTime === 3 ? DATE_HOUR_FORMAT : DATE_FORMAT;
    this.stepMinutes = this.stepMinutes ? this.stepMinutes : + STEP_MIN;
    let toolTipsTrans = this._translate.datepickerTranslates;
    Functions.dataTimePicker(<HTMLInputElement>this.el.nativeElement, this._translate.translateLanguage,
      this.tipoDateTime, formData, this.stepMinutes, show, toolTipsTrans, this.minDate, this.maxDate, this.minDays, this.minHour, this.maxHour,
      this.weekDays, this.orderHourLimit, podeMostar);
  }
}
