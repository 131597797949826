export class GenericType {
    /// Definição dos tipos para o contexto Pricelist
    public static readonly COMPANY: string = 'Company';
    public static readonly DOCUMENT: string = 'CompanyRepositoryDiskFiles';

    // Definição dos tipos para o contexto Pricelist
    public static readonly PRICELIST_TABELA_GERAL: number = 60;
    public static readonly PRICELIST_LISTA_DE_PREÇOS: number = 61;
    public static readonly PRICELIST_CAMPANHA: number = 62;
    public static readonly PRICELIST_COND_ESPECIAIS: number = 63;

    // Outros...
    public static readonly COMPANY_GENERIC: number = 70;

    public static readonly PROCESS_REQUESTS_GENERIC: number = 74;
    public static readonly PROCESS_REQUESTS_ANSWER: number = 75;
    public static readonly PROCESS_COMPLAINTS_GENERIC: number = 76;
    public static readonly PROCESS_COMPLAINTS_ANSWER: number = 77;
    

    // Transporte
    public static readonly TRANSPORT_REQUEST_ENTREGA_INTEGRATION = 'Delivery';
    public static readonly TRANSPORT_REQUEST_RECOLHA_INTEGRATION = 'Picking';
    public static readonly TRANSPORT_REQUEST_Groupage_INTEGRATION = 'Groupage';
    public static readonly TRANSPORT_REQUEST_INTEGRATED_INTEGRATION = 'Integrated';
    public static readonly TRANSPORT_FORNECEDOR_INTEGRATION = 'Supplier';
    public static readonly TRANSPORT_REQUEST_INTERNAL_INTEGRATION = 'Internal';
    public static readonly TRANSPORT_REQUEST_ENTREGA: number = 90;
    public static readonly TRANSPORT_REQUEST_RECOLHA: number = 91;
    public static readonly TRANSPORT_REQUEST_INTERNAL: number = 92;
    public static readonly TRANSPORT_ENTREGA: number = 100;
    public static readonly TRANSPORT_RECOLHA: number = 101;
    public static readonly TRANSPORT_FORNECEDOR: number = 103;
    public static readonly TRANSPORT_INTERNAL: number = 104;
    public static readonly TRANSPORT_GroupageM: number = 105;

    public static readonly DELIVERY_SUPPLIER:number = 130;
    public static readonly PICKING_SUPPLIER:number = 131;


    public static readonly TRANSPORT_ENTREGA_LOJA: number = 104;
    public static readonly TRANSPORT_RECOLHA_LOJA: number = 105;

    public static readonly TRANSPORT_REQUEST_INBOUND_INTEGRATION = 'Inbound';
    public static readonly TRANSPORT_REQUEST_OUTBOUND_INTEGRATION = 'Outbound';
    public static readonly TRANSPORT_REQUEST_INBOUND = 97;
    public static readonly TRANSPORT_REQUEST_OUTBOUND = 96;
    public static readonly TRANSPORT_INBOUND: number = 107;
    public static readonly TRANSPORT_OUTBOUND: number = 106;

    public static readonly COMPANY_REGISTRATION: number = 320;

    public static readonly EXPENSE_DOCUMENT: number = 334;
    public static readonly EXPENSE_SCAN: number = 335;

    //Documentos Rangel
    public static readonly INVOICE_SAP: number = 336;
    public static readonly CREDITO_MEMO_SAP: number = 337;
    public static readonly DEBIT_MEMO_SAP: number = 338;

    public static readonly RFI_DOCUMENT: number = 403;
    public static readonly FISCAL_RESIDENCY_CERTIFICATE: number = 404;

    constructor(
        /// O ID do tipos de documentos
        public ID: number,

        /// Descrição do tipo de documento
        public Description: string,

        /// O contexto a que se refere (nome da tabela, tipo de documento)
        public Context?: string,

        /// A empresa associada a determinado tipo de documentos,
        /// se NULL, todas as empresas teem acesso ao tipo de documento
        public OwnerID?: number,

        /// Código de integração
        public IntegrationID?: string,

         /// Código de integração
        public CompanyID?: number
    ) { }
}
