import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateValueService } from 'src/app/services/translate-value.service';

@Component({
  selector: 'error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  @Input() control: any;

  constructor(private _translateValueService: TranslateValueService) 
  {}

  ngOnInit(): void {}

  get error() {
    if(!this.control.touched)
      return null;
      
    if (!this.control || !this.control.errors || this.control.errors.length === 0)
      return null;
    
    //Validar se propriedade existe 
    if(!(Object.keys(this.control.errors)?.at(0) in this.allErrors))
      return null;

    
    //Validar se propriedade existe 
    if( !Object.keys(this.control.errors) || !Object.keys(this.control.errors).length || !(Object.keys(this.control.errors)[0] in this.allErrors))
      return null;
      
    let firstError =  Object.keys(this.control.errors)[0];

    if (this.control.errors.hasOwnProperty('params') && firstError)
      return this._translateValueService.get(this.allErrors[firstError], JSON.parse(this.control.errors['params']));

    return firstError ? this._translateValueService.get(this.allErrors[firstError]) : null ;
  }

  allErrors = {
    required: 'FIELD_REQUIRED_',
    lengthMax: 'LENGTH_MAX',
    invalidDate: 'INVALID_DATE',
    invalidRangeDates: 'DATES_BETWEEN',
    areEqual: 'DOES_NOT_ALLOW_SAME_DATES',
    email: 'EMAIL_INVALID',
    numberVal: 'NUMBER_INVALID',
    numberMin: 'NUMBER_MIN_ERROR',
    numberOfDecimalPlaces: 'NUMBER_DECIMAL_ERROR',
    numberMinMax: 'NUMBER_MINMAX_ERROR',
    numberMax: 'NUMBER_MAX_ERROR',
    lessThenCurrentDate: 'DATE_INFERIOR_THAN_CURR_DATE'
  }
}