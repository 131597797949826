import { UntypedFormControl, ValidatorFn, UntypedFormGroup, FormControl } from '@angular/forms';
import { DateTimePickerDirective } from '../../directives/datepicker.directive';

export class ModelValidators {

  static get validDate(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.value) {
        let s = DateTimePickerDirective.convertToString(control.value, false);

        if (s === 'Invalid date') { // -Pode não ser null, mas inválida
          return { 'invalidDate': true };
        } else { // -Se for válida

          return null;
        }
      } else { // -Se for null
        return null;
      }
    };
  }
  /**
   * Valida se a data está entre as datas dos formControls
   * @param  {FormControl} initialDate data inicial
   * @param  {FormControl} endingDate data de encerramento
   * @returns ValidatorFn
   */
  static validDateBetweenInterval(initialDate: FormControl, endingDate: FormControl): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      if (control.value) {
        let expenseDate = DateTimePickerDirective.convertToDate(control.value, false);
        let startDate = DateTimePickerDirective.convertToDate(initialDate.value, false);
        let endDate = DateTimePickerDirective.convertToDate(endingDate.value, false);

        if (expenseDate < startDate || expenseDate > endDate) {
          return {
            'outsideInterval': true
          };
        }else{
          return null;
        }
      } else { // -Se for null
        return null;
      }
    };
  }

  static validIntervalDates(group: UntypedFormGroup, iniData: string, fimData: string, allowEqual: boolean = false): any {
    /// <summary>
    /// Validar se uma data é superior à outra, é uma validacao do form todo
    /// ex:  {
    // validator: (formGroup: FormGroup) => {
    //    return ModelValidators.validIntervalDates(formgroup, 'beginDate', 'endDate', true);
    // }
    /// </summary>
    /// <param name="group" type="FormGroup">Form a validar</param>
    /// <param name="iniData" type="string">Nome do formControl inicial</param>
    /// <param name="fimData" type="string">Nome do formControl inicial</param>
    /// <param name="allowEqual" type="boolean = false">Se true aceita valores iguais para a data inicial e final</param>
    /// <returns type=""></returns>

    let valid = true;
    let iniDateValue: any, fimDateValue: any;

    for (let name in group.controls) {
      if (group.controls.hasOwnProperty(name)) {
        if (name === iniData) {
          iniDateValue = group.controls[name].value;
        }
        if (name === fimData) {
          fimDateValue = group.controls[name].value;
        }
      }
    }
    if (iniDateValue && fimDateValue && group.controls[iniData].value && group.controls[fimData].value) {
      let dataIni = DateTimePickerDirective.convertToDate(iniDateValue, false);
      let dataFim = DateTimePickerDirective.convertToDate(fimDateValue, false);

      if (dataFim < dataIni) {
        valid = false;
        group.controls[fimData].setErrors({ 'invalidRangeDates': true });
        group.controls[iniData].setErrors({ 'invalidRangeDates': true });
        group.controls[iniData].markAsDirty();
        group.controls[fimData].markAsDirty();
        return {
          invalidRangeDates: true
        };
      }

      if (!allowEqual && dataFim.getTime() === dataIni.getTime()) {

        valid = false;
        group.controls[fimData].setErrors({ 'areEqual': true });
        group.controls[iniData].setErrors({ 'areEqual': true });
        group.controls[iniData].markAsDirty();
        group.controls[fimData].markAsDirty();
        return {
          areEqual: true
        };
      }
    }
    if (valid && group.controls[iniData].value && group.controls[fimData].value) {
      let iniDataErrors = group.get(iniData).errors;
      let iniDataErrorsString = new Array<string>();

      if (iniDataErrors != null) {
        Object.keys(iniDataErrors).forEach((keyError: string) => {
          if (keyError !== 'invalidRangeDates' && keyError !== 'areEqual') {
            iniDataErrorsString.push(keyError);
          }
        });
      }
      group.controls[iniData].setErrors(null);
      iniDataErrorsString.forEach((key: string) => {
        group.controls[iniData].setErrors({ key: true });
      });

      let fimDataErrors = group.get(fimData).errors;
      let fimDataErrorsString = new Array<string>();

      if (fimDataErrors != null) {
        Object.keys(fimDataErrors).forEach((keyError: string) => {
          if (keyError !== 'invalidRangeDates' && keyError !== 'areEqual') {
            fimDataErrorsString.push(keyError);
          }
        });
      }
      group.controls[fimData].setErrors(null);
      fimDataErrorsString.forEach((key: string) => {
        group.controls[fimData].setErrors({ key: true });
      });
      return null;
    }
  }

  // exemplo: 'novoCodSeccao': [this.modelSeccao, Validators.compose([Validators.required, ModelValidators.numberVal({ min: 1 })])]
  static numberVal(prms: { min?: number, max?: number, decimalPlaces?: string | number } = {}): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.value === undefined || control.value === null || control.value === '') {
        return null;
      }
      // parametros
      let min = 'min';
      let max = 'max';
      let decimalPlaces = 'decimalPlaces';
      let val: number;

      if (typeof control.value === 'string') {


        //Código retirado do typing.ts, se for preciso mexer aqui, muito provavelmente é preciso mexer no typing::revertDecimal
        if (control.value.toString().indexOf('.') > -1 && control.value.toString().indexOf(',') > -1){
          if (control.value.toString().indexOf('.') > control.value.toString().indexOf(',')){
            // Se a , estiver 1º (EN) vai retirar as virgulas e colocar so a , como .
            val = +control.value.replace(/\,/g, '')
          }else{
            // Se o . estiver 1º (PT) vai retirar os pontos e colocar a , como .
            val = +control.value.replace(/\./g, '').replace(',', '.');
          }
        }else if (control.value.toString().indexOf(',') !== -1){
          //Se só tiver uma , vamos assumir que é o separador decimal e subtituiu
          val = +control.value.replaceAll(',', '.');
        }else{
          //Se for por exemplo 2.0000, converte para int -> 2
          val = +control.value;
        }

        // if (control.value.toString().indexOf(',') !== -1) {
        //   val = +control.value.replace(/\./g, '').replace(',', '.');

        //   if (isNaN(val) && control.value.toString().indexOf('.') > control.value.toString().indexOf(',')){
        //     val = +control.value.replace(/\,/g, '').replace(',', '.')
        //   }

        // } else {
        //   val = +control.value;
        // }
      } else {
        val = control.value;
      }

      // se for um number (decimal ou inteiro)
      if (!isNaN(val)) {
        // se inteiro
        if (+(prms[decimalPlaces]) === 0) {
          if (!/^-?\d+$/.test(val.toString())) { // aceita se tiver "-" ou não (^-?) e só digitos 0-9 (d)
            return { 'numberVal': true };
          }

        } else { // se decimal
          let match = ('' + val).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
          let casasDecimaisContadas = (Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0)));
          // ver se da para por como em baixo
          if (casasDecimaisContadas > prms[decimalPlaces]) {
            return { 'numberOfDecimalPlaces': true, 'params': '{\"0\":' + prms[decimalPlaces] + '}' };
          }
        }

        // quer seja inteiro ou decimal
        if (!isNaN(prms[min]) && !isNaN(prms[max])) {
          return val < prms[min] || val > prms[max] ? {
            'numberMinMax': true, 'params': '{\"0\":' + prms[min] + ',\"1\":' + prms[max] + '}'
          } : null;
        } else if (!isNaN(prms[min])) {
          return val < prms[min] ? { 'numberMin': true, 'params': '{\"0\":' + prms[min] + '}' } : null;
        } else if (!isNaN(prms[max])) {
          return val > prms[max] ? { 'numberMax': true, 'params': '{\"0\":' + prms[max] + '}' } : null;
        } else {
          return null;
        }

      } else { // se não for number
        return { 'numberVal': true };
      }
    };
  }

  static validIntervalNumbers(group: UntypedFormGroup, initalControl: string, finalControl: string, allowEqual: boolean = false): any {
    /// <summary>
    /// Validar se um valor é superior a outro, é uma validacao do form todo
    /// ex:  {
    // validator: (formGroup: FormGroup) => {
    //    return ModelValidators.validIntervalDates(formgroup, 'initalControl', 'finalControl', true);
    // }
    /// </summary>
    /// <param name="group" type="FormGroup">Form a validar</param>
    /// <param name="initalControl" type="string">Nome do formControl inicial</param>
    /// <param name="finalControl" type="string">Nome do formControl final</param>
    /// <param name="allowEqual" type="boolean = false">Se true aceita valores iguais para o valor inicial e final</param>
    /// <returns type=""></returns>

    let valid = true;
    let initialValue: number, finalValue: number;

    for (let name in group.controls) {
      if (group.controls.hasOwnProperty(name)) {
        if (name === initalControl) {
          initialValue = group.controls[name].value;
        }
        if (name === finalControl) {
          finalValue = group.controls[name].value;
        }
      }
    }
    if (initialValue && finalValue) {
      if (finalValue < initialValue) {
        valid = false;
        group.controls[initalControl].setErrors({ 'invalidRangeNumbers': true });
        group.controls[initalControl].markAsDirty();
        group.controls[finalControl].setErrors({ 'invalidRangeNumbers': true });
        group.controls[finalControl].markAsDirty();
        return {
          invalidRangeNumbers: true
        };
      }

      if (!allowEqual && finalValue === initialValue) {
        valid = false;
        group.controls[initalControl].setErrors({ 'areEqual': true });
        group.controls[initalControl].markAsDirty();
        group.controls[finalControl].setErrors({ 'areEqual': true });
        group.controls[finalControl].markAsDirty();
        return {
          areEqual: true
        };
      }
    }
    if (valid) {
      let initialControlErrors = group.get(initalControl).errors;
      let initialControlErrorsString = new Array<string>();

      if (initialControlErrors != null) {
        Object.keys(initialControlErrors).forEach((keyError: string) => {
          if (keyError !== 'invalidRangeNumbers' && keyError !== 'areEqual') {
            initialControlErrorsString.push(keyError);
          }
        });
      }
      group.controls[initalControl].setErrors(null);
      initialControlErrorsString.forEach((key: string) => {
        group.controls[initalControl].setErrors({ key: true });
      });

      let finalControlErrors = group.get(finalControl).errors;
      let finalControlErrorsString = new Array<string>();

      if (finalControlErrors != null) {
        Object.keys(finalControlErrors).forEach((keyError: string) => {
          if (keyError !== 'invalidRangeNumbers' && keyError !== 'areEqual') {
            finalControlErrorsString.push(keyError);
          }
        });
      }
      group.controls[finalControl].setErrors(null);
      finalControlErrorsString.forEach((key: string) => {
        group.controls[finalControl].setErrors({ key: true });
      });
      return null;
    }
  }

  static get notNull(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } => {
      return (control.value === 'null') ? { 'notNull': true } : null;
    };
  }

  /// validar se os dois campos sao iguais, no fields colocar o nome(s) dos campos a comparar
  static areEqual(group: UntypedFormGroup, fields: string | string[]) {
    let valid = true;
    let var1 = null;
    let var2 = null;

    for (let name in group.controls) {
      // if (fields.includes(name))
      if (fields.indexOf(name) > -1) { // se o field faz parte dos que é para verificar se sao iguais
        if (var1 == null) { // se ja tem a primeira variavel preenchida
          var1 = group.controls[name].value;
        } else {
          var2 = group.controls[name].value;
          if (var1 !== var2) {
            valid = false;
          }
        }
      }
    }

    if (valid) {
      return null;
    }

    let field = fields[fields.length - 1];
    if (group.controls.hasOwnProperty(field)) {
      group.controls[field].setErrors({ 'areEqual': true });
    }



    return {
      // vai devolver erro = true
      areEqual: true
    };
  }

  static get emailFormat(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: boolean } => {
      const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

      if (control.value && control.value !== '' && (control.value.length <= 4 || !EMAIL_REGEXP.test(control.value))) {
        return { 'email': true };
      }
      return null;
    };
  }

  // validação intervalo de números
  static lengthVal(prms: {min?: number, max?: number} = {}): ValidatorFn {
    // exemplo: 'novoCodSeccao': [this.modelSeccao, Validators.compose([Validators.required, ModelValidators.lengthVal({ min: 1, max: 50 })])]
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.value === null || control.value === '') {
        return null;
      }
      // parametros
      let min = 'min';
      let max = 'max';
      let val: string = control.value ? control.value : '';

      if (!isNaN(prms[min]) && !isNaN(prms[max])) {
        if (prms[min] !== prms[max]) {
          return val.length < prms[min] || val.length > prms[max] ? {
            'lengthMinMax': true, 'params': '{\"0\":' + prms[min] + ',\"1\":' + prms[max] + '}'
          } : null;
        } else {
          return val.length < prms[min] || val.length > prms[max] ? { 'lengthEquals': true, 'params': '{\"0\":' + prms[min] + '}' } : null;
        }
      } else if (!isNaN(prms[min])) {
        return val.length < prms[min] ? { 'lengthMin': true, 'params': '{\"0\":' + prms[min] + '}' } : null;
      } else if (!isNaN(prms[max])) {
        return val.length > prms[max] ? { 'lengthMax': true, 'params': '{\"0\":' + prms[max] + '}' } : null;
      } else {
        return null;
      }

    };
  }

  // valida se determinado campo começa pelo algarismo inserido
  static validCharacters(prms: any = {}): ValidatorFn {
    // exemplo: ModelValidators.validCharacters({ startBy: 2, charactersToValidate: 0 })
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.value === null || control.value === '') {
        return null;
      }
      // parametros
      let startBy = 'startBy';
      let charactersToValidate = 'charactersToValidate';
      let val: string = control.value;

      if (!isNaN(prms[charactersToValidate])) {
        return (val.charAt(prms[charactersToValidate])).toString() !== (prms[startBy]).toString() ? {
          'charactersPosition': true, 'params': '{\"0\":' + prms[startBy] + '}'
        } : null;
      } else {
        return null;
      }
    };
  }

  static containsValue(items: string[]): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (items.indexOf(control.value) !== -1) {
        return {
          'contains': true
        };
      }
      return null;
    };
  }

  static requiredString(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (!control.value || control.value.trim() === '') {
        return {
          'required': true
        };
      }
      return null;
    };
  }

  // VALIDAÇÃO NIF
  static vatValidator(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (!control.parent || control.parent.get('Country').value !== 'PT') {
        return null;
      }

      if (!control.value || control.value.trim() === '') {
        // return {
        //   'invalidVAT': true
        // };
        return null;
      }

      let vatNumber = control.value;
      let hasError = 0;
      if (
        vatNumber.substr(0, 1) !== '1' && // pessoa singular
        vatNumber.substr(0, 1) !== '2' && // pessoa singular
        vatNumber.substr(0, 1) !== '3' && // pessoa singular
        vatNumber.substr(0, 2) !== '45' && // pessoa singular não residente
        vatNumber.substr(0, 1) !== '5' && // pessoa colectiva
        vatNumber.substr(0, 1) !== '6' && // administração pública
        vatNumber.substr(0, 2) !== '70' && // herança indivisa
        vatNumber.substr(0, 2) !== '71' && // pessoa colectiva não residente
        vatNumber.substr(0, 2) !== '72' && // fundos de investimento
        vatNumber.substr(0, 2) !== '77' && // atribuição oficiosa
        vatNumber.substr(0, 2) !== '79' && // regime excepcional
        vatNumber.substr(0, 1) !== '8' && // empresário em nome individual (extinto)
        vatNumber.substr(0, 2) !== '90' && // condominios e sociedades irregulares
        vatNumber.substr(0, 2) !== '91' && // condominios e sociedades irregulares
        vatNumber.substr(0, 2) !== '98' && // não residentes
        vatNumber.substr(0, 2) !== '99' // sociedades civis
      ) { hasError = 1; }

      let check1 = vatNumber.substr(0, 1) * 9;
      let check2 = vatNumber.substr(1, 1) * 8;
      let check3 = vatNumber.substr(2, 1) * 7;
      let check4 = vatNumber.substr(3, 1) * 6;
      let check5 = vatNumber.substr(4, 1) * 5;
      let check6 = vatNumber.substr(5, 1) * 4;
      let check7 = vatNumber.substr(6, 1) * 3;
      let check8 = vatNumber.substr(7, 1) * 2;

      let total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
      let division = total / 11;
      let module11 = total - parseInt(division + '', 10) * 11;
      let comparator: number;
      if (module11 === 1 || module11 === 0) {
        comparator = 0; // excepção
      } else { comparator = 11 - module11; }

      let lastDigit = vatNumber.substr(8, 1) * 1;
      if (lastDigit !== comparator) { hasError = 1; }

      if (hasError === 1) {
        return {
          'invalidVAT': true
        };
      }

      return null;
    };
  }

  static get lessThenCurrentDate(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.value) {
        let currentDate = new Date()
        currentDate.setHours(0,0,0,0);
        let date = DateTimePickerDirective.convertToDate(control.value, true);
        if (date < currentDate) {
          return {
            'lessThenCurrentDate': true
          };
        }
      }

      return null;
    };
  }

  static get higherThenCurrentDate(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } => {
      if (control.value) {
        let currentDate = new Date()
        currentDate.setHours(0,0,0,0);
        let date = DateTimePickerDirective.convertToDate(control.value, true);
        if (date > currentDate) {
          return {
            'higherThenCurrentDate': true
          };
        }
      }

      return null;
    };
  }


}
