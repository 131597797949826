// bibliotecas
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { DateTimePickerDirective } from '../../../directives/datepicker.directive';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

// servicos
import { TranslateService } from '@ngx-translate/core';
import { TranslateValueService } from '../../../services/translate-value.service';
import { MessageService } from '../../../services/message.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CompanyService } from '../../../services/company.service';
import { UserService } from '../../../services/user.service';

// modelos
import { ModelValidators } from '../../../models/validators/validators';
import { ReturnStatusHtml } from '../../../models/returnStatus';

// constantes
import { ALIAS, LANGUAGE } from '../../../constants/global';

// ***__***_________  MODALS _________ ***__***
import { ChooseModalComponent } from '../choose-modal/choose-modal.component';

// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../../../modules/treatments.module';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  selector: 'app-msg-tab',
  templateUrl: './msg.html'
})
export class MessageTabComponent implements OnInit, OnDestroy, OnChanges {

  @Input() startDate: string = null; // Data de inicio
  @Input() endDate: string = null; // Data de fim
  @Input() context: string = null; // Context
  @Input() contextID: number = null; // EntityID
  @Input() allowDelete: boolean = false; // se vai permitir que o utilizador que criou elimine as mensagens
  @Input() allowCreate: boolean = false; // se vai permitir criar novas mensagens
  @Input() hasFilters: boolean = false; // se tem filtros é chat senão são apenas msg's simples
  @Input() ownerID: number = null; // ID da empresa logada
  @Input() partnerID: number = null; // ID do parceiro enviado para pesquisa
  @Input() userID: number = null; // ID do parceiro enviado para pesquisa
  @Input() allowedCompanies: any = []; //Empresas para enviar mensagem
  listMessages: any = null;
  tableMsg: any = null;
  destroy$: Subject<boolean> = new Subject<boolean>();
  companyIDChoosen: number = 0;
  datai: string = null; // Data atual - 1 mês
  dataf: string = null; // Data atual

  translateValues: Array<string> = null;
  companiesList: Array<any> = new Array<any>();
  usersList: Array<any> = new Array<any>();
  form: UntypedFormGroup;
  noError: boolean = true; // saber se o formulario tem erros
  validationMessages = {};
  allowDeleteMessagesRoleID: number = null;

  constructor(private translateService: TranslateService,
    private translateValueService: TranslateValueService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: UntypedFormBuilder,
    private _messageService: MessageService,
    private companyService: CompanyService,
    private userService: UserService,
    private _dialog: MatDialog,
    private _errorTreat: ErrorTreatmentFunctions,
    private dialog: MatDialog
  ) { 

    this.validationMessages = {
      'MessageContent': {
        'required': 'FIELD_REQUIRED_'
      },
      'StartDate': { 'invalidDate': this.translateValueService.get('INVALID_DATE'), 'invalidRangeDates': this.translateValueService.get('DATES_BETWEEN') },
      'EndDate': { 'invalidDate': this.translateValueService.get('INVALID_DATE'), 'areEqual': this.translateValueService.get('DOES_NOT_ALLOW_SAME_DATES') },
      'OwnerID': {
        'required': 'FIELD_REQUIRED_'
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['allowedCompanies']) {
      this.companiesList = this.allowedCompanies;
    }
  }

  ngOnInit() {

    // Preencher lista com o conteudo enviado pelo contexto 
    this.companiesList = this.allowedCompanies.length > 0 ? this.allowedCompanies : this.companiesList;

    this.allowDeleteMessagesRoleID = this._messageService.getAllowDeleteMessagesRoleID(this.allowDeleteMessagesRoleID);

    if (this.hasFilters) {

      if (this.allowedCompanies && this.allowedCompanies.length > 0)
      {
        this.companyService.getPartners(null, false, true).pipe(takeUntil(this.destroy$)).subscribe((response: Array<any>) => this.companiesList = response);
      }
      else
      {
        this.companyService.getPartners(null, false, true).pipe(takeUntil(this.destroy$)).subscribe((response: Array<any>) => this.companiesList = response);
      }


      this.startDate = DateTimePickerDirective.convertToString(new Date(new Date().setMonth(new Date().getMonth() - 1)), false);
      this.endDate = DateTimePickerDirective.convertToString(new Date(), false);
      // guardar semore estas datas
      this.datai = this.startDate;
      this.dataf = this.endDate;
    }

    this.translateService.get(['BUTTON.DELETE', 'SERVER_TIMEOUT', 'SERVER_ERROR', 'PERMISSIONS_ERROR', 'OCCURRED_ERROR', 'FILTER',
      'BUTTON.CLEAR', 'RECEPTION', 'SENDING', 'NEW_MESSAGE', 'UNREAD_MESSAGE', 'ANSWER']).subscribe((response: string[]) => {
        this.translateValues = response;

        let that = this;
        let aoColumnsMsg = [];

        this._messageService.get(this.context, this.contextID, this.startDate, this.endDate, this.partnerID).pipe(takeUntil(this.destroy$)).subscribe((responseService: ReturnStatusHtml) => {
          if (responseService.ReturnStatus.Successfull) {

            that.listMessages = responseService.ReturnStatus.ReturnObject.Messages;
            // refrescar notificacoes
            that._messageService.refreshNotifications();

            that.listMessages.forEach((msg: any, index: any) => {
              msg.MessageDate = DateTimePickerDirective.convertToString(msg.MessageDate, true);
            });
            that.buildForm();
            if (this.companiesList.length === 1 && this.companiesList[0]) { // se só tiver um empresa carregar logo os dados
              let idSelected = this.companiesList[0].ID;

              this.form.controls['MessageToCompanyID'].setValue(idSelected);
              
              //No contexto 'Chat' o EntityID é o ID da Empresa com que se comunica, nos outros contextos o ID é o da Entidade (p.e Encomenda, Pedido de Cotação)
              /*
              if(this.context == 'Chat') {
                this.form.controls['EntityID'].setValue(idSelected);
              }
              */
              this.form.controls['EntityName'].setValue(this.companiesList[0].Name);
              // carregar lista de utilzadores com a resposta do pedido com o id da empresa selecionada
              this.userService.getAllCompanyUsers(idSelected).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
                this.usersList = resp;
              });
            }

            // verificar se id existe
            if (this.partnerID !== null && typeof (this.partnerID) !== 'undefined') {
              // verificar se o id existe na lista
              let index = this.companiesList.findIndex((r: any) => (+r.ID) === (+this.partnerID));
              if (index > -1) {
                let nome = (this.companiesList.find((r: any) => (+r.ID) === (+this.partnerID)).Name);
                this.form.controls['CompanyNameToFilter'].setValue(nome);
              }
            }

            let buttons = function (id: string, userIDMessage: number, entityID: number, ownerID: number): string { // o que vai ser inserido na coluna de botoes
              let aux = '';

              //GM 2023-06-22 se chat o EntityID é o ID da empresa da sessão
              //O comportamento não será distinto do generico
              /*
              if (that.context === 'Chat') { // Se for Chat, então o contextID é o ID do parceiro
                if (entityID === that.contextID) { // receção
                  aux += '<a class="btn btn-sm table-answer_msg" data-atrrid="' + ownerID + '"><span title="' + that.translateValues['ANSWER'] + '" data-toggle="modal" data-target="#mod-answer"><i class="fas fa-reply-all blue backwhite"></i></span></a>';
                }
                //se o setting da role para eliminar as mensagens estiver definido e o utilizador contiver esta role - deixa eliminar mensagens
                if (that.allowDeleteMessagesRoleID && that._authenticationService.session.roles.includes(that.allowDeleteMessagesRoleID)) {
                  aux += '<a class="btn btn-sm table-delete_msg" data-atrrid="' + id + '"><span title="' + that.translateValues['BUTTON.DELETE'] + '" data-toggle="modal" data-target="#mod-delete"><i class="far fa-trash-alt color-danger backwhite"></i></span></a>';
                } //se o setting não estiver definido, faz o comportamento normal
                else if (that.allowDelete && that.userID === userIDMessage) { // so pode apagar se poder editar a fatura
                  aux += '<a class="btn btn-sm table-delete_msg" data-atrrid="' + id + '"><span title="' + that.translateValues['BUTTON.DELETE'] + '" data-toggle="modal" data-target="#mod-delete"><i class="far fa-trash-alt color-danger backwhite"></i></span></a>';
                }
              } else {
              */
              // Senão o contextID é o ID da entidade onde pertence a mensagem (OrderID, InvoiceID, etc)
              if (ownerID !== that._authenticationService.session.company.ID && that.hasFilters) { // receção
                aux += '<a class="btn btn-sm table-answer_msg" data-atrrid="' + ownerID + '"><span title="' + that.translateValues['ANSWER'] + '" data-toggle="modal" data-target="#mod-answer"><i class="fas fa-reply-all blue backwhite"></i></span></a>';
              }
              //se o setting da role para eliminar as mensagens estiver definido e o utilizador contiver esta role - deixa eliminar mensagens
              if (that.allowDeleteMessagesRoleID && that._authenticationService.session.roles.includes(that.allowDeleteMessagesRoleID)) {
                aux += '<a class="btn btn-sm table-delete_msg" data-atrrid="' + id + '"><span title="' + that.translateValues['BUTTON.DELETE'] + '" data-toggle="modal" data-target="#mod-delete"><i class="far fa-trash-alt color-danger backwhite"></i></span></a>';
              } //se o setting não estiver definido, faz o comportamento normal
              else if (that.allowDelete && ownerID === that._authenticationService.session.company.ID && that.userID === userIDMessage) { // so pode apagar se poder editar a fatura
                aux += '<a class="btn btn-sm table-delete_msg" data-atrrid="' + id + '"><span title="' + that.translateValues['BUTTON.DELETE'] + '" data-toggle="modal" data-target="#mod-delete"><i class="far fa-trash-alt color-danger backwhite"></i></span></a>';
              }
              //}
              return aux;
            };

            let auxIcons = function (ownerID: number, entityID: number, read: boolean): string {
              let aux = '';
              
              //GM 2023-06-22 se chat o EntityID é o ID da empresa da sessão
              /*
              if (that.context === 'Chat') { // Se for Chat, então o contextID é o ID do parceiro
                if (ownerID === that.contextID) { // envio
                  aux += '<i data-toggle="tooltip" title="' + that.translateValues['SENDING'] + '" class="fas fa-long-arrow-alt-left green" style="font-size: 15px;"></i>';
                  if (!read) { // se estiver não lida
                    aux += '<br><i data-toggle="tooltip" title="' + that.translateValues['UNREAD_MESSAGE'] + '" class="far fa-envelope blue"></i>';
                  }
                }
                if (entityID === that.contextID) { // receção
                  aux += '<i data-toggle="tooltip" title="' + that.translateValues['RECEPTION'] + '" class="fas fa-long-arrow-alt-right red" style="font-size: 15px;"></i>';
                  if (!read) { // se estiver não lida
                    aux += '<br><i data-toggle="tooltip" title="' + that.translateValues['NEW_MESSAGE'] + '" class="fas fa-envelope blue"></i>';
                  }
                }
              } else {
                */
              // Senão o contextID é o ID da entidade onde pertence a mensagem (OrderID, InvoiceID, etc)
              if (ownerID === that._authenticationService.session.company.ID) { // envio
                aux += '<i data-toggle="tooltip" title="' + that.translateValues['SENDING'] + '" class="fas fa-long-arrow-alt-left green" style="font-size: 15px;"></i>';
                if (!read) { // se estiver não lida
                  aux += '<br><i data-toggle="tooltip" title="' + that.translateValues['UNREAD_MESSAGE'] + '" class="far fa-envelope blue"></i>';
                }
              }
              if (ownerID !== that._authenticationService.session.company.ID) { // receção
                aux += '<i data-toggle="tooltip" title="' + that.translateValues['RECEPTION'] + '" class="fas fa-long-arrow-alt-right red" style="font-size: 15px;"></i>';
                if (!read) { // se estiver não lida
                  aux += '<br><i data-toggle="tooltip" title="' + that.translateValues['NEW_MESSAGE'] + '" class="fas fa-envelope blue"></i>';
                }
              }
              //}
              return aux;
            };

            aoColumnsMsg.push({ 'data': 'ID', 'class': 'verticalMiddle' });
            aoColumnsMsg.push({ 'data': 'MessageFromID', 'class': 'verticalMiddle' });
            aoColumnsMsg.push({ 'data': 'HasRead', 'class': 'verticalMiddle' });
            aoColumnsMsg.push({ 'data': 'OwnerID', 'class': 'verticalMiddle' });
            aoColumnsMsg.push({ 'data': 'EntityID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return auxIcons(full.OwnerID, data, full.HasRead); } });
            aoColumnsMsg.push({ 'data': 'MessageDate', 'class': 'verticalMiddle reducedSize' }); // data criação
            if (this.hasFilters) {
              aoColumnsMsg.push({ 'data': 'OwnerName', 'class': 'verticalMiddle reducedSize' }); // empresa origem
            }
            aoColumnsMsg.push({ 'data': 'MessageFromName', 'class': 'verticalMiddle reducedSize' }); // utilizador origem
            if (this.hasFilters) {
              aoColumnsMsg.push({ 'data': 'EntityName', 'class': 'verticalMiddle reducedSize' }); // empresa destino
              aoColumnsMsg.push({ 'data': 'MessageToName', 'class': 'verticalMiddle reducedSize' }); // utilizador destino
            }
            aoColumnsMsg.push({ 'data': 'MessageContent', 'class': 'verticalMiddle reducedSize' });
            aoColumnsMsg.push({ 'data': 'ID', 'width': '110px', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data, full.MessageFromID, full.EntityID, full.OwnerID); } });

            let translates = {
              serverTimeout: that.translateValues['SERVER_TIMEOUT'],
              serverError: that.translateValues['SERVER_ERROR'],
              clear: response['BUTTON.CLEAR'],
              search: response['FILTER']
            },
              columnDefsMsg = [
                { 'targets': [0, 1, 2, 3], 'visible': false }, // colocar como hidden o que vier do ficheiro de configuracoes
                { 'targets': [0, 1, 2, 3, 4, -1], 'orderable': false }, // nao permitir ordenar pelas colunas
              ];

            that.tableMsg = Functions.datatablesWithDataSet('#tableMessages', that.listMessages, translates, aoColumnsMsg, columnDefsMsg,
              ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 5, false, false, false, false);

            that.tableMsg.on('draw', function () {
              let btnsDelete = document.getElementsByClassName('table-delete_msg');
              for (let btnDelete of Array.from(btnsDelete)) {
                btnDelete.addEventListener('click', function (ev: any) {
                  that.onRemoveMessage(this.getAttribute('data-atrrid'));
                });
              }

              let btnsAnswer = document.getElementsByClassName('table-answer_msg');
              for (let btnAnswer of Array.from(btnsAnswer)) {
                btnAnswer.addEventListener('click', function (ev: any) {
                  that.onAnswerMessage(this.getAttribute('data-atrrid'));
                });
              }
            });

            // Atualizar o contador
            that.updateMessagesCounter();
          } else { // o que acontece se der erro
            that._errorTreat.treatErrorResponse(responseService);
          }
        }); // fim do pedido do servico
      }); // fim do translate
  }

  /* tslint:disable:member-ordering */
  formErrors = {
    'MessageContent': '',
    'StartDate': '',
    'EndDate': '',
    'OwnerID': ''
  };
  /**
   * Atualizar o contador de mensagens
   */
  updateMessagesCounter() {
    let element = document.getElementById('MessagesTitle');

    // Verifica se é para colocar contador de mensagens
    if (element) {
      // Limpa o contador
      // element.innerText = element.innerText.split('(')[0].trim();
      element.innerHTML = element.innerHTML.split(' <span class="badge">')[0];

      // Adiciona o contador se existirem mensagens
      if (this.listMessages.length > 0) {
        // element.innerText = element.innerText + ' (' + this.listMessages.length + ')';
        element.innerHTML = element.innerHTML + ' <span class="badge">' + this.listMessages.length + '</span>';
      }
    }
  }

  onValueChanged(value?: any) {
    this.noError = true;
    if (!this.form) { return; }
    const form = this.form;
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);

        if (control && !control.valid && control.enabled && control.dirty) {
          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {
              this.formErrors[field] = messages[key] + ' ';
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  } // fim onValueChanged

  buildForm(): void {
    let auxDate = DateTimePickerDirective.convertToString(new Date(), true);
    // let isDisabled: boolean = this.hasFilters ? true : false;
    let contextOrSelectedCompanyIDValue;

    
    //No contexto 'Chat' o EntityID é o ID da Empresa com que se comunica, nos outros contextos o ID é o da Entidade (p.e Encomenda, Pedido de Cotação)
    //GM 2023-06-22 se chat o EntityID é o ID da empresa da sessão
    contextOrSelectedCompanyIDValue = this.contextID;
    /*
    if(this.context != 'Chat'){
      contextOrSelectedCompanyIDValue = this.contextID;
    }else{
      // se não tiver filtros é o componente msg's normal, caso contrário é o chat
      contextOrSelectedCompanyIDValue = !this.hasFilters ? this.contextID : null;
    }
    */
    let ownerIDValue = this.hasFilters ? this.ownerID : null;

    this.form = this._formBuilder.group({
      'ID': [],
      'StartDate': [this.startDate, Validators.compose([ModelValidators.validDate])],
      'EndDate': [this.endDate, Validators.compose([ModelValidators.validDate])],
      'Context': [this.context],
      'EntityID': [contextOrSelectedCompanyIDValue], // se tiver hasFilters = true, EntityID = ID da empresa selecionada na combobox Empresa
      'MessageDate': [{ value: auxDate, disabled: true }, Validators.compose([ModelValidators.validDate])],
      'MessageContent': [null, Validators.required],
      'MessageFromID': [null, ''],
      'MessageFromName': [null, ''],
      'MessageToID': [null, ''],
      'MessageToName': [{ value: null, disabled: true }, ''],
      'EntityName': [{ value: null, disabled: true }, ''],
      'CompanyNameToFilter': [{ value: null, disabled: true }, ''],
      'OwnerID': [ownerIDValue, ''], // se tiver hasFilters = true, = ID da empresa logada
      'MessageToCompanyID': [null, ''] // armazena o ID da empresa destino da mensagem, se null é para todos
    },
      {
        validator: (formgroup: UntypedFormGroup) => {
          return ModelValidators.validIntervalDates(formgroup, 'StartDate', 'EndDate', true);
        }
      }
    );

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
      ModelValidators.validIntervalDates(this.form, 'StartDate', 'EndDate', true);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação
  }

  // Apagar mensagem
  onRemoveMessage(id: number) {
    if (!this.allowDelete) { // valida se permite a eliminação
      Functions.gritter(this.translateValues['PERMISSIONS_ERROR'], 'danger');
      return;
    }
    let msgToDelete = this.listMessages.filter((msg: any) => Number(msg.ID) === Number(id));
    if (msgToDelete.length !== 1) {
      Functions.gritter(this.translateValues['OCCURRED_ERROR'], 'danger');
      return;
    }
    msgToDelete = msgToDelete[0];
    let userCriator = msgToDelete.MessageFromID;
    let userID = this._authenticationService.session.user.ID;

    if (userCriator === userID) {
      let dialogRef = this._dialog.open(DeleteModalComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._messageService.delete(id).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if (response.ReturnStatus.Successfull) {
              // retirar da lista de mensagens
              const index: number = this.listMessages.indexOf(msgToDelete);
              if (index !== -1) {
                this.listMessages.splice(index, 1);
              }
              this.tableMsg.clear(); // limpar a tabela
              this.tableMsg.rows.add(this.listMessages).draw(); // preencher tabela com novos dados
              Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');

              // Atualizar o contador
              this.updateMessagesCounter();
            } else {
              this._errorTreat.treatErrorResponse(response);
            }
          });
        }
      });
    } else {
      Functions.gritter(this.translateValues['PERMISSIONS_ERROR'], 'danger');
    }
  }

  // Responder a mensagem
  onAnswerMessage(ownerID: number) {
    let index = this.companiesList.findIndex((r: any) => (+r.ID) === (+ownerID));

    if (index > -1) {
      // id da empresa selecionada = EntityID;
      this.form.controls['MessageToCompanyID'].setValue(this.companiesList[index].ID);
      
      //No contexto 'Chat' o EntityID é o ID da Empresa com que se comunica, nos outros contextos o ID é o da Entidade (p.e Encomenda, Pedido de Cotação)
      /*
      if(this.context == 'Chat'){
        this.form.controls['EntityID'].setValue(this.companiesList[index].ID);
      }
      */
      this.form.controls['EntityName'].setValue(this.companiesList[index].Name);
      this.companyIDChoosen = this.companiesList[index].ID;
      // limpar lista;
      this.usersList = [];
      // limpar user antigo
      this.form.controls['MessageToID'].setValue(null);
      this.form.controls['MessageToName'].setValue(null);
      // re(carregar) lista de utilzadores com a resposta do pedido com o id da empresa selecionada
      this.userService.getAllCompanyUsers(this.companyIDChoosen).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
        this.usersList = resp;
      });
      // forçar focus na mensagem
      document.getElementById('messageInput').focus();
    }
  }

  onSearch() {
    let that = this;
    this._messageService.get(this.context, this.contextID, this.form.get('StartDate').value, this.form.get('EndDate').value, this.partnerID).pipe(takeUntil(this.destroy$)).subscribe((responseService: ReturnStatusHtml) => {
      if (responseService.ReturnStatus.Successfull) {
        that.listMessages = responseService.ReturnStatus.ReturnObject.Messages;
        that.listMessages.forEach((msg: any, index: any) => {
          msg.MessageDate = DateTimePickerDirective.convertToString(msg.MessageDate, true);
        });
        that.tableMsg.clear(); // limpar a tabela
        that.tableMsg.rows.add(that.listMessages).draw(); // preencher tabela com novos dados
      } else { // o que acontece se der erro
        that._errorTreat.treatErrorResponse(responseService);
      }
    }); // fim do pedido do servico
  }

  onSend() {
    if (!this.allowCreate) { // valida se permite a criação
      Functions.gritter(this.translateValues['PERMISSIONS_ERROR'], 'danger');
      return;
    }
    let auxDate = DateTimePickerDirective.convertToString(new Date(), true);
    this.form.get('MessageDate').setValue(auxDate);
    this.noError = true; // limpar variavel que verifica se há erros
    this.onValueChanged();

    if (this.noError) {
      if (!this.hasFilters) {
        this.addMessage();
      } else {
        if ((this.form.get('EntityID').value === null || this.form.get('EntityID').value === '')) {
          Functions.gritter(this.translateValueService.get('YOU_MUST_SELECT_THE_RECIPIENTS'), 'danger');
        } else {
          this.addMessage();
        }
      }
    }
  }

  addMessage() {
    let that = this;
    this._messageService.add(this.form.getRawValue(), this.datai, this.dataf, this.partnerID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        if (response.ReturnStatus.ReturnObject) {
          that.listMessages = response.ReturnStatus.ReturnObject.Messages;
          that.listMessages.forEach((msg: any, index: any) => {
            msg.MessageDate = DateTimePickerDirective.convertToString(msg.MessageDate, true);
          });
          // refrescar notificacoes
          that._messageService.refreshNotifications();
          that.tableMsg.clear(); // limpar a tabela
          that.tableMsg.rows.add(that.listMessages).draw(); // preencher tabela com novos dados
        }

        this.form.get('MessageContent').setValue(null);
        this.form.get('MessageContent').markAsPristine();
        this.form.get('MessageContent').markAsUntouched();
        this.formErrors.MessageContent = '';

        if (that.hasFilters) {
          this.form.get('StartDate').setValue(this.datai);
          this.form.get('EndDate').setValue(this.dataf);
        }

        if (!response.ReturnStatus.WarningMessage) {
          Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
        } else {
          Functions.gritter(response.ReturnStatus.WarningMessage, 'warning');
        }

        // Atualizar o contador
        this.updateMessagesCounter();
      } else {
        this._errorTreat.treatErrorResponse(response);
      }
    });
  }

  // Selecionar empresa destino
  selectCompanyID() {

    // Preencher lista com o conteudo enviado pelo contexto   
    this.companiesList = this.allowedCompanies.length > 0 ? this.allowedCompanies : this.companiesList;

    this.translateService.get(['SELECT_COMPANY', 'CODE', 'NAME']).subscribe(response => {
      let that = this;

      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.companiesList, null, response['SELECT_COMPANY'], aoColumns, columnDefs, null, 1,
              null, null, that.form.controls['EntityID'].value),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.companiesList.findIndex((r: any) => r.ID === resultID);
            // id da empresa selecionada = EntityID;
            this.form.controls['MessageToCompanyID'].setValue(this.companiesList[index].ID);
            
            //No contexto 'Chat' o EntityID é o ID da Empresa com que se comunica, nos outros contextos o ID é o da Entidade (p.e Encomenda, Pedido de Cotação)
            /*
            if(this.context == 'Chat'){
              this.form.controls['EntityID'].setValue(this.companiesList[index].ID);
            }
            */
            this.form.controls['EntityName'].setValue(this.companiesList[index].Name);
            this.companyIDChoosen = this.companiesList[index].ID;
            // limpar lista;
            this.usersList = [];
            // limpar user antigo
            this.form.controls['MessageToID'].setValue(null);
            this.form.controls['MessageToName'].setValue(null);
            // re(carregar) lista de utilzadores com a resposta do pedido com o id da empresa selecionada
            this.userService.getAllCompanyUsers(this.companyIDChoosen).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
              this.usersList = resp;
            });
          }
        }
      });
    });
  }

  // Seleciona utilizador destino
  selectUserID() {
    this.translateService.get(['SELECT_USER', 'NAME']).subscribe(response => {
      let that = this;

      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 1 - nome user
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        // { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.usersList, null, response['SELECT_USER'], aoColumns, columnDefs, null, 1,
              null, null, that.form.controls['MessageToID'].value, true),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            if (result === -1) {
              this.form.controls['MessageToID'].setValue(null);
              this.form.controls['MessageToName'].setValue(null);
            } else {
              let index = this.usersList.findIndex((r: any) => r.ID === resultID);
              // id do user selecionado é representado pelo EntityID;
              this.form.controls['MessageToID'].setValue(this.usersList[index].ID);
              this.form.controls['MessageToName'].setValue(this.usersList[index].Name);
            }
          }
        }
      });
    });
  }

  // Selecionar ID empresa a filtrar
  selectCompanyIDToFilter() {
    let idSelected = null;

    if (this.partnerID !== null && typeof (this.partnerID) !== 'undefined') {
      idSelected = this.partnerID.toString();
    }

    this.translateService.get(['SELECT_COMPANY', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.companiesList, null, response['SELECT_COMPANY'], aoColumns, columnDefs, null, 1,
              null, null, idSelected, true),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            if (result === -1) {
              this.form.controls['CompanyNameToFilter'].setValue(null);
              this.partnerID = null;
            } else {
              let index = this.companiesList.findIndex((r: any) => r.ID === resultID);
              // id da empresa selecionada = EntityID;
              this.form.controls['CompanyNameToFilter'].setValue(this.companiesList[index].Name);
              this.partnerID = this.companiesList[index].ID;
            }
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.tableMsg.destroy();
  }

}
