import { Component, Input, OnInit } from '@angular/core';
import { log } from 'console';

@Component({
  selector: 'panel',
  templateUrl: './panel.component.html',
  styles: ['.margin { margin-right: 10px}']
})
export class PanelComponent implements OnInit {

  @Input() id: string = '';
  @Input() title: string = '';
  @Input() rightTitle: string = '';
  @Input() invalid: boolean = false;
  /** Se o painel esta expandido por defeito */
  @Input() expanded = true;

  isPanelOpen: boolean = this.expanded;

  constructor() { }

  ngOnInit() {
  }

  togglePanelOpen() {
    this.isPanelOpen = !this.isPanelOpen;
  }

}
