///<reference path="typings.d.ts" />
import { DecimalPipe } from '@angular/common';

// Extensão genérica para arredondamentos de casa decimais
Number.prototype.round = function (places: number | string): number {
  return +(Math.round(+(this + 'e+' + places)) + 'e-' + places);
};

// Converte o número para string formatado de acordo com as casas decimais pretendidas
Number.prototype.formatDecimal = function (language: string, decimalPlaces: number): string {
  let decimalPipe = new DecimalPipe(language);
  return decimalPipe.transform(this, '1.' + decimalPlaces + '-' + decimalPlaces);
};

/**
 * Adicionar zeros à esquerda
 * @param  {number} size Tamanho total da string a ser devolvida
 * @returns string
 */
Number.prototype.pad = function (size: number): string {
  let s = this + '';
  while (s.length < size) { s = '0' + s; }
  return s;
};

// Reverter o numero formatado de acordo com as casas decimais pretendidas (que é uma string) p/ numero
String.prototype.revertDecimal = function (): number {
  // verificar se o valor é um numero e retirar caso seja preciso as formatacoes
  let numberValue: number = 0;
  if (+this === 0) {
    return 0;
  }
  if (!this) {
    return null;
  }

  if (isNaN(+this)) 
  {
    //GM 2024-08-20 Se tiver . e , pode ser o separador do milhar e do decimal
    //Nota em PT o separador do milhar é . e o separador é , e em EN é ao contrário
    //Vou alterar o comportamento porque como estava só funcionava para PT
    //Alteração para ter em conta que o 1º separador presente no nº será o do milhar
    if (this.indexOf('.') && this.indexOf(','))
    {
      if (this.indexOf('.') > this.indexOf(','))
      {
        // Se a , estiver 1º (EN) vai retirar as virgulas e colocar so a , como .
        numberValue = +this.split(',').join('');
      }
      else
      {
        // Se o . estiver 1º (PT) vai retirar os pontos e colocar a , como .
        numberValue = +this.split('.').join('').replace(',', '.');
      }
    }
    else if (this.indexOf(',')) 
    {
      numberValue = +this.replace(',', '.');
    }
    if (isNaN(numberValue)) {
      return null;
    }
  } else 
  {
    numberValue = +this;
  }
  return numberValue;
};


/**
 * fazer a substituição numa string uma substring por outra, substitui todas as ocorrencias da substring
 * @param  {string} toReplace - substring a substituir
 * @param  {string} forReplace - substring nova para repor a que foi retirada
 * @returns string
 */
// String.prototype.replaceAll = (toReplace: string, forReplace: string): string => {
//   return this.split(toReplace).join(forReplace);
// };

/**
 * Substituir caracteres na posição indicada
 * @param  {number} index Indíce da posição a susbstituir os caracteres
 * @param  {string} replacement Conjunto de caracteres para substituir
 */
String.prototype.replaceAt = function (index: number, replacement: string): string {
  return this.substr(0, index) + replacement + this.substr(index + replacement.length);
};

Array.prototype.equals = function <T>(array: T[]) {
  if (!array)
      return false;

  if (this.length !== array.length)
      return false;

  for (let i = 0, l = this.length; i < l; i++) {
      if (this[i] !== array[i]) {
          return false;
      }
  }
  return true;
}


