
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { AuthenticationService } from './authentication.service';
import { DeliveryNote } from '../models/deliveryNote';
import { of as observableOf, Observable } from 'rxjs';
import { MasterService } from './master.service';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { DataTableFunctions } from '../modules/datatables.module';


@Injectable()
export class DeliveryNoteService {

  // deliveryNote = new DeliveryNote();
  // detailsList: DeliveryNoteDetail[];
  // deliveryNoteDetail = new DeliveryNoteDetail();
  dataIniSale: string;
  dataFimSale: string;
  dataIniPurchase: string;
  dataFimPurchase: string;
  dataIniDispatch: string;
  dataFimDispatch: string;
  dataIniAccesory: string;
  dataFimAccesory: string;
  dataIniGlobal: string;
  dataFimGlobal: string;
  orderNumberFilter: string;
  datatableParametersPurchases: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersSales: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersDispatch: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersAccesory: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);
  datatableParametersGlobal: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, null, 0);

  private _deliveryNoteTypes: Array<any>;
  private _deliveryNoteStates: Array<any>;
  private _deliveryNotesReturnReasons: Array<any>;

  // Settings Guia
  private _showDeliveryNoteType: boolean;
  private _defaultDeliveryNoteType: string;
  private _showCodeAT: boolean;
  private _showCodeAT_Purchase: boolean;
  private _showReturnNoteNumber: boolean;

  private _showBuyerDepartmentCode: boolean;
  private _showBuyerDepartmentName: boolean;
  private _showBuyerDivisionCode: boolean;

  private _showPanelPlaceOfLoading: boolean; // panel: local de carga
  private _showPanelPlaceOfDelivery: boolean; // panel: local de descarga
  private _showPanelClient: boolean; // panel: local de Client
  private _showPanelReferences: boolean; // panel: local de References
  private _showPanelPackages:boolean; //panel: local de Packages
  private _showPanelObservations:boolean; // panel: observações

  private _showPackType: boolean;
  private _showPackQuantity: boolean;
  private _showPackDimension: boolean;
  private _showTotalNetVolume: boolean;
  private _showTotalNetVolumeUOMCode: boolean;
  private _isDeliveryToRequired: boolean;
  private _editableDeliveryPanelFields: boolean;
  private _editableBuyerPanelFields: boolean;
  private _editableBuyerPanelFieldsAccessoryDeliveryNote: boolean;
  private SALE_showOthersReferencesTypes: boolean;
  private PURCHASE_showOthersReferencesTypes: boolean;
  private hideOtherReferences_PURCHASES: boolean; //Esconde a Tabela "Outras Referências"
  private hideNetWeight_PURCHASES: boolean; //esconde peso liquido
  private hideGrossWeight_PURCHASES: boolean; //esconde peso bruto
  private hideNetVolume_PURCHASES: boolean; //esconde volume liquido
  private showPalleteNumber_PURCHASES: boolean; //mostra o nr de paletes
  private showVolumeNumber_PURCHASES: boolean; //mostra o nr de volumes
  private showTotalWeight_PURCHASES: boolean; // mostra o peso total 
  private PURCHASE_showEdiPanel: boolean;
  private _showTotalPackages: boolean;
  private hideOtherReferences_SALES: boolean; //Esconde a Tabela "Outras Referências"
  private showNumberOfVolumes: boolean;
  private showNumberOfPalletes: boolean;
  private showTotalWeight: boolean;
  private hideNetWeight: boolean;
  private hideGrossWeight: boolean;

  private buyerOrderNumberMandatory: boolean; //Valida se a seleção do Nº de Encomenda é obrigatório
  private hideAddDetailOnOrderSelect: boolean; //Valida se é necessário esconder o botão para adicionar Novos Detalhes à Guia ao selecionar uma Encomenda

  private useSupplierMainAddressAsDefaultLoadingLocation: boolean; // Valida se usa a Morada preenchida na Ficha do Fornecedor como Morada Default para Local de Entrega
  private hideLoadingLocationSelector: boolean; //Valida se esconde o Selector do Local de Entrega

  private afterSaveRemainOnSamePage: boolean;
  private redirectOnUpdate: boolean;

  // settings Detalhes da Guia
  private _showPackagingPanel: boolean;
  private _showPackageSequencePanel: boolean;
  private _showBuyerProductCode: boolean;
  private _showSupplierProductCode: boolean;
  private _showBatchNumber: boolean;
  private _showBatchExpiryDate: boolean;
  private _allowCreateEmptyDetail: boolean;
  private _showProductsAutomatically: boolean;
  private _showButtonSelectProducts: boolean;
  private _showProdutcsListRole: number;
  private _showReferencesPanel_sales: boolean;
  private _showReferencesPanel_purchase: boolean;
  private _showAlternativeColumns: boolean;
  private _showChooseOrder: boolean;
  private _getAskDocNumberToChangeState: boolean;
  private _getdefaultWeightUOMCode: string;
  private _getdefaultLoadingHours:number;
  private _getdefaultDischargeHours:number;
  private _getdefaultUOMCode: string;
  private _showPackingPanel: boolean;
  private _getdefaultTypeFilter: string;
  SALES_columnInVisible: Array<number>;
  SALES_fillEndDate: boolean;
  PURCHASES_columnInVisible: Array<number>;
  PURCHASES_fillEndDate: boolean;
  private optionalStandardProductCode: boolean; //Setting que indica se o StandardCode (EAN) é opcional
  private _blockSupplierFields: boolean; //Setting que indica se o StandardCode (EAN) é opcional

  private _showATCUD_purchase: boolean;
  private _showATCUD_sale: boolean;
  private _orderNumberReadOnly_sale: boolean;


  // Settings Accessory 
  private ACCESSORY_showDeliveryNoteType: boolean;
  private ACCESSORY_showCodeAT: boolean;
  private ACCESSORY_showReturnNoteNumber: boolean;
  
  private ACCESSORY_showBuyerDepartmentCode: boolean;
  private ACCESSORY_showBuyerDepartmentName: boolean;
  private ACCESSORY_showBuyerDivisionCode: boolean;
  
  private ACCESSORY_showPanelPlaceOfLoading: boolean; // panel: local de carga
  private ACCESSORY_showPanelPlaceOfDelivery: boolean; // panel: local de descarga
  private ACCESSORY_showPanelClient: boolean; // panel: local de Client
  private ACCESSORY_showPanelReferences: boolean; // panel: local de References
  private ACCESSORY_showPanelPackages:boolean; //panel: local de Packages
  private ACCESSORY_showPanelObservations:boolean; // panel: observações
  
  private ACCESSORY_showPackType: boolean;
  private ACCESSORY_showPackQuantity: boolean;
  private ACCESSORY_showPackDimension: boolean;
  private ACCESSORY_showTotalNetVolume: boolean;
  private ACCESSORY_showTotalNetVolumeUOMCode: boolean;
  private ACCESSORY_isDeliveryToRequired: boolean;
  private ACCESSORY_editableDeliveryPanelFields: boolean;
  private ACCESSORY_editableBuyerPanelFields: boolean;
  private ACCESSORY_editableBuyerPanelFieldsAccessoryDeliveryNote: boolean;
  private ACCESSORY_columnInVisible: Array<number>;
  private ACCESSORY_fillEndDate: boolean;
  private ACCESSORY_showOthersReferencesTypes: boolean;

    // Settings Accessory 
    private GLOBAL_showDeliveryNoteType: boolean;
    private GLOBAL_showCodeAT: boolean;
    private GLOBAL_showReturnNoteNumber: boolean;
    
    private GLOBAL_showBuyerDepartmentCode: boolean;
    private GLOBAL_showBuyerDepartmentName: boolean;
    private GLOBAL_showBuyerDivisionCode: boolean;
    
    private GLOBAL_showPanelPlaceOfLoading: boolean; // panel: local de carga
    private GLOBAL_showPanelPlaceOfDelivery: boolean; // panel: local de descarga
    private GLOBAL_showPanelClient: boolean; // panel: local de Client
    private GLOBAL_showPanelReferences: boolean; // panel: local de References
    private GLOBAL_showPanelPackages:boolean; //panel: local de Packages
    private GLOBAL_showPanelObservations:boolean; // panel: observações
    
    private GLOBAL_showPackType: boolean;
    private GLOBAL_showPackQuantity: boolean;
    private GLOBAL_showPackDimension: boolean;
    private GLOBAL_showTotalNetVolume: boolean;
    private GLOBAL_showTotalNetVolumeUOMCode: boolean;
    private GLOBAL_isDeliveryToRequired: boolean;
    private GLOBAL_editableDeliveryPanelFields: boolean;
    private GLOBAL_editableBuyerPanelFields: boolean;
    private GLOBAL_editableBuyerPanelFieldsAccessoryDeliveryNote: boolean;
    private GLOBAL_columnInVisible: Array<number>;
    private GLOBAL_fillEndDate: boolean;
    private GLOBAL_showOthersReferencesTypes: boolean;

  DISPATCH_columnInVisible: Array<number>;
  DISPATCH_fillEndDate: boolean;


  columnInVisible_Details_S: Array<number>;
  columnInVisible_Details_P: Array<number>;

  private _controller: string = 'DeliveryNote';

  constructor(private http: HttpClientCustom, private authenticationService: AuthenticationService,
    private _masterService: MasterService,
    private _errorTreat: ErrorTreatmentFunctions,
    private _dataTableF: DataTableFunctions) {
    // tamanho do array = ao nº de colunas no html correspondente
  }

  get deliveryNoteTypes(): Observable<Array<any>> {
    if (this._deliveryNoteTypes === undefined) {
      return this.getComplements('GetAllTypes').pipe(
        map((response: ReturnStatusHtml) => {
          if (response.ReturnStatus.Successfull) {
            return this._deliveryNoteTypes = response.ReturnStatus.ReturnObject.Types;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(response);
          }
        }));
    } else {
      return observableOf(this._deliveryNoteTypes);
    }
  }

  get deliveryNoteStates(): Observable<Array<any>> {
    if (this._deliveryNoteStates === undefined) {
      return this.getComplements('GetAllStates').pipe(
        map((response: ReturnStatusHtml) => {
          if (response.ReturnStatus.Successfull) {
            return this._deliveryNoteStates = response.ReturnStatus.ReturnObject.States;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(response);
          }
        }));
    } else {
      return observableOf(this._deliveryNoteStates);
    }
  }

  get DeliveryNotesReturnReasons(): Observable<Array<any>>{
    if (this._deliveryNotesReturnReasons === undefined) {
      return this.getComplements('GetAllDeliveryNotesReturnReasons').pipe(
        map((response: ReturnStatusHtml) => {
          if (response.ReturnStatus.Successfull) {
            return this._deliveryNotesReturnReasons = response.ReturnStatus.ReturnObject.Types;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(response);
          }
        }));
    } else {
      return observableOf(this._deliveryNotesReturnReasons);
    }
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getSALES_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.SALES_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveriesNotesSale', 'ColumVisible');
      this.SALES_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.SALES_columnInVisible;
  }

  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getSALES_fillEndDate(defaultValue: boolean): boolean {
    if (typeof this.SALES_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveriesNotesSale', 'FillEndDate');
      if (settingValue != null) {
        this.SALES_fillEndDate = settingValue;
      } else {
        this.SALES_fillEndDate = defaultValue;
      }
    }
    return this.SALES_fillEndDate;
  }

  getPURCHASES_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.PURCHASES_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveriesNotesPurchase', 'ColumVisible');
      this.PURCHASES_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.PURCHASES_columnInVisible;
  }

  /**
   * Setting de carregamento por defeito da data final
   * @param  {boolean} defaultValue - Valor por defeito do parametro
   * @returns string
   */
  getPURCHASES_fillEndDate(defaultValue: boolean): boolean {
    if (typeof this.PURCHASES_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveriesNotesPurchase', 'FillEndDate');
      if (settingValue != null) {
        this.PURCHASES_fillEndDate = settingValue;
      } else {
        this.PURCHASES_fillEndDate = defaultValue;
      }
    }
    return this.PURCHASES_fillEndDate;
  }

  get_ColumnInVisible_Details_S(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_Details_S === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ColumVisible');
      this.columnInVisible_Details_S = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible_Details_S;
  }

  get_ColumnInVisible_Details_S_LM(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ColumVisible');
      this.columnInVisible_Details_S = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    return this.columnInVisible_Details_S;
  }

  get_ColumnInVisible_Details_P(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_Details_P === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ColumVisible');
      this.columnInVisible_Details_P = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible_Details_P;
  }


  getDISPATCH_fillEndDate(defaultValue: boolean): boolean {
    if (typeof this.DISPATCH_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DispatchAdvices', 'FillEndDate');
      if (settingValue != null) {
        this.DISPATCH_fillEndDate = settingValue;
      } else {
        this.DISPATCH_fillEndDate = defaultValue;
      }
    }
    return this.DISPATCH_fillEndDate;
  }

  getDISPATCH_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.DISPATCH_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DispatchAdvices', 'ColumVisible');
      this.DISPATCH_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.DISPATCH_columnInVisible;
  }
  /**
   * Download de PDF do aviso de expedicao
   * @param  {number} id
   * @returns Observable
   */
  downloadPDFDispatchAdvice(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFDispatchAdvice?id=' + id);
  }
  /**
  * Download de Excel do aviso de expedicao
  * @param  {number} id
  * @returns Observable
  */
  downloadExcelDispatchAdvice(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelDispatchAdvices?id=' + id);
  }
  /**
   * @param  {any} params
   * @param  {any} filters
   *    {string} firstdate
   *    {string} lastdate
   *    {number} supplierID?
   *    {number} buyerID?
   * @returns Observable
   */
  downloadExcelDispatchAdviceList(params: any, filters: any): Observable<any> {
    if (filters != null) {
      filters.parameters = params;
    } else {
      filters = { parameters: params};
    }
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelDispatchAdvicesList', filters);
  }

  /**
  * Obter encomenda pelo id
  * @param  {number} id
  * @returns Observable
  */
  get(id: number, getDetails?: boolean): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + '&getDetails=' + getDetails).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Obter Guias associadas ao ID da Encomenda submetida
  * @param  {number} orderID
  * @returns Observable
  */
  getDeliveryNotesByOrderID(orderID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetDeliveryNotesByOrderID?orderID=' + orderID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
  * Obter numero documento seguinte
  * @returns Observable
  */
  getNextDocumentNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextDocumentNumber').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Eliminar guia
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar guia
   * @param  {DeliveryNote} deliveryNote
   * @returns Observable
   */
  update(deliveryNote: DeliveryNote, forceSave: boolean = false): Observable<ReturnStatusHtml> {
    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(deliveryNote));
    formData.append('forceSave', JSON.stringify(forceSave));

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Adicionar guia
   * @param  {DeliveryNote} deliveryNote
   * @returns Observable
   */
  add(deliveryNote: DeliveryNote, checkDetailsEmpty:boolean = true, forceSave: boolean = false): Observable<ReturnStatusHtml> {
    // GM 2018-03-28 melhorar performance do parse do json
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(deliveryNote));
    formData.append('forceSave', JSON.stringify(forceSave));


    return this.http.put(SERVICE_URL + this._controller + '/Add?checkDetailsEmpty=' + checkDetailsEmpty, formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Submeter guia
   * @param  {number} id
   * @returns Observable
   */
  submit(id: number, validate?: boolean, deliveryNote: any = null, forceSave: boolean = false): Observable<ReturnStatusHtml> {
    let obj;
    if (validate != null) {
      obj = { id: id, validate: validate, forceSave: forceSave};
    } else {
      obj = { id: id, forceSave: forceSave};
    }

    //Se a Guia for enviada, adiciona a mesma ao objeto a ser enviado para a API (Caso contrário será enviado a NULL)
    obj.deliveryNote = deliveryNote;

    return this.http.post(SERVICE_URL + this._controller + '/Submit', obj).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Criar e Submeter guia
   * @param  {number} id
   * @returns Observable
   */
  createAndSubmit(deliveryNote: DeliveryNote, forceSave: boolean = false): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(deliveryNote));
    formData.append('forceSave', JSON.stringify(forceSave));

    return this.http.post(SERVICE_URL + this._controller + '/CreateAndSubmitDeliveryNote', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  cancel(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/CancelDeliveryNote', {deliveryNoteID:id}).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter historico workflow pelo id
   * @param  {number} id
   * @returns Observable
   */
  getHistory(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetHistory?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter lista de workflow steps pelo id
   * @param  {number} deliveryNoteID
   * @returns Observable
   */
  getWorkflowSteps(deliveryNoteID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowSteps?deliveryNoteID=' + deliveryNoteID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  applyWorkflowStep(deliveryNoteId: number, stepId: number, notes: string, docNumber: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { deliveryNoteId: deliveryNoteId, stepId: stepId, notes: notes, docNumber: docNumber }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
 * Download de PDF da guia
 * @param  {number} id
 * @returns Observable
 */
  downloadPDFDeliveryNote(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFDeliveryNote?id=' + id);
  }

  /**
 * Download de Excel da guia
 * @param  {number} id
 * @returns Observable
 */
  downloadExcelDeliveryNote(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelDeliveryNote?id=' + id);
  }

  /**
 * Download para Excel da lista de guias
 * @param  {number} id
 * @returns Observable
 */
  downloadExcelDeliveryNotesList(saleOrPurchase: string, params: any, firstdate: string, lastdate: string, deliveryNoteType: string | null = null): Observable<any> {
    return this.http.postFile(
      SERVICE_URL + this._controller + '/DownloadExcelDeliveryNotesList', 
      { 
        saleOrPurchase: saleOrPurchase, 
        parameters: params, 
        firstdate: firstdate, 
        lastdate: lastdate,
        deliveryNoteType: deliveryNoteType
      }
    );
  }

   // *** VERIFICAÇÕES FICHEIRO DE SETTINGS GLOBAL DELIVERY NOTES ****

 getGLOBAL_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
  if (typeof this.GLOBAL_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotes', 'ColumVisible');
    this.GLOBAL_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
  }
  return this.GLOBAL_columnInVisible;
}

getGLOBAL_fillEndDate(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotes', 'FillEndDate');
    if (settingValue != null) {
      this.GLOBAL_fillEndDate = settingValue;
    } else {
      this.GLOBAL_fillEndDate = defaultValue;
    }
  }
  return this.GLOBAL_fillEndDate;
}

 get_GLOBALshowADeliveryNoteType(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showDeliveryNoteType === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowDeliveryNoteType');
    if (settingValue != null) {
      this.GLOBAL_showDeliveryNoteType = settingValue;
    } else {
      this.GLOBAL_showDeliveryNoteType = defaultValue;
    }
  }
  return this.GLOBAL_showDeliveryNoteType;
}

get_GLOBALshowCodeAT(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showCodeAT === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowCodeAT');
    if (settingValue != null) {
      this.GLOBAL_showCodeAT = settingValue;
    } else {
      this.GLOBAL_showCodeAT = defaultValue;
    }
  }
  return this.GLOBAL_showCodeAT;
}

get_GLOBALshowReturnNoteNumber(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showReturnNoteNumber === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowReturnNoteNumber');
    if (settingValue != null) {
      this.GLOBAL_showReturnNoteNumber = settingValue;
    } else {
      this.GLOBAL_showReturnNoteNumber = defaultValue;
    }
  }
  return this.GLOBAL_showReturnNoteNumber;
}

get_GLOBALshowBuyerDepartmentCode(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showBuyerDepartmentCode === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowBuyerDepartmentCode');
    if (settingValue != null) {
      this.GLOBAL_showBuyerDepartmentCode = settingValue;
    } else {
      this.GLOBAL_showBuyerDepartmentCode = defaultValue;
    }
  }
  return this.GLOBAL_showBuyerDepartmentCode;
}

get_GLOBALshowBuyerDepartmentName(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showBuyerDepartmentName === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowBuyerDepartmentName');
    if (settingValue != null) {
      this.GLOBAL_showBuyerDepartmentName = settingValue;
    } else {
      this.GLOBAL_showBuyerDepartmentName = defaultValue;
    }
  }
  return this.GLOBAL_showBuyerDepartmentName;
}

get_GLOBALshowBuyerDivisionCode(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showBuyerDivisionCode === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowBuyerDivisionCode');
    if (settingValue != null) {
      this.GLOBAL_showBuyerDivisionCode = settingValue;
    } else {
      this.GLOBAL_showBuyerDivisionCode = defaultValue;
    }
  }
  return this.GLOBAL_showBuyerDivisionCode;
}

get_GLOBALshowPanelPlaceOfLoading(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showPanelPlaceOfLoading === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPanelPlaceOfLoading');
    if (settingValue != null) {
      this.GLOBAL_showPanelPlaceOfLoading = settingValue;
    } else {
      this.GLOBAL_showPanelPlaceOfLoading = defaultValue;
    }
  }
  return this.GLOBAL_showPanelPlaceOfLoading;
}

get_GLOBALshowPanelPlaceOfDelivery(defaultValue: boolean): boolean{
  if (typeof this.GLOBAL_showPanelPlaceOfDelivery === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPanelPlaceOfDelivery');
    if (settingValue != null) {
      this.GLOBAL_showPanelPlaceOfDelivery = settingValue;
    } else {
      this.GLOBAL_showPanelPlaceOfDelivery = defaultValue;
    }
  }
  return this.GLOBAL_showPanelPlaceOfDelivery;
}

get_GLOBALshowPanelClient(defaultValue: boolean):boolean {
  if (typeof this.GLOBAL_showPanelClient === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPanelClient');
    if (settingValue != null) {
      this.GLOBAL_showPanelClient = settingValue;
    } else {
      this.GLOBAL_showPanelClient = defaultValue;
    }
  }
  return this.GLOBAL_showPanelClient;
}

get_GLOBALshowPanelReferences(defaultValue: boolean):boolean{
  if (typeof this.GLOBAL_showPanelReferences === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPanelReferences');
    if (settingValue != null) {
      this.GLOBAL_showPanelReferences = settingValue;
    } else {
      this.GLOBAL_showPanelReferences = defaultValue;
    }
  }
  return this.GLOBAL_showPanelReferences;
}

get_GLOBALshowPanelPackages(defaultValue: boolean): boolean{
  if (typeof this.GLOBAL_showPanelPackages === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPanelPackages');
    if (settingValue != null) {
      this.GLOBAL_showPanelPackages = settingValue;
    } else {
      this.GLOBAL_showPanelPackages = defaultValue;
    }
  }
  return this.GLOBAL_showPanelPackages;
}

get_GLOBALshowPanelObservations(defaultValue: boolean): boolean{
  if (typeof this.GLOBAL_showPanelObservations === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPanelObservations');
    if (settingValue != null) {
      this.GLOBAL_showPanelObservations = settingValue;
    } else {
      this.GLOBAL_showPanelObservations = defaultValue;
    }
  }
  return this.GLOBAL_showPanelObservations;
}

get_GLOBALshowPackType(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showPackType === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPackType');
    if (settingValue != null) {
      this.GLOBAL_showPackType = settingValue;
    } else {
      this.GLOBAL_showPackType = defaultValue;
    }
  }
  return this.GLOBAL_showPackType;
}

get_GLOBALshowPackQuantity(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showPackQuantity === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPackQuantity');
    if (settingValue != null) {
      this.GLOBAL_showPackQuantity = settingValue;
    } else {
      this.GLOBAL_showPackQuantity = defaultValue;
    }
  }
  return this.GLOBAL_showPackQuantity;
}

get_GLOBALshowPackDimension(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showPackDimension === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowPackDimension');
    if (settingValue != null) {
      this.GLOBAL_showPackDimension = settingValue;
    } else {
      this.GLOBAL_showPackDimension = defaultValue;
    }
  }
  return this.GLOBAL_showPackDimension;
}

get_GLOBALshowTotalNetVolume(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showTotalNetVolume === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowTotalNetVolume');
    if (settingValue != null) {
      this.GLOBAL_showTotalNetVolume = settingValue;
    } else {
      this.GLOBAL_showTotalNetVolume = defaultValue;
    }
  }
  return this.GLOBAL_showTotalNetVolume;
}

get_GLOBALshowTotalNetVolumeUOMCode(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showTotalNetVolumeUOMCode === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowTotalNetVolumeUOMCode');
    if (settingValue != null) {
      this.GLOBAL_showTotalNetVolumeUOMCode = settingValue;
    } else {
      this.GLOBAL_showTotalNetVolumeUOMCode = defaultValue;
    }
  }
  return this.GLOBAL_showTotalNetVolumeUOMCode;
}

get_GLOBALisDeliveryToRequired(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_isDeliveryToRequired === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'IsDeliveryToRequired');
    if (settingValue != null) {
      this.GLOBAL_isDeliveryToRequired = settingValue;
    } else {
      this.GLOBAL_isDeliveryToRequired = defaultValue;
    }
  }
  return this.GLOBAL_isDeliveryToRequired;
}

get_GLOBALeditableDeliveryPanelFields(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_editableDeliveryPanelFields === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'EditableDeliveryPanelFields');
    if (settingValue != null) {
      this.GLOBAL_editableDeliveryPanelFields = settingValue;
    } else {
      this.GLOBAL_editableDeliveryPanelFields = defaultValue;
    }
  }
  return this.GLOBAL_editableDeliveryPanelFields;
}

get_GLOBALeditableBuyerPanelFields(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_editableBuyerPanelFields === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'EditableBuyerPanelFields');
    if (settingValue != null) {
      this.GLOBAL_editableBuyerPanelFields = settingValue;
    } else {
      this.GLOBAL_editableBuyerPanelFields = defaultValue;
    }
  }
  return this.GLOBAL_editableBuyerPanelFields;
}

get_GLOBALshowOthersReferencesTypes(defaultValue: boolean): boolean {
  if (typeof this.GLOBAL_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('GlobalDeliveriesNotesDetails', 'ShowOthersReferencesTypes');
    if (settingValue != null) {
      this.GLOBAL_showOthersReferencesTypes = settingValue;
    } else {
      this.GLOBAL_showOthersReferencesTypes = defaultValue;
    }
  }
  return this.GLOBAL_showOthersReferencesTypes;
}
 // *** VERIFICAÇÕES FICHEIRO DE SETTINGS ACCESSORY DELIVERY NOTES ****

 getACCESSORY_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
  if (typeof this.ACCESSORY_columnInVisible === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotes', 'ColumVisible');
    this.ACCESSORY_columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
  }
  return this.ACCESSORY_columnInVisible;
}

getACCESSORY_fillEndDate(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_fillEndDate === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotes', 'FillEndDate');
    if (settingValue != null) {
      this.ACCESSORY_fillEndDate = settingValue;
    } else {
      this.ACCESSORY_fillEndDate = defaultValue;
    }
  }
  return this.ACCESSORY_fillEndDate;
}

 get_ACCESSORYshowADeliveryNoteType(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showDeliveryNoteType === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowDeliveryNoteType');
    if (settingValue != null) {
      this.ACCESSORY_showDeliveryNoteType = settingValue;
    } else {
      this.ACCESSORY_showDeliveryNoteType = defaultValue;
    }
  }
  return this.ACCESSORY_showDeliveryNoteType;
}

get_ACCESSORYshowCodeAT(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showCodeAT === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowCodeAT');
    if (settingValue != null) {
      this.ACCESSORY_showCodeAT = settingValue;
    } else {
      this.ACCESSORY_showCodeAT = defaultValue;
    }
  }
  return this.ACCESSORY_showCodeAT;
}

get_ACCESSORYshowReturnNoteNumber(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showReturnNoteNumber === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowReturnNoteNumber');
    if (settingValue != null) {
      this.ACCESSORY_showReturnNoteNumber = settingValue;
    } else {
      this.ACCESSORY_showReturnNoteNumber = defaultValue;
    }
  }
  return this.ACCESSORY_showReturnNoteNumber;
}

get_ACCESSORYshowBuyerDepartmentCode(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showBuyerDepartmentCode === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowBuyerDepartmentCode');
    if (settingValue != null) {
      this.ACCESSORY_showBuyerDepartmentCode = settingValue;
    } else {
      this.ACCESSORY_showBuyerDepartmentCode = defaultValue;
    }
  }
  return this.ACCESSORY_showBuyerDepartmentCode;
}

get_ACCESSORYshowBuyerDepartmentName(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showBuyerDepartmentName === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowBuyerDepartmentName');
    if (settingValue != null) {
      this.ACCESSORY_showBuyerDepartmentName = settingValue;
    } else {
      this.ACCESSORY_showBuyerDepartmentName = defaultValue;
    }
  }
  return this.ACCESSORY_showBuyerDepartmentName;
}

get_ACCESSORYshowBuyerDivisionCode(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showBuyerDivisionCode === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowBuyerDivisionCode');
    if (settingValue != null) {
      this.ACCESSORY_showBuyerDivisionCode = settingValue;
    } else {
      this.ACCESSORY_showBuyerDivisionCode = defaultValue;
    }
  }
  return this.ACCESSORY_showBuyerDivisionCode;
}

get_ACCESSORYshowPanelPlaceOfLoading(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showPanelPlaceOfLoading === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPanelPlaceOfLoading');
    if (settingValue != null) {
      this.ACCESSORY_showPanelPlaceOfLoading = settingValue;
    } else {
      this.ACCESSORY_showPanelPlaceOfLoading = defaultValue;
    }
  }
  return this.ACCESSORY_showPanelPlaceOfLoading;
}

get_ACCESSORYshowPanelPlaceOfDelivery(defaultValue: boolean): boolean{
  if (typeof this.ACCESSORY_showPanelPlaceOfDelivery === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPanelPlaceOfDelivery');
    if (settingValue != null) {
      this.ACCESSORY_showPanelPlaceOfDelivery = settingValue;
    } else {
      this.ACCESSORY_showPanelPlaceOfDelivery = defaultValue;
    }
  }
  return this.ACCESSORY_showPanelPlaceOfDelivery;
}

get_ACCESSORYshowPanelClient(defaultValue: boolean):boolean {
  if (typeof this.ACCESSORY_showPanelClient === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPanelClient');
    if (settingValue != null) {
      this.ACCESSORY_showPanelClient = settingValue;
    } else {
      this.ACCESSORY_showPanelClient = defaultValue;
    }
  }
  return this.ACCESSORY_showPanelClient;
}

get_ACCESSORYshowPanelReferences(defaultValue: boolean):boolean{
  if (typeof this.ACCESSORY_showPanelReferences === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPanelReferences');
    if (settingValue != null) {
      this.ACCESSORY_showPanelReferences = settingValue;
    } else {
      this.ACCESSORY_showPanelReferences = defaultValue;
    }
  }
  return this.ACCESSORY_showPanelReferences;
}

get_ACCESSORYshowPanelPackages(defaultValue: boolean): boolean{
  if (typeof this.ACCESSORY_showPanelPackages === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPanelPackages');
    if (settingValue != null) {
      this.ACCESSORY_showPanelPackages = settingValue;
    } else {
      this.ACCESSORY_showPanelPackages = defaultValue;
    }
  }
  return this.ACCESSORY_showPanelPackages;
}

get_ACCESSORYshowPanelObservations(defaultValue: boolean): boolean{
  if (typeof this.ACCESSORY_showPanelObservations === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPanelObservations');
    if (settingValue != null) {
      this.ACCESSORY_showPanelObservations = settingValue;
    } else {
      this.ACCESSORY_showPanelObservations = defaultValue;
    }
  }
  return this.ACCESSORY_showPanelObservations;
}

get_ACCESSORYshowPackType(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showPackType === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPackType');
    if (settingValue != null) {
      this.ACCESSORY_showPackType = settingValue;
    } else {
      this.ACCESSORY_showPackType = defaultValue;
    }
  }
  return this.ACCESSORY_showPackType;
}

get_ACCESSORYshowPackQuantity(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showPackQuantity === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPackQuantity');
    if (settingValue != null) {
      this.ACCESSORY_showPackQuantity = settingValue;
    } else {
      this.ACCESSORY_showPackQuantity = defaultValue;
    }
  }
  return this.ACCESSORY_showPackQuantity;
}

get_ACCESSORYshowPackDimension(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showPackDimension === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowPackDimension');
    if (settingValue != null) {
      this.ACCESSORY_showPackDimension = settingValue;
    } else {
      this.ACCESSORY_showPackDimension = defaultValue;
    }
  }
  return this.ACCESSORY_showPackDimension;
}

get_ACCESSORYshowTotalNetVolume(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showTotalNetVolume === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowTotalNetVolume');
    if (settingValue != null) {
      this.ACCESSORY_showTotalNetVolume = settingValue;
    } else {
      this.ACCESSORY_showTotalNetVolume = defaultValue;
    }
  }
  return this.ACCESSORY_showTotalNetVolume;
}

get_ACCESSORYshowTotalNetVolumeUOMCode(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showTotalNetVolumeUOMCode === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowTotalNetVolumeUOMCode');
    if (settingValue != null) {
      this.ACCESSORY_showTotalNetVolumeUOMCode = settingValue;
    } else {
      this.ACCESSORY_showTotalNetVolumeUOMCode = defaultValue;
    }
  }
  return this.ACCESSORY_showTotalNetVolumeUOMCode;
}

get_ACCESSORYisDeliveryToRequired(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_isDeliveryToRequired === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'IsDeliveryToRequired');
    if (settingValue != null) {
      this.ACCESSORY_isDeliveryToRequired = settingValue;
    } else {
      this.ACCESSORY_isDeliveryToRequired = defaultValue;
    }
  }
  return this.ACCESSORY_isDeliveryToRequired;
}

get_ACCESSORYeditableDeliveryPanelFields(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_editableDeliveryPanelFields === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'EditableDeliveryPanelFields');
    if (settingValue != null) {
      this.ACCESSORY_editableDeliveryPanelFields = settingValue;
    } else {
      this.ACCESSORY_editableDeliveryPanelFields = defaultValue;
    }
  }
  return this.ACCESSORY_editableDeliveryPanelFields;
}

get_ACCESSORYeditableBuyerPanelFields(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_editableBuyerPanelFields === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'EditableBuyerPanelFields');
    if (settingValue != null) {
      this.ACCESSORY_editableBuyerPanelFields = settingValue;
    } else {
      this.ACCESSORY_editableBuyerPanelFields = defaultValue;
    }
  }
  return this.ACCESSORY_editableBuyerPanelFields;
}

get_ACCESSORYshowOthersReferencesTypes(defaultValue: boolean): boolean {
  if (typeof this.ACCESSORY_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
    let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'ShowOthersReferencesTypes');
    if (settingValue != null) {
      this.ACCESSORY_showOthersReferencesTypes = settingValue;
    } else {
      this.ACCESSORY_showOthersReferencesTypes = defaultValue;
    }
  }
  return this.ACCESSORY_showOthersReferencesTypes;
}
  // *** VERIFICAÇÕES FICHEIRO DE SETTINGS ****
  get_showDeliveryNoteType(defaultValue: boolean): boolean {
    if (typeof this._showDeliveryNoteType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowDeliveryNoteType');
      if (settingValue != null) {
        this._showDeliveryNoteType = settingValue;
      } else {
        this._showDeliveryNoteType = defaultValue;
      }
    }
    return this._showDeliveryNoteType;
  }

  get_defaultDeliveryNoteType(defaultValue: string): string {
    if (typeof this._defaultDeliveryNoteType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'DefaultDeliveryNoteType');
      if (settingValue != null) {
        this._defaultDeliveryNoteType = settingValue;
      } else {
        this._defaultDeliveryNoteType = defaultValue;
      }
    }
    return this._defaultDeliveryNoteType;
  }

  get_showCodeAT(defaultValue: boolean): boolean {
    if (typeof this._showCodeAT === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowCodeAT');
      if (settingValue != null) {
        this._showCodeAT = settingValue;
      } else {
        this._showCodeAT = defaultValue;
      }
    }
    return this._showCodeAT;
  }

  get_showReturnNoteNumber(defaultValue: boolean): boolean {
    if (typeof this._showReturnNoteNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowReturnNoteNumber');
      if (settingValue != null) {
        this._showReturnNoteNumber = settingValue;
      } else {
        this._showReturnNoteNumber = defaultValue;
      }
    }
    return this._showReturnNoteNumber;
  }

  get_showBuyerDepartmentCode(defaultValue: boolean): boolean {
    if (typeof this._showBuyerDepartmentCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowBuyerDepartmentCode');
      if (settingValue != null) {
        this._showBuyerDepartmentCode = settingValue;
      } else {
        this._showBuyerDepartmentCode = defaultValue;
      }
    }
    return this._showBuyerDepartmentCode;
  }

  get_showBuyerDepartmentName(defaultValue: boolean): boolean {
    if (typeof this._showBuyerDepartmentName === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowBuyerDepartmentName');
      if (settingValue != null) {
        this._showBuyerDepartmentName = settingValue;
      } else {
        this._showBuyerDepartmentName = defaultValue;
      }
    }
    return this._showBuyerDepartmentName;
  }

  get_showBuyerDivisionCode(defaultValue: boolean): boolean {
    if (typeof this._showBuyerDivisionCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowBuyerDivisionCode');
      if (settingValue != null) {
        this._showBuyerDivisionCode = settingValue;
      } else {
        this._showBuyerDivisionCode = defaultValue;
      }
    }
    return this._showBuyerDivisionCode;
  }

  get_showPanelPlaceOfLoading(defaultValue: boolean): boolean {
    if (typeof this._showPanelPlaceOfLoading === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPanelPlaceOfLoading');
      if (settingValue != null) {
        this._showPanelPlaceOfLoading = settingValue;
      } else {
        this._showPanelPlaceOfLoading = defaultValue;
      }
    }
    return this._showPanelPlaceOfLoading;
  }

  get_showPanelPlaceOfDelivery(defaultValue: boolean): boolean{
    if (typeof this._showPanelPlaceOfDelivery === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPanelPlaceOfDelivery');
      if (settingValue != null) {
        this._showPanelPlaceOfDelivery = settingValue;
      } else {
        this._showPanelPlaceOfDelivery = defaultValue;
      }
    }
    return this._showPanelPlaceOfDelivery;
  }

  get_showPanelClient(defaultValue: boolean):boolean {
    if (typeof this._showPanelClient === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPanelClient');
      if (settingValue != null) {
        this._showPanelClient = settingValue;
      } else {
        this._showPanelClient = defaultValue;
      }
    }
    return this._showPanelClient;
  }

  get_showPanelReferences(defaultValue: boolean):boolean{
    if (typeof this._showPanelReferences === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPanelReferences');
      if (settingValue != null) {
        this._showPanelReferences = settingValue;
      } else {
        this._showPanelReferences = defaultValue;
      }
    }
    return this._showPanelReferences;
  }

  get_showPanelPackages(defaultValue: boolean): boolean{
    if (typeof this._showPanelPackages === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPanelPackages');
      if (settingValue != null) {
        this._showPanelPackages = settingValue;
      } else {
        this._showPanelPackages = defaultValue;
      }
    }
    return this._showPanelPackages;
  }

  get_showPanelObservations(defaultValue: boolean): boolean{
    if (typeof this._showPanelObservations === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPanelObservations');
      if (settingValue != null) {
        this._showPanelObservations = settingValue;
      } else {
        this._showPanelObservations = defaultValue;
      }
    }
    return this._showPanelObservations;
  }

  get_showPackType(defaultValue: boolean): boolean {
    if (typeof this._showPackType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPackType');
      if (settingValue != null) {
        this._showPackType = settingValue;
      } else {
        this._showPackType = defaultValue;
      }
    }
    return this._showPackType;
  }

  get_showPackQuantity(defaultValue: boolean): boolean {
    if (typeof this._showPackQuantity === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPackQuantity');
      if (settingValue != null) {
        this._showPackQuantity = settingValue;
      } else {
        this._showPackQuantity = defaultValue;
      }
    }
    return this._showPackQuantity;
  }

  get_showPackDimension(defaultValue: boolean): boolean {
    if (typeof this._showPackDimension === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPackDimension');
      if (settingValue != null) {
        this._showPackDimension = settingValue;
      } else {
        this._showPackDimension = defaultValue;
      }
    }
    return this._showPackDimension;
  }

  get_showTotalPackages(defaultValue: boolean): boolean {
    if (typeof this._showTotalPackages === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowTotalPackages');
      if (settingValue != null) {
        this._showTotalPackages = settingValue;
      } else {
        this._showTotalPackages = defaultValue;
      }
    }
    return this._showTotalPackages;
  }

  get_hideOtherReferences_SALES(defaultValue: boolean): boolean {
    if (typeof this.hideOtherReferences_SALES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'HideOtherReferences');
      if (settingValue != null) {
        this.hideOtherReferences_SALES = settingValue;
      } else {
        this.hideOtherReferences_SALES = defaultValue;
      }
    }
    return this.hideOtherReferences_SALES;
  }

  get_showNumberOfVolumes(defaultValue: boolean): boolean {
    if (typeof this.showNumberOfVolumes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowNumberOfVolumes');
      if (settingValue != null) {
        this.showNumberOfVolumes = settingValue;
      } else {
        this.showNumberOfVolumes = defaultValue;
      }
    }
    return this.showNumberOfVolumes;
  }

  get_showNumberOfPalletes(defaultValue: boolean): boolean {
    if (typeof this.showNumberOfPalletes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowNumberOfPalletes');
      if (settingValue != null) {
        this.showNumberOfPalletes = settingValue;
      } else {
        this.showNumberOfPalletes = defaultValue;
      }
    }
    return this.showNumberOfPalletes;
  }

  get_showTotalWeight(defaultValue: boolean): boolean {
    if (typeof this.showTotalWeight === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowTotalWeight');
      if (settingValue != null) {
        this.showTotalWeight = settingValue;
      } else {
        this.showTotalWeight = defaultValue;
      }
    }
    return this.showTotalWeight;
  }

  get_hideNetWeight(defaultValue: boolean): boolean {
    if (typeof this.hideNetWeight === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'HideNetWeight');
      if (settingValue != null) {
        this.hideNetWeight = settingValue;
      } else {
        this.hideNetWeight = defaultValue;
      }
    }
    return this.hideNetWeight;
  }

  get_hideGrossWeight(defaultValue: boolean): boolean {
    if (typeof this.hideGrossWeight === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'HideGrossWeight');
      if (settingValue != null) {
        this.hideGrossWeight = settingValue;
      } else {
        this.hideGrossWeight = defaultValue;
      }
    }
    return this.hideGrossWeight;
  }

  get_showTotalNetVolume(defaultValue: boolean): boolean {
    if (typeof this._showTotalNetVolume === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowTotalNetVolume');
      if (settingValue != null) {
        this._showTotalNetVolume = settingValue;
      } else {
        this._showTotalNetVolume = defaultValue;
      }
    }
    return this._showTotalNetVolume;
  }

  get_showTotalNetVolumeUOMCode(defaultValue: boolean): boolean {
    if (typeof this._showTotalNetVolumeUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowTotalNetVolumeUOMCode');
      if (settingValue != null) {
        this._showTotalNetVolumeUOMCode = settingValue;
      } else {
        this._showTotalNetVolumeUOMCode = defaultValue;
      }
    }
    return this._showTotalNetVolumeUOMCode;
  }

  get_isDeliveryToRequired(defaultValue: boolean): boolean {
    if (typeof this._isDeliveryToRequired === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'IsDeliveryToRequired');
      if (settingValue != null) {
        this._isDeliveryToRequired = settingValue;
      } else {
        this._isDeliveryToRequired = defaultValue;
      }
    }
    return this._isDeliveryToRequired;
  }

  get_editableDeliveryPanelFields(defaultValue: boolean): boolean {
    if (typeof this._editableDeliveryPanelFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'EditableDeliveryPanelFields');
      if (settingValue != null) {
        this._editableDeliveryPanelFields = settingValue;
      } else {
        this._editableDeliveryPanelFields = defaultValue;
      }
    }
    return this._editableDeliveryPanelFields;
  }

  get_editableBuyerPanelFields(defaultValue: boolean): boolean {
    if (typeof this._editableBuyerPanelFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'EditableBuyerPanelFields');
      if (settingValue != null) {
        this._editableBuyerPanelFields = settingValue;
      } else {
        this._editableBuyerPanelFields = defaultValue;
      }
    }
    return this._editableBuyerPanelFields;
  }

  get_editableBuyerPanelFieldsAccessoryDeliveryNote(defaultValue: boolean): boolean{
    if (typeof this._editableBuyerPanelFieldsAccessoryDeliveryNote === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('AccessoryDeliveriesNotesDetails', 'EditableBuyerPanelFields');
      if (settingValue != null) {
        this._editableBuyerPanelFieldsAccessoryDeliveryNote = settingValue;
      } else {
        this._editableBuyerPanelFieldsAccessoryDeliveryNote = defaultValue;
      }
    }
    return this._editableBuyerPanelFieldsAccessoryDeliveryNote;  
  }

  getSALE_showOthersReferencesTypes(defaultValue: boolean): boolean {
    if (typeof this.SALE_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowOthersReferencesTypes');
      if (settingValue != null) {
        this.SALE_showOthersReferencesTypes = settingValue;
      } else {
        this.SALE_showOthersReferencesTypes = defaultValue;
      }
    }
    return this.SALE_showOthersReferencesTypes;
  }

  getPURCHASE_showOthersReferencesTypes(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showOthersReferencesTypes === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'ShowOthersReferencesTypes');
      if (settingValue != null) {
        this.PURCHASE_showOthersReferencesTypes = settingValue;
      } else {
        this.PURCHASE_showOthersReferencesTypes = defaultValue;
      }
    }
    return this.PURCHASE_showOthersReferencesTypes;
  }

  get_hideOtherReferences_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.hideOtherReferences_PURCHASES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'HideOtherReferences');
      if (settingValue != null) {
        this.hideOtherReferences_PURCHASES = settingValue;
      } else {
        this.hideOtherReferences_PURCHASES = defaultValue;
      }
    }
    return this.hideOtherReferences_PURCHASES;
  }

  get_hideNetWeight_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.hideNetWeight_PURCHASES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'HideNetWeight');
      if (settingValue != null) {
        this.hideNetWeight_PURCHASES = settingValue;
      } else {
        this.hideNetWeight_PURCHASES = defaultValue;
      }
    }
    return this.hideNetWeight_PURCHASES;
  }

  get_hideGrossWeight_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.hideGrossWeight_PURCHASES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'HideGrossWeight');
      if (settingValue != null) {
        this.hideGrossWeight_PURCHASES = settingValue;
      } else {
        this.hideGrossWeight_PURCHASES = defaultValue;
      }
    }
    return this.hideGrossWeight_PURCHASES;
  }

  get_hideNetVolume_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.hideNetVolume_PURCHASES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'HideNetVolume');
      if (settingValue != null) {
        this.hideNetVolume_PURCHASES = settingValue;
      } else {
        this.hideNetVolume_PURCHASES = defaultValue;
      }
    }
    return this.hideNetVolume_PURCHASES;
  }

  get_showPalleteNumber_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.showPalleteNumber_PURCHASES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'ShowPalleteNumber');
      if (settingValue != null) {
        this.showPalleteNumber_PURCHASES = settingValue;
      } else {
        this.showPalleteNumber_PURCHASES = defaultValue;
      }
    }
    return this.showPalleteNumber_PURCHASES;
  }

  get_showTotalWeight_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.showTotalWeight_PURCHASES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'ShowTotalWeight');
      if (settingValue != null) {
        this.showTotalWeight_PURCHASES = settingValue;
      } else {
        this.showTotalWeight_PURCHASES = defaultValue;
      }
    }
    return this.showTotalWeight_PURCHASES;
  }

  get_showVolumeNumber_PURCHASES(defaultValue: boolean): boolean {
    if (typeof this.showVolumeNumber_PURCHASES === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'ShowVolumeNumber');
      if (settingValue != null) {
        this.showVolumeNumber_PURCHASES = settingValue;
      } else {
        this.showVolumeNumber_PURCHASES = defaultValue;
      }
    }
    return this.showVolumeNumber_PURCHASES;
  }

  getPURCHASE_showEDIPanel(defaultValue: boolean): boolean {
    if (typeof this.PURCHASE_showEdiPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'ShowEDIPanel');
      if (settingValue != null) {
        this.PURCHASE_showEdiPanel = settingValue;
      } else {
        this.PURCHASE_showEdiPanel = defaultValue;
      }
    }
    return this.PURCHASE_showEdiPanel;
  }


  // Detalhes da guia Venda
  get_showPackagingPanel_SaleDetail(defaultValue: boolean): boolean {
    if (typeof this._showPackagingPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowPackagingPanel');
      if (settingValue != null) {
        this._showPackagingPanel = settingValue;
      } else {
        this._showPackagingPanel = defaultValue;
      }
    }
    return this._showPackagingPanel;
  }

  get_showPackageSequencePanel_SaleDetail(defaultValue: boolean): boolean {
    if (typeof this._showPackageSequencePanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowPackageSequencePanel');
      if (settingValue != null) {
        this._showPackageSequencePanel = settingValue;
      } else {
        this._showPackageSequencePanel = defaultValue;
      }
    }
    return this._showPackageSequencePanel;
  }

  get_showBuyerProductCode_SaleDetail(defaultValue: boolean): boolean {
    if (typeof this._showBuyerProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowBuyerProductCode');
      if (settingValue != null) {
        this._showBuyerProductCode = settingValue;
      } else {
        this._showBuyerProductCode = defaultValue;
      }
    }
    return this._showBuyerProductCode;
  }

  get_showSupplierProductCode_SaleDetail(defaultValue: boolean): boolean {
    if (typeof this._showSupplierProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowSupplierProductCode');
      if (settingValue != null) {
        this._showSupplierProductCode = settingValue;
      } else {
        this._showSupplierProductCode = defaultValue;
      }
    }
    return this._showSupplierProductCode;
  }

  get_showBatchNumber_SaleDetail(defaultValue: boolean): boolean {
    if (typeof this._showBatchNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowBatchNumber');
      if (settingValue != null) {
        this._showBatchNumber = settingValue;
      } else {
        this._showBatchNumber = defaultValue;
      }
    }
    return this._showBatchNumber;
  }

  get_showBatchExpiryDate_SaleDetail(defaultValue: boolean): boolean {
    if (typeof this._showBatchExpiryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowBatchExpiryDate');
      if (settingValue != null) {
        this._showBatchExpiryDate = settingValue;
      } else {
        this._showBatchExpiryDate = defaultValue;
      }
    }
    return this._showBatchExpiryDate;
  }

  get_showProductsAutomatically(defaultValue: boolean): boolean {
    if (typeof this._showProductsAutomatically === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowProductsAutomatically');
      if (settingValue != null) {
        this._showProductsAutomatically = settingValue;
      } else {
        this._showProductsAutomatically = defaultValue;
      }
    }
    return this._showProductsAutomatically;
  }

  get_showButtonAddNewDetail(defaultValue: boolean): boolean {
    if (typeof this._allowCreateEmptyDetail === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'AllowCreateEmptyDetail');
      if (settingValue != null) {
        this._allowCreateEmptyDetail = settingValue;
      } else {
        this._allowCreateEmptyDetail = defaultValue;
      }
    }
    return this._allowCreateEmptyDetail;
  }

  get_showButtonSelectProducts(defaultValue: boolean): boolean {
    if (typeof this._showButtonSelectProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowButtonSelectProducts');
      if (settingValue != null) {
        this._showButtonSelectProducts = settingValue;
      } else {
        this._showButtonSelectProducts = defaultValue;
      }
    }
    return this._showButtonSelectProducts;
  }

  get_showProdutcsListRole(defaultValue: number): number {
    if (typeof this._showProdutcsListRole === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowProdutcsListRole');
      if (settingValue != null) {
        this._showProdutcsListRole = settingValue;
      } else {
        this._showProdutcsListRole = defaultValue;
      }
    }
    return this._showProdutcsListRole;
  }

  get_showReferencesPanel_SaleDetail(defaultValue: boolean): boolean {
    if (typeof this._showReferencesPanel_sales === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'ShowReferencesPanel');
      if (settingValue != null) {
        this._showReferencesPanel_sales = settingValue;
      } else {
        this._showReferencesPanel_sales = defaultValue;
      }
    }
    return this._showReferencesPanel_sales;
  }

  // Detalhes da guia compra
  get_showPackagingPanel_PurchaseDetail(defaultValue: boolean): boolean {
    if (typeof this._showPackagingPanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ShowPackagingPanel');
      if (settingValue != null) {
        this._showPackagingPanel = settingValue;
      } else {
        this._showPackagingPanel = defaultValue;
      }
    }
    return this._showPackagingPanel;
  }

  get_showPackageSequencePanel_PurchaseDetail(defaultValue: boolean): boolean {
    if (typeof this._showPackageSequencePanel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ShowPackageSequencePanel');
      if (settingValue != null) {
        this._showPackageSequencePanel = settingValue;
      } else {
        this._showPackageSequencePanel = defaultValue;
      }
    }
    return this._showPackageSequencePanel;
  }

  get_showBuyerProductCode_PurchaseDetail(defaultValue: boolean): boolean {
    if (typeof this._showBuyerProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ShowBuyerProductCode');
      if (settingValue != null) {
        this._showBuyerProductCode = settingValue;
      } else {
        this._showBuyerProductCode = defaultValue;
      }
    }
    return this._showBuyerProductCode;
  }

  get_showSupplierProductCode_PurchaseDetail(defaultValue: boolean): boolean {
    if (typeof this._showSupplierProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ShowSupplierProductCode');
      if (settingValue != null) {
        this._showSupplierProductCode = settingValue;
      } else {
        this._showSupplierProductCode = defaultValue;
      }
    }
    return this._showSupplierProductCode;
  }

  get_showBatchNumber_PurchaseDetail(defaultValue: boolean): boolean {
    if (typeof this._showBatchNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ShowBatchNumber');
      if (settingValue != null) {
        this._showBatchNumber = settingValue;
      } else {
        this._showBatchNumber = defaultValue;
      }
    }
    return this._showBatchNumber;
  }

  get_showBatchExpiryDate_PurchaseDetail(defaultValue: boolean): boolean {
    if (typeof this._showBatchExpiryDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ShowBatchExpiryDate');
      if (settingValue != null) {
        this._showBatchExpiryDate = settingValue;
      } else {
        this._showBatchExpiryDate = defaultValue;
      }
    }
    return this._showBatchExpiryDate;
  }

  get_showReferencesPanel_PurchaseDetail(defaultValue: boolean): boolean {
    if (typeof this._showReferencesPanel_purchase === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchaseDetail', 'ShowReferencesPanel');
      if (settingValue != null) {
        this._showReferencesPanel_purchase = settingValue;
      } else {
        this._showReferencesPanel_purchase = defaultValue;
      }
    }
    return this._showReferencesPanel_purchase;
  }

  get_showCodeAT_Purchase(defaultValue: boolean): boolean {
    if (typeof this._showCodeAT_Purchase === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'ShowCodeAT');
      if (settingValue != null) {
        this._showCodeAT_Purchase = settingValue;
      } else {
        this._showCodeAT_Purchase = defaultValue;
      }
    }
    return this._showCodeAT_Purchase;
  }

  get_showATCUD_Purchase(defaultValue: boolean): boolean {
    if (typeof this._showATCUD_purchase === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNotePurchase', 'ShowATCUD');
      if (settingValue != null) {
        this._showATCUD_purchase = settingValue;
      } else {
        this._showATCUD_purchase = defaultValue;
      }
    }
    return this._showATCUD_purchase;
  }

  getAlternativeColumns(defaultValue: boolean): boolean {
    if(typeof this._showAlternativeColumns === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('DeliveriesNotesSale', 'AlternativeColumns');
      if(settingValue != null){
        this._showAlternativeColumns = settingValue;
      }else {
        this._showAlternativeColumns = defaultValue;
      }
    }
    return this._showAlternativeColumns;
  }

  get_showChooseOrder(defaultValue: boolean): boolean {
    if(typeof this._showChooseOrder === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowChooseOrder');
      if(settingValue != null){
        this._showChooseOrder = settingValue;
      }else {
        this._showChooseOrder = defaultValue;
      }
    }
    return this._showChooseOrder;
  }

  get_showPackagingPanel(defaultValue: boolean): boolean {
    if(typeof this._showPackingPanel === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowPackingPanel');
      if(settingValue != null){
        this._showPackingPanel = settingValue;
      }else {
        this._showPackingPanel = defaultValue;
      }
    }
    return this._showPackingPanel;
  }

  getAskDocNumberToChangeState(defaultValue: boolean): boolean {
    if(typeof this._getAskDocNumberToChangeState === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'AskDocNumberToChangeState');
      if(settingValue != null){
        this._getAskDocNumberToChangeState = settingValue;
      }else {
        this._getAskDocNumberToChangeState = defaultValue;
      }
    }
    return this._getAskDocNumberToChangeState;
  }

  get_defaultWeightUOMCode(defaultValue: string): string {
    if (typeof this._getdefaultWeightUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'DefaultWeightUOMCode');
      if (settingValue != null) {
        this._getdefaultWeightUOMCode = settingValue;
      } else {
        this._getdefaultWeightUOMCode = defaultValue;
      }
    }
    return this._getdefaultWeightUOMCode;
  }

  get_defaultLoadingHours(defaultValue:number):number{
    if (typeof this._getdefaultLoadingHours === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'DefaultLoadingHours');
      if (settingValue != null) {
        this._getdefaultLoadingHours = settingValue;
      } else {
        this._getdefaultLoadingHours = defaultValue;
      }
    }
    return this._getdefaultLoadingHours;
  }
  get_defaultDischargeHours(defaultValue:number):number{
    if (typeof this._getdefaultDischargeHours === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'DefaultDischargeHours');
      if (settingValue != null) {
        this._getdefaultDischargeHours = settingValue;
      } else {
        this._getdefaultDischargeHours = defaultValue;
      }
    }
    return this._getdefaultDischargeHours;
  }

  get_defaultUOMCode(defaultValue:string):string {
    if (typeof this._getdefaultUOMCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'DefaultUOMCode');
      if (settingValue != null) {
        this._getdefaultUOMCode = settingValue;
      } else {
        this._getdefaultUOMCode = defaultValue;
      }
    }
    return this._getdefaultUOMCode;
  }

  getSALES_optionalStandardProductCode(defaultValue: boolean): boolean {
    if (typeof this.optionalStandardProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'OptionalStandardCode');
      if (settingValue != null) {
        this.optionalStandardProductCode = settingValue;
      } else {
        this.optionalStandardProductCode = defaultValue;
      }
    }
    return this.optionalStandardProductCode;
  }

  get_blockSupplierFields(defaultValue: boolean): boolean {
    if (typeof this._blockSupplierFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSaleDetail', 'BlockSupplierFields');
      if (settingValue != null) {
        this._blockSupplierFields = settingValue;
      } else {
        this._blockSupplierFields = defaultValue;
      }
    }
    return this._blockSupplierFields;
  }

  get_buyerOrderNumberMandatory(defaultValue: boolean): boolean {
    if (typeof this.buyerOrderNumberMandatory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'BuyerOrderNumberMandatory');
      if (settingValue != null) {
        this.buyerOrderNumberMandatory = settingValue;
      } else {
        this.buyerOrderNumberMandatory = defaultValue;
      }
    }
    return this.buyerOrderNumberMandatory;
  }

  get_hideAddDetailOnOrderSelect(defaultValue: boolean): boolean {
    if (typeof this.hideAddDetailOnOrderSelect === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'HideAddDetailOnOrderSelect');
      if (settingValue != null) {
        this.hideAddDetailOnOrderSelect = settingValue;
      } else {
        this.hideAddDetailOnOrderSelect = defaultValue;
      }
    }
    return this.hideAddDetailOnOrderSelect;
  }

  get_useSupplierMainAddressAsDefaultLoadingLocation(defaultValue: boolean): boolean {
    if (typeof this.useSupplierMainAddressAsDefaultLoadingLocation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'UseSupplierMainAddressAsDefaultLoadingLocation');
      if (settingValue != null) {
        this.useSupplierMainAddressAsDefaultLoadingLocation = settingValue;
      } else {
        this.useSupplierMainAddressAsDefaultLoadingLocation = defaultValue;
      }
    }
    return this.useSupplierMainAddressAsDefaultLoadingLocation;
  }

  get_hideLoadingLocationSelector(defaultValue: boolean): boolean {
    if (typeof this.hideLoadingLocationSelector === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'HideLoadingLocationSelector');
      if (settingValue != null) {
        this.hideLoadingLocationSelector = settingValue;
      } else {
        this.hideLoadingLocationSelector = defaultValue;
      }
    }
    return this.hideLoadingLocationSelector;
  }

  get_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.afterSaveRemainOnSamePage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.afterSaveRemainOnSamePage = settingValue;
      } else {
        this.afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.afterSaveRemainOnSamePage;
  }


  get_redirectOnUpdate(defaultValue: boolean): boolean {
    if (typeof this.redirectOnUpdate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'RedirectOnUpdate');
      if (settingValue != null) {
        this.redirectOnUpdate = settingValue;
      } else {
        this.redirectOnUpdate = defaultValue;
      }
    }
    return this.redirectOnUpdate;
  }

  get_showATCUD_Sale(defaultValue: boolean): boolean {
    if (typeof this._showATCUD_sale === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'ShowATCUD');
      if (settingValue != null) {
        this._showATCUD_sale = settingValue;
      } else {
        this._showATCUD_sale = defaultValue;
      }
    }
    return this._showATCUD_sale;
  }

  get_orderNumberReadOnly_sale(defaultValue: boolean): boolean {
    if (typeof this._orderNumberReadOnly_sale === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveryNoteSale', 'OrderNumberReadOnly');
      if (settingValue != null) {
        this._orderNumberReadOnly_sale = settingValue;
      } else {
        this._orderNumberReadOnly_sale = defaultValue;
      }
    }
    return this._orderNumberReadOnly_sale;
  }
  
  get_defaultTypeFilter(defaultValue: string): string {
    if (typeof this._getdefaultTypeFilter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DeliveriesNotesSale', 'DefaultTypeFilter');
      if (settingValue != null) {
        this._getdefaultTypeFilter = settingValue;
      } else {
        this._getdefaultTypeFilter = defaultValue;
      }
    }
    return this._getdefaultTypeFilter;
  }

  
  uploadSSCCsFile(formData: FormData,id:number, transportID:number,companyID: number = 0): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UploadSSCCsFile?id='+ id + '&transportID='+ transportID + '&companyID=' + companyID, formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  uploadSimplifiedSSCCsFile(formData: FormData,id:number, transportID:number,companyID: number = 0): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UploadSimplifiedSSCCsFile?id='+ id + '&transportID='+ transportID + '&companyID=' + companyID, formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

    // Devolver excel dos produtos de um pedido de transporte
  exportSSCCsToExcel(id: number, transportID:number, companyID: number = 0): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelSSCCsList?id=' + id + '&transportID='+transportID + '&companyID=' + companyID);
  }


   // Devolver excel dos produtos de um pedido de transporte
   exportSSCCsSimplifiedToExcel(id: number, transportID:number, companyID: number = 0): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelSimplifiedSSCCList?id=' + id + '&transportID='+transportID + '&companyID=' + companyID);
  }

  createSSCCAutomatacly(deliveryNoteID: number, transportID:number, details: any = []): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    formData.append('deliveryNoteID', JSON.stringify(deliveryNoteID));
    formData.append('transportID', JSON.stringify(transportID));
    formData.append('details', JSON.stringify(details));


    return this.http.post(SERVICE_URL + this._controller + '/AutomaticCreateSSCCs', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
}
