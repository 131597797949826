import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import {
  SERVICE_URL, setCurrencyDecimal, setQuantityDecimal, setUnitaryPriceDecimal, setTaxAmountDecimal,
  setVolumeDecimal, setWeightDecimal, setCurrencySymbol, setCurrencyCode, setLanguage
} from '../constants/global';
import { Login } from '../models/login';
import { Session } from '../models/session';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Role } from '../models/role';
import { MasterService } from './master.service';
import { RegistrationRequest } from '../models/registration-request';
import { User, UsersIban } from '../models/user';
import { GenericFieldExtension } from 'src/app/models/generic-field-extension';
import { CONTEXT_USER } from './../constants/global';
import { ErrorTreatmentFunctions } from 'src/app/modules/treatments.module';

declare var defineToken: any;

@Injectable()
export class AuthenticationService {
  // Sessão
  private _session: Session;
  private allowedRoles: Role[];

  private _controller: string = 'Session';

  // Listas
  private _genericFieldsExtension: GenericFieldExtension[];

  // Settings
  private redirectAfterLogin: string;
  private showChangeImage: boolean;
  private disableUserName: boolean;
  private companiesDefaultOrderColIndex: number;
  private inactivePasswordChange: boolean;
  private showBreadCrumb: boolean;
  private _userIBANs: Array<any>;

  constructor(
    private http: HttpClientCustom,
    private _masterService: MasterService,
    private _errorTreat: ErrorTreatmentFunctions
  ) { }

  public get session() {
    return this._session;
  }

  public get isUserAdmin() {
    return this._session.roles.find(role => role === Role.ROLE_ADMIN) ? true : false;
  }

  getAllowedRoles(): Observable<Role[]> {
    if (this.allowedRoles === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetAllowedRoles').pipe(
        map((responseRaw: any) => {
          try {
            let response = this._masterService.convertToReturnStatusHtml(responseRaw);

            if (response.ReturnStatus.Successfull) {
              this.allowedRoles = response.ReturnStatus.ReturnObject as Role[];

              return this.allowedRoles.sort((n1: Role, n2: Role) => {
                if (n1.Name > n2.Name) {
                  return 1;
                }

                if (n1.Name < n2.Name) {
                  return -1;
                }

                return 0;
              });
            } else if (!response.IsAuthenticated) {
              // this.router.navigate(['/login']);
              document.location.href = document.location.origin;
            } else {
              return [];
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this.allowedRoles.sort((n1: Role, n2: Role) => {
        if (n1.Name > n2.Name) {
          return 1;
        }

        if (n1.Name < n2.Name) {
          return -1;
        }
        return 0;
      }));
    }
  }

  /**
   * Ir buscar os valores de configurações que estão no ficheiro *PortalSettings.json, cujos dados são retornados no login
   * @param  {string} name - Valor a procurar na primeira Key (normalmente a key é Name)
   * @param  {string} parameter - Key a procurar no segundo objecto (normalmente o objecto é Parameters)
   * @param  {string=null} principalString_op - Colocar caso nao seja o array Screens
   * @param  {string=null} nameString_op - Colocar caso nao seja o objecto com a key Name
   * @param  {string=null} parametersString_op - Colocar caso nao seja o objecto com a key Parameters
   * @returns any - Null se nao entrar
   */
  getSettingPortal(name: string, parameter: string, principalString_op?: string,
    nameString_op?: string, parametersString_op?: string): any {
    if (this._session && this._session.settings) {
      let objectSettings = JSON.parse(this._session.settings);
      let parameters = null,
        nameString = nameString_op ? nameString_op : 'Name',
        parametersString = parametersString_op ? parametersString_op : 'Parameters',
        screensString = principalString_op ? principalString_op : 'Screens';

      if (objectSettings.hasOwnProperty(screensString)) { // verificar se tem o array screen

        let screens = objectSettings[screensString];

        for (let screenSettings of screens) { // correr o array screen
          for (let key in screenSettings) { // correr o objecto dentro do array screen
            // sair se for prototype
            if (!screenSettings.hasOwnProperty(key)) { continue; }

            if (key === nameString) {
              if (screenSettings[key] === name) { // verificar se o name � igual ao que o utilizador quer
                parameters = screenSettings[parametersString];

                for (let prop in parameters) {
                  // sair se for prototype
                  if (!parameters.hasOwnProperty(prop)) { continue; }

                  if (prop === parameter) { // se a parametro tem o mesmo nome que o utilizador quer
                    return parameters[prop]; // vai devolver o valor do parametro
                  }
                }
              }
            }
          }
        }
      }
    }

    return null; // nao encontrou nada
  }
  /** Se a settingVar já tiver valor simplesmente usa esse valor se não vai buscar o setting
   * @param  {boolean} defaultValue valor por defeito caso não encontre o setting
   * @param  {boolean} settingVar variavel do setting (para não estar sempre a ir buscar o setting de novo)
   * @param  {string} name nome 
   * @param  {string} parameter parametro
   * 
   * @example
   * private _paymentConditionRequired: boolean;
   *
   * public get paymentConditionRequired () {
   *    this._paymentConditionRequired = this.authenticationService.getBooleanSetting(false, this._paymentConditionRequired, 'OrderPurchase', 'PaymentConditionRequired');
   *    return this._paymentConditionRequired;
   * }
   */
  getBooleanSetting(defaultValue: boolean, settingVar: boolean, name: string, parameter: string): boolean {
    if (typeof settingVar === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal(name, parameter);
      if (settingValue != null) {
        settingVar = settingValue;
      } else {
        settingVar = defaultValue;
      }
    }
    return settingVar;
  }  

  getArrayNumberSetting(defaultValue: Array<number>, settingVar: Array<number>, name: string, parameter: string): Array<number> {
    if (typeof settingVar === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal(name, parameter);
      if (settingValue != null) {
        settingVar = settingValue;
      } else {
        settingVar = defaultValue;
      }
    }
    return settingVar;
  }  

  isAuthenticated(): Observable<Session> {
    return this.http.get(SERVICE_URL + this._controller + '/VerifyAuthentication').pipe(
      map((response: any) => {
        try {
          this._session = response.ReturnStatus.ReturnObject as Session;
          // Se tiver um token válido, altera o token da app
          if (this._session.token) {
            this.http.setToken(this._session.token);
            defineToken(this._session.token);
          }

          setLanguage(this._session.language);

          // ir buscar as configuracoes para datas e decimais
          let aux = this.getSettingPortal('Decimais', 'Currency', 'Formats');
          if (aux) {
            setCurrencyDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Quantity', 'Formats');
          if (aux) {
            setQuantityDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Unitary', 'Formats');
          if (aux) {
            setUnitaryPriceDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Volume', 'Formats');
          if (aux) {
            setVolumeDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Weight', 'Formats');
          if (aux) {
            setWeightDecimal(aux);
          }
          aux = this.getSettingPortal('Currency', 'Symbol', 'Formats');
          if (aux) {
            setCurrencySymbol(aux);
          }
          aux = this.getSettingPortal('Currency', 'Code', 'Formats');
          if (aux) {
            setCurrencyCode(aux);
          }
          aux = this.getSettingPortal('Decimais', 'TaxAmount', 'Formats');
          if (aux) {
            setTaxAmountDecimal(aux);
          }
          
          return this._session;
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  login(model: Login): Observable<Session> {
    return this.http.post(SERVICE_URL + this._controller + '/Login', model).pipe(
      map((response: any) => {
        try {
          this._session = response.ReturnStatus.ReturnObject as Session;
          // Se tiver um token válido, altera o token da app
          if (this._session.token) {
            this.http.setToken(this._session.token);
            defineToken(this._session.token);
          }

          setLanguage(this._session.language);

          // ir buscar as configuracoes para datas e decimais
          let aux = this.getSettingPortal('Decimais', 'Currency', 'Formats');
          if (aux) {
            setCurrencyDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Quantity', 'Formats');
          if (aux) {
            setQuantityDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Unitary', 'Formats');
          if (aux) {
            setUnitaryPriceDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Volume', 'Formats');
          if (aux) {
            setVolumeDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Weight', 'Formats');
          if (aux) {
            setWeightDecimal(aux);
          }
          aux = this.getSettingPortal('Currency', 'Symbol', 'Formats');
          if (aux) {
            setCurrencySymbol(aux);
          }
          aux = this.getSettingPortal('Currency', 'Code', 'Formats');
          if (aux) {
            setCurrencyCode(aux);
          }
          aux = this.getSettingPortal('Decimais', 'TaxAmount', 'Formats');
          if (aux) {
            setTaxAmountDecimal(aux);
          }

          // Limpar dados em memória
          this._genericFieldsExtension = undefined;

          return this._session;
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  loginSSO(token: string): Observable<Session> {
    return this.http.post(SERVICE_URL + this._controller + '/LoginSSO', { token: token }).pipe(
      map((response: any) => {
        try {
          this._session = response.ReturnStatus.ReturnObject as Session;
          // Se tiver um token válido, altera o token da app
          if (this._session.token) {
            this.http.setToken(this._session.token);
            defineToken(this._session.token);
          }

          setLanguage(this._session.language);

          // ir buscar as configuracoes para datas e decimais
          let aux = this.getSettingPortal('Decimais', 'Currency', 'Formats');
          if (aux) {
            setCurrencyDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Quantity', 'Formats');
          if (aux) {
            setQuantityDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Unitary', 'Formats');
          if (aux) {
            setUnitaryPriceDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Volume', 'Formats');
          if (aux) {
            setVolumeDecimal(aux);
          }
          aux = this.getSettingPortal('Decimais', 'Weight', 'Formats');
          if (aux) {
            setWeightDecimal(aux);
          }
          aux = this.getSettingPortal('Currency', 'Symbol', 'Formats');
          if (aux) {
            setCurrencySymbol(aux);
          }
          aux = this.getSettingPortal('Currency', 'Code', 'Formats');
          if (aux) {
            setCurrencyCode(aux);
          }
          aux = this.getSettingPortal('Decimais', 'TaxAmount', 'Formats');
          if (aux) {
            setTaxAmountDecimal(aux);
          }

          return this._session;
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  logout(): Observable<Session> {
    return this.http.post(SERVICE_URL + this._controller + '/Logout', null).pipe(
      map((response: any) => {
        try {
          this._session = response.ReturnStatus.ReturnObject as Session;
          return this._session;
        } catch (error) {
          this._masterService.handleError(error);
          return this._session;
        }
      }));
  }

  changeCompany(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangeCompany', { id: id }).pipe(
      map((response: any) => {
        try {
          let responseReturn = response as ReturnStatusHtml;
          if (responseReturn.ReturnStatus.Successfull) {
            this._session = responseReturn.ReturnStatus.ReturnObject;
            this.http.setToken(this._session.token);
          }
          return this._masterService.convertToReturnStatusHtml(response);
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  activateAccount(email: string, locale: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ActivateAccount', { email: email, locale: locale }).pipe(
      map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  requestPasswordReset(email: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/RequestPasswordReset', { email: email }).pipe(
      map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  resetPassword(requestID: string, locale: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ResetPassword', { requestID: requestID, locale: locale }).pipe(
      map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  changeEmail(requestID: string, locale: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangeEmail', { requestID: requestID, locale: locale }).pipe(
      map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  register(entity: RegistrationRequest): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + 'RegistrationRequest/Add', { entity: entity }).pipe(
      map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Alteração de password por esta expirar
  updatePassword(newPassword: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdatePassword', { newPassword: newPassword })
      .pipe(map(response => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolve o caminho no menu
  getMenuPath(identifier: string, subIdentifier: string = null): Observable<string> {
    if (this.session && this.session.menu) {
      identifier = identifier.toLowerCase();

      for (let i = 0; i < this.session.menu.length; i++) {
        if ((this.session.menu[i].identifier).toLowerCase() === identifier) {
          return observableOf(this.session.menu[i].name);
        }

        for (let j = 0; j < this.session.menu[i].submenu.length; j++) {
          if (!subIdentifier) {
            if ((this.session.menu[i].submenu[j].identifier).toLowerCase() === identifier) {
            
              return observableOf(this.session.menu[i].name + ' - ' + this.session.menu[i].submenu[j].name);
            }
          } else {
            if ((this.session.menu[i].submenu[j].identifier).toLowerCase() === identifier && (this.session.menu[i].submenu[j].name).toLowerCase() === subIdentifier) {
              return observableOf(this.session.menu[i].name + ' - ' + this.session.menu[i].submenu[j].name);
            }
          }
        }
      }
    }

    return observableOf('');
  }

// Devolve o caminho no menu
getMenuName(identifier: string): Observable<string> {
  if (this.session && this.session.menu) {
    for (let i = 0; i < this.session.menu.length; i++) {
      if ((this.session.menu[i].identifier).toLowerCase() === identifier) {
        return observableOf(this.session.menu[i].name);
      }

      for (let j = 0; j < this.session.menu[i].submenu.length; j++) {
    
        if ((this.session.menu[i].submenu[j].identifier).toLowerCase() === identifier) {
        
          return observableOf(this.session.menu[i].name + ' - ' + this.session.menu[i].submenu[j].name);
        }
      }
    }
  }

  return observableOf('');
}


  //#region Imagens de Login
  getAuthenticationNews(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAuthenticationNews')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter o conteúdo do ficheiro do repositório
   * @param  {number} associatedFileID ID do ficheiro
   * @returns Observable
   */
  getAssociatedFile(associatedFileID: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/GetAssociatedFile?id=' + associatedFileID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Imagens de Login

  // Perfil
  /**
   * Devolver o perfil do utilizador
   * @returns Observable
   */
  getProfile(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Session/GetProfile').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Atualizar o perfil do utilizador
   * @param  {User} model
   * @param  {boolean} hasPhoto
   * @param  {File} photo?
   * @returns Observable
   */
  updateProfile(model: User, hasPhoto: boolean, photo?: File): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData(); // necessario criar um formData para conseguir enviar o ficheiro

    formData.append('entity', JSON.stringify(model));
    formData.append('newPassword', JSON.stringify(model.NewPassword));
    formData.append('confirmNewPassword', JSON.stringify(model.ConfirmNewPassword));
    formData.append('hasPhoto', JSON.stringify(hasPhoto));
    if (photo) {
      formData.append('photo', photo, photo.name); // vai adicionar ao formulario o ficheiro escolhido
    }

    return this.http.post(SERVICE_URL + 'Session/UpdateProfile', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  // FIM - Perfil

  // Settings
  get_redirectAfterLogin(defaultValue: string): string {
    if (typeof this.redirectAfterLogin === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal('Login', 'RedirectAfterLogin');
      if (settingValue != null) {
        this.redirectAfterLogin = settingValue;
      } else {
        this.redirectAfterLogin = defaultValue;
      }
    }
    return this.redirectAfterLogin;
  }

  get_showChangeImage(defaultValue: boolean): boolean {
    if (typeof this.showChangeImage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal('Profile', 'ShowChangeImage');
      if (settingValue != null) {
        this.showChangeImage = settingValue;
      } else {
        this.showChangeImage = defaultValue;
      }
    }
    return this.showChangeImage;
  }

  get_disableUserName(defaultValue: boolean): boolean {
    if (typeof this.disableUserName === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal('Profile', 'DisableUserName');
      if (settingValue != null) {
        this.disableUserName = settingValue;
      } else {
        this.disableUserName = defaultValue;
      }
    }
    return this.disableUserName;
  }

  get_companiesDefaultOrderColIndex(defaultValue: number): number {
    if (typeof this.companiesDefaultOrderColIndex === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal('Profile', 'CompaniesDefaultOrderColIndex');
      if (settingValue != null) {
        this.companiesDefaultOrderColIndex = settingValue;
      } else {
        this.companiesDefaultOrderColIndex = defaultValue;
      }
    }
    return this.companiesDefaultOrderColIndex;
  }

  get_inactivePasswordChange(defaultValue: boolean): boolean {
    if (typeof this.inactivePasswordChange === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal('Profile', 'InactivePasswordChange');
      if (settingValue != null) {
        this.inactivePasswordChange = settingValue;
      } else {
        this.inactivePasswordChange = defaultValue;
      }
    }
    return this.inactivePasswordChange;
  }

  get_showBreadCrumb(defaultValue: boolean): boolean {
    if (typeof this.showBreadCrumb === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.getSettingPortal('Breadcrumb', 'ShowBreadCrumb');
      if (settingValue != null) {
        this.showBreadCrumb = settingValue;
      } else {
        this.showBreadCrumb = defaultValue;
      }
    }
    return this.showBreadCrumb;
  }


  //#region showIban
  private _showIban: boolean;

  /** Permitir um utlizador ou empresa ter IBANs a ele associado */
  public get showIban () {
    this._showIban = this.getBooleanSetting(false, this._showIban, 'Profile', 'ShowIban');
    return this._showIban;
  }

  //#endregion
  
  // FIM - Settings

  //MFA

  get_mfaKey(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Session/GetMfaKey').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  microsoftLogin(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Session/LoginAzure').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  googleLogin(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Session/LoginGoogle').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  checkMFACode(Code: string, MandatoryMFA: boolean): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/CheckMFACode', { code: Code, isMandatoryMFA: MandatoryMFA })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  UpdateMfaAuth(HasMFA: boolean): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateMfaAuth', { hasMFA: HasMFA })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Novadelta
  get genericFieldsExtension(): Observable<GenericFieldExtension[]> {
    if (this._genericFieldsExtension === undefined) {
      return this.http.get(`${SERVICE_URL}Common/GetGenericFieldsExtension?context=${CONTEXT_USER}&referenceID=${this.session.user.ID}`).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._genericFieldsExtension = responseReturn.ReturnStatus.ReturnObject as GenericFieldExtension[];
              return this._genericFieldsExtension;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._genericFieldsExtension);
    }
  }


  getVersion(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'Session/GetVersion').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

}
