import { Component, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ALIAS, LANGUAGE } from 'src/app/constants/global';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from 'src/app/models/table-column';

declare var Functions: any;

@Component({
  templateUrl: './check-items-modal.html'
})
export class CheckItemsModalComponent implements AfterViewInit {
  items: CheckItem[];
  title: string;
  // Permite edição
  enabled: boolean;
  // Mostra Código
  showCode: boolean;
  // Mostra Nome
  showName: boolean;

  // Filtros
  codeFilter: string;
  nameFilter: string;

  // Definir table do angular 
  tableAngular: boolean = false;
  data: any;
  displayedColumns : TableColumn[];
  selectedRowsEntities : any = {key: 'ID', alreadySelectedKeys: []};
  selectedRows : any;

  constructor(public dialogRef: MatDialogRef<CheckItemsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private translateService: TranslateService) {
    this.items = data.items;
    this.title = data.title;
    this.enabled = data.enabled;
    this.showCode = data.showCode;
    this.showName = data.showName;
    this.selectedRows = data.selectedRows;

    this.tableAngular = data.tableAngular;
    this.data = data.list;
    this.displayedColumns = data.displayedColumns;

    if(data.selectedRows){
      this.selectedRowsEntities.alreadySelectedKeys = data.selectedRows
    }
  }

  ngAfterViewInit(): void {
    if(!this.tableAngular)
    {
      this.translateService.get(['BUTTON.EDIT', 'BUTTON.DELETE', 'BUTTON.CLEAR', 'REFRESH', 'BUTTON.ALL', 'SERVER_TIMEOUT', 'SERVER_ERROR', 'FILTER']).subscribe(translation => {
        let that = this;
  
        let translates = {
          clear: translation['BUTTON.CLEAR'],
          update: translation.REFRESH,
          all: translation['BUTTON.ALL'],
          serverTimeout: translation.SERVER_TIMEOUT,
          serverError: translation.SERVER_ERROR,
          search: translation['FILTER']
        }, aoColumns = [
          { 'data': 'ID', 'visible': false, 'orderable': false },
          { 'data': 'Code', 'class': 'verticalMiddle', 'visible': this.showCode },
          { 'data': 'Name', 'class': 'verticalMiddle', 'visible': this.showName },
          {
            'data': 'Selected', 'class': 'verticalMiddle text-center', 'render': function (data: boolean, type: string, row: CheckItem, meta: any) {
              if (type === 'sort' || type === 'type') {
                return data;
              } else {
                return '<div class="md-checkbox" style="height: 17px"><input id="' + row.ID + '" type="checkbox" class="check-item" ' + (data ? 'checked' : '') + ' /><label for="' + row.ID + '"></label></div>';
              }
            }
          }
        ], columnDefs = [
          { 'targets': [-1], 'width': '110px' }, // colocar a ultima coluna mais pequena
          { 'targets': '_all', 'defaultContent': '' }
        ];
  
        let table = Functions.datatablesWithDataSet('#table-list', this.items, translates, aoColumns, columnDefs,
          ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', this.showName ? 2 : 1, true, false, false, false, 'asc');
  
        table.on('draw', function () {
          let checkboxList = document.getElementsByClassName('check-item');
          for (let checkbox of Array.from(checkboxList)) {
            // adicionar à checkbox o evento click
            checkbox.addEventListener('click', function (ev: any) {
              that.storeItem(this);
            });
          }
        });
      });

    }
  }

  storeItem(element: HTMLInputElement) {
    // Seleciona / deseleciona o item
    this.items.find(r => r.ID.toString() === element.id).Selected = element.checked;

    // Atualiza a lista da tabela para reflectir na ordenação
    Functions.datatables_newDataSet('#table-listCheck', this.items);
  }

  save() {
    // Devolve os IDs dos item selecionados´
    if(!this.tableAngular)
    {
      if (this.items) {
        this.dialogRef.close(this.items.filter(r => r.Selected).map(r => r.ID));
      }
    }
    else
    {
      if(this.selectedRows && this.selectedRows.selectedRows)
      {
        this.dialogRef.close(this.selectedRows.selectedRows);
      }
    }
  }

  getSelectedRows(selectedRows)
  {
    this.selectedRows = selectedRows;
  }
}

export class CheckItem {
  ID: string;
  Selected: boolean;
  Code: string;
  Name: string;

  constructor(ID: string, Selected: boolean, Code: string, Name: string) {
    this.ID = ID;
    this.Selected = Selected;
    this.Code = Code;
    this.Name = Name;
  }
}
