import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig } from './configs/app.config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// services
import { RequestInterceptor } from './services/app/request.interceptor';
import { HttpClientCustom } from './services/http-client';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuard } from './services/app/auth-guard.service';
import { TranslateValueService } from './services/translate-value.service';
import { CompanyService } from './services/company.service';
import { UserService } from './services/user.service';
import { LocationService } from './services/location.service';
import { ProductService } from './services/product.service';
import { CommonService } from './services/common.service';
import { WorkflowService } from './services/workflow.service';
import { GeocodingService } from './services/geocoding.service';
import { SalesService } from './services/sales.service';
import { InventoryService } from './services/inventory.service';
import {ShareWalletService} from './services/shareWallet.service';
import { DataImportsService } from './services/dataimports.service';
import { OrderService } from './services/order.service';
import { OrderWorkflowService } from './services/orderWorkflow.service';
import { InvoiceService } from './services/invoice.service';
import { BudgetService } from './services/budget.service';
import { MessageService } from './services/message.service';
import { DigitalArchiveService } from './services/digitalArchive.service';
import { ScriptService } from './services/script.service';
import { AnalisysAxesService } from './services/analisysAxes.service';
import { CostCenterService } from './services/costCenter.service';
import { DeliveryNoteService } from './services/deliveryNote.service';
import { CostCenterConfigService } from './services/costCenterConfig.service';
import { FileSharingService } from './services/fileSharing.service';
import { ContractService } from './services/contract.service';
import { QuotationService } from './services/quotation.service';
import { ServicesService } from './services/services.service';
import { CompanyDeliveryScheduleService } from './services/companyDeliverySchedule.service';
import { PodService } from './services/pod.service';
import { DashboardService } from './services/dashboard.service';
import { GenericExportService } from './services/generic-export.service';
import { WarehouseService } from './services/warehouse.service';
import { BillingAreaService } from './services/billing-area.service';
import { CompanyGroupService } from './services/company-group.service';
import { TaxGroupService } from './services/tax-group.service';
import { ProductServiceTypologyService } from './services/product-service-typology.service';
import { RegisterSupplierService } from './services/register-supplier.service';
import { ExpenseService } from './services/expense.service';
import { FoldersService } from './services/folders.service';

// componentes
import { AppComponent } from './app.component';
import { AppComponentList } from './app-routing.component';
import { CompanyInfoModalComponent } from './components/layout/company-info-modal/company-info-modal.component';
import { QuotationTransitionModalComponent } from './components/quotation/workflow/transition-modal.component';
import { EmailDocumentsModalComponent } from './components/processes/email-documents-modal.component';
import { ExportDocumentsModalComponent } from './components/processes/export-documents-modal.component';
import { FoldersModalComponent } from './components/folders/folders-modal/folders-modal.component';

// routes
import { routing } from './app-routing.component';

// Módulo responsável por importar pipes, directives e components que sejam necessários em vários módulos
import { SharedModule } from './shared/shared.module';
import { GlobalErrorHandler } from './services/app/global-error-handler';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Importação de funções adicionais a tipos já conhecidos
import '../typings';

// Histórico de rotas
import { RoutingState } from './services/routingState.service';

// Biblioteca e locale para definir nos pipes de date, currency, decimal e percent
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeBg from '@angular/common/locales/bg';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeEl from '@angular/common/locales/el';
import localeIt from '@angular/common/locales/it';
import localePl from '@angular/common/locales/pl';
import localeRo from '@angular/common/locales/ro';
import { TransportService } from './services/transport.service';
import { TaskService } from './services/task.service';
import { OrderTempService } from './services/orderTemp.service';
import { TicketConfigModalComponent } from './components/specific/teixeiraduarte/tickets-configs/ticket-config-modal.component';
import { PaymentPrevisionConfigModalComponent } from './components/specific/teixeiraduarte/payment-prevision-configs/payment-prevision-config-modal.component';
import { PurchaseProductsModalComponent } from './components/shared/purchase-products-modal/purchase-products-modal.component';
import { AccountingConfigService } from './services/accountingConfig.service';
import { CADistributionModalComponent } from './components/shared/distribution-modal/ca/ca-distribution-modal.component';
import { ReceptionsModalComponent } from './components/orders/receptions-modal.component';
import { ViewReceptionsModalComponent } from './components/orders/viewReceptions-modal.component';
import { ReceptionModalComponent } from './components/orders/reception-modal.component';
import { ImageModalComponent } from './components/shared/image-modal/image-modal.component';
import { AccountingGroupService } from './services/accounting-group.service';
import { QuotationSupplierResponseTransitionModalComponent } from './components/quotation-contests/workflow/transition-modal.component';
import { ResponsesModalComponent } from './components/orders/responses-modal.component';
import { GenericTableService } from './services/generic-table.service';
import { CKEditorModule } from 'ng2-ckeditor';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LoadDocumentService } from './services/load-document.service';
import { IntegrationProfileService } from './services/integrationProfile.service';
import { RouteReuseStrategy } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';




// Definir o locale da app
registerLocaleData(localePt, 'pt');
registerLocaleData(localeBg, 'bg');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEl, 'el');
registerLocaleData(localeIt, 'it');
registerLocaleData(localePl, 'pl');
registerLocaleData(localeRo, 'ro');

// Função para carregar as configurações iniciais
export function initConfig(config: AppConfig) {
  return () => config.load();
}

// aot requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './resources/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    SharedModule.forRoot(),
    CKEditorModule,
    MatSelectModule,
  ],
  declarations: [
    AppComponent,
    AppComponentList,
    CompanyInfoModalComponent, QuotationTransitionModalComponent, AppComponent, EmailDocumentsModalComponent, ExportDocumentsModalComponent, TicketConfigModalComponent, PaymentPrevisionConfigModalComponent, PurchaseProductsModalComponent, CADistributionModalComponent, ReceptionsModalComponent, ViewReceptionsModalComponent, ReceptionModalComponent, ResponsesModalComponent,ImageModalComponent,QuotationSupplierResponseTransitionModalComponent,FoldersModalComponent
  ],
  entryComponents: [
    CompanyInfoModalComponent, QuotationTransitionModalComponent, EmailDocumentsModalComponent, ExportDocumentsModalComponent, TicketConfigModalComponent, PaymentPrevisionConfigModalComponent, PurchaseProductsModalComponent, CADistributionModalComponent, ReceptionsModalComponent, ViewReceptionsModalComponent, ReceptionModalComponent, ResponsesModalComponent,ImageModalComponent,QuotationSupplierResponseTransitionModalComponent, FoldersModalComponent
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [Title, HttpClientCustom, AuthenticationService, AuthGuard, TranslateValueService, CompanyService, UserService, LocationService,
    ProductService, CommonService, WorkflowService, GeocodingService, SalesService, InventoryService, TaskService, DataImportsService, OrderService, GenericTableService,InvoiceService, TransportService,
    BudgetService, MessageService, DigitalArchiveService, ScriptService, OrderWorkflowService, AnalisysAxesService, CostCenterService,
    DeliveryNoteService, CostCenterConfigService, FileSharingService, ContractService, QuotationService, ServicesService, CompanyDeliveryScheduleService, WarehouseService,BillingAreaService,CompanyGroupService,TaxGroupService,ProductServiceTypologyService, RegisterSupplierService,
    PodService, DashboardService, GenericExportService, RoutingState, AppConfig, OrderTempService,ShareWalletService, AccountingConfigService, AccountingGroupService, ExpenseService, IntegrationProfileService, FoldersService,LoadDocumentService,
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
