
import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { OrderWorkflow, CompanyOrderTypeWorkflowMap } from '../models/orderWorkflow';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { MasterService } from './master.service';
import { AuthenticationService } from './authentication.service';
import { Role } from '../models/role';

@Injectable()
export class OrderWorkflowService {

  orderWorkflow = new OrderWorkflow();

  private _orderWorkflowUsers: Array<any>;
  private _orderWorkflowRoles: Role[];

  private _controller: string = 'OrderWorkflow';

  // Settings
  private possibleStatesP: any[];
  private possibleStatesS: any[];
  private possibleStatesRequest: number[];
  private showExcessAmountFields: boolean;

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService) {
  }

  /**
   * Obter utilizadores
   * @returns Observable
   */
  public get orderWorkflowUsers(): Observable<Array<any>> {
    if (this._orderWorkflowUsers === undefined) {
      return this.http.get(SERVICE_URL + 'User/GetAll').pipe(map((response) => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return this._orderWorkflowUsers = responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._orderWorkflowUsers);
    }
  }

  /**
   * Obter roles
   * @returns Observable
   */
   public get orderWorkflowRoles(): Observable<Role[]> {
    if (this._orderWorkflowRoles === undefined) {
      return this.http.get(SERVICE_URL + 'User/GetAllowedRoles').pipe(map((responseRaw: any) => {
        try {
          let response = this._masterService.convertToReturnStatusHtml(responseRaw);

          if (response.ReturnStatus.Successfull) {
            this._orderWorkflowRoles = response.ReturnStatus.ReturnObject as Role[];
            return this._orderWorkflowRoles.sort((n1: Role, n2: Role) => {
              if (n1.Name > n2.Name) {
                return 1;
              }

              if (n1.Name < n2.Name) {
                return -1;
              }

              return 0;
            });
          } else if (!response.IsAuthenticated) {
            document.location.href = document.location.origin;
          } else {
            return [];
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
    } else {
      return observableOf(this._orderWorkflowRoles.sort((n1: Role, n2: Role) => {
        if (n1.Name > n2.Name) {
          return 1;
        }

        if (n1.Name < n2.Name) {
          return -1;
        }
        return 0;
      }));
    }
  }

  /**
   * Obter todos workflow's
   * @returns Observable
   */
  getAll(PurchasesOrSales: string, context?: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAllWorkflows', { PurchasesOrSales: PurchasesOrSales, context: context }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Eliminar workflow
   * @param  {number} id
   * @returns Observable
   */
  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Editar workflow
   * @param  {OrderWorkflow} orderWorkflow
   * @returns Observable
   */
  update(orderWorkflow: OrderWorkflow): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Update', orderWorkflow).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Adicionar workflow
   * @param  {OrderWorkflow} orderWorkflow
   * @returns Observable
   */
  add(orderWorkflow: OrderWorkflow): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/Add', orderWorkflow).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter workflow por id
   * @param  {number} id
   * @returns Observable
   */
  get(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Eliminar companyOrderTypeWorkflowMap
   * @param  {CompanyOrderTypeWorkflowMap} companyOrderTypeWorkflowMap
   * @returns Observable
   */
  deleteCompanyOrderType(companyOrderTypeWorkflowMap: CompanyOrderTypeWorkflowMap): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + 'CompanyOrderTypeWorkflowMap/Delete', companyOrderTypeWorkflowMap).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateCompanyOrderType(buyerID: number, supplierID: number, typeID: string, workflow?: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + 'CompanyOrderTypeWorkflowMap/Update', { buyerID: buyerID, supplierID: supplierID, typeID: typeID, workflow: workflow }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Adicionar companyOrderTypeWorkflowMap
   * @param  {CompanyOrderTypeWorkflowMap} companyOrderTypeWorkflowMap
   * @returns Observable
   */
  addCompanyOrderType(companyOrderTypeWorkflowMap: CompanyOrderTypeWorkflowMap): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + 'CompanyOrderTypeWorkflowMap/Add', companyOrderTypeWorkflowMap).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter todos companyOrderTypeWorkflowMap's
   * @returns Observable
   */
  getAllCompanyOrderType(PurchasesOrSales: string, context?: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + 'CompanyOrderTypeWorkflowMap/GetAll', { PurchasesOrSales: PurchasesOrSales, context: context }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Settings
  get_possibleStatesP(defaultValue: any[]): any[] {
    if (typeof this.possibleStatesP === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('PurchasesOrderWorkflow', 'PossibleStates');
      if (settingValue != null && settingValue.length > 0) {
        this.possibleStatesP = settingValue;
      } else {
        this.possibleStatesP = defaultValue;
      }
    }
    return this.possibleStatesP;
  }

  get_possibleStatesS(defaultValue: any[]): any[] {
    if (typeof this.possibleStatesS === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('SalesOrderWorkflow', 'PossibleStates');
      if (settingValue != null && settingValue.length > 0) {
        this.possibleStatesS = settingValue;
      } else {
        this.possibleStatesS = defaultValue;
      }
    }
    return this.possibleStatesS;
  }

  get_possibleStatesRequest(defaultValue: number[]): number[] {
    if (typeof this.possibleStatesRequest === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('RequestWorkflow', 'PossibleStates');
      if (settingValue != null && settingValue.length > 0) {
        this.possibleStatesRequest = settingValue;
      } else {
        this.possibleStatesRequest = defaultValue;
      }
    }
    return this.possibleStatesRequest;
  }

  get_showExcessAmountFields(defaultValue: boolean): boolean {
    if (typeof this.showExcessAmountFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('RequestWorkflow', 'ShowExcessAmountFields');
      if (settingValue != null) {
        this.showExcessAmountFields = settingValue;
      } else {
        this.showExcessAmountFields = defaultValue;
      }
    }
    return this.showExcessAmountFields;
  }
  // FIM - Settings
}
