import { Location } from './location';
import { Company } from './company';
import { DecimalPipe } from '@angular/common';
import { Optional } from '@angular/core';
import { CURRENCY_DECIMAL, WEIGHT_DECIMAL, VOLUME_DECIMAL, QUANTITY_DECIMAL, UNITARY_PRICE_DECIMAL, TAX_AMOUNT_DECIMAL } from '../constants/global';
import { DateTimePickerDirective } from '../directives/datepicker.directive';
import { CostCenterConfig } from './costCenterConfig';
import { AccountingConfig } from './accountingConfig';
import { TipologiaProdutoServico } from './tipologia-produto-servico';
import { GenericFieldExtension } from './generic-field-extension';

export enum InvoiceDocumentState { STATE_PENDING = 1, STATE_GRAVADA = 5, STATE_EM_APROVACAO = 8,
  STATE_SUBMETIDA = 9, STATE_APROVACAO_INTERMEDIA = 10, STATE_POR_ASSINAR = 20, STATE_USER_APPROVED = 43, 
  STATE_USER_REJECTED = 44, STATE_POR_CONFERIR = 72, STATE_CONTABILIZACAO_AUTO = 73, STATE_POR_CONTABILIZAR = 74, 
  STATE_TRATADA = 75, STATE_VALIDADA = 81, STATE_POR_PAGAR = 82, STATE_ALTERACAO_CONFIRMADA = 83, STATE_ALTERACAO_REJEITADA = 84 }

export class Invoice {

  constructor(
    public ID: number = 0,
    public OwnerID: number = 0,
    public SupplierID: number = 0,
    public NumberOfLines: number = 0,
    public InvoiceYear: number = 0,
    // -Identidicacao
    public InvoiceType: string = 'CommercialInvoice', // tipo fatura por defeito
    @Optional() public InvoiceSubType: string = null, // subtipo
    public InvoiceState: number = 0, // estado
    @Optional() public CreationDate: string = null, // data criacao
    @Optional() public InvoiceNumber: string = null, // numero
    @Optional() public InvoiceDate: string = null, // data
    //@Optional() public InvoiceSerie: string = null, // numero de faturação

    // -Referencias
    @Optional() public SuppliersDeliveryNoteNumber: string = null, // n guia entrega
    @Optional() public BuyersOrderNumber: string = null, // n encomenda
    @Optional() public BuyersOrderDate: string = null, // data encomenda
    @Optional() public BuyersCostCentre: string = null, // centro custo cliente
    @Optional() public CertificateNumber: string = null, // n certificado
    @Optional() public OriginalInvoiceType: string = null, // tipo doc origem
    @Optional() public OriginalInvoiceNumber: string = null, // n doc origem
    @Optional() public OriginalInvoiceDate: string = null, // data documento origem
    @Optional() public OtherReferences: Array<InvoiceOtherReferenceValue> = null, // outras referencias

    // -Cliente
    @Optional() public BuyerID: number = null, // cod cliente
    @Optional() public BuyerIdentifier: string = null, // cod cliente
    @Optional() public BuyerName: string = null, // nome cliente
    @Optional() public BuyerTaxNumber: string = null, // nif cliente
    @Optional() public BuyerStreet: string = null, // morada cliente
    @Optional() public BuyerPostalCode: string = null, // cod postal cliente
    @Optional() public BuyerCity: string = null, // localidade cliente
    @Optional() public BuyerCountryCode: string = null, // pais cliente
    @Optional() public BuyerTelephone: string = null, // telefone cliente
    @Optional() public BuyerFax: string = null, // fax cliente
    @Optional() public BuyerElectronicMail: string = null, // email cliente
    @Optional() public BuyerDepartmentCode: string = null, // cod departamento cliente
    @Optional() public BuyerDepartmentName: string = null, // nome departamento cliente
    @Optional() public BuyerDivisionCode: string = null, // codigo divisao cliente

    // -Entrega
    @Optional() public DeliveryToID: string = null,
    @Optional() public DeliveryToInternalID: string = null,
    @Optional() public DeliveryToIdentifier: string = null, // codigo
    @Optional() public DeliveryToName: string = null, // nome
    @Optional() public DeliveryToStreet: string = null, // morada
    @Optional() public DeliveryToPostalCode: string = null, // cod postal
    @Optional() public DeliveryToCity: string = null, // localidade
    @Optional() public DeliveryToCountryCode: string = null, // pais
    @Optional() public DeliveryToTelephone: string = null, // telefone
    @Optional() public DeliveryToFax: string = null, // fax
    @Optional() public DeliveryToElectronicMail: string = null, // email
    @Optional() public DeliveryLoadingLocation: string = null, // local carga
    @Optional() public DeliveryLoadingDate: string = null, // data carga
    @Optional() public DeliveryConditionsValue: string = null, // cod entrega
    @Optional() public DeliveryFreeText: string = null, // observacoes

    // -Faturar a
    @Optional() public InvoiceToID: string = null,
    @Optional() public InvoiceToInternalID: string = null,
    @Optional() public InvoiceToIdentifier: string = null, // codigo
    @Optional() public InvoiceToName: string = null, // nome
    @Optional() public InvoiceToTaxNumber: string = null, // nif
    @Optional() public InvoiceToStreet: string = null, // morada
    @Optional() public InvoiceToPostalCode: string = null, // cod postal
    @Optional() public InvoiceToCity: string = null, // localidade
    @Optional() public InvoiceToCountryCode: string = null, // pais
    @Optional() public InvoiceToTelephone: string = null, // telefone
    @Optional() public InvoiceToFax: string = null, // fax
    @Optional() public InvoiceToElectronicMail: string = null, // email

    // -Condicoes de pagamento
    @Optional() public PaymentConditions: InvoicePaymentCondition[] = null, // lista de condicoes de pagamento
    @Optional() public InvoiceDueDate: string = null, // data de vencimento
    @Optional() public ServicesTermDate: string = null, // data dos termos do serviço

    // -Descontos
    @Optional() public Discounts: InvoiceDiscountValue[] = null, // lista de valores com tipo, valor e data

    // -Abonos
    @Optional() public Allowances: InvoiceAllowanceValue[] = null, // lista de valores com tipo, valor e data

    // -Impostos
    @Optional() public TaxSummary: InvoiceTax[] = null, // lista de resumo de impostos

    // -Totais
    @Optional() public CurrencyCode: string = null, // moeda
    @Optional() public CurrencyOther: string = null, // moeda
    @Optional() public CurrencyExchange: number = null, // Câmbio
    @Optional() public TotalNetAmount: number = null, // liquido
    @Optional() public TotalGrossAmount: number = null, // bruto
    @Optional() public TotalChargeAmount: number = null, // cobrado
    @Optional() public TotalDiscountAmount: number = null, // descontos
    @Optional() public TotalPackingCostAmount: number = null, // embalamento
    @Optional() public TotalTransportAmount: number = null, // transporte
    @Optional() public TotalRetencaoIrsAmount: number = null, // irs
    @Optional() public TotalRetencaoIrsPercentage: number = null, // irs
    @Optional() public TotalTaxAmount: number = null, // impostos
    @Optional() public TotalEnvironmentalTaxAmount: number = null, // taxas ambiente
    @Optional() public TotalPrePaymentAmount: number = null, // pre-pagamento
    @Optional() public TotalPayableAmount: number = null, // a pagar
    @Optional() public TotalNetWeight: number = null, // peso liq
    @Optional() public TotalNetWeightUOMCode: string = null, // un peso liq
    @Optional() public TotalNetWeightUOMOther: string = null, // un peso liq
    @Optional() public TotalGrossWeight: number = null, // peso bruto
    @Optional() public TotalGrossWeightUOMCode: string = null, // un peso bruto
    @Optional() public TotalGrossWeightUOMOther: string = null, // un peso bruto
    @Optional() public TotalNetVolume: number = null, // volume liq
    @Optional() public TotalNetVolumeUOMCode: string = null, // un volume liq
    @Optional() public TotalNetVolumeUOMOther: string = null, // un volume liq

    // -Observacoes
    @Optional() public FreeText: string = null, // observacoes
    

    // -Msg edi
    @Optional() public MessageID: string = null, // mensagem id
    @Optional() public MessageDate: string = null, // data
    @Optional() public MessageSender: string = null, // emissor
    @Optional() public MessageReceiver: string = null, // recetor

    @Optional() public CurrencyExchange_form: string = null, // Câmbio
    @Optional() public TotalNetAmount_form: string = null, // liquido
    @Optional() public TotalGrossAmount_form: string = null, // bruto
    @Optional() public TotalChargeAmount_form: string = null, // cobrado
    @Optional() public TotalDiscountAmount_form: string = null, // descontos
    @Optional() public TotalPackingCostAmount_form: string = null, // embalamento
    @Optional() public TotalTransportAmount_form: string = null, // transporte
    @Optional() public TotalRetencaoIrsAmount_form: string = null, // irs
    @Optional() public TotalRetencaoIrsPercentage_form: number = null, // irs
    @Optional() public TotalTaxAmount_form: string = null, // impostos
    @Optional() public TotalEnvironmentalTaxAmount_form: string = null, // taxas ambiente
    @Optional() public TotalTaxableAmount_form: string = null, // valor tributavel
    @Optional() public TotalPrePaymentAmount_form: string = null, // pre-pagamento
    @Optional() public TotalPayableAmount_form: string = null, // a pagar
    @Optional() public TotalNetWeight_form: string = null, // peso liq
    @Optional() public TotalGrossWeight_form: string = null, // peso bruto
    @Optional() public TotalNetVolume_form: string = null, // volume liq

    @Optional() public Details: InvoiceDetail[] = null,

    // outros
    @Optional() public OrderID: number = null,
    @Optional() public DeliveryNoteID: number = null,
    @Optional() public BuyerInternalID: string = null,
    @Optional() public DeliveryLocation: string = null,
    @Optional() public DeliveryDate: string = null,
    @Optional() public TotalTaxableAmount: number = null,
    @Optional() public DeliveryTransportID: string = null,

    // fornecedor
    @Optional() public SupplierIdentifier: string = null,
    @Optional() public SupplierInternalID: string = null,
    @Optional() public SupplierName: string = null,
    @Optional() public SupplierStreet: string = null,
    @Optional() public SupplierCity: string = null,
    @Optional() public SupplierPostalCode: string = null,
    @Optional() public SupplierCountryCode: string = null,
    @Optional() public SupplierTaxNumber: string = null,
    @Optional() public SupplierTelephone: string = null, // telefone cliente
    @Optional() public SupplierFax: string = null, // fax cliente
    @Optional() public SupplierElectronicMail: string = null, // email cliente
    @Optional() public SupplierDepartmentCode: string = null, // cod departamento cliente
    @Optional() public SupplierDepartmentName: string = null, // nome departamento cliente
    @Optional() public SupplierDivisionCode: string = null, // codigo divisao cliente

    // Distribuicao
    @Optional() public CostCentersConfig: CostCenterConfig[] = null,

    @Optional() public StateIsEditable: boolean = null,
    @Optional() public Source: string = null,

    @Optional() public MessageSenderID: number = null,
    @Optional() public MessageReceiverID: number = null,
    @Optional() public HashControl: string = null,
    @Optional() public LastUpdated: Date = null,
    @Optional() public LastUpdatedBy: number = null,
    @Optional() public BuyerWorkflowID: number = null,
    @Optional() public SupplierWorkflowID: number = null,
    @Optional() public CreatedBy: number = null,
    @Optional() public ReadDate: Date = null,
    @Optional() public LastNotificationDate: Date = null,
    @Optional() public EmailsTo: string = null,
    @Optional() public BillingAreaID: number = null,
    @Optional() public CodeAT: string = null,
    @Optional() public IsErpIntegrated: boolean = null,
    @Optional() public BuyerContractNumber: string = null,
    @Optional() public BuyerIntegrationID: string = null,

    //PaymentConditions
    @Optional() public PaymentMethod: string = null, // forma de pagamento
    @Optional() public Paid: boolean = false, // pago

    //Totais
    @Optional() public ValueInEuros: number = null, // valor em euros
    @Optional() public TotalAmountToPay: number = null, // valor total a pagar

    //Contabilização
    @Optional() public AccountingConfigList: AccountingConfig[] = null,


    @Optional() public ATMEntity: string = null, 
    @Optional() public ATMReference: string = null, 
    @Optional() public IBAN: string = null, 
    @Optional() public ATCUD: string = null,
    @Optional() public InvoiceSerie: string = null,  

    //Referências
    @Optional() public AuditCode: string = null, //Cód. Auditoria

    @Optional() public Invalid: boolean = false, //Indica se a fatura está inválida
    @Optional() public IsHistory: boolean = false, //Indica se a fatura está em histórico
    @Optional() public CentralPayment: boolean = null,
    @Optional() public PaymentBlocked: boolean = null,

    //Listagem de GenericFields
    @Optional() public FieldsExtensions: GenericFieldExtension[] = [],

    @Optional() public MeansOfPayment: string = null,
    @Optional() public PaymentCondition: string = null,
    @Optional() public TotalAmountToPay_form: string = null, // volume liq
    //@Optional() public OrderType: string = null, // Tipo de encomenda

  ) {
  }


  afterChooseBuyerIdentifier(buyerChosen: Company) {
    let resultForm = {} as any;
    resultForm.BuyerID = buyerChosen.ID;
    resultForm.BuyerIdentifier = buyerChosen.InternalID;
    resultForm.BuyerName = buyerChosen.Name;
    resultForm.BuyerStreet = buyerChosen.Street;
    resultForm.BuyerCity = buyerChosen.City;
    resultForm.BuyerPostalCode = buyerChosen.PostalCode;
    resultForm.BuyerTaxNumber = buyerChosen.TaxNumber;
    resultForm.BuyerCountryCode = buyerChosen.Country;

    // quando muda o cliente limpa os dados de entrega e de faturacao
    resultForm.DeliveryToIdentifier = null;
    resultForm.DeliveryToName = null;
    resultForm.DeliveryToStreet = null;
    resultForm.DeliveryToPostalCode = null;
    resultForm.DeliveryToCity = null;
    resultForm.DeliveryToCountryCode = null;
    resultForm.DeliveryToElectronicMail = null;
    resultForm.DeliveryToTelephone = null;
    resultForm.DeliveryToFax = null;

    resultForm.InvoiceToID = null;
    resultForm.InvoiceToIdentifier = null;
    resultForm.InvoiceToName = null;
    resultForm.InvoiceToTaxNumber = null;
    resultForm.InvoiceToStreet = null;
    resultForm.InvoiceToPostalCode = null;
    resultForm.InvoiceToCity = null;
    resultForm.InvoiceToCountryCode = null;
    resultForm.InvoiceToElectronicMail = null;
    resultForm.InvoiceToTelephone = null;
    resultForm.InvoiceToFax = null;

    return resultForm;
  }

  afterChooseDeliveryToIdentifier(locationChosen: Location) {
    let resultForm = {} as any;
    resultForm.DeliveryToIdentifier = locationChosen.ID;
    resultForm.DeliveryToName = locationChosen.Name;
    resultForm.DeliveryToStreet = locationChosen.Street;
    resultForm.DeliveryToPostalCode = locationChosen.PostalCode;
    resultForm.DeliveryToCity = locationChosen.City;
    resultForm.DeliveryToCountryCode = locationChosen.CountryCode;
    resultForm.DeliveryToElectronicMail = locationChosen.Email;
    resultForm.DeliveryToTelephone = locationChosen.Phone;
    resultForm.DeliveryToFax = locationChosen.Fax;

    return resultForm;
  }

  afterChooseInvoiceToIdentifier(locationChosen: Location) {
    let resultForm = {} as any;
    resultForm.InvoiceToIdentifier = locationChosen.ID;
    resultForm.InvoiceToName = locationChosen.Name;
    resultForm.InvoiceToTaxNumber = locationChosen.TaxNumber;
    resultForm.InvoiceToStreet = locationChosen.Street;
    resultForm.InvoiceToPostalCode = locationChosen.PostalCode;
    resultForm.InvoiceToCity = locationChosen.City;
    resultForm.InvoiceToCountryCode = locationChosen.CountryCode;
    resultForm.InvoiceToElectronicMail = locationChosen.Email;
    resultForm.InvoiceToTelephone = locationChosen.Phone;
    resultForm.InvoiceToFax = locationChosen.Fax;
    return resultForm;
  }
}
export class InvoiceOtherReferenceValue {
  constructor(
    @Optional() public ReferenceType: string = null, // tipo
    @Optional() public ReferenceValue: string = null, // valor
    @Optional() public ReferenceDate: string = null // data
  ) { }
}
export class InvoicePaymentCondition {
  constructor(
    public ID: number = 0, // id
    @Optional() public DiscountType: string = null, // tipo
    @Optional() public NumberDays: number = null, // numero dias
    @Optional() public Amount: number = null, // valor
    @Optional() public Percentage: number = null, // percentagem
    @Optional() public FreeText: string = null, // observacoes
    @Optional() public CurrencyCode: string = null,
    @Optional() public CurrencyOther: string = null,

    @Optional() public Amount_form: string = null, // valor

  ) { }
}
export class InvoiceDiscountValue {
  constructor(
    @Optional() public DiscountType: string = null, // tipo
    @Optional() public Amount: number = null, // valor
    @Optional() public Percentage: number = null, // percentagem
    @Optional() public CurrencyCode: string = null, // moeda
    @Optional() public CurrencyOther: string = null, // moeda

    @Optional() public Amount_form: string = null, // valor

    @Optional() public MultiplicationFactor: number = null

  ) { }
}
export class InvoiceAllowanceValue {
  constructor(
    @Optional() public AllowanceType: string = null, // tipo
    @Optional() public Amount: number = null, // valor
    @Optional() public Percentage: number = null, // percentagem
    @Optional() public CurrencyCode: string = null,
    @Optional() public CurrencyOther: string = null,

    @Optional() public Amount_form: string = null, // valor

    @Optional() public MultiplicationFactor: number = null

  ) { }
}
export class InvoiceTax {
  constructor(
    public ID: number = 0, // pk
    @Optional() public InvoiceID: number = null, // referencia ao id da fatura
    @Optional() public InvoiceDetailID: number = null, // referencia do detalhe da fatura
    @Optional() public TaxTypeCode: string = null, // tipo de taxa
    @Optional() public TaxTypeOther: string = null, // descricao da taxa qunado tipo =outra
    @Optional() public TaxSubTypeCode: string = null, // subtipo da taxa
    @Optional() public TaxRate: number = null, // valor da taxa
    @Optional() public TaxableAmount: number = null, // valor taxavel
    @Optional() public TaxableCurrencyCode: string = null, // cod moeda
    @Optional() public TaxAmount: number = null, // valor da taxa
    @Optional() public TaxCurrencyCode: string = null, // cod moeda
    @Optional() public TaxCurrencyOther: string = null, // descriçãoo caso outro codigo de moeda
    @Optional() public FreeText: string = null, // texto livre

    @Optional() public TaxableAmount_form: string = null, // valor
    @Optional() public TaxAmount_form: string = null, // valor
    @Optional() public VatExemptReasonID: string = null //id da isenção


  ) { }
}
export class InvoiceDetail {

  constructor(
    public ID: number = 0,
    public InvoiceID: number = 0,
    @Optional() public ProductID: number = null,
    @Optional() public LineNumber: number = null,
    @Optional() public BuyerProductCode: string = null,
    @Optional() public SupplierProductCode: string = null,
    @Optional() public StandardProductCode: string = null,
    @Optional() public ProductDescription: string = null,
    @Optional() public BuyersOrderNumber: string = null,
    @Optional() public BuyersOrderDate: string = null,
    @Optional() public BuyersOrderLineNumber: string = null,
    @Optional() public DeliveryDate: string = null,
    @Optional() public SuppliersDeliveryNoteNumber: string = null,
    @Optional() public BuyersCostCenter: string = null,
    @Optional() public BatchNumber: string = null,
    @Optional() public BatchExpiryDate: string = null,
    @Optional() public OtherReferences: Array<InvoiceOtherReferenceValue> = null,
    @Optional() public Quantity: number = null,
    @Optional() public QuantityUOMCode: string = null,
    @Optional() public QuantityUOMOther: string = null,
    @Optional() public BonusQuantity: number = null,
    @Optional() public BonusQuantityUOMCode: string = null,
    @Optional() public BonusQuantityUOMOther: string = null,
    @Optional() public PackQuantity: number = null,
    @Optional() public PackQuantityUOMCode: string = null,
    @Optional() public PackQuantityUOMOther: string = null,
    @Optional() public PackSize: number = null,
    @Optional() public NetUnitPrice: number = null,
    @Optional() public GrossUnitPrice: number = null,
    @Optional() public TotalNetAmount: number = null,
    @Optional() public TotalGrossAmount: number = null,
    @Optional() public TotalTransportAmount: number = null,
    @Optional() public Taxes: InvoiceTax[] = null,
    @Optional() public Allowances: InvoiceAllowanceValue[] = null,
    @Optional() public Discounts: InvoiceDiscountValue[] = null,
    @Optional() public FreeText: string = null,
    @Optional() public VatExemptReasonID: string = null, // motivo de isencao

    @Optional() public GrossUnitCurrencyCode: string = null,
    @Optional() public GrossUnitCurrencyOther: string = null,
    @Optional() public NetUnitCurrencyCode: string = null,
    @Optional() public NetUnitCurrencyOther: string = null,
    @Optional() public TotalNetCurrencyCode: string = null,
    @Optional() public TotalNetCurrencyOther: string = null,
    @Optional() public TotalGrossCurrencyCode: string = null,
    @Optional() public TotalGrossCurrencyOther: string = null,
    @Optional() public VatID: string = null,

    @Optional() public Quantity_form: string = null,
    @Optional() public BonusQuantity_form: string = null,
    @Optional() public PackQuantity_form: string = null,
    @Optional() public PackSize_form: string = null,
    @Optional() public NetUnitPrice_form: string = null,
    @Optional() public GrossUnitPrice_form: string = null,
    @Optional() public TotalNetAmount_form: string = null,
    @Optional() public TotalGrossAmount_form: string = null,
    @Optional() public TotalTransportAmount_form: string = null,

    // Distribuicao
    @Optional() public CostCentersConfig: CostCenterConfig[] = null,
    @Optional() public AccountingConfigList: AccountingConfig[] = null,

    @Optional() public TipologiaProdutoServicoID: number = null,
    @Optional() public TipologiaProdutoServico: TipologiaProdutoServico = null,

    @Optional() public StartDate: string = null,
    @Optional() public EndDate: string = null,

    @Optional() public ApprovedProposalNumber: string = null,
    @Optional() public BuyerContractNumber: string = null,

    //Listagem de GenericFields
    @Optional() public FieldsExtensions: GenericFieldExtension[] = [],

    //Unidade de preço
    @Optional() public PriceUnit: string = null,

    //Tipo de encomenda
    @Optional() public OrderType: string = null,

    //ID do detalhe da encomenda
    @Optional() public OrderDetailID: string = null,

    //Processo do detalhe da encomenda
    @Optional() public Process: string = null,
  
    //Fracção do detalhe da encomenda
    @Optional() public Fraction: string = null
  ) { }
}

export class TransformModel {

  /**
  * Transforma a(s) ENTIDADE(S) conforme settings
  *
  */
  transformObject(invoiceObject: Invoice = null, translLanguage: string = 'pt') {
    let decimalPipe = new DecimalPipe(translLanguage);

    // datas
    invoiceObject.CreationDate = invoiceObject.CreationDate ? DateTimePickerDirective.convertToString(invoiceObject.CreationDate, true) : null;
    invoiceObject.InvoiceDate = invoiceObject.InvoiceDate ? DateTimePickerDirective.convertToString(invoiceObject.InvoiceDate, true) : null;
    invoiceObject.BuyersOrderDate = invoiceObject.BuyersOrderDate ? DateTimePickerDirective.convertToString(invoiceObject.BuyersOrderDate, true) : null;
    invoiceObject.OriginalInvoiceDate = invoiceObject.OriginalInvoiceDate ? DateTimePickerDirective.convertToString(invoiceObject.OriginalInvoiceDate, true) : null;
    invoiceObject.DeliveryLoadingDate = invoiceObject.DeliveryLoadingDate ? DateTimePickerDirective.convertToString(invoiceObject.DeliveryLoadingDate, true) : null;
    invoiceObject.InvoiceDueDate = invoiceObject.InvoiceDueDate ? DateTimePickerDirective.convertToString(invoiceObject.InvoiceDueDate, true) : null;
    invoiceObject.ServicesTermDate = invoiceObject.ServicesTermDate ? DateTimePickerDirective.convertToString(invoiceObject.ServicesTermDate, true) : null;
    invoiceObject.MessageDate = invoiceObject.MessageDate ? DateTimePickerDirective.convertToString(invoiceObject.MessageDate, true) : null;
    if (invoiceObject.OtherReferences && invoiceObject.OtherReferences.length > 0) {
      invoiceObject.OtherReferences.forEach((reference: InvoiceOtherReferenceValue) => {
        reference.ReferenceDate = reference.ReferenceDate ? DateTimePickerDirective.convertToString(reference.ReferenceDate, true) : null;
      });
    }

    // moedas
    invoiceObject.CurrencyExchange_form = invoiceObject.CurrencyExchange ? decimalPipe.transform(invoiceObject.CurrencyExchange, '1.6-6') : null;
    invoiceObject.TotalNetAmount_form = invoiceObject.TotalNetAmount ? decimalPipe.transform(invoiceObject.TotalNetAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalGrossAmount_form = invoiceObject.TotalGrossAmount ? decimalPipe.transform(invoiceObject.TotalGrossAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalChargeAmount_form = invoiceObject.TotalChargeAmount ? decimalPipe.transform(invoiceObject.TotalChargeAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalDiscountAmount_form = invoiceObject.TotalDiscountAmount ? decimalPipe.transform(invoiceObject.TotalDiscountAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalPackingCostAmount_form = invoiceObject.TotalPackingCostAmount ? decimalPipe.transform(invoiceObject.TotalPackingCostAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalTransportAmount_form = invoiceObject.TotalTransportAmount ? decimalPipe.transform(invoiceObject.TotalTransportAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalRetencaoIrsAmount_form = invoiceObject.TotalRetencaoIrsAmount ? decimalPipe.transform(invoiceObject.TotalRetencaoIrsAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalTaxAmount_form = invoiceObject.TotalTaxAmount ? decimalPipe.transform(invoiceObject.TotalTaxAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalEnvironmentalTaxAmount_form = invoiceObject.TotalEnvironmentalTaxAmount ? decimalPipe.transform(invoiceObject.TotalEnvironmentalTaxAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalTaxableAmount_form = invoiceObject.TotalTaxableAmount ? decimalPipe.transform(invoiceObject.TotalTaxableAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalPrePaymentAmount_form = invoiceObject.TotalPrePaymentAmount ? decimalPipe.transform(invoiceObject.TotalPrePaymentAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    invoiceObject.TotalPayableAmount_form = invoiceObject.TotalPayableAmount ? decimalPipe.transform(invoiceObject.TotalPayableAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    if (invoiceObject.PaymentConditions && invoiceObject.PaymentConditions.length > 0) {
      invoiceObject.PaymentConditions.forEach((pCond: InvoicePaymentCondition) => {
        pCond.Amount_form = pCond.Amount ? decimalPipe.transform(pCond.Amount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      });
    }
    if (invoiceObject.Discounts && invoiceObject.Discounts.length > 0) {
      invoiceObject.Discounts.forEach((disc: InvoiceDiscountValue) => {
        disc.Amount_form = disc.Amount ? decimalPipe.transform(disc.Amount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      });
    }
    if (invoiceObject.Allowances && invoiceObject.Allowances.length > 0) {
      invoiceObject.Allowances.forEach((allow: InvoiceAllowanceValue) => {
        allow.Amount_form = allow.Amount ? decimalPipe.transform(allow.Amount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      });
    }
    if (invoiceObject.TaxSummary && invoiceObject.TaxSummary.length > 0) {
      invoiceObject.TaxSummary.forEach((taxS: InvoiceTax) => {
        taxS.TaxableAmount_form = taxS.TaxableAmount ? decimalPipe.transform(taxS.TaxableAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
        taxS.TaxAmount_form = taxS.TaxAmount ? decimalPipe.transform(taxS.TaxAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      });
    }
    invoiceObject.TotalAmountToPay_form = invoiceObject.TotalAmountToPay ? decimalPipe.transform(invoiceObject.TotalAmountToPay, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;

    // pesos
    invoiceObject.TotalNetWeight_form = invoiceObject.TotalNetWeight ? decimalPipe.transform(invoiceObject.TotalNetWeight, '1.' + WEIGHT_DECIMAL + '-' + WEIGHT_DECIMAL) : null;
    invoiceObject.TotalGrossWeight_form = invoiceObject.TotalGrossWeight ? decimalPipe.transform(invoiceObject.TotalGrossWeight, '1.' + WEIGHT_DECIMAL + '-' + WEIGHT_DECIMAL) : null;

    // volumes
    invoiceObject.TotalNetVolume_form = invoiceObject.TotalNetVolume ? decimalPipe.transform(invoiceObject.TotalNetVolume, '1.' + VOLUME_DECIMAL + '-' + VOLUME_DECIMAL) : null;


    if (invoiceObject.Details && invoiceObject.Details.length > 0) {
      invoiceObject.Details.forEach((detail: InvoiceDetail) => {
        this.transformObjectDetail(detail, translLanguage);
      });
    }
    return invoiceObject;
  }
  transformObjectDetail(detail: InvoiceDetail, translLanguage: string): InvoiceDetail {
    if (detail) {

      let decimalPipe = new DecimalPipe(translLanguage);
      detail.DeliveryDate = detail.DeliveryDate ? DateTimePickerDirective.convertToString(detail.DeliveryDate, true) : null;
      detail.OtherReferences.forEach((reference: InvoiceOtherReferenceValue) => {
        reference.ReferenceDate = reference.ReferenceDate ? DateTimePickerDirective.convertToString(reference.ReferenceDate, true) : null;
      });
      detail.Quantity_form = detail.Quantity ? decimalPipe.transform(detail.Quantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.BonusQuantity_form = detail.BonusQuantity ? decimalPipe.transform(detail.BonusQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.PackQuantity_form = detail.PackQuantity ? decimalPipe.transform(detail.PackQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.PackSize_form = detail.PackSize ? decimalPipe.transform(detail.PackSize, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.NetUnitPrice_form = detail.NetUnitPrice ? decimalPipe.transform(detail.NetUnitPrice, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      detail.GrossUnitPrice_form = detail.GrossUnitPrice ? decimalPipe.transform(detail.GrossUnitPrice, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      detail.TotalNetAmount_form = detail.TotalNetAmount ? decimalPipe.transform(detail.TotalNetAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      detail.TotalGrossAmount_form = detail.TotalGrossAmount ? decimalPipe.transform(detail.TotalGrossAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      detail.TotalTransportAmount_form = detail.TotalTransportAmount ? decimalPipe.transform(detail.TotalTransportAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      if (detail.Taxes && detail.Taxes.length > 0) {
        detail.Taxes.forEach((reference: InvoiceTax) => {
          this.transformObjectTax(reference, translLanguage);
        });
      }
      if (detail.Discounts && detail.Discounts.length > 0) {
        detail.Discounts.forEach((reference: InvoiceDiscountValue) => {
          reference.Amount_form = reference.Amount ? decimalPipe.transform(reference.Amount, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
        });
      }
      if (detail.Allowances && detail.Allowances.length > 0) {
        detail.Allowances.forEach((reference: InvoiceAllowanceValue) => {
          reference.Amount_form = reference.Amount ? decimalPipe.transform(reference.Amount, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
        });
      }

      detail.StartDate = detail.StartDate ? DateTimePickerDirective.convertToString(detail.StartDate, true) : null;
      detail.EndDate = detail.EndDate ? DateTimePickerDirective.convertToString(detail.EndDate, true) : null;
      
      //GM 2022-07-26
      detail.BuyersOrderDate = detail.BuyersOrderDate ? DateTimePickerDirective.convertToString(detail.BuyersOrderDate, true) : null;
        
      return detail;
    }
    return null;
  }
  transformObjectTax(taxObj: InvoiceTax, translLanguage: string): InvoiceTax {
    if (taxObj) {
      let decimalPipe = new DecimalPipe(translLanguage);
      taxObj.TaxableAmount_form = taxObj.TaxableAmount ? typeof taxObj.TaxableAmount === 'string' ? taxObj.TaxableAmount : decimalPipe.transform(taxObj.TaxableAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : '0';
      taxObj.TaxAmount_form = taxObj.TaxAmount ? typeof taxObj.TaxAmount === 'string' ? taxObj.TaxAmount : decimalPipe.transform(taxObj.TaxAmount, '1.' + TAX_AMOUNT_DECIMAL + '-' + TAX_AMOUNT_DECIMAL) : '0';
      return taxObj;
    }
    return null;
  }

  /**
  * Transforma o FORMULÁRIO conforme settings
  *
  */
  transformFormDetail(detail: any, translLanguage: string): any {
    if (detail) {

      let decimalPipe = new DecimalPipe(translLanguage);
      detail.get('DeliveryDate').setValue(detail.get('DeliveryDate').value ? DateTimePickerDirective.convertToString(detail.get('DeliveryDate').value, true) : null);

      detail.get('OtherReferences').controls.forEach((reference: any) => {
        reference.get('ReferenceDate').setValue(reference.get('ReferenceDate').value ? DateTimePickerDirective.convertToString(reference.get('ReferenceDate').value, true) : null);
      });
      detail.get('Quantity').setValue(detail.get('Quantity').value && !isNaN(detail.get('Quantity').value) ? decimalPipe.transform(detail.get('Quantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : detail.get('Quantity').value);
      detail.get('BonusQuantity').setValue(detail.get('BonusQuantity').value && !isNaN(detail.get('BonusQuantity').value) ? decimalPipe.transform(detail.get('BonusQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : detail.get('BonusQuantity').value);
      detail.get('PackQuantity').setValue(detail.get('PackQuantity').value && !isNaN(detail.get('PackQuantity').value) ? decimalPipe.transform(detail.get('PackQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : detail.get('PackQuantity').value);
      detail.get('PackSize').setValue(detail.get('PackSize').value && !isNaN(detail.get('PackSize').value) ? decimalPipe.transform(detail.get('PackSize').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : detail.get('PackSize').value);
      detail.get('NetUnitPrice').setValue(detail.get('NetUnitPrice').value && !isNaN(detail.get('NetUnitPrice').value) ? decimalPipe.transform(Number(detail.get('NetUnitPrice').value), '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : detail.get('NetUnitPrice').value);
      detail.get('GrossUnitPrice').setValue(detail.get('GrossUnitPrice').value && !isNaN(detail.get('GrossUnitPrice').value) ? decimalPipe.transform(Number(detail.get('GrossUnitPrice').value), '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : detail.get('GrossUnitPrice').value);
      detail.get('TotalNetAmount').setValue(detail.get('TotalNetAmount').value && !isNaN(detail.get('TotalNetAmount').value) ? decimalPipe.transform(Number(detail.get('TotalNetAmount').value), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : detail.get('TotalNetAmount').value);
      detail.get('TotalGrossAmount').setValue(detail.get('TotalGrossAmount').value && !isNaN(detail.get('TotalGrossAmount').value) ? decimalPipe.transform(Number(detail.get('TotalGrossAmount').value), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : detail.get('TotalGrossAmount').value);
      detail.get('TotalTransportAmount').setValue(detail.get('TotalTransportAmount').value && !isNaN(detail.get('TotalTransportAmount').value) ? decimalPipe.transform(Number(detail.get('TotalTransportAmount').value), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : detail.get('TotalTransportAmount').value);

      detail.get('Taxes').controls.forEach((reference: any) => {
        reference.get('TaxableAmount').setValue(reference.get('TaxableAmount').value && !isNaN(reference.get('TaxableAmount').value) ? decimalPipe.transform(Number(reference.get('TaxableAmount').value), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : reference.get('TaxableAmount').value);
        reference.get('TaxAmount').setValue(reference.get('TaxAmount').value && !isNaN(reference.get('TaxAmount').value) ? decimalPipe.transform(Number(reference.get('TaxAmount').value), '1.' + TAX_AMOUNT_DECIMAL + '-' + TAX_AMOUNT_DECIMAL) : reference.get('TaxAmount').value);
      });

      detail.get('Discounts').controls.forEach((reference: any) => {
        if (reference.get('Amount').value && !isNaN(reference.get('Amount').value)) {
          reference.get('Amount').setValue(reference.get('Amount').value ? decimalPipe.transform(reference.get('Amount').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : reference.get('Amount').value);
        }
      });

      detail.get('Allowances').controls.forEach((reference: any) => {
        if (reference.get('Amount').value && !isNaN(reference.get('Amount').value)) {
          reference.get('Amount').setValue(reference.get('Amount').value ? decimalPipe.transform(reference.get('Amount').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : reference.get('Amount').value);
        }
      });

      if (detail.get('StartDate') != null && detail.get('EndDate') != null) {
        detail.get('StartDate').setValue(detail.get('StartDate').value ? DateTimePickerDirective.convertToString(detail.get('StartDate').value, true) : null);
        detail.get('EndDate').setValue(detail.get('EndDate').value ? DateTimePickerDirective.convertToString(detail.get('EndDate').value, true) : null);
      }
      return detail;
    }
    return null;
  }

  transformFormTaxesDetail(detail: any, translLanguage: string): any {
    if (detail) {
      let decimalPipe = new DecimalPipe(translLanguage);
      detail.get('Taxes').controls.forEach((reference: any) => {
        //GM 2022-12-18 tem de se ter atenção às condições ternárias abaixo porque o valor 0 dá false mas é para contar como nº
        // tem mesmo de se verificar se é indefinido ou null
        reference.get('TaxableAmount').setValue(reference.get('TaxableAmount').value != null && reference.get('TaxableAmount').value != undefined ? decimalPipe.transform(reference.get('TaxableAmount').value, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null);
        reference.get('TaxAmount').setValue(reference.get('TaxAmount').value != null && reference.get('TaxAmount').value != undefined ? decimalPipe.transform(reference.get('TaxAmount').value.toString().revertDecimal(), '1.' + TAX_AMOUNT_DECIMAL + '-'+ TAX_AMOUNT_DECIMAL) : null);
      });
      return detail;
    }
    return null;
  }

  /**
  * Reverte a(s) ENTIDADES(S) para permitir fazer cálculos matemáticos em ts
  *
  */
  revertObject(invoiceObj: any = null) {
    if (!invoiceObj) {
      return;
    }
    let invoiceObject: Invoice = invoiceObj;

    // moedas
    // Exemplo antigo
    // invoiceObject.TotalNetAmount = this.transformNumber(invoiceObj.TotalNetAmount);
    invoiceObject.CurrencyExchange = invoiceObj.CurrencyExchange ? ((invoiceObj.CurrencyExchange).toString()).revertDecimal() : null;
    invoiceObject.TotalNetAmount = invoiceObj.TotalNetAmount ? ((invoiceObj.TotalNetAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalGrossAmount = invoiceObj.TotalGrossAmount ? ((invoiceObj.TotalGrossAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalChargeAmount = invoiceObj.TotalChargeAmount ? ((invoiceObj.TotalChargeAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalDiscountAmount = invoiceObj.TotalDiscountAmount ? ((invoiceObj.TotalDiscountAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalPackingCostAmount = invoiceObj.TotalPackingCostAmount ? ((invoiceObj.TotalPackingCostAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalTransportAmount = invoiceObj.TotalTransportAmount ? ((invoiceObj.TotalTransportAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalRetencaoIrsAmount = invoiceObj.TotalRetencaoIrsAmount ? ((invoiceObj.TotalRetencaoIrsAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalTaxAmount = invoiceObj.TotalTaxAmount ? ((invoiceObj.TotalTaxAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalEnvironmentalTaxAmount = invoiceObj.TotalEnvironmentalTaxAmount ? ((invoiceObj.TotalEnvironmentalTaxAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalPrePaymentAmount = invoiceObj.TotalPrePaymentAmount ? ((invoiceObj.TotalPrePaymentAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalPayableAmount = invoiceObj.TotalPayableAmount ? ((invoiceObj.TotalPayableAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalTaxableAmount = invoiceObj.TotalTaxableAmount ? ((invoiceObj.TotalTaxableAmount).toString()).revertDecimal() : null;
    invoiceObject.TotalAmountToPay = invoiceObj.TotalAmountToPay ? ((invoiceObj.TotalAmountToPay).toString()).revertDecimal() : null;


    if (invoiceObj.PaymentConditions && invoiceObj.PaymentConditions.length > 0) {
      invoiceObj.PaymentConditions.forEach((pCond: any) => {
        pCond.Amount = pCond.Amount ? ((pCond.Amount).toString()).revertDecimal() : null;
      });
      invoiceObject.PaymentConditions = invoiceObj.PaymentConditions;
    }
    if (invoiceObj.Discounts && invoiceObj.Discounts.length > 0) {
      invoiceObj.Discounts.forEach((disc: any) => {
        disc.Amount = disc.Amount ? ((disc.Amount).toString()).revertDecimal() : null;
      });
      invoiceObject.Discounts = invoiceObj.Discounts;
    }
    if (invoiceObj.Allowances && invoiceObj.Allowances.length > 0) {
      invoiceObj.Allowances.forEach((allow: any) => {
        allow.Amount = allow.Amount ? ((allow.Amount).toString()).revertDecimal() : null;
      });
      invoiceObject.Allowances = invoiceObj.Allowances;
    }
    invoiceObject.TaxSummary = this.revertObjectTaxes(invoiceObj.TaxSummary);

    // pesos
    invoiceObject.TotalNetWeight = invoiceObj.TotalNetWeight ? ((invoiceObj.TotalNetWeight).toString()).revertDecimal() : null;
    invoiceObject.TotalGrossWeight = invoiceObj.TotalGrossWeight ? ((invoiceObj.TotalGrossWeight).toString()).revertDecimal() : null;

    // volumes
    invoiceObject.TotalNetVolume = invoiceObj.TotalNetVolume ? ((invoiceObj.TotalNetVolume).toString()).revertDecimal() : null;

    invoiceObject.Details = this.revertObjectDetails(invoiceObj.Details);

    return invoiceObject;
  }
  revertObjectDetails(detailsObj: any): InvoiceDetail[] {
    if (detailsObj && detailsObj.length > 0) {
      detailsObj.forEach((detail: any) => {
        detail = this.revertObjectDetail(detail);
      });
    }
    return detailsObj;
  }
  revertObjectDetail(detailsObj: any): InvoiceDetail {
    let detailsInvObj: InvoiceDetail = detailsObj;

    // detailsInvObj.Quantity = this.transformNumber(detailsObj.Quantity);

    detailsInvObj.Quantity = detailsObj.Quantity ? ((detailsObj.Quantity).toString()).revertDecimal() : null;
    detailsInvObj.BonusQuantity = detailsObj.BonusQuantity ? ((detailsObj.BonusQuantity).toString()).revertDecimal() : null;
    detailsInvObj.PackQuantity = detailsObj.PackQuantity ? ((detailsObj.PackQuantity).toString()).revertDecimal() : null;
    detailsInvObj.PackSize = detailsObj.PackSize ? ((detailsObj.PackSize).toString()).revertDecimal() : null;
    detailsInvObj.NetUnitPrice = detailsObj.NetUnitPrice ? ((detailsObj.NetUnitPrice).toString()).revertDecimal() : null;
    detailsInvObj.GrossUnitPrice = detailsObj.GrossUnitPrice ? ((detailsObj.GrossUnitPrice).toString()).revertDecimal() : null;
    detailsInvObj.TotalNetAmount = detailsObj.TotalNetAmount ? ((detailsObj.TotalNetAmount).toString()).revertDecimal() : null;
    detailsInvObj.TotalGrossAmount = detailsObj.TotalGrossAmount ? ((detailsObj.TotalGrossAmount).toString()).revertDecimal() : null;
    detailsInvObj.TotalTransportAmount = detailsObj.TotalTransportAmount ? ((detailsObj.TotalTransportAmount).toString()).revertDecimal() : null;
    detailsInvObj.Taxes = this.revertObjectTaxes(detailsObj.Taxes);
    if(detailsObj.Discounts){
      detailsObj.Discounts.forEach((reference: any) => {
        reference.Amount = reference.Amount ? ((reference.Amount).toString()).revertDecimal() : null;
      });
    }
    detailsInvObj.Discounts = detailsObj.Discounts;

    if(detailsObj.Allowances){
      detailsObj.Allowances.forEach((reference: any) => {
        reference.Amount = reference.Amount ? ((reference.Amount).toString()).revertDecimal() : null;
      });
    }    
    detailsInvObj.Allowances = detailsObj.Allowances;

    if (detailsObj.AccountingConfigList) {
      detailsObj.AccountingConfigList.forEach((config: any) => {
        config.ValorFixo = config.ValorFixo ? ((config.ValorFixo).toString()).revertDecimal() : null;
      });
      detailsInvObj.AccountingConfigList = detailsObj.AccountingConfigList;
    }

    return detailsInvObj;
  }
  revertObjectTaxes(taxesObj: any): InvoiceTax[] {
    if (taxesObj && taxesObj.length > 0) {
      taxesObj.forEach((tax: any) => {
        tax = this.revertObjectTax(tax);
      });
      return taxesObj;
    }
    return null;
  }
  revertObjectTax(taxObj: any): InvoiceTax {
    if (taxObj) {
      taxObj.TaxableAmount = taxObj.TaxableAmount ? ((taxObj.TaxableAmount).toString()).revertDecimal() : 0;
      taxObj.TaxAmount = taxObj.TaxAmount ? ((taxObj.TaxAmount).toString()).revertDecimal() : 0;
      taxObj.TaxRate = taxObj.TaxRate ? ((taxObj.TaxRate).toString()).revertDecimal() : 0;
      return taxObj;
    }
    return null;
  }
  revertObjectDiscount(disObj: any): InvoiceDiscountValue {
    disObj.Amount = disObj.Amount ? ((disObj.Amount).toString()).revertDecimal() : null;
    return disObj;
  }

}
