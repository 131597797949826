
import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { MasterService } from './master.service';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';
import { DataTableFunctions } from '../modules/datatables.module';
import { GenericFieldExtension } from '../models/generic-field-extension';

@Injectable()
export class DataImportsService {

  private _profiles: Array<any>;
  private _types: Array<any>;
  private _MSDtypes: Array<any>;
  private _controller: string = 'DataImport';
  private autoclose: boolean;
  private msd: boolean;
  private showComplexFilesCombo: boolean;
  private showTypeFilesCombo: boolean;
  private setPreviousMonthDefaultDate: boolean;
  private getAddCSVToInventory: boolean;
  private setSupplier: boolean; //Indica se é necessário selecionar um Fornecedor antes de fazer Upload do ficheiro CSV
  private setDefaultCurrency: string;
  columnInVisible: Array<number>;
  private showMSDTypes: boolean;

  private exportTransportRequestErrors : boolean;
  private _importationsTypes: Array<any>;
  private _satatesNames: Array<any>;
  private _complexFiles: Array<any>;
  

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null], 10, [[6, 'desc']], 0);
  datatableParametersAgentProfiles: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[1, 'asc']], 0);
  datatableParametersProfiles: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);

  constructor(private http: HttpClientCustom, private _masterService: MasterService,
    private _errorTreat: ErrorTreatmentFunctions, private authenticationService: AuthenticationService, private _dataTableF: DataTableFunctions) { }

  get profiles(): Observable<Array<any>> {
    if (this._profiles === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetProfiles').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._profiles = responseReturn.ReturnStatus.ReturnObject;
              return this._profiles;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._profiles);
    }
  }

  get types(): Observable<Array<any>> {
    if (this._types === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetImportationTypes').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._types = responseReturn.ReturnStatus.ReturnObject;
              return this._types;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._types);
    }
  }

  get MSDtypes(): Observable<Array<any>> {
    if (this._MSDtypes === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetMSDImportationTypes').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._MSDtypes = responseReturn.ReturnStatus.ReturnObject;
              return this._MSDtypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._MSDtypes);
    }
  }

  /****************GET METHODS**********************/

  getProfileList(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetProfileList').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getProfileList2(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetProfileList2').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getProfileByID(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetProfileByID?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getImportsList(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetImportsList?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getStatesNames(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetStatesNames').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getImportationDetails(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetImportationDetails?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getImportationTypes(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetImportationTypes').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get_ShowMSDImportationTypes(defaultValue: boolean): boolean {
    if(typeof this.showMSDTypes === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('ControlPannel','ShowOnlyMsdImportationTypes');
      if(settingValue != null){
        this.showMSDTypes = settingValue;
      } else{
        this.showMSDTypes = defaultValue;
      }
    }
    return this.showMSDTypes;
  }

  getDropdown(id: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetDropdown?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getCurrencies(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetCurencies').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getManageAgentProfile(id: any): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetManageAgentProfile?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getImportProfiles(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetImportProfiles').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  setImportationProfileEntity(entity: any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SetImportationProfileEntity', { entity: entity }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  createImportationProfileEntity(entity: any,salesChanges:boolean,inventoryChanges:boolean): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/CreateImportationProfileEntity', { entity: entity,salesChanges,inventoryChanges }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getComplexFiles(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetComplexFiles').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
    
  }

  /****************UPDATE METHODS**********************/

  updateImportProfile(profile: any, action: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateImportProfile', { profile: profile, action: action}).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /****************CREATE METHODS**********************/

  createImportProfile(profile: any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/CreateProfile', { profile: profile }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /****************DELETE METHODS**********************/

  deleteProfile(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteProfile?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteImportation(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteImportation?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /****************DOWNLOAD METHODS**********************/
  downloadFile(filePath: string): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadFile?path=' + filePath);
  }

  downloadTemplate(template: string): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadTemplate?template=' + template);
  }

  downloadExcelProfile(id: any, profileType: string): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/ExportProfileExcel', { id: id, profileType: profileType });
  }

  downloadExcelProfiles(): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/ExportProfilesToExcel', {});
  }

  /****************UPLOAD METHODS**********************/

  uploadImportFile(file: File, profileID: number, wholesaler: number, startDate: string, endDate: string,locationID:number): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name); 
    formData.append('profileID', profileID.toString());
    formData.append('wholesaler', wholesaler.toString());
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('locationID', locationID.toString());
    
    return this.http.put(SERVICE_URL + this._controller + '/UploadImportFile', formData);
  }

  uploadImportMultipleFile(files: Array<File>, profileID: number, wholesaler: number, startDate: string, endDate: string,locationID:number): Observable<any> {
    let formData: FormData = new FormData();
    let i = 0
    files.forEach(file => {
      formData.append('uploadFile'+i, file, file.name);
      i++;
    });
    formData.append('profileID', profileID.toString());
    formData.append('wholesaler', wholesaler.toString());
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('locationID', locationID.toString());
    return this.http.put(SERVICE_URL + this._controller + '/UploadImportMultipleFile', formData);
  }

  uploadTemplateFile(file: File, typeID: number, fieldsExtensions: GenericFieldExtension[] = []): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('typeID', typeID.toString());
    //Se forem enviados Extended Fields, adiciona-os ao Form Data
    if(fieldsExtensions.length > 0){
      formData.append('fieldsExtensions', JSON.stringify(fieldsExtensions));      
    }
    return this.http.put(SERVICE_URL + this._controller + '/UploadTemplateFile', formData);
  }

  uploadTemplateMultipleFile(files: Array<File>, typeID: number, fieldsExtensions: GenericFieldExtension[] = []): Observable<any> {
    let formData: FormData = new FormData();
    let i = 0
    files.forEach(file => {
      formData.append('uploadFile'+i, file, file.name);
      i++;
    });
    formData.append('typeID', typeID.toString());

    //Se forem enviados Extended Fields, adiciona-os ao Form Data
    if(fieldsExtensions.length > 0){
      formData.append('fieldsExtensions', JSON.stringify(fieldsExtensions));      
    }
    return this.http.put(SERVICE_URL + this._controller + '/UploadTemplateMultipleFile', formData);
  }

  uploadComplexFile(file: File, complexFile: string, startDate: string, endDate: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('uploadFile', file, file.name);
    formData.append('complexFile', complexFile);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    return this.http.put(SERVICE_URL + this._controller + '/UploadComplexFiles', formData);
  }

  uploadComplexMultipleFile(files: Array<File>, complexFile: string, startDate: string, endDate: string): Observable<any> {
    let formData: FormData = new FormData();
    let i = 0
    files.forEach(file => {
      formData.append('uploadFile'+i, file, file.name);
      i++;
    });
    formData.append('complexFile', complexFile);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    return this.http.put(SERVICE_URL + this._controller + '/UploadComplexMultipleFiles', formData);
  }



  /*****************************SETTINGS************************/

  getAutoclose(): boolean {
    if (typeof this.autoclose === 'undefined') { // verificar se ainda nao tem valor
      if (this.authenticationService.getSettingPortal('Importations', 'AutoCloseUpload') != null) {
        this.autoclose = this.authenticationService.getSettingPortal('Importations', 'AutoCloseUpload');
      } else {
        this.autoclose = false;
      }
    }
    return this.autoclose;
  }

  IsMSD(): boolean {
    if (typeof this.msd === 'undefined') { // verificar se ainda nao tem valor
      if (this.authenticationService.getSettingPortal('Importations', 'IsMSD') != null) {
        this.msd = this.authenticationService.getSettingPortal('Importations', 'IsMSD');
      } else {
        this.msd = false;
      }
    }
    return this.msd;
  }

  ShowComplexFilesCombo(defaultValue: boolean): boolean{
    if (typeof this.showComplexFilesCombo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ManageImports', 'ShowComplexFilesCombo');
      if(settingValue != null){
        this.showComplexFilesCombo = settingValue
      }else {
        this.showComplexFilesCombo = defaultValue;
      }
    }
    return this.showComplexFilesCombo;
  }
  ShowTypeFilesCombo(defaultValue: boolean): boolean{
    if (typeof this.showTypeFilesCombo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ManageImports', 'ShowTypeFilesCombo');
      if(settingValue != null){
        this.showTypeFilesCombo = settingValue
      }else {
        this.showTypeFilesCombo = defaultValue;
      }
    }
    return this.showTypeFilesCombo;
  }

  get_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DataImport', 'ColumVisible');
      this.columnInVisible = this._dataTableF.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible;
  }

  get_ExportTransportRequestErrors(defaultValue: boolean): boolean {
    if (typeof this.exportTransportRequestErrors === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('DataImport', 'ExportTransportRequestErrors');
      if(settingValue != null){
        this.exportTransportRequestErrors = settingValue
      }else {
        this.exportTransportRequestErrors = defaultValue;
      }
    }
    return this.exportTransportRequestErrors;
  }
  
  SetPreviousMonthDefaultDate(defaultValue: boolean): boolean{
    if (typeof this.setPreviousMonthDefaultDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ManageImports', 'SetPreviousMonthDefaultDate');
      if(settingValue != null){
        this.setPreviousMonthDefaultDate = settingValue
      }else {
        this.setPreviousMonthDefaultDate = defaultValue;
      }
    }
    return this.setPreviousMonthDefaultDate;
  }

  GetAddCSVToInventory(defaultValue: boolean): boolean{
    if (typeof this.getAddCSVToInventory === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Profile', 'AddCSVToInventory');
      if(settingValue != null){
        this.getAddCSVToInventory = settingValue
      }else {
        this.getAddCSVToInventory = defaultValue;
      }
    }
    return this.getAddCSVToInventory;
  }

  getDefaultCurrency(defaultValue: string): string{
    if (typeof this.setPreviousMonthDefaultDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Currency', 'Code', 'Formats');
      if(settingValue != null){
        this.setDefaultCurrency = settingValue
      }else {
        this.setDefaultCurrency = defaultValue;
      }
    }
    return this.setDefaultCurrency;
  }
  
  SetSupplier(defaultValue: boolean): boolean{
    if (typeof this.setSupplier === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ManageImports', 'SetSupplier');
      if(settingValue != null){
        this.setSupplier = settingValue
      }else {
        this.setSupplier = defaultValue;
      }
    }
    return this.setSupplier;
  }


  downloadExcelTransportRequestError(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelTransportRequestError?id=' + id);
  }

  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  public get importationTypes(): Observable<Array<any>> {
    if (this._importationsTypes === undefined) {
      return this.getComplements('GetImportationTypes').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._importationsTypes = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._importationsTypes);
    }
  }  

  public get statesNames(): Observable<Array<any>> {
    if (this._satatesNames === undefined) {
      return this.getComplements('GetStatesNames').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._satatesNames = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._satatesNames);
    }
  } 

  public get complexFiles(): Observable<Array<any>> {
    if (this._complexFiles === undefined) {
      return this.getComplements('GetComplexFiles').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._complexFiles = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._complexFiles);
    }
  } 
  
}
