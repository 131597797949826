import { of as observableOf, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { Product } from '../models/product';
import { ReturnStatusHtml } from '../models/returnStatus';
import { Brand } from '../models/brand';
import { Dropdown } from '../models/dropdown';
import { ProductType } from '../models/product-type';
import { LogisticUnit } from '../models/logistic-unit';
import { LogisticUnitType } from '../models/logistic-unit-type';
import { LogisticUnitUsage } from '../models/logistic-unit-usage';
import { MasterService } from './master.service';
import { AuthenticationService } from './authentication.service';
// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { DatatableParameters } from '../models/datatable-parameters';
import { WorkflowStep } from '../models/workflow-step';
import { DataTableFunctions } from '../modules/datatables.module';
import { TaxArea } from '../models/tax-area';

export enum PDFType {
  Commercial = 0,
  Technical = 1
}

@Injectable()
export class ProductService {
  supplierID: number;

  private _pimConfigurations: any;
  private _brands: Brand[];
  private _productCategory: Array<any>;
  private _speciesList: Array<any>;
  private _products: Array<any>;

  filter_CategoryID: number;
  filter_FamilyID: number;
  filters: any = new Object();
  filtersBaglass: any = null;
  datatableParametersEditor: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null,null,null,null,null,null], 10, [[1, 'asc']], 0);
  datatableParametersViewer: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[1, 'asc']], 0);
  datatableParametersRoutings: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  datatableParametersPims: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null], 10, [[5, 'asc']], 0);
  datatableParametersBaglass: DatatableParameters = new DatatableParameters([null, null, null, null, null, null], 10, [[1, 'asc']], 0);

  private _controller: string = 'Product';

  // Settings
  private showAttributesListPanel_editor: boolean;
  private showIsDeprecated_editor: boolean;
  private showIsBuy_editor: boolean;
  private showIsSale_editor: boolean;
  private showAttributesListPanel_sale: boolean;
  private showIsDeprecated_sale: boolean;
  private showIsBuy_sale: boolean;
  private showIsSale_sale: boolean;
  private AllowAssociateProductsRefs: boolean;
  private AllowMassiveCatalogsAssociation: boolean;

  private columnInVisible_editor: number[];
  private showImages_editor: boolean;
  private showType_editor: boolean;
  private showShortName_editor: boolean;
  private showPresentation_editor: boolean;
  private showCodGrupoContabilizacao_editor: boolean;
  private showCategory_editor: boolean;
  private showSpecie_editor: boolean;
  private showApplication_editor: boolean;
  private nameMaxLength_editor: number;
  private showFamily_editor: boolean;
  private showNetUnitBuyPrice_editor: boolean;
  private showBuyCurrencyCode_editor: boolean;
  private statusTabLogisticEdit_editor: Array<number>; // estado em que pode editar os campos da tab de dados logisticos
  private showNetUnitPrice_editor: boolean;
  private columnInVisiblePack_editor: number[];
  private showPanelAdditionalInfo: boolean;
  private showCompanyGroup: boolean;
  private defaultValueIsBuy: boolean;
  private defaultValueIsSale: boolean;
  private manageAttachesByAdminsOnly: boolean;

  private showImages_viewer: boolean;
  private showType_viewer: boolean;
  private showShortName_viewer: boolean;
  private showPresentation_viewer: boolean;
  private showCategory_viewer: boolean;
  private showFamily_viewer: boolean;
  private showNetUnitBuyPrice_viewer: boolean;
  private showBuyCurrencyCode_viewer: boolean;
  private columnInVisible_viewer: number[];
  private columnInVisiblePack_viewer: number[];
  private showFilters: boolean;
  private showFilterProductsInPriceList: boolean;

  private afterSaveRemainOnSamePage: boolean;
  private redirectOnUpdate: boolean;

  // Settings PIM
  private showCommercialPDF: boolean;
  private showTechnicalPDF: boolean;
  private defaultActiveFilterValue?: boolean;
  private showTypeColumn: boolean;
  private columnInVisible: number[];
  private showUnitOfMeasure: boolean;
  private showVatRate: boolean;
  private showLogisticUnitMaterial: boolean;
  private showLogisticUnitPackageType: boolean;
  private showFishingFoodFields: boolean;
  private showFoodPreparationState: boolean;

  private showAccountingCodeList: boolean; //Valida se mostra o Código Contabilístico : lista
  private showAccountingCode: boolean; //Valida se mostra o Código Contabilístico : texto livre
  private optionalStandardProductCode: boolean; //Setting que indica se o StandardCode (EAN) é opcional

  private gallery: string[];

  private _directions: Dropdown[];
  private _infoTypes: Dropdown[];
  private _angles: Dropdown[];
  private _insOrOuts: Dropdown[];

  private _productTypes: ProductType[];
  private _logisticUnits: LogisticUnit[];
  private _GPCBrickCodes1: Dropdown[];
  private _GPCBrickCodes2: Dropdown[];
  private ShowInactiveRed: boolean;
  private AllowTechnicalForm: boolean;

  private _GPCBrickCodes3: Dropdown[];
  private _GPCBrickCodes4: Dropdown[];
  private _promotionTypes: Dropdown[];
  private _productTaxTypes: Dropdown[];
  private _productbarCodeTypes: Dropdown[];
  private _logisticUnitTypes: LogisticUnitType[];
  private _packageTypes: Dropdown[];
  private _logisticUnitUsages: LogisticUnitUsage[];
  private _foodContactTypes: Dropdown[];
  private _foodProductionMethods: Dropdown[];
  private _foodCatchMethods: Dropdown[];
  private _foodFrozens: Dropdown[];
  private _foodCatchAreas: Dropdown[];
  private _foodSubCatchAreas: Dropdown[];
  private _containmentLevels: Dropdown[];
  private _allergens: Dropdown[];
  private _foodPreparationStates: Dropdown[];
  private _energyOrNutrients: Dropdown[];
  private _nutrientsPrecisions: Dropdown[];
  private _vitaminesAndMinerals: Dropdown[];
  private _nutrientsPercentageTypes: Dropdown[];
  private _otherNutrients: Dropdown[];
  private _foodDateInfos: Dropdown[];
  private _packageRecycleSchemes: Dropdown[];
  private _packageTermsAndConditions: Dropdown[];
  private _packageFunctions: Dropdown[];
  private _wayNextTypes: Dropdown[];
  private _taxAreas: TaxArea[];

  constructor(private http: HttpClientCustom, private _masterService: MasterService,
    private _errorTreat: ErrorTreatmentFunctions,
    private authenticationService: AuthenticationService,
    private dataTableFunctions: DataTableFunctions
  ) { }


  public productCategoriesForSupplier(supplierID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + 'GetAllCategories?ownerID=' + supplierID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  public getProductsByCategories(categoriesIDs: string)
  {
    return this.http.get(SERVICE_URL + this._controller + '/' + 'GetProductsByCategories?categoriesIDs=' + categoriesIDs).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  public getProductsByFamilies(familiesIDs: string)
  {
    return this.http.get(SERVICE_URL + this._controller + '/' + 'GetProductsByFamilies?familiesIDs=' + familiesIDs).pipe(
      map((response: any) => response));
  }

  public get productCategories(): Observable<Array<any>> {
    if (this._productCategory === undefined) {
      return this.getComplements('GetAllCategories').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._productCategory = response.ReturnStatus.ReturnObject.Categories;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._productCategory);
    }
  }

  get_InactiveShowRed(defaultValue: boolean): boolean {
    if (typeof this.ShowInactiveRed === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductsEditor', 'ShowInactiveRed');//seting conforme o back
      if (settingValue != null) {
        this.ShowInactiveRed = settingValue;
      } else {
        this.ShowInactiveRed = defaultValue;
      }
    }
    return this.ShowInactiveRed;
  }

  getAllowTechnicalForm(defaultValue: boolean): boolean {
    if (typeof this.AllowTechnicalForm === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductsEditor', 'AllowTechnicalForm');//seting conforme o back
      if (settingValue != null) {
        this.AllowTechnicalForm = settingValue;
      } else {
        this.AllowTechnicalForm = defaultValue;
      }
    }
    return this.AllowTechnicalForm;
  }
  
  get taxAreas(): Observable<TaxArea[]> {
    if (!this._taxAreas) {
      return this.getComplements('GetTaxAreas').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._taxAreas = response.ReturnStatus.ReturnObject;
        } else {
          return new Array<TaxArea>();
        }
      }));
    } else {
      return observableOf(this._taxAreas);
    }
  }

  public get speciesList(): Observable<Array<any>> {
    if (this._speciesList === undefined) {
      return this.getComplements('GetAllSpecies').pipe(map((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          return this._speciesList = response.ReturnStatus.ReturnObject.ProductSpecies;
        } else {
          return new Array<any>();
        }
      }));
    } else {
      return observableOf(this._speciesList);
    }
  }


  getComplements(action: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/' + action).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  get(id: number, ignorePermissions: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/Get?id=' + id + '&ignorePermissions=' + ignorePermissions).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  getAllCatalogs(start: number, howMany: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + 'Catalog/GetAllPaged', { start: start, length: howMany })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  getAllValidateCatalogProducts(productID: number, selectAll: boolean, firstTime: boolean): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + 'Catalog/GetAllValidateCatalogProducts', { productID: productID, selectAll: selectAll, firstTime: firstTime})
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  getCatalogsByProductID(model: Product): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetCatalogsByProductID', { product: model }).
      pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
   * Obter numero documento seguinte
   * @param  {number} OwnerId
   * @param  {number} supplierID
   * @returns Observable
   */
  getNextNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextNumber').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  add(model: Product): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();

    for (let i = 0; i < model.Media.length; i++) {
      if (model.Media[i].imageFile !== null) {
        formData.append('media[' + i + ']', model.Media[i].imageFile, model.Media[i].OriginalName);
        model.Media[i].FileContent = null;
        model.Media[i].imageFile = null;
      }
    }

    formData.append('product', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/Add', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  UpdateProductToCatalogs(model: Product, catalogIds: Array<number>, catalogRemoveIds): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateProductToCatalogs', { product: model, Catalogs: catalogIds, CatalogsToRemove: catalogRemoveIds }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(model: Product): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();

    for (let i = 0; i < model.Media.length; i++) {
      if (model.Media[i].imageFile !== null) {
        formData.append('media[' + i + ']', model.Media[i].imageFile, model.Media[i].OriginalName);
        model.Media[i].FileContent = null;
        model.Media[i].imageFile = null;
      }
    }

    formData.append('product', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/Update', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));

  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));

  }
  getStocks(id: number) {
    return this.http.get(SERVICE_URL + this._controller + '/GetStockProduct?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getImages(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetImages?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getImageFile(mediaID: number, productID: number, width?: number, height?: number, mediaVersionID?: number, browserSupport?: boolean): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/GetImage?id=' + mediaID + '&productID=' + productID + '&width=' + width + '&height=' + height + (mediaVersionID ? '&mediaVersionID=' + mediaVersionID : '') + (browserSupport ? '&browserSupport=true' : '')).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getPIM(id: number, typeID: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetPIM?id=' + id + '&typeID=' + typeID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deletePIM(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeletePIM?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  submitPIM(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitPIM', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getWorkflowSteps(id: number): Observable<WorkflowStep[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowSteps?id=' + id).pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  changeWorkflowStep(id: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangeWorkflowStep', { id: id, stepID: stepID, notes: notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getPIMByVersion(id: number, version: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetPIMByVersion?id=' + id + '&version=' + version).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getPIMChanges(id: number): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetPIMChanges?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getGS1(standardCode: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetGS1?standardCode=' + standardCode).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  GetAllBasedOnPartnerAndCatalog(partnerID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAllForDatatable', { partnerID: partnerID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetAllProductsList(): Observable<Array<any>> {
    return this.http.get(SERVICE_URL + this._controller + '/GetAllProductsList').pipe(
      map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            if (this._products === undefined) {
              this._products = [];
            }
            return this._products = responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }



  get PIMConfigurations(): Observable<any> {
    if (this._pimConfigurations === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetConfigurations').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._pimConfigurations = responseReturn.ReturnStatus.ReturnObject;
              return this._pimConfigurations;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._pimConfigurations);
    }
  }

  get brands(): Observable<Brand[]> {
    if (this._brands === undefined) {
      return this.http.get(SERVICE_URL + 'Brand/GetAll').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._brands = responseReturn.ReturnStatus.ReturnObject as Brand[];
              return this._brands;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._brands);
    }
  }

  // #region Baglass
  sendRequestStock(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SendRequestStock', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  sendRequestPrice(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SendRequestPrice', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  // #endregion Baglass

  // #region Settings

  /**
   * ***************** EDITOR **************************************************************************************
  */
  /**
  * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
  * @param  {Array<number>} defaultValue - Valor por defeito do parametro
  * @param  {Array<string>} columnsNames - nomes das colunas
  * @returns Array
  */
  getEDITOR_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductsEditor', 'ColumnVisible');
      this.columnInVisible_editor = this.dataTableFunctions.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible_editor;
  }

  getEDITOR_showFilters(defaultValue: boolean): boolean {
    if (typeof this.showFilters === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductsEditor', 'ShowFilters');
      if (settingValue !== null) {
        this.showFilters = settingValue;
      } else {
        this.showFilters = defaultValue;
      }
    }
    return this.showFilters;
  }

  getAllowAssociateProductsRefs(defaultValue: boolean): boolean {
    if (typeof this.AllowAssociateProductsRefs === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'AllowAssociateProductsRefs');
      if (settingValue != null) {
        this.AllowAssociateProductsRefs = settingValue;
      } else {
        this.AllowAssociateProductsRefs = defaultValue;
      }
    }
    return this.AllowAssociateProductsRefs;
  }

  getAllowMassiveCatalogsAssociation(defaultValue: boolean): boolean {
    if (typeof this.AllowMassiveCatalogsAssociation === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'AllowMassiveCatalogsAssociation');
      if (settingValue != null) {
        this.AllowMassiveCatalogsAssociation = settingValue;
      } else {
        this.AllowMassiveCatalogsAssociation = defaultValue;
      }
    }
    return this.AllowMassiveCatalogsAssociation;
  }



  getEDITOR_showAttributesListPanel(defaultValue: boolean): boolean {
    if (typeof this.showAttributesListPanel_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowAttributesListPanel');
      if (settingValue != null) {
        this.showAttributesListPanel_editor = settingValue;
      } else {
        this.showAttributesListPanel_editor = defaultValue;
      }
    }
    return this.showAttributesListPanel_editor;
  }

  getEDITOR_showIsDeprecated(defaultValue: boolean): boolean {
    if (typeof this.showIsDeprecated_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowIsDeprecated');
      if (settingValue != null) {
        this.showIsDeprecated_editor = settingValue;
      } else {
        this.showIsDeprecated_editor = defaultValue;
      }
    }
    return this.showIsDeprecated_editor;
  }

  getEDITOR_showIsBuy(defaultValue: boolean): boolean {
    if (typeof this.showIsBuy_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowIsBuy');
      if (settingValue != null) {
        this.showIsBuy_editor = settingValue;
      } else {
        this.showIsBuy_editor = defaultValue;
      }
    }
    return this.showIsBuy_editor;
  }

  getEDITOR_showIsSale(defaultValue: boolean): boolean {
    if (typeof this.showIsSale_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowIsSale');
      if (settingValue != null) {
        this.showIsSale_editor = settingValue;
      } else {
        this.showIsSale_editor = defaultValue;
      }
    }
    return this.showIsSale_editor;
  }

  getEDITOR_showImages(defaultValue: boolean): boolean {
    if (typeof this.showImages_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowImages');
      if (settingValue != null) {
        this.showImages_editor = settingValue;
      } else {
        this.showImages_editor = defaultValue;
      }
    }
    return this.showImages_editor;
  }

  getEDITOR_showType(defaultValue: boolean): boolean {
    if (typeof this.showType_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowType');
      if (settingValue != null) {
        this.showType_editor = settingValue;
      } else {
        this.showType_editor = defaultValue;
      }
    }
    return this.showType_editor;
  }

  getEDITOR_showShortName(defaultValue: boolean): boolean {
    if (typeof this.showShortName_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowShortName');
      if (settingValue != null) {
        this.showShortName_editor = settingValue;
      } else {
        this.showShortName_editor = defaultValue;
      }
    }
    return this.showShortName_editor;
  }

  getEDITOR_showPresentation(defaultValue: boolean): boolean {
    if (typeof this.showPresentation_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowPresentation');
      if (settingValue != null) {
        this.showPresentation_editor = settingValue;
      } else {
        this.showPresentation_editor = defaultValue;
      }
    }
    return this.showPresentation_editor;
  }

  getEDITOR_showCodGrupoContabilizacao(defaultValue: boolean): boolean {
    if (typeof this.showCodGrupoContabilizacao_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowCodGrupoContabilizacao');
      if (settingValue != null) {
        this.showCodGrupoContabilizacao_editor = settingValue;
      } else {
        this.showCodGrupoContabilizacao_editor = defaultValue;
      }
    }
    return this.showCodGrupoContabilizacao_editor;
  }

  getEDITOR_showCategory(defaultValue: boolean): boolean {
    if (typeof this.showCategory_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowCategory');
      if (settingValue != null) {
        this.showCategory_editor = settingValue;
      } else {
        this.showCategory_editor = defaultValue;
      }
    }
    return this.showCategory_editor;
  }

  getEDITOR_showSpecie(defaultValue: boolean): boolean {
    if (typeof this.showSpecie_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowSpecie');
      if (settingValue != null) {
        this.showSpecie_editor = settingValue;
      } else {
        this.showSpecie_editor = defaultValue;
      }
    }
    return this.showSpecie_editor;
  }

  getEDITOR_showApplication(defaultValue: boolean): boolean {
    if (typeof this.showApplication_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowApplication');
      if (settingValue != null) {
        this.showApplication_editor = settingValue;
      } else {
        this.showApplication_editor = defaultValue;
      }
    }
    return this.showApplication_editor;
  }

  getEDITOR_nameMaxLength(defaultValue: number): number {
    if (typeof this.nameMaxLength_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'NameMaxLength');
      if (settingValue != null) {
        this.nameMaxLength_editor = settingValue;
      } else {
        this.nameMaxLength_editor = defaultValue;
      }
    }
    return this.nameMaxLength_editor;
  }

  getEDITOR_showFamily(defaultValue: boolean): boolean {
    if (typeof this.showFamily_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowFamily');
      if (settingValue != null) {
        this.showFamily_editor = settingValue;
      } else {
        this.showFamily_editor = defaultValue;
      }
    }
    return this.showFamily_editor;
  }

  getEDITOR_showNetUnitBuyPrice(defaultValue: boolean): boolean {
    if (typeof this.showNetUnitBuyPrice_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowNetUnitBuyPrice');
      if (settingValue != null) {
        this.showNetUnitBuyPrice_editor = settingValue;
      } else {
        this.showNetUnitBuyPrice_editor = defaultValue;
      }
    }
    return this.showNetUnitBuyPrice_editor;
  }

  getEDITOR_showNetUnitPrice(defaultValue: boolean): boolean {
    if (typeof this.showNetUnitPrice_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowNetUnitPrice');
      if (settingValue != null) {
        this.showNetUnitPrice_editor = settingValue;
      } else {
        this.showNetUnitPrice_editor = defaultValue;
      }
    }
    return this.showNetUnitPrice_editor;
  }

  getEDITOR_showBuyCurrencyCode(defaultValue: boolean): boolean {
    if (typeof this.showBuyCurrencyCode_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowBuyCurrencyCode');
      if (settingValue != null) {
        this.showBuyCurrencyCode_editor = settingValue;
      } else {
        this.showBuyCurrencyCode_editor = defaultValue;
      }
    }
    return this.showBuyCurrencyCode_editor;
  }

  getEDITOR_statusTabLogisticEdit(defaultValue: Array<number>): Array<number> {
    if (typeof this.statusTabLogisticEdit_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'FormTransnauticaEditionStates');
      if (settingValue != null) {
        this.statusTabLogisticEdit_editor = settingValue;
      } else {
        this.statusTabLogisticEdit_editor = defaultValue;
      }
    }
    return this.statusTabLogisticEdit_editor;
  }

  getEDITOR_ColumnInVisiblePack(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisiblePack_editor === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ColumnVisiblePack');
      this.columnInVisiblePack_editor = this.dataTableFunctions.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisiblePack_editor;
  }

  getEDITOR_showPanelAdditionalInfo(defaultValue: boolean): boolean {
    if (typeof this.showPanelAdditionalInfo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowPanelAdditionalInfo');
      if (settingValue != null) {
        this.showPanelAdditionalInfo = settingValue;
      } else {
        this.showPanelAdditionalInfo = defaultValue;
      }
    }
    return this.showPanelAdditionalInfo;
  }

  getEDITOR_showCompanyGroup(defaultValue: boolean): boolean {
    if (typeof this.showCompanyGroup === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowCompanyGroup');
      if (settingValue != null) {
        this.showCompanyGroup = settingValue;
      } else {
        this.showCompanyGroup = defaultValue;
      }
    }
    return this.showCompanyGroup;
  }

  getEDITOR_defaultValueIsSale(defaultValue: boolean): boolean {
    if (typeof this.defaultValueIsSale === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'DefaultValueIsSale');
      if (settingValue != null) {
        this.defaultValueIsSale = settingValue;
      } else {
        this.defaultValueIsSale = defaultValue;
      }
    }
    return this.defaultValueIsSale;
  }

  getEDITOR_manageAttachesByAdminsOnly(defaultValue: boolean): boolean {
    if (typeof this.manageAttachesByAdminsOnly === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ManageAttachesByAdminsOnly');
      if (settingValue != null) {
        this.manageAttachesByAdminsOnly = settingValue;
      } else {
        this.manageAttachesByAdminsOnly = defaultValue;
      }
    }
    return this.manageAttachesByAdminsOnly;
  }

  getEDITOR_defaultValueIsBuy(defaultValue: boolean): boolean {
    if (typeof this.defaultValueIsBuy === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'DefaultValueIsBuy');
      if (settingValue != null) {
        this.defaultValueIsBuy = settingValue;
      } else {
        this.defaultValueIsBuy = defaultValue;
      }
    }
    return this.defaultValueIsBuy;
  }

  getEDITOR_showFilterProductInPriceList(defaultValue: boolean): boolean {
    if (typeof this.showFilterProductsInPriceList === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductsEditor', 'ShowFilterProductsInPriceList');
      if (settingValue !== null) {
        this.showFilterProductsInPriceList = settingValue;
      } else {
        this.showFilterProductsInPriceList = defaultValue;
      }
    }
    return this.showFilterProductsInPriceList;
  }
  
  getEDITOR_showAccountingCodeList(defaultValue: boolean): boolean {
    if (typeof this.showAccountingCodeList === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowAccoutingCodeList');
      if (settingValue != null) {
        this.showAccountingCodeList = settingValue;
      } else {
        this.showAccountingCodeList = defaultValue;
      }
    }
    return this.showAccountingCodeList;
  }
  
  getEDITOR_showAccountingCode(defaultValue: boolean): boolean {
    if (typeof this.showAccountingCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'ShowAccoutingCode');
      if (settingValue != null) {
        this.showAccountingCode = settingValue;
      } else {
        this.showAccountingCode = defaultValue;
      }
    }
    return this.showAccountingCode;
  }
  

  getEDITOR_optionalStandardProductCode(defaultValue: boolean): boolean {
    if (typeof this.optionalStandardProductCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'OptionalStandardCode');
      if (settingValue != null) {
        this.optionalStandardProductCode = settingValue;
      } else {
        this.optionalStandardProductCode = defaultValue;
      }
    }
    return this.optionalStandardProductCode;
  }

  getEDITOR_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.afterSaveRemainOnSamePage === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.afterSaveRemainOnSamePage = settingValue;
      }
      else {
        this.afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.afterSaveRemainOnSamePage;
  }
  getEDITOR_redirectOnUpdate(defaultValue: boolean): boolean {
    if (typeof this.redirectOnUpdate === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('ProductEditor', 'RedirectOnUpdate');
      if (settingValue != null) {
        this.redirectOnUpdate = settingValue;
      }
      else {
        this.redirectOnUpdate = defaultValue;
      }
    }
    return this.redirectOnUpdate;
  }
  
  /**
   * ***************** SALE **************************************************************************************
  */
  getSALE_showAttributesListPanel(defaultValue: boolean): boolean {
    if (typeof this.showAttributesListPanel_sale === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowAttributesListPanel');
      if (settingValue != null) {
        this.showAttributesListPanel_sale = settingValue;
      } else {
        this.showAttributesListPanel_sale = defaultValue;
      }
    }
    return this.showAttributesListPanel_sale;
  }

  getSALE_showIsDeprecated(defaultValue: boolean): boolean {
    if (typeof this.showIsDeprecated_sale === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowIsDeprecated');
      if (settingValue != null) {
        this.showIsDeprecated_sale = settingValue;
      } else {
        this.showIsDeprecated_sale = defaultValue;
      }
    }
    return this.showIsDeprecated_sale;
  }

  getSALE_showIsBuy(defaultValue: boolean): boolean {
    if (typeof this.showIsBuy_sale === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowIsBuy');
      if (settingValue != null) {
        this.showIsBuy_sale = settingValue;
      } else {
        this.showIsBuy_sale = defaultValue;
      }
    }
    return this.showIsBuy_sale;
  }

  getSALE_showIsSale(defaultValue: boolean): boolean {
    if (typeof this.showIsSale_sale === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowIsSale');
      if (settingValue != null) {
        this.showIsSale_sale = settingValue;
      } else {
        this.showIsSale_sale = defaultValue;
      }
    }
    return this.showIsSale_sale;
  }

  getVIEWER_showImages(defaultValue: boolean): boolean {
    if (typeof this.showImages_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowImages');
      if (settingValue != null) {
        this.showImages_viewer = settingValue;
      } else {
        this.showImages_viewer = defaultValue;
      }
    }
    return this.showImages_viewer;
  }

  getVIEWER_showType(defaultValue: boolean): boolean {
    if (typeof this.showType_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowType');
      if (settingValue != null) {
        this.showType_viewer = settingValue;
      } else {
        this.showType_viewer = defaultValue;
      }
    }
    return this.showType_viewer;
  }

  getVIEWER_showShortName(defaultValue: boolean): boolean {
    if (typeof this.showShortName_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowShortName');
      if (settingValue != null) {
        this.showShortName_viewer = settingValue;
      } else {
        this.showShortName_viewer = defaultValue;
      }
    }
    return this.showShortName_viewer;
  }

  getVIEWER_showPresentation(defaultValue: boolean): boolean {
    if (typeof this.showPresentation_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowPresentation');
      if (settingValue != null) {
        this.showPresentation_viewer = settingValue;
      } else {
        this.showPresentation_viewer = defaultValue;
      }
    }
    return this.showPresentation_viewer;
  }

  getVIEWER_showCategory(defaultValue: boolean): boolean {
    if (typeof this.showCategory_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowCategory');
      if (settingValue != null) {
        this.showCategory_viewer = settingValue;
      } else {
        this.showCategory_viewer = defaultValue;
      }
    }
    return this.showCategory_viewer;
  }

  getVIEWER_showFamily(defaultValue: boolean): boolean {
    if (typeof this.showFamily_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowFamily');
      if (settingValue != null) {
        this.showFamily_viewer = settingValue;
      } else {
        this.showFamily_viewer = defaultValue;
      }
    }
    return this.showFamily_viewer;
  }

  getVIEWER_showNetUnitBuyPrice(defaultValue: boolean): boolean {
    if (typeof this.showNetUnitBuyPrice_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowNetUnitBuyPrice');
      if (settingValue != null) {
        this.showNetUnitBuyPrice_viewer = settingValue;
      } else {
        this.showNetUnitBuyPrice_viewer = defaultValue;
      }
    }
    return this.showNetUnitBuyPrice_viewer;
  }

  getVIEWER_showBuyCurrencyCode(defaultValue: boolean): boolean {
    if (typeof this.showBuyCurrencyCode_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ShowBuyCurrencyCode');
      if (settingValue != null) {
        this.showBuyCurrencyCode_viewer = settingValue;
      } else {
        this.showBuyCurrencyCode_viewer = defaultValue;
      }
    }
    return this.showBuyCurrencyCode_viewer;
  }

  /**
   * Vai verificar se há foi definido algum valor na variavel do servico, caso contrario vai verificar o ficheiro de setting se tem valor se nao tiver usa valor transferido no parametro
   * @param  {Array<number>} defaultValue - Valor por defeito do parametro
   * @param  {Array<string>} columnsNames - nomes das colunas
   * @returns Array
   */
  getVIEWER_ColumnInVisible(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisible_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductsViewer', 'ColumnVisible');
      this.columnInVisible_viewer = this.dataTableFunctions.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisible_viewer;
  }

  getVIEWER_ColumnInVisiblePack(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.columnInVisiblePack_viewer === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('ProductViewer', 'ColumnVisiblePack');
      this.columnInVisiblePack_viewer = this.dataTableFunctions.getInvColumns(defaultValue, columnsNames, settingValue);
    }
    return this.columnInVisiblePack_viewer;
  }

  /**
    * ***************** OUTROS **************************************************************************************
   */
  get_gallery(productKind: string, defaultValue: string[]): string[] {
    if (typeof this.gallery === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Product' + productKind, 'Gallery');
      if (settingValue != null) {
        this.gallery = settingValue;
      } else {
        this.gallery = defaultValue;
      }
    }
    return this.gallery;
  }

  // Settings PIM
  get_showCommercialPDF(defaultValue: boolean): boolean {
    if (typeof this.showCommercialPDF === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pims', 'ShowCommercialPDF');
      if (settingValue != null) {
        this.showCommercialPDF = settingValue;
      } else {
        this.showCommercialPDF = defaultValue;
      }
    }
    return this.showCommercialPDF;
  }

  get_showTechnicalPDF(defaultValue: boolean): boolean {
    if (typeof this.showTechnicalPDF === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pims', 'ShowTechnicalPDF');
      if (settingValue != null) {
        this.showTechnicalPDF = settingValue;
      } else {
        this.showTechnicalPDF = defaultValue;
      }
    }
    return this.showTechnicalPDF;
  }

  get_defaultActiveFilterValue(defaultValue?: boolean): boolean | null {
    if (typeof this.defaultActiveFilterValue === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pims', 'DefaultActiveFilterValue');
      if (settingValue != null) {
        this.defaultActiveFilterValue = settingValue;
      } else {
        this.defaultActiveFilterValue = defaultValue;
      }
    }
    return this.defaultActiveFilterValue;
  }

  get_showTypeColumn(defaultValue: boolean): boolean {
    if (typeof this.showTypeColumn === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pims', 'ShowTypeColumn');
      if (settingValue != null) {
        this.showTypeColumn = settingValue;
      } else {
        this.showTypeColumn = defaultValue;
      }
    }
    return this.showTypeColumn;
  }

  get_showUnitOfMeasure(defaultValue: boolean): boolean {
    if (typeof this.showUnitOfMeasure === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pim', 'UnitOfMeasure');
      if (settingValue != null) {
        this.showUnitOfMeasure = settingValue;
      } else {
        this.showUnitOfMeasure = defaultValue;
      }
    }
    return this.showUnitOfMeasure;
  }

  get_showVatRate(defaultValue: boolean): boolean {
    if (typeof this.showVatRate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pim', 'VatRate');
      if (settingValue != null) {
        this.showVatRate = settingValue;
      } else {
        this.showVatRate = defaultValue;
      }
    }
    return this.showVatRate;
  }

  get_showLogisticUnitMaterial(defaultValue: boolean): boolean {
    if (typeof this.showLogisticUnitMaterial === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pim', 'ShowLogisticUnitMaterial');
      if (settingValue != null) {
        this.showLogisticUnitMaterial = settingValue;
      } else {
        this.showLogisticUnitMaterial = defaultValue;
      }
    }
    return this.showLogisticUnitMaterial;
  }

  get_showLogisticUnitPackageType(defaultValue: boolean): boolean {
    if (typeof this.showLogisticUnitPackageType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pim', 'ShowLogisticUnitPackageType');
      if (settingValue != null) {
        this.showLogisticUnitPackageType = settingValue;
      } else {
        this.showLogisticUnitPackageType = defaultValue;
      }
    }
    return this.showLogisticUnitPackageType;
  }

  get_showFishingFoodFields(defaultValue: boolean): boolean {
    if (typeof this.showFishingFoodFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pim', 'ShowFishingFoodFields');
      if (settingValue != null) {
        this.showFishingFoodFields = settingValue;
      } else {
        this.showFishingFoodFields = defaultValue;
      }
    }
    return this.showFishingFoodFields;
  }

  get_showFoodPreparationState(defaultValue: boolean): boolean {
    if (typeof this.showFoodPreparationState === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Pim', 'ShowFoodPreparationState');
      if (settingValue != null) {
        this.showFoodPreparationState = settingValue;
      } else {
        this.showFoodPreparationState = defaultValue;
      }
    }
    return this.showFoodPreparationState;
  }

  //#region SubjectToWithholding

  //#region showSubjectToWithholding
  private _showSubjectToWithholding: boolean;

  /** Mostrar campo que define se o produto é sujeito a retenção */
  public get showSubjectToWithholding () {
    this._showSubjectToWithholding = this.authenticationService.getBooleanSetting(false, this._showSubjectToWithholding, 'ProductEditor', 'ShowSubjectToWithholding');
    return this._showSubjectToWithholding;
  }
  //#endregion showSubjectToWithholding

  //#region defaultValueSubjectToWithholding
  private _defaultValueSubjectToWithholding: boolean;

  /** Setting que define o valor por defeito do campo SubjectToWithholding */
  public get defaultValueSubjectToWithholding () {
    this._defaultValueSubjectToWithholding = this.authenticationService.getBooleanSetting(false, this._defaultValueSubjectToWithholding, 'ProductEditor', 'DefaultValueSubjectToWithholding');
    return this._defaultValueSubjectToWithholding;
  }
  //#endregion defaultValueSubjectToWithholding

  //#endregion SubjectToWithholding
  
  //#region UseTaxAreas
  
  private _useTaxAreas: boolean;

  /** Mostrar campo que define se o produto é sujeito a retenção */
  public get useTaxAreas () {
    this._useTaxAreas = this.authenticationService.getBooleanSetting(false, this._useTaxAreas, 'ProductEditor', 'UseTaxAreas');
    return this._useTaxAreas;
  }

  //#endregion UseTaxAreas

  // FIM - Settings PIM

  /**
  * Download de Excel do produto
  * @param  {number} id
  * @returns Observable
  */
  downloadExcelProduct(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelProduct?id=' + id);
  }

  /**
  * Download para Excel da lista de produtos Editor
  * @param  {number} id
  * @returns Observable
  */
  downloadExcelProductsEditorList(params: any, filters: Product = null, moreFilters: any = {}): Observable<any> {
    // return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProductsEditorList', { parameters: params, filters: filters });
    moreFilters.parameters = params;
    moreFilters.filters = filters;
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProductsEditorList', moreFilters);
  }

  /**
  * Download para Excel da lista de produtos Viewer
  * @param  {number} id
  * @returns Observable
  */
  downloadExcelProductsViewerList(params: any, supplierID: number, filters: Product = null, moreFilters: any = {}): Observable<any> {
    // return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProductsViewerList', { parameters: params, supplierID: supplierID, filters: filters });
    moreFilters.parameters = params;
    moreFilters.supplierID = supplierID;
    moreFilters.filters = filters;
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelProductsViewerList', moreFilters);
  }

  // #endregion Settings

  // BEGIN - Logistic Units
  get logisticUnits(): Observable<LogisticUnit[]> {
    if (this._logisticUnits === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetLogisticUnits').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._logisticUnits = responseReturn.ReturnStatus.ReturnObject as LogisticUnit[];
              return this._logisticUnits;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._logisticUnits);
    }
  }

  get logisticUnitTypes(): Observable<LogisticUnitType[]> {
    if (this._logisticUnitTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetLogisticUnitTypes').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._logisticUnitTypes = responseReturn.ReturnStatus.ReturnObject as LogisticUnitType[];
              return this._logisticUnitTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._logisticUnitTypes);
    }
  }

  get logisticUnitUsages(): Observable<LogisticUnitUsage[]> {
    if (this._logisticUnitUsages === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetLogisticUnitUsages').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._logisticUnitUsages = responseReturn.ReturnStatus.ReturnObject as LogisticUnitUsage[];
              return this._logisticUnitUsages;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._logisticUnitUsages);
    }
  }
  // END - Logistic Units

  // BEGIN - Dropdowns
  get directions(): Observable<Dropdown[]> {
    if (this._directions === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.IMAGE_DIRECTION_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._directions = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._directions;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._directions);
    }
  }

  get infoTypes(): Observable<Dropdown[]> {
    if (this._infoTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.IMAGE_INFO_TYPE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._infoTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._infoTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._infoTypes);
    }
  }

  get angles(): Observable<Dropdown[]> {
    if (this._angles === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.IMAGE_ANGLE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._angles = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._angles;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._angles);
    }
  }

  get insOrOuts(): Observable<Dropdown[]> {
    if (this._insOrOuts === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.IMAGE_IN_OR_OUT_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._insOrOuts = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._insOrOuts;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._insOrOuts);
    }
  }

  get productTypes(): Observable<ProductType[]> {
    if (this._productTypes === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetProductTypes').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._productTypes = responseReturn.ReturnStatus.ReturnObject as ProductType[];
              return this._productTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._productTypes);
    }
  }

  get gPCBrickCodes1(): Observable<Dropdown[]> {
    if (this._GPCBrickCodes1 === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.GPC_BRICK_CODE_1).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._GPCBrickCodes1 = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._GPCBrickCodes1;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._GPCBrickCodes1);
    }
  }
  get gPCBrickCodes2(): Observable<Dropdown[]> {
    if (this._GPCBrickCodes2 === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.GPC_BRICK_CODE_2).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._GPCBrickCodes2 = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._GPCBrickCodes2;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._GPCBrickCodes2);
    }
  }
  get gPCBrickCodes3(): Observable<Dropdown[]> {
    if (this._GPCBrickCodes3 === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.GPC_BRICK_CODE_3).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._GPCBrickCodes3 = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._GPCBrickCodes3;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._GPCBrickCodes3);
    }
  }
  get gPCBrickCodes4(): Observable<Dropdown[]> {
    if (this._GPCBrickCodes4 === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.GPC_BRICK_CODE_4).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._GPCBrickCodes4 = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._GPCBrickCodes4;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._GPCBrickCodes4);
    }
  }
  get promotionTypes(): Observable<Dropdown[]> {
    if (this._promotionTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.PROMOTION_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._promotionTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._promotionTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._promotionTypes);
    }
  }
  get productTaxTypes(): Observable<Dropdown[]> {
    if (this._productTaxTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.PRODUCT_TAX_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._productTaxTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._productTaxTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._productTaxTypes);
    }
  }

  get barCodeTypes(): Observable<Dropdown[]> {
    if (this._productbarCodeTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.BAR_CODE_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._productbarCodeTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._productbarCodeTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._productbarCodeTypes);
    }
  }

  get packageTypes(): Observable<Dropdown[]> {
    if (this._packageTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.PACKAGE_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._packageTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._packageTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._packageTypes);
    }
  }

  get foodContactTypes(): Observable<Dropdown[]> {
    if (this._foodContactTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_CONTACT_TYPE_LIST).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodContactTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodContactTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodContactTypes);
    }
  }

  get foodProductionMethods(): Observable<Dropdown[]> {
    if (this._foodProductionMethods === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_PRODUCTION_METHOD).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodProductionMethods = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodProductionMethods;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodProductionMethods);
    }
  }

  get foodCatchMethods(): Observable<Dropdown[]> {
    if (this._foodCatchMethods === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_CATCH_METHOD).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodCatchMethods = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodCatchMethods;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodCatchMethods);
    }
  }

  get foodFrozens(): Observable<Dropdown[]> {
    if (this._foodFrozens === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_FROZEN).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodFrozens = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodFrozens;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodFrozens);
    }
  }

  get foodCatchAreas(): Observable<Dropdown[]> {
    if (this._foodCatchAreas === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_CATCH_AREA).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodCatchAreas = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodCatchAreas;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodCatchAreas);
    }
  }

  get foodSubCatchAreas(): Observable<Dropdown[]> {
    if (this._foodSubCatchAreas === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_SUB_CATCH_AREA).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodSubCatchAreas = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodSubCatchAreas;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodSubCatchAreas);
    }
  }

  get containmentLevels(): Observable<Dropdown[]> {
    if (this._containmentLevels === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.CONTAINMENT_LEVEL).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._containmentLevels = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._containmentLevels;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._containmentLevels);
    }
  }

  get allergens(): Observable<Dropdown[]> {
    if (this._allergens === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.ALLERGENS).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._allergens = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._allergens;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._allergens);
    }
  }

  get foodPreparationStates(): Observable<Dropdown[]> {
    if (this._foodPreparationStates === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_PREPARATION_STATE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodPreparationStates = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodPreparationStates;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodPreparationStates);
    }
  }

  get energyOrNutrients(): Observable<Dropdown[]> {
    if (this._energyOrNutrients === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.ENERGY_OR_NUTRIENTS).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._energyOrNutrients = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._energyOrNutrients;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._energyOrNutrients);
    }
  }

  get nutrientsPrecisions(): Observable<Dropdown[]> {
    if (this._nutrientsPrecisions === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.NUTRIENTS_PRECISION).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._nutrientsPrecisions = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._nutrientsPrecisions;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._nutrientsPrecisions);
    }
  }

  get vitaminesAndMinerals(): Observable<Dropdown[]> {
    if (this._vitaminesAndMinerals === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.VITAMINES_AND_MINERALS).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._vitaminesAndMinerals = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._vitaminesAndMinerals;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._vitaminesAndMinerals);
    }
  }

  get nutrientsPercentageTypes(): Observable<Dropdown[]> {
    if (this._nutrientsPercentageTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.NUTRIENTS_PERCENTAGE_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._nutrientsPercentageTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._nutrientsPercentageTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._nutrientsPercentageTypes);
    }
  }

  get otherNutrients(): Observable<Dropdown[]> {
    if (this._otherNutrients === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.OTHER_NUTRIENTS).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._otherNutrients = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._otherNutrients;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._otherNutrients);
    }
  }

  get foodDateInfos(): Observable<Dropdown[]> {
    if (this._foodDateInfos === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.FOOD_DATE_INFO).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._foodDateInfos = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._foodDateInfos;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._foodDateInfos);
    }
  }

  get packageRecycleSchemes(): Observable<Dropdown[]> {
    if (this._packageRecycleSchemes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.PACKAGE_RECYCLE_SCHEME).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._packageRecycleSchemes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._packageRecycleSchemes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._packageRecycleSchemes);
    }
  }

  get packageTermsAndConditions(): Observable<Dropdown[]> {
    if (this._packageTermsAndConditions === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.PACKAGE_TERMS_AND_CONDITIONS).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._packageTermsAndConditions = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._packageTermsAndConditions;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._packageTermsAndConditions);
    }
  }

  get packageFunctions(): Observable<Dropdown[]> {
    if (this._packageFunctions === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.PACKAGE_FUNCTION).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._packageFunctions = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._packageFunctions;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._packageFunctions);
    }
  }

  get wayNextTypes(): Observable<Dropdown[]> {
    if (this._wayNextTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.WAY_NEXT_TYPE).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._wayNextTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._wayNextTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._wayNextTypes);
    }
  }
  // END - Dropdowns

  // Geral para todos os ecrãs
  getAllForPurchasesList(parameters: any, supplierID: number, getProductMedia: boolean, code: string, name: string, categoryID: number, familyID: number, isFilterByPriceList?: boolean): Observable<any> {
    return this.http.post(SERVICE_URL + this._controller + '/GetAllForPurchasesList', {
      parameters: parameters, supplierID: supplierID, getProductMedia: getProductMedia, code: code, name: name, categoryID: categoryID, familyID: familyID, isFilterByPriceList: isFilterByPriceList
    });
  }

  getOwnForPurchasesList(parameters: any, supplierID: number, getProductMedia: boolean, code: string, name: string, categoryID: number, familyID: number, isFilterByPriceList?: boolean, filterByOrderTypes: string[] = null): Observable<any> {
    return this.http.post(SERVICE_URL + this._controller + '/GetOwnForPurchasesList', {
      parameters: parameters, supplierID: supplierID, getProductMedia: getProductMedia, code: code, name: name, categoryID: categoryID, familyID: familyID, isFilterByPriceList: isFilterByPriceList, filterByOrderTypes: filterByOrderTypes
    });
  }
  // END - Geral para todos os ecrãs

  // Exportações
  downloadExcelPIMsList(params: any, filterBySapCode?: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelPIMsList', { parameters: params, filterBySapCode: filterBySapCode });
  }

  downloadPDF(id: number, type: PDFType, sapCode?: string): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDF?id=' + id + '&type=' + type + (sapCode ? '&sapCode=' + sapCode : ''));
  }
  // END - Exportações
}
