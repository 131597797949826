import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class InventoryService {

  private _controller: string = 'Inventory';

  //para guardar os valores dos filtros
  filter_selectedDataIni_I: string;
  filter_selectedDataFim_I: string;
  filter_selectedDataIni_H: string;
  filter_selectedDataFim_H: string;
  filter_Archived: string; // para manter o valor do filtro do archived

  showReprocessButton: boolean;
  showReprocessInventoryButton: boolean;
  //INVENTORY IMPORT
  showColMonthYear: boolean;
  ColumnNameReportDate: string;
  showColMessageSenderName: boolean;
  ColumnNameAgentName: string;
  showDeleteButton: boolean;
  showArchivedCheckbox: boolean;

  //Inventory Import Details
  showHigicolAllInputs: boolean;
  showColumnToDelete: boolean; 
  showMSDDataTable: boolean;

  //INVENTORY HISTORY
  showColMessageSenderNameHistory: boolean;
  showColStoreHistory: boolean;
  showColImportDateHistory: boolean;
  showColMonthYearHistory: boolean;
  columnNameInventoryReportDateHistory: string;
  showColAgentName: boolean;
  filterInventoryBySessionID: boolean;
  resultOnFirstLoad : boolean;

  //Control Pannel
  dataControlPannelMes: any;
  dataControlPannelAno: any;
  controlPanelWholesalerName: any;

  datatableParametersImport: DatatableParameters = new DatatableParameters([null, null, null, null], 10, [[3, 'desc']], 0);
  datatableParametersHistory: DatatableParameters = new DatatableParameters([null, null, null, null, null], 10, [[3, 'desc']], 0);
  filterHistory: any = new Object();
  

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private authenticationService: AuthenticationService) { }

  //#region Inventory
  /**
   * Devolver um histórico de inventário
   * @param  {number} id ID do histórico de inventário
   * @returns Observable
   */
  getReport(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetReport?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getEDIReport(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetEDIReport?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver excel do histórico de inventários
  exportReportsListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelReportsList',
      { parameters: params, beginDate: this.filterHistory.BeginDate, endDate: this.filterHistory.EndDate });
  }

  // Devolver excel de um histórico de inventário
  exportReportToExcel(id: number, params: any = null, product: string = null): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelReport',
      { id: id, parameters: params, product: product });
  }

  getDates(dates:any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetDates' ,{firstdate:dates.firstdate,lastdate:dates.lastdate,type:dates.type,archived:dates.archived}).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#endregion Inventory

  /****************GET METHODS**********************/

  getEDIInventoryReportYears(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetEDIInventoryReportYears')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getInventoryReportYears(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetInventoryReportYears')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  /******************POST METHODS**********************/

  getInventoryImportReportDetailsEdit(id: number, startFrom: number, until: number, deletedRows: any, editedRows: any, orderField: string, order: any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetInventoryImportReportDetailsEdit', { id: id, start: startFrom, length: until, deleted: deletedRows, edited: editedRows, orderField: orderField, order: order })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /****************UPDATE METHODS**********************/
  updateEntity(id: number, rowsToDeleteID: Array<number>, editedRows: any): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateEntity', { id: id, rowsToDelete: rowsToDeleteID, editedRows: editedRows })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateEDIReport(inventory: any): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/UpdateEDIReport', { inventory: inventory })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /****************DELETE METHODS**********************/

  deleteLine(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteLine?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteLineHistory(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteLineHistory?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  /****************DOWNLOAD METHODS**********************/
  // TODO: perguntar porque esta a usar posts e porque esta a usar http2????

  downloadExcelImportErrosList(parameters: DatatableParameters, dates: any): Observable<any> {

    /* let headers = new Headers();
     headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
     headers.append('Pragma', 'no-cache');
     headers.append('Expires', '-1');

     let options = new RequestOptions({ withCredentials: true, headers, responseType: ResponseContentType.Blob});
     return this.http2.post(SERVICE_URL + this._controller + '/DownloadExcelImportErrosList', options)
       .pipe(map(res => res.blob());
       */
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelImportErrosList', {parameters: parameters, month: dates.month, year: dates.year, archived: dates.archived});
  }

  downloadLineExcelImportErrosList(id: number): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadLineExcelImportErrosList', { id: id });
  }

  downloadExcelInventoryHistory(parameters: DatatableParameters, dates: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelInventoryHistory', {parameters: parameters, month: dates.month, year: dates.year });
  }

  downloadLineExcelInventoryHistory(id: number): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadLineExcelInventoryHistory', { id: id });
  }

  /****************SETTINGS**********************/
  //Reprocess Button
  getInventory_showReprocessButton(defaultValue: boolean): boolean {
    if(typeof this.showReprocessButton === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ShowReprocessButton');
      if(settingValue != null){
        this.showReprocessButton = settingValue;
      } else{
        this.showReprocessButton = defaultValue;
      }
    }
    return this.showReprocessButton;
  }
  //Reprocess Inventory Button
  getInventory_showReprocessInventoryButton(defaultValue: boolean): boolean {
    if(typeof this.showReprocessInventoryButton === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ShowReprocessInventoryButton');
      if(settingValue != null){
        this.showReprocessInventoryButton = settingValue;
      } else{
        this.showReprocessInventoryButton = defaultValue;
      }
    }
    return this.showReprocessInventoryButton;
  }

  /*SETTINGS INVENTORY IMPORT*/
  getInventory_showColMonthYear(defaultValue: boolean): boolean {
    if(typeof this.showColMonthYear === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ShowColMonthYear');
      if(settingValue != null){
        this.showColMonthYear = settingValue;
      } else{
        this.showColMonthYear = defaultValue;
      }
    }
    return this.showColMonthYear;
  }

  getInventory_showColMessageSenderName(defaultValue: boolean): boolean {
    if(typeof this.showColMessageSenderName === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','showColMessageSenderName');
      if(settingValue != null){
        this.showColMessageSenderName = settingValue;
      } else{
        this.showColMessageSenderName = defaultValue;
      }
    }
    return this.showColMessageSenderName;
  }

  getInventory_columnNameAgentName(defaultValue: string): string {
    if(typeof this.ColumnNameAgentName === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ColumnNameAgentName');
      if(settingValue != null){
        this.ColumnNameAgentName = settingValue;
      } else{
        this.ColumnNameAgentName = defaultValue;
      }
    }
    return this.ColumnNameAgentName;
  }

  getInventory_columnReportDate(defaultValue: string): string {
    if(typeof this.ColumnNameReportDate === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ColumnNameReportDate');
      if(settingValue != null){
        this.ColumnNameReportDate = settingValue;
      } else{
        this.ColumnNameReportDate = defaultValue;
      }
    }
    return this.ColumnNameReportDate;
  }

  getInventory_showDeleteButton(defaultValue: boolean): boolean {
    if(typeof this.showDeleteButton === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ShowDeleteButton');
      if(settingValue != null){
        this.showDeleteButton = settingValue;
      } else{
        this.showDeleteButton = defaultValue;
      }
    }
    return this.showDeleteButton;
  }

  getInventory_showArchivedCheckbox(defaultValue: boolean): boolean {
    if(typeof this.showArchivedCheckbox === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ShowArchivedCheckbox');
      if(settingValue != null){
        this.showArchivedCheckbox = settingValue;
      } else{
        this.showArchivedCheckbox = defaultValue;
      }
    }
    return this.showArchivedCheckbox;
  }

  getInventory_showMSDScrean(defaultValue: boolean): boolean {
    if(typeof this.showMSDDataTable === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ShowMSDDataTable');
      if(settingValue != null){
        this.showMSDDataTable = settingValue;
      } else{
        this.showMSDDataTable = defaultValue;
      }
    }
    return this.showMSDDataTable;
  }


  /*SETTINGS INVENTORY IMPORT DETAILS*/
  getInventory_ShowHigicolAllInputs(defaultValue: boolean): boolean {
    if(typeof this.showHigicolAllInputs === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImportDetails','ShowHigicolAllInputs');
      if(settingValue != null){
        this.showHigicolAllInputs = settingValue;
      } else{
        this.showHigicolAllInputs = defaultValue;
      }
    }
    return this.showHigicolAllInputs;
  }

  getInventory_showColumnToDelete(defaultValue: boolean): boolean {
    if(typeof this.showColumnToDelete === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImportDetails','ShowColumnToDelete');
      if(settingValue != null){
        this.showColumnToDelete = settingValue;
      } else{
        this.showColumnToDelete = defaultValue;
      }
    }
    return this.showColumnToDelete;
  }

  /*SETTINGS INVENTORY HISTORY*/
  getInventory_showColMessageSenderNameHistory(defaultValue: boolean): boolean {
    if(typeof this.showColMessageSenderNameHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ShowColMessageSenderNameHistory');
      if(settingValue != null){
        this.showColMessageSenderNameHistory = settingValue;
      } else{
        this.showColMessageSenderNameHistory = defaultValue;
      }
    }
    return this.showColMessageSenderNameHistory;
  }

  getInventory_showColStoreHistory(defaultValue: boolean): boolean {
    if(typeof this.showColStoreHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ShowColStoreHistory');
      if(settingValue != null){
        this.showColStoreHistory = settingValue;
      } else{
        this.showColStoreHistory = defaultValue;
      }
    }
    return this.showColStoreHistory;
  }

  getInventory_showColMonthYearHistory(defaultValue: boolean): boolean {
    if(typeof this.showColMonthYearHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ShowColMonthYearHistory');
      if(settingValue != null){
        this.showColMonthYearHistory = settingValue;
      } else{
        this.showColMonthYearHistory = defaultValue;
      }
    }
    return this.showColMonthYearHistory;
  }

  getInventory_showColImportDateHistory(defaultValue: boolean): boolean {
    if(typeof this.showColImportDateHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ShowColImportDateHistory');
      if(settingValue != null){
        this.showColImportDateHistory = settingValue;
      } else{
        this.showColImportDateHistory = defaultValue;
      }
    }
    return this.showColImportDateHistory;
  }

  getInventory_columnNameInventoryReportDateHistory(defaultValue: string): string {
    if(typeof this.columnNameInventoryReportDateHistory === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ColumnNameInventoryReportDateHistory');
      if(settingValue != null){
        this.columnNameInventoryReportDateHistory = settingValue;
      } else{
        this.columnNameInventoryReportDateHistory = defaultValue;
      }
    }
    return this.columnNameInventoryReportDateHistory;
  }

  getInventory_showColShowColAgentName(defaultValue: boolean): boolean {
    if(typeof this.showColAgentName === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ShowColAgentName');
      if(settingValue != null){
        this.showColAgentName = settingValue;
      } else{
        this.showColAgentName = defaultValue;
      }
    }
    return this.showColAgentName;
  }

  getInventoryHistory_resultOnFirstLoad(defaultValue: boolean): boolean {
    if(typeof this.resultOnFirstLoad === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','ResultOnFirstLoad');
      if(settingValue != null){
        this.resultOnFirstLoad = settingValue;
      } else{
        this.resultOnFirstLoad = defaultValue;
      }
    }
    return this.resultOnFirstLoad;
  }

  getInventoryEDI_resultOnFirstLoad(defaultValue: boolean): boolean {
    if(typeof this.resultOnFirstLoad === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryImport','ResultOnFirstLoad');
      if(settingValue != null){
        this.resultOnFirstLoad = settingValue;
      } else{
        this.resultOnFirstLoad = defaultValue;
      }
    }
    return this.resultOnFirstLoad;
  }

  get_filterInventoryBySessionID(defaultValue: boolean): boolean {
    if(typeof this.filterInventoryBySessionID === 'undefined'){
      let settingValue = this.authenticationService.getSettingPortal('InventoryHistory','FilterInventoryBySessionID');
      if(settingValue != null){
        this.filterInventoryBySessionID = settingValue;
      } else{
        this.filterInventoryBySessionID = defaultValue;
      }
    }
    return this.filterInventoryBySessionID;
  }


}
