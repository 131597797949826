// **__**________  JAVASCRIPT ________ **__**
import moment from 'moment-timezone';

declare var Functions: any;

// Localhost
export let SERVICE_URL = '';
export let WEBSITE_URL = '';
export let ALIAS = '';
export let LANGUAGE: string = 'pt';
export let GLOBAL_ERROR_PAGE: boolean = true;
export let HIDE_PORTAL_NAME_LOGIN: boolean = false;
export let PORTAL_NAME: string = 'ID4PORTAL';
export let SUPPORT_EMAIL: string;
export let TIME_ZONE: string = 'Europe/Lisbon';
export let PORTAL_TITLE_NAME: string = ''

export let setServiceURL = function (value: string): void {
  SERVICE_URL = value;
};
export let setWebsiteURL = function (value: string): void {
  WEBSITE_URL = value;
};
export let setALIAS = function (value: string): void {
  ALIAS = value;
};
export let setLanguage = function (value: string): void {
  LANGUAGE = value;
  CKEDITOR.config.language = value;
  moment().tz('Europe/Lisbon').format();
};
export let setGlobalErrorPage = function (value: boolean): void {
  GLOBAL_ERROR_PAGE = value;
};
export let setPortalName = function (value: string): void {
  PORTAL_NAME = value;
};
export let setHidePortalNameOnLogin = function (value: boolean): void {
  HIDE_PORTAL_NAME_LOGIN = value;
};
export let setSupportEmail = function (value: string): void {
  SUPPORT_EMAIL = value;
};
export let setTimeZone = function (value: string): void {
  TIME_ZONE = value;
};

export let setPortalTitleName = function (value: string): void {
  PORTAL_TITLE_NAME = value;
};

export const PAGE_TITLE = 'title';
export const RETURN_URL = 'returnUrl';
export const OBJECT_ID = 'id';
export const OBJECT_TYPE = 'type';
export const COMPANY_ID = 'companyid';
export const LANGUAGE_CODE_PT = 'pt_PT';
export const KIND_OF_NATURE = { SALE: 'sale', PURCHASE: 'purchase' };
export const EMPRESA = "empresa";
export const DOCUMENT_NUMBER = "documentNumber";
export const EXERCICIO = "exercicio";
export const ABC = 'ABC';

export const CONTEXT = {
  Company: {
    TITLE_WORKFLOW: 'CONFIGURATION_CUSTOMER_INFORMATION_MANAGEMENT_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_CUSTOMER_INFORMATION_MANAGEMENT_SCRIPTS',
    TYPE: 'CLIENT_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'COMPANIES',
    ALL: 'ALL_COMPANIES',
    SINGULAR: 'COMPANY',
    DOCKMODAL: '#dockmodal-dynamic-partners',
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  product: {
    TITLE_WORKFLOW: 'CONFIGURATION_PRODUCT_INFORMATION_MANAGEMENT_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_PRODUCT_INFORMATION_MANAGEMENT_SCRIPTS',
    TYPE: 'PRODUCT_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'PRODUCTS',
    ALL: 'ALL_PRODUCTS',
    SINGULAR: 'PRODUCT',
    DOCKMODAL: '#dockmodal-dynamic-products',
    WORKFLOW: {
      IS_GENERIC: false,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: true
    }
  },
  Pricelist: {
    TITLE_WORKFLOW: 'CONFIGURATION_PRICES_LIST_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_PRICES_LIST_SCRIPTS',
    TYPE: 'PRICES_LIST_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'PRICES_LIST',
    ALL: 'ALL_PRICES_LISTS',
    SINGULAR: 'PRICES_LIST',
    DOCKMODAL: null,
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: true,
      HAS_STEP_CUSTOM_VALIDATION: true,
      HAS_STEP_FAMILY: false
    }
  },
  RegistrationRequest: {
    TITLE_WORKFLOW: 'CONFIGURATION_REGISTRATION_REQUEST_MANAGEMENT_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_REGISTRATION_REQUEST_MANAGEMENT_SCRIPTS',
    TYPE: 'REGISTRATION_REQUEST_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'REGISTRATION_REQUESTS',
    ALL: 'ALL_REGISTRATION_REQUESTS',
    SINGULAR: 'REGISTRATION_REQUEST',
    DOCKMODAL: null,
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  ScannedDocument: {
    TITLE_WORKFLOW: 'CONFIGURATION_SCANNED_DOCUMENT_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_SCANNED_DOCUMENT_MANAGEMENT_SCRIPTS',
    TYPE: 'SCANNED_DOCUMENT_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'SCANNED_DOCUMENTS',
    ALL: 'ALL_SCANNED_DOCUMENTS',
    SINGULAR: 'SCANNED_DOCUMENT',
    DOCKMODAL: null,
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  TransportRequest: {
    TITLE_WORKFLOW: 'CONFIGURATION_TRANSPORT_REQUESTS_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_TRANSPORT_REQUESTS_SCRIPTS',
    TYPE: 'TRANSPORT_REQUESTS_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'TRANSPORT_REQUESTS',
    ALL: 'ALL_TRANSPORT_REQUESTS',
    SINGULAR: 'TRANSPORT_REQUEST',
    DOCKMODAL: null,
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  Transport: {
    TITLE_WORKFLOW: 'CONFIGURATION_TRANSPORT_PLANNINGS_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_TRANSPORT_PLANNINGS_SCRIPTS',
    TYPE: 'TRANSPORT_PLANNINGS_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'TRANSPORT_PLANNINGS',
    ALL: 'ALL_TRANSPORT_PLANNINGS',
    SINGULAR: 'TRANSPORT_PLANNING',
    DOCKMODAL: null,
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  TransportPoint: {
    TITLE_WORKFLOW: 'CONFIGURATION_TRANSPORT_POINTS_RULES',
    TITLE_SCRIPT: 'MANAGEMENT_TRANSPORT_POINTS_SCRIPTS',
    TYPE: 'TRANSPORT_POINTS_TYPES',
    STATE_TYPE: 'TAB',
    NAME: 'TRANSPORT_POINTS',
    ALL: 'ALL_TRANSPORT_POINTS',
    SINGULAR: 'TRANSPORT_POINT',
    DOCKMODAL: null,
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  AlticeFine: {
    TITLE_WORKFLOW: 'CONFIGURATION_DOCUMENT_RULES',
    TYPE: 'DOCUMENT_TYPES',
    STATE_TYPE: 'TAB',
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  CompanyRegistration: {
    TITLE_WORKFLOW: 'CONFIGURATION_REGISTRATION_RULES',
    TYPE: 'COMPANY_REGISTRATION',
    STATE_TYPE: 'TAB',
    WORKFLOW: {
      IS_GENERIC: true,
      HAS_STEP_COUNTRY: false,
      HAS_STEP_CUSTOM_VALIDATION: false,
      HAS_STEP_FAMILY: false
    }
  },
  Partner:{
    Record: 'Record',
    Users: 'Users',
    Addresses: 'Addresses',
    Warehouses: 'Warehouses',
    PartnerIdentities: 'PartnerIdentities',
    PartnerIdentitiesForMSD: 'PartnerIdentitiesForMSD',
    Species: 'Species',
    Segments: 'Segments',
    Regions: 'Regions',
    Provinces: 'Provinces',
    TradingPartnerTab: 'TradingPartnerParam',
    DigitalArchive: 'DigitalArchive',
  }
};

export const CONTEXT_COMPANY = 'Company';
export const CONTEXT_PARTNER = 'Partner';
export const CONTEXT_PRICES_LIST = 'Pricelist';
export const CONTEXT_PRODUCT = 'product';
export const CONTEXT_ORDER = 'Order';
export const CONTEXT_INVOICE = 'Invoice';
export const CONTEXT_REPDISKFILES = 'RepositoryDiskFiles';
export const CONTEXT_REGISTRATION_REQUEST = 'RegistrationRequest';
export const CONTEXT_LOAD_DOCUMENT = 'LoadDocument';
export const CONTEXT_SCANNED_DOCUMENT = 'ScannedDocument';
export const CONTEXT_SCANNED_DOCUMENT_DETAIL = 'ScannedDocumentDetail';
export const CONTEXT_TRANSPORT_REQUEST = 'TransportRequest';
export const CONTEXT_TRANSPORT_REQUEST_DETAIL = 'TransportRequestDetail';
export const CONTEXT_TRANSPORT_PLANNING = 'Transport';
export const CONTEXT_TRANSPORT_PLANNING_POINT = 'TransportPoint';
export const CONTEXT_TRANSPORT_PLANNING_POINT_DETAIL = 'TransportPointDetail';
export const CONTEXT_LOCATION = 'Location';
export const CONTEXT_QUOTATION = 'Quotation';
export const CONTEXT_PAYMENT = 'Payment';
export const CONTEXT_PROCESS = "Process";
export const CONTEXT_PROCESS_DOCUMENTS = "ProcessDocument";
export const CONTEXT_USER = 'User';
export const CONTEXT_TRANSPORT_GROUPAGE = 'Groupage'
//Constante que corresponde ao nome do ecrã na BD e que nos permite saber as permissões (allowRead, etc)
export const CONTEXT_PROCESS_BD = "Processes";
export const CONTEXT_FINANCIAL_MOVEMENTS = 'FinancialMovements';
export const CONTEXT_PAYMENTS_BATCHES = 'PaymentsBatches';
export const CONTEXT_INTERNAL_MOVEMENTS = 'InternalMovements';


// ********** Variaveis que podem ser alteradas no ficheiro json de configuracoes do projeto **********

// ___*** DATAS ***___
// export let DATE_FORMAT = 'dd/mm/yyyy';
export let DATE_FORMAT = 'DD/MM/YYYY';
export let setDateFormat = function (value: string): void {
  DATE_FORMAT = value;
};
// export let DATE_HOUR_FORMAT = 'dd/mm/yyyy hh:ii';
export let DATE_HOUR_FORMAT = DATE_FORMAT + ' HH:mm:ss';
export let setDateHourFormat = function (value: string): void {
  DATE_HOUR_FORMAT = value;
  Functions.datatablesDatetimeFormat(DATE_HOUR_FORMAT);
};
export let STEP_MIN = '1';
export let setStepMin = function (value: string): void {
  STEP_MIN = value;
};

// ___*** DECIMAIS ***___
export let CURRENCY_DECIMAL = '2';
export let setCurrencyDecimal = function (value: string): void {
  CURRENCY_DECIMAL = value;
};
export let QUANTITY_DECIMAL = '4';
export let setQuantityDecimal = function (value: string): void {
  QUANTITY_DECIMAL = value;
};
export let UNITARY_PRICE_DECIMAL = '4';
export let setUnitaryPriceDecimal = function (value: string): void {
  UNITARY_PRICE_DECIMAL = value;
};
export let VOLUME_DECIMAL = '3';
export let setVolumeDecimal = function (value: string): void {
  VOLUME_DECIMAL = value;
};
export let WEIGHT_DECIMAL = '3';
export let setWeightDecimal = function (value: string): void {
  WEIGHT_DECIMAL = value;
};
export let CURRENCY_SYMBOL = '';
export let setCurrencySymbol = function (value: string): void {
  CURRENCY_SYMBOL = value;
};
export let CURRENCY_CODE = 'EUR';
export let setCurrencyCode = function (value: string): void {
  CURRENCY_CODE = value;
};
export let TAX_AMOUNT_DECIMAL = '4';
export let setTaxAmountDecimal = function (value: string): void {
  TAX_AMOUNT_DECIMAL = value;
};

// ___*** ROLES ***___
export let USER_ADMIN = 'USER_ADMIN';
export let ORDERS_PURCHASE_ROLE = 'ORDERS_PURCHASE_ROLE';
export let ORDERS_SALE_ROLE = 'ORDERS_SALE_ROLE';
export let DELIVERY_NOTES_SALE = 'DELIVERY_NOTES_SALE';
export let INVOICES_PURCHASE_ROLE = 'INVOICES_PURCHASE_ROLE';
export let INVOICES_SALE_ROLE = 'INVOICES_SALE_ROLE';
export let REQUESTS_ENQUAD_ORC = 'REQUEST_ENQ_ORC';

export let ROLES = {
  USER_ADMIN: 1,
  ORDERS_SALE_ROLE: 2,
  ORDERS_PURCHASE_ROLE: 4,
  DELIVERY_NOTES_SALE: 102,
  INVOICES_PURCHASE_ROLE: 14,
  INVOICES_SALE_ROLE: 12,
  MASTER_DOCUMENTS: 220,
  REQUESTS_ENQUAD_ORC: 206,
  SUPPLIER: 400
};

// ********** FIM - Variaveis que podem ser alteradas no ficheiro json de configuracoes do projeto **********

// Dias da semana com os códigos da classe DayOfWeek do c#
export const DAYS_OF_WEEK = [
  { name: 'SUNDAY', code: 0 },
  { name: 'MONDAY', code: 1 },
  { name: 'TUESDAY', code: 2 },
  { name: 'WEDNESDAY', code: 3 },
  { name: 'THURSDAY', code: 4 },
  { name: 'FRIDAY', code: 5 },
  { name: 'SATURDAY', code: 6 }
];

// Horas do dia
export const HOURS_OF_DAY = [
  { name: '00', code: 0 },
  { name: '01', code: 1 },
  { name: '02', code: 2 },
  { name: '03', code: 3 },
  { name: '04', code: 4 },
  { name: '05', code: 5 },
  { name: '06', code: 6 },
  { name: '07', code: 7 },
  { name: '08', code: 8 },
  { name: '09', code: 9 },
  { name: '10', code: 10 },
  { name: '11', code: 11 },
  { name: '12', code: 12 },
  { name: '13', code: 13 },
  { name: '14', code: 14 },
  { name: '15', code: 15 },
  { name: '16', code: 16 },
  { name: '17', code: 17 },
  { name: '18', code: 18 },
  { name: '19', code: 19 },
  { name: '20', code: 20 },
  { name: '21', code: 21 },
  { name: '22', code: 22 },
  { name: '23', code: 23 }
];

// Google Maps API Key
export const GOOGLEMAPS_KEY: string = 'AIzaSyA_e4huwplnC3Hge1XuC12Iol-VGVfeGK4';

// Tipos de Dados
export enum FIELD_TYPE {
  STRING = 0,
  BOOLEAN = 1
}

// Tipos de imagem permitidos para upload
export const IMAGE_ACCEPT = 'image/gif,image/png,image/jpg,image/jpeg';
