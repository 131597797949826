export class Role {
  public static readonly CUSTOMER_ROLES: number[] = [2, 12, 102, 501];
  public static readonly SUPPLIER_ROLES: number[] = [4, 14, 104];
  public static readonly ROLE_ORDERS_BUYER: number = 4;
  public static readonly ROLE_ADMIN: number = 1;
  public static readonly ROLE_NO_ACCESS: number = 0;
  public static readonly ROLE_ARMAZEM_ENTREGA: number = 44;
  public static readonly ROLE_ARMAZEM_ORIGEM: number = 45;
  public static readonly ROLE_PRICELIST_ESPANHA: number = 701;
  public static readonly ROLE_PRICELIST_FRANCA: number = 702;
  public static readonly ROLE_TRANSPORT_SUPPLIER: number = 305;
  public static readonly ROLE_TRANSPORT_MANAGER: number = 306;
  public static readonly ROLE_TRANSPORT_TRANSPORTER: number = 307;
  public static readonly ROLE_TRANSPORT_USER: number = 308;
  public static readonly ROLE_TRANSPORT_CONSULT: number = 309;
  public static readonly ROLE_INVOICES_BUYER: number = 14;
  public static readonly ROLE_INVOICES_CONSULT: number = 13;
  public static readonly ROLE_INVOICES_SUPPLIER: number = 12;
  public static readonly ROLE_DELIVERY_NOTE_SUPPLIER: number = 102;
  public static readonly ROLE_EXPENSES_VALIDATION: number = 317;
  public static readonly ROLE_MSD_USER: number = 600;
  public static readonly ROLE_MSD_USER_POULRTY: number = 601;
  public static readonly ROLE_MSD_USER_LIVESTOCK_SWINE: number = 602;
  public static readonly ROLE_MSD_USER_COMPANION_ANIMALS: number = 603;
  public static readonly ROLE_MSD_USER_LIVESTOCK_RUMINANTES: number = 604;
  /*public static readonly ROLE_PAYMENTS: number = 600;
  public static readonly ROLE_PAYMENTS_CONSULT: number = 601;
  public static readonly ROLE_PAYMENTS_APROV: number = 602;*/

  constructor(
    public ID: number,
    public Name: string,
    public OppositeRole: number,
    public AllowReceiveEmail: boolean,
    public ServiceID: string,
    public IsSelected?:  boolean
  ) { }
}
