// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, NgZone, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { takeUntil, first } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorTreatmentFunctions } from '../../../modules/treatments.module';

// ***__***_________  SERVICOS _________ ***__***
import { TranslateService } from '@ngx-translate/core';
import { TranslateValueService } from '../../../services/translate-value.service';
import { CompanyDeliveryScheduleService } from '../../../services/companyDeliverySchedule.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonService } from '../../../services/common.service';

// ***__***_________  MODELOS _________ ***__***
import { OrderDetail, OrderTax, OrderAllowance, OrderDiscount, OrderNote, OrderReference, TransformModel } from '../../../models/order';
import { CostCenterConfig, TransformModelCC } from '../../../models/costCenterConfig';
import { Dropdown } from '../../../models/dropdown';
import { ModelValidators } from '../../../models/validators/validators';
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { ValueAddedTax } from '../../../models/value-added-tax';

// ***__***_________  MODALS _________ ***__***
import { ChooseModalComponent } from '../../shared/choose-modal/choose-modal.component';

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import { CURRENCY_DECIMAL, CURRENCY_SYMBOL, UNITARY_PRICE_DECIMAL, STEP_MIN, DATE_HOUR_FORMAT, QUANTITY_DECIMAL } from '../../../constants/global';

import { DistributionModalComponent } from '../../shared/distribution-modal/distribution-modal.component';
import { ChooseModalParam } from 'src/app/models/choose-modal-param';
import { DistributionModalParam } from 'src/app/models/distribution-modal-param';
import { CADistributionModalComponent } from '../../shared/distribution-modal/ca/ca-distribution-modal.component';
import { AccountingConfig, TransformModelAC } from 'src/app/models/accountingConfig';
import { TipologiaProdutoServico } from 'src/app/models/tipologia-produto-servico';
import { RequestsService } from 'src/app/services/requests.service';
import { OrderService } from 'src/app/services/order.service';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './orderDetails-for-all-companies-modal.html'
})
export class OrderDetailForAllCompaniesModalComponent implements OnInit, OnDestroy {
  collPnPopup1: boolean = true;
  collPnPopup2: boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public detailForm: UntypedFormGroup;

  noError: boolean = true;
  submitted: boolean = false;
  formErrors: Array<string> = new Array<string>(); // erros do formulario, array para campos
  formErrorsParam: Array<string> = new Array<string>(); // parametros para passar para a string de erro do campo
  validationMessages: Array<string> = new Array<string>(); // msg de erro a mostrar ao utilizador
  allowEdit: boolean = false; // se tem permissoes de edicao
  model: OrderDetail = null;
  tranfModelCC = new TransformModelCC(); // necessario para usar as funcoes de configuração de numeros
  tranfModelAC = new TransformModelAC();
  decimalPlaces3: number = 3;

  // arrays para options dos selects
  orderTaxes: Array<any> = new Array<any>();
  orderAllowances: Array<any> = new Array<any>();
  orderDiscounts: Array<any> = new Array<any>();
  valueAddedTaxes: ValueAddedTax[] = [];
  otherReferenceTypes: Dropdown[] = [];

  detailTaxes: Array<any> = new Array<any>();
  detailUnitsG: Array<any> = new Array<any>();
  detailUnitsW: Array<any> = new Array<any>();
  detailUnitsV: Array<any> = new Array<any>();

  listaMoradasLocaisEntrega: Array<any> = new Array<any>();
  allCountries: Array<any> = new Array<any>();
  providers: Array<any> = new Array<any>();

  familiesList: Array<any> = new Array<any>();
  familiesListLevel1: Array<any> = new Array<any>();
  familiesListLevel2: Array<any> = new Array<any>();

  showPackagingPanel: boolean = false;
  showTaxesPanel: boolean = false;
  showNotesPanel: boolean = false;
  showAllowancesPanel: boolean = false;
  showDiscountsPanel: boolean = false;
  showReferencesPanel: boolean = false;
  showStatePanel: boolean = false;
  showSupplierPanel: boolean = false;
  showDeliveryPanel: boolean = false;
  productQuantityValidations: boolean;

  showFamily: boolean = false;
  showOrderedQuantityUOMCode: boolean = false;
  showBonusQuantity: boolean = false;
  showBonusQuantityUOM: boolean = false;
  showNetUnitPrice: boolean = false;
  showTotalNetAmountDetail: boolean = false;
  showGrossUnitPrice: boolean = false;
  showTotalGrossAmount: boolean = false;
  showDistributionButton: boolean = false;
  showPackQuantityUOM: boolean = false;
  showPackUnitsPerLayer: boolean = false;
  showLatestAcceptableDate: boolean = false;
  showTotalNetAmount: boolean = false;
  disableTaxes: boolean = false;
  showOthersReferencesTypes: boolean = false;

  isEditable: boolean; // serve para distinguir entre detalhes purchases / detalhes sales
  formDisabled: boolean; // para saber se os detalhes purchases se podem editar ou sao so de consulta

  familyNameLevel1: string;
  familyNameLevel2: string;

  familiesLv1: Array<any> = new Array<any>();
  familiesLv2: Array<any> = new Array<any>();
  familiesLv3: Array<any> = new Array<any>();

  familyLv1: number;
  familyLv2: number;
  familyLv3: number;

  changedForm: boolean = false;
  oldFormJson: string;

  hasDistribution: boolean;
  saveOnServer: boolean = false;
  context: string = '';
  currentLanguage: string;

  isServiceProvider: boolean;

  // listas a enviar p/ distribuição
  typeList: Array<Dropdown> = new Array<Dropdown>(); // tipo (centro de custo/eixo de analise)
  debitCreditList: Array<Dropdown> = new Array<Dropdown>(); // debito ou credito
  incidenceList: Array<Dropdown> = new Array<Dropdown>(); // listagem de incidencias
  analisysAxesList: Array<any> = new Array<any>(); // listagem de eixos de análise
  divisionKeysList: Array<any> = new Array<any>(); // listagem de chaves de divisão
  allCostCentersList: Array<any> = new Array<any>(); // listagem de todos os centros de custo
  documentType: string = ''; // tipo de documento
  // ** fim listas distribuição

  private _cur_symbol: string = CURRENCY_SYMBOL;
  // private _decimalPipe: DecimalPipe;

  useDeliveryDateSchedule: boolean = false;
  minDays: number = 0;
  minHour: number = 0;
  maxHour: number = 23;
  weekDays: Array<boolean> = new Array<boolean>(false, false, false, false, false, false, false);

  deliveryDateRequiredNonSP: boolean = false;
  isRequiredDeliveryPlaceGroupDetailNonSP: boolean = false;
  showDeliveryDateOnBasePanel: boolean = false;

  showDetailDeliveryPanel: boolean = false; // variavel global p/ definir se panel de entrega é para aparecer ou nao

  tranfModel = new TransformModel(); // necessario para usar as funcoes de configuração de hora e numeros

  showReturnedQuantity: boolean = false;
  useAccountingConfig: boolean = false;

  showDistributionExpenseAccount: boolean = true;
  showDistributionInitiativeCode: boolean = true;
  showDistributionActivityCode: boolean = true;
  showDistributionFixedAssetRecordType: boolean = true;
  showDistributionAmortizationBookCode: boolean = true;
  showDistributionVATaccountingGroup: boolean = true;
  showDistributionRefactor: boolean = true;
  showDistributionAccountingType: boolean = true;
  showAccountingVatGroup: boolean = true;

  accountTypeList: Array<Dropdown> = new Array<Dropdown>();

  accountProductTypes: Array<TipologiaProdutoServico> = new Array<TipologiaProdutoServico>();
  accountProductSubTypes: Array<TipologiaProdutoServico> = new Array<TipologiaProdutoServico>();
  vatTreatmentTypes: Array<Dropdown> = new Array<Dropdown>();

  showDistributionDetails: boolean = false;
  getProductServiceType: boolean = false;

  enableSaveButton: boolean = false;

  //setting para colocar uma label específica para o botão da contabilização/distribuição
  distributionButtonLabel: string = 'DISTRIBUTION';

  showProductServiceTypeFields: boolean = false;
  showDistributionVatTreatmentType: boolean = true;
  showDistributionCodMensualizacao: boolean = true;

  validationMessagesTaxes = {};
  validationMessagesAllowance = {};
  validationMessagesDiscount = {};
  validationMessagesOtherReferences = {};
  validationMessagesNotes = {};

  constructor(public dialogRef: MatDialogRef<OrderDetailForAllCompaniesModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private zone: NgZone,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private translateValueService: TranslateValueService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private companyDeliveryScheduleService: CompanyDeliveryScheduleService,
    private authenticationService: AuthenticationService,
    private _errorTreat: ErrorTreatmentFunctions,
    private commonService: CommonService,
    private orderService: OrderService) {
    // Listas de dados
    this.commonService.valueAddedTaxes.subscribe((response: Array<ValueAddedTax>) => this.valueAddedTaxes = response);

    this.context = data.context;

    this.currentLanguage = translateValueService.translateLanguage;

    this.model = data.detailModel;
    this.detailUnitsG = data.detailUnitsG;
    this.detailUnitsW = data.detailUnitsW;
    this.detailUnitsV = data.detailUnitsV;

    this.showPackagingPanel = data.showPackagingPanel;
    this.showTaxesPanel = data.showTaxesPanel;
    this.showNotesPanel = data.showNotesPanel;
    this.showAllowancesPanel = data.showAllowancesPanel;
    this.showDiscountsPanel = data.showDiscountsPanel;
    this.showReferencesPanel = data.showReferencesPanel;
    this.showStatePanel = data.showStatePanel;

    this.showFamily = data.showFamily;
    this.showOrderedQuantityUOMCode = data.showOrderedQuantityUOMCode;
    this.showBonusQuantity = data.showBonusQuantity;
    this.showBonusQuantityUOM = data.showBonusQuantityUOM;
    this.showNetUnitPrice = data.showNetUnitPrice;
    this.showTotalNetAmountDetail = data.showTotalNetAmountDetail;
    this.showGrossUnitPrice = data.showGrossUnitPrice;
    this.showTotalGrossAmount = data.showTotalGrossAmount;
    this.showTotalNetAmount = data.showTotalNetAmount;
    this.showLatestAcceptableDate = data.showLatestAcceptableDate;
    this.showOthersReferencesTypes = data.showOthersReferencesTypes;

    this.familiesList = data.familiesList;
    this.useAccountingConfig = data.useAccountingConfig;

    //Configurações das colunas do painel de contabilização
    this.showDistributionExpenseAccount = data.showDistributionExpenseAccount;
    this.showDistributionInitiativeCode = data.showDistributionInitiativeCode;
    this.showDistributionActivityCode = data.showDistributionActivityCode;
    this.showDistributionFixedAssetRecordType = data.showDistributionFixedAssetRecordType;
    this.showDistributionAmortizationBookCode = data.showDistributionAmortizationBookCode;
    this.showDistributionVATaccountingGroup = data.showDistributionVATaccountingGroup;
    this.showDistributionRefactor = data.showDistributionRefactor;
    this.showDistributionAccountingType = data.showDistributionAccountingType;
    this.showAccountingVatGroup = data.showAccountingVatGroup;
    this.showDistributionVatTreatmentType = data.showDistributionVatTreatmentType;
    this.showDistributionCodMensualizacao = data.showDistributionCodMensualizacao;

    //Listas para o painel da contabilização
    this.accountTypeList = data.accountTypeList;

    //Listas para o Tipo de Produto/Serviço
    this.accountProductTypes = data.accountProductTypes;
    this.accountProductSubTypes = data.accountProductSubTypes;
    this.vatTreatmentTypes = data.vatTreatmentTypes;

    //Para saber se é para mostar os campos relativos ao tipo Produto/Serviço
    this.getProductServiceType = data.getProductServiceType;

    this.showDistributionDetails = data.showDistributionDetails;

    //Para poder (ou não) editar a contabilização
    this.enableSaveButton = data.enableSaveButton;

    this.distributionButtonLabel = data.distributionButtonLabel;

    // Verifica se é para mostrar os tipos de outras referências
    if (this.showOthersReferencesTypes) {
      // Tipos de Outras Referências
      this.commonService.otherReferenceTypes.pipe(takeUntil(this.destroy$)).subscribe(response => this.otherReferenceTypes = response);
    }

    if (data.context === 'encomendaCompra') { // encomenda de compra (as editaveis)

      this.listaMoradasLocaisEntrega = data.locations;
      this.allCountries = data.allCountries;
      this.providers = data.providers == null ? [] : data.providers;

      this.showDeliveryPanel = data.showDeliveryPanel;
      this.showSupplierPanel = data.showSupplierPanel;

      this.productQuantityValidations = data.productQuantityValidations;
      this.showDistributionButton = data.showDistributionButton;
      this.showPackQuantityUOM = data.showPackQuantityUOM;
      this.showPackUnitsPerLayer = data.showPackUnitsPerLayer;

      this.disableTaxes = data.disableTaxes;
      this.showDeliveryDateOnBasePanel = data.showDeliveryDateOnBasePanel;

      this.isServiceProvider = data.isServiceProvider;

      this.useDeliveryDateSchedule = data.useDeliveryDateSchedule;
      this.deliveryDateRequiredNonSP = data.deliveryDateRequiredNonSP;
      this.isRequiredDeliveryPlaceGroupDetailNonSP = data.isRequiredDeliveryPlaceGroupDetailNonS;

      if (this.useAccountingConfig) {
        if (this.model && this.model.AccountingConfigList && this.model.AccountingConfigList.length > 0) {
          this.hasDistribution = true;
        }
      } else {
        if (this.model && this.model.CostCentersConfig && this.model.CostCentersConfig.length > 0) {
          this.hasDistribution = true;
        }
      }


      this.showReturnedQuantity = data.showReturnedQuantity;

    } else if (data.context === 'requisicao') { // para alterar o nome de quantidade encomendada para quantidade requisitada

      this.listaMoradasLocaisEntrega = data.locations;
      this.allCountries = data.allCountries;
      this.providers = data.providers == null ? [] : data.providers;

      this.showDeliveryPanel = data.showDeliveryPanel == null ? true : data.showDeliveryPanel;
      this.showSupplierPanel = data.showSupplierPanel == null ? false : data.showSupplierPanel;
      this.showDistributionButton = data.showDistributionButton;
      this.showPackQuantityUOM = data.showPackQuantityUOM;
      this.showPackUnitsPerLayer = data.showPackUnitsPerLayer;

      if ((this.model && this.model.CostCentersConfig && this.model.CostCentersConfig.length > 0)
        || (this.detailForm && this.detailForm.controls != null && this.detailForm.controls['CostCentersConfig'] != null
          && this.detailForm.controls['CostCentersConfig'].value.length > 0)) {
        this.hasDistribution = true;
      }

    } else {
      // encomenda de venda
      this.isEditable = false;
      this.showDeliveryPanel = data.showDeliveryPanel;

      this.showDeliveryDateOnBasePanel = data.showDeliveryDateOnBasePanel;

      this.disableTaxes = data.disableTaxes;

      // fazer o form
      let noteValue: string = '';
      let otherNotes = [];
      if (this.model && this.model.Notes && this.model.Notes.length > 0) {
        if (this.model.Notes.find((x: any) => x.NoteType === 'PortalObs')) {
          noteValue = this.model.Notes.find((x: any) => x.NoteType === 'PortalObs').NoteValue;
        }
        this.model.Notes.forEach(element => {
          if (element.NoteType !== 'PortalObs') {
            otherNotes.push(element);
          }
        });
      }


      this.detailForm = this.formBuilder.group({
        'ID': [this.model.ID],
        // Identificação da linha
        'LineNumber': [this.model.LineNumber],
        'FamilyID': [this.model.FamilyID],
        'FamilyLv1': [null],
        'FamilyLv2': [null],
        'FamilyLv3': [null],
        'BuyerProductCode': [this.model.BuyerProductCode],
        'SupplierProductCode': [this.model.SupplierProductCode],
        'StandardProductCode': [this.model.StandardProductCode],
        'ProductDescription': [this.model.ProductDescription],
        'ProductID': [this.model.ProductID],
        // Quantidades e preços
        'OrderedQuantity': [this.model.OrderedQuantity_form],

        'OrderedQuantityUOMCode': [this.model.OrderedQuantityUOMCode],
        'OrderedQuantityUOMCodeOther': [this.model.OrderedQuantityUOMCodeOther],
        'BonusQuantity': [this.model.BonusQuantity_form],
        'BonusQuantityUOMCode': [this.model.BonusQuantityUOMCode],

        'NetUnitPrice': [this.model.NetUnitPrice_form],
        'GrossUnitPrice': [this.model.GrossUnitPrice_form],
        'TotalNetAmount': [this.model.TotalNetAmount_form],
        'TotalGrossAmount': [this.model.TotalGrossAmount_form],

        // Local de entrega por linha
        'DeliverLocationID': [this.model.DeliverLocationID],
        'DeliveryLocationIdentifier': [this.model.DeliveryLocationIdentifier],
        'DeliveryLocationName': [this.model.DeliveryLocationName],
        'DeliveryLocationStreet': [this.model.DeliveryLocationStreet],
        'DeliveryLocationPostalCode': [this.model.DeliveryLocationPostalCode],
        'DeliveryLocationCity': [this.model.DeliveryLocationCity],
        'DeliveryLocationCountryCode': [this.model.DeliveryLocationCountryCode],
        'DeliveryEarliestAcceptableDate': [this.model.DeliveryEarliestAcceptableDate],
        'DeliveryLatestAcceptableDate': [this.model.DeliveryLatestAcceptableDate],
        'OrderedBy': [this.model.OrderedBy],

        // Embalagens
        'PackQuantity': [this.model.PackQuantity_form],
        'PackQuantityUOM': [this.model.PackQuantityUOM],
        'PackSize': [this.model.PackSize_form],
        'PackUnitsPerLayer': [this.model.PackUnitsPerLayer_form],
        'PackAverageGrossWeight': [this.model.PackAverageGrossWeight_form],

        // Taxas
        'Taxes': this.formBuilder.array([]),
        // Abonos
        'Allowances': this.formBuilder.array([]),
        // Descontos
        'Discounts': this.formBuilder.array([]),
        // Referências
        'OtherReferences': this.formBuilder.array([]),
        // Observações
        'Notes': this.formBuilder.array([]),
        // Variável que espera no servidor para transformar em NotesCSV ****************************
        'NoteToShow': [noteValue],
        'OtherNotes': this.initOtherNotes(otherNotes),
        // Estado
        'LineState': [this.model.LineState],
        'RevisedQuantity': [this.model.RevisedQuantity_form],
        'DespatchedQuantity': [this.model.DespatchedQuantity_form],
        'SatisfiedQuantity': [this.model.SatisfiedQuantity_form],
        'ReceivedQuantity': [this.model.ReceivedQuantity_form],
        'InvoiceToQuantity': [this.model.InvoiceToQuantity_form],
        'ReturnedQuantity': [this.model.ReturnedQuantity_form],

        'Quantity': [this.model.Quantity_form],

        // P/a distribuição
        'CostCentersConfig': this.formBuilder.array([]),
        // 'ID_unic': [this.idUnic],
        'OrderDetailID': [this.model.OrderDetailID],
        'LineType': [this.model.LineType],
        'LineTypeOther': [this.model.LineTypeOther],
        'BuyersGlobalOrderNumber': [this.model.BuyersGlobalOrderNumber],
        'BuyersGlobalOrderType': [this.model.BuyersGlobalOrderType],
        'MasterOrderDetailID': [this.model.MasterOrderDetailID],
        'ChildCreatedBy': [this.model.ChildCreatedBy],
        'CostCenterID': [this.model.CostCenterID],
        'ProductPackID': [this.model.ProductPackID],
        'PriceListProductStepID': [this.model.PriceListProductStepID],
        'UnitAmountMaxDiference': [this.model.UnitAmountMaxDiference],
        'PercentageMaxDiference': [this.model.PercentageMaxDiference],
        'TotalWeight': [this.model.TotalWeight],
        'TotalVolume': [this.model.TotalVolume],
        'HandlerUserID': [this.model.HandlerUserID],
        'AccountProductType': [this.model.TipologiaProdutoServico ? this.model.TipologiaProdutoServico.ParentID : null],
        'AccountProductSubtype': [this.model.TipologiaProdutoServico ? this.model.TipologiaProdutoServico.ID : null],
        'VatTreatmentType': [this.model.TipologiaProdutoServico ? this.model.TipologiaProdutoServico.TipoTratamentoIva : null],
        'TipologiaProdutoServicoID': [this.model.TipologiaProdutoServicoID ? this.model.TipologiaProdutoServicoID : null]        
      });
    
      this.addTaxe(false);
      this.addAllowance(false);
      this.addDiscount(false);
      this.addOtherReference(false);
      this.addNote(false);

      this.detailForm.disable();
    }

    if (this.showDeliveryPanel) {
      this.showDetailDeliveryPanel = true;
    }


    if (data.context === 'encomendaCompra' || data.context === 'requisicao') {
      this.isEditable = true;
      this.allowEdit = data.allowEdit;
      this.typeList = data.typeList;
      this.debitCreditList = data.debitCreditList;
      this.incidenceList = data.incidenceList;
      this.analisysAxesList = data.analisysAxesList;
      this.divisionKeysList = data.divisionKeysList;
      this.allCostCentersList = data.allCostCentersList;
      this.documentType = data.documentType == null ? '' : data.documentType;
      this.saveOnServer = data.saveOnServer;
      this.formDisabled = data.formDisabled;

      this.validationMessages = data.validationMessagesDetails;
      this.detailForm = data.detailForm;
    }

    //Se as taxas são para apresentar preciso dos tipos de taxas
    if (this.showTaxesPanel) {
      this.detailTaxes = data.detailTaxes;
    }


    this.getFamilyInformation();


    if (this.showFamily && this.familiesLv1 != null && this.familiesLv1.length > 0) { // verificar se existe list lvl 1
      this.detailForm.controls['FamilyLv1'].setValidators([Validators.required]); // add validador
      this.detailForm.controls['FamilyLv1'].updateValueAndValidity(); // atualizar validador
    }

    this.oldFormJson = JSON.stringify(this.detailForm.getRawValue());  
    
    this.validationMessagesTaxes = {
        // 'AmountCurrencyCode': '',
        // 'AmountCurrencyCodeOther': '',
        'TaxRate': {
          'numberVal': 'NUMBER_INVALID',
          'numberMin': 'NUMBER_MIN_ERROR',
          'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
        },
        'TaxAmount': {
          'numberVal': 'NUMBER_INVALID',
          'numberMin': 'NUMBER_MIN_ERROR',
          'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
        },
        // 'TaxTypeCode': '',
        // 'TaxTypeCodeOther': ''
    }

    this.validationMessagesAllowance = {
      'Percentage ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'Amount ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      }
    }

    this.validationMessagesDiscount = {
      'Percentage ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'Amount ': {
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      }
    }
    
    this.validationMessagesOtherReferences = {
      'ReferenceDate ': {
        'invalidDate': 'INVALID_DATE'
      },
    }

    this.validationMessagesNotes = {};
  }

  ngOnInit() {
    let that = this;

    if (this.isEditable) {
      // let isReadonly = this.detailForm.get('id').disabled;

      this.detailForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDetails(value);
      });

      this.onValueChangedDetails(); // p/ 1ªx
      this.zone.onStable.pipe(first()).subscribe(() => {
        // that.addTaxe(false);
        // that.addAllowance(false);
        // that.addDiscount(false);
        // that.addOtherReference(false);
        // that.addNote(false);

        if (that.formDisabled) {
          that.detailForm.disable();
        } else if (that.disableTaxes) {
          that.detailForm.get('Taxes').disable();
        }
      });

      if (!this.formDisabled) {
        this.getDeliveryScheduleForDeliveryDate(this.useDeliveryDateSchedule, this.showDeliveryDateOnBasePanel, this.isServiceProvider);
      }

      // if (isReadonly) {
      //   this.detailForm.disable();
      // }     
    }
    
    this.changedForm = false;

    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    //se for para editar e o campo TipologiaProdutoServicoID estiver preenchido, vamos buscar o filho com base no pai e no tratamento de IVA
    if (this.detailForm.controls['ID'].value !== 0 && this.getProductServiceType && this.model != null && this.detailForm.controls['TipologiaProdutoServicoID'].value != null) {
      let accountProductType = this.detailForm.controls['AccountProductType'].value;
      let vatTreatmentType = this.detailForm.controls['VatTreatmentType'].value;
      this.getChildsList(accountProductType, vatTreatmentType);
      //se o valor do tipo de produto/serviço vier preenchido, vamos buscar a descrição (o nome)
      let accountProductTypeEntity = this.accountProductTypes.find(x => x.ID === accountProductType);
      let accountProductTypeName = accountProductTypeEntity ? accountProductTypeEntity.Name : null;
      this.detailForm.controls['AccountProductTypeDescription'].setValue(accountProductTypeName);
    }

    if (this.getProductServiceType && this.model && !this.model.ProductID) {
      this.showProductServiceTypeFields = true;
    }

    this.oldFormJson = JSON.stringify(this.detailForm.getRawValue());  
  }

  getFamilyInformation() {
    this.familiesLv1 = this.familiesList.filter(r => r.Level === 1);

    if (this.model && this.model.FamilyID !== 0 && this.model.FamilyID !== null && typeof (this.model.FamilyID) !== 'undefined') {
      let family = this.familiesList.filter(r => r.ID === this.model.FamilyID)[0];
      if (family != null) {
        switch (family.Level) {
          case 1:
            this.familyLv1 = family.ID;
            this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === family.ID);
            this.familiesLv3 = [];
            break;
          case 2:
            this.familyLv1 = family.ParentID;
            this.familyLv2 = family.ID;
            this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === family.ParentID);
            this.familiesLv3 = this.familiesList.filter(r => r.Level === 3 && r.ParentID === family.ID);
            break;
          case 3:
            this.familyLv1 = this.familiesList.filter(r => r.ID === family.ParentID)[0].ParentID;
            this.familyLv2 = family.ParentID;
            this.familyLv3 = family.ID;
            this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === this.familyLv1);
            this.familiesLv3 = this.familiesList.filter(r => r.Level === 3 && r.ParentID === family.ParentID);
            break;
        }
      }
    }
  }

  onChangeFamilyLv1($event: any) {
    this.familiesLv2 = this.familiesList.filter(r => r.Level === 2 && r.ParentID === +$event.value);
    this.detailForm.get('FamilyLv2').setValue(null);
    this.familiesLv3 = [];
    this.detailForm.get('FamilyLv3').setValue(null);
    if (this.showFamily && this.familiesLv2 != null && this.familiesLv2.length > 0) { // verificar se existe list lvl 2
      this.detailForm.controls['FamilyLv2'].setValidators([Validators.required]); // add validador
      this.detailForm.controls['FamilyLv2'].updateValueAndValidity(); // atualizar validador
      this.detailForm.controls['FamilyLv3'].setValidators(null); // remover validador lvl 3
      this.detailForm.controls['FamilyLv3'].updateValueAndValidity(); // atualizar validador
    }
  }

  onChangeFamilyLv2($event: any) {
    this.familiesLv3 = this.familiesList.filter(r => r.Level === 3 && r.ParentID === +$event.value);
    this.detailForm.get('FamilyLv3').setValue(null);
    if (this.showFamily && this.familiesLv3 != null && this.familiesLv3.length > 0) { // verificar se existe list lvl 3
      this.detailForm.controls['FamilyLv3'].setValidators([Validators.required]); // add validador
      this.detailForm.controls['FamilyLv3'].updateValueAndValidity(); // atualizar validador
    }
  }

  // #region Detail
  onValueChangedDetails(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    
    if (this.oldFormJson) {
      
      this.changedForm  = JSON.stringify(form.getRawValue()) !== this.oldFormJson;          
      this.ref.detectChanges();
    }

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {
          this.noError = false;
          const messages = this.validationMessages[field];

          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  save() {
    if (this.allowEdit) { // verificar se permissoes
      this.submitted = true;
      this.noError = true;
      this.onValueChangedDetails();

      if (this.detailForm.controls['AccountProductSubtype'].value != null) {
        this.detailForm.controls['TipologiaProdutoServicoID'].setValue(this.detailForm.controls['AccountProductSubtype'].value);
      }

      if (this.noError) {
        this.dialogRef.close(this.detailForm); // nao pode enviar so o value por causa dos campos disabled
      }
      this.submitted = false;
    }
  }

  // obter info locais de entrega (moradas)
  selectLocalDeliveryDetails() {
    this.translateService.get(['SELECT_DELIVERY_LOCAL', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.listaMoradasLocaisEntrega, null, response['SELECT_DELIVERY_LOCAL'], aoColumns, columnDefs, null, 1, null),
          disableClose: true, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.listaMoradasLocaisEntrega.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['DeliverLocationID'].setValue(this.listaMoradasLocaisEntrega[index].ID);
            this.detailForm.controls['DeliveryLocationIdentifier'].setValue(this.listaMoradasLocaisEntrega[index].IntegrationID);
            this.detailForm.controls['DeliveryLocationName'].setValue(this.listaMoradasLocaisEntrega[index].Name);
            this.detailForm.controls['DeliveryLocationStreet'].setValue(this.listaMoradasLocaisEntrega[index].Street);
            this.detailForm.controls['DeliveryLocationPostalCode'].setValue(this.listaMoradasLocaisEntrega[index].PostalCode);
            this.detailForm.controls['DeliveryLocationCity'].setValue(this.listaMoradasLocaisEntrega[index].City);
            this.detailForm.controls['DeliveryLocationCountryCode'].setValue(this.listaMoradasLocaisEntrega[index].CountryCode);
          }
        }
      });

    });

  }

  selectProvider() {
    this.translateService.get(['SELECT_SUPPLIER_TITLE', 'CODE', 'TAX_NUMBER', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'InternalID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - nº contribuinte
        { 'data': 'TaxNumber', 'class': 'verticalMiddle', 'title': response['TAX_NUMBER'] }, // 2 - nif
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome cliente
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.providers, null, response['SELECT_SUPPLIER_TITLE'], aoColumns, columnDefs, null, 1, null),
          disableClose: true // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          let resultID = parseFloat(result);
          if (resultID != null || resultID.toString().length > 0) {
            let index = this.providers.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['SupplierID'].setValue(this.providers[index].ID);
            this.detailForm.controls['SupplierIdentifier'].setValue(this.providers[index].InternalID);
            this.detailForm.controls['SupplierName'].setValue(this.providers[index].Name);
          }
        }
      });
    });
  }

  // distribuição dos detalhes da encomenda
  openDistribution() {
    if (this.useAccountingConfig) {
      this.openDistributionAccountConfig();
    } else {

      const control = <UntypedFormArray>this.detailForm.controls['CostCentersConfig'];
      // converter incidência para string para fazer match com o select
      control.controls.forEach((costCenter: any) => {
        costCenter.get('Incidence').setValue((costCenter.get('Incidence').value).toString());
      });

      let valor = this.detailForm.controls['TotalNetAmount'].value !== 0 && this.detailForm.controls['TotalNetAmount'].value ? (this.detailForm.controls['TotalNetAmount'].value).toString().revertDecimal() : 0;

      let id = (this.model) ? this.model.ID : 0;

      let dialogRef = this.dialog.open(DistributionModalComponent, {
        data: // dados que vai enviar para o componente da modal
          new DistributionModalParam(this.formDisabled, 'OrderDetail', id, control.getRawValue(),
            this.saveOnServer, valor, this.documentType, this.typeList, this.debitCreditList,
            this.incidenceList, this.analisysAxesList, this.divisionKeysList,
            this.allCostCentersList),
        disableClose: false, // nao permitir fechar modal com escape ou clique fora
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          // limpar formArray original p/ substituir por novo
          this.detailForm.controls['CostCentersConfig'] = this.formBuilder.array([]);

          for (let cost of result) {
            const newControl = <UntypedFormArray>this.detailForm.controls['CostCentersConfig'];
            const addrCtrl = this.initOrderCostCenterConfig(cost);
            newControl.push(addrCtrl);
          }
          this.hasDistribution = this.detailForm.controls['CostCentersConfig'].value.length > 0;
        }
      });
    }
  }

  openDistributionAccountConfig() {
    const control = <UntypedFormArray>this.detailForm.controls['AccountingConfigList'];

    let valor = this.detailForm.controls['TotalNetAmount'].value !== 0 && this.detailForm.controls['TotalNetAmount'].value ? (this.detailForm.controls['TotalNetAmount'].value).toString().revertDecimal() : 0;

    let id = (this.model) ? this.model.ID : 0;

    let dialogRef = this.dialog.open(CADistributionModalComponent, {
      data:{ // dados que vai enviar para o componente da modal
        distributionModalParam: new DistributionModalParam(this.formDisabled, 'OrderDetail', id, null,
      this.saveOnServer, valor, this.documentType, null, null, null, this.analisysAxesList, this.divisionKeysList,
      this.allCostCentersList, control.getRawValue(), this.showDistributionExpenseAccount,
      this.showDistributionInitiativeCode, this.showDistributionActivityCode, this.showDistributionFixedAssetRecordType, this.showDistributionAmortizationBookCode,
      this.showDistributionVATaccountingGroup, this.showDistributionRefactor, this.showDistributionAccountingType, this.showDistributionVatTreatmentType, 
      this.showDistributionCodMensualizacao, this.enableSaveButton, false, false, this.showAccountingVatGroup),},
      disableClose: false, // nao permitir fechar modal com escape ou clique fora
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        // limpar formArray original p/ substituir por novo
        this.detailForm.controls['AccountingConfigList'] = this.formBuilder.array([]);

        for (let config of result) {
          const newControl = <UntypedFormArray>this.detailForm.controls['AccountingConfigList'];
          const addrCtrl = this.initOrderAccountingConfig(config);
          newControl.push(addrCtrl);
        }
        this.hasDistribution = this.detailForm.controls['AccountingConfigList'].value.length > 0;
      }
    });
  }

  // quando a quantidade encomendada ou o preço unitario sao alterados
  quantityOrNetUnitPriceChange(val: any) {
    let value = val ? (val).toString().revertDecimal() : null;

    if (value !== null) {
      let quantity = this.detailForm.get('OrderedQuantity').value ? (this.detailForm.get('OrderedQuantity').value).toString().revertDecimal() : 0;
      let price = this.detailForm.get('NetUnitPrice').value ? (this.detailForm.get('NetUnitPrice').value).toString().revertDecimal() : 0;
      let result = quantity * price;
      this.detailForm.get('TotalNetAmount').setValue(result.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));
    }
  }

  // #region OrderQtdMethods
  /* Atenção!!!
  Aqui (modal), quando clica no increaseOrderedQtd() ou no reduceOrderedQtd() vai sempre fazer o checkQuantityIncrement()
  porque clicar nos dois primeiros desencadeia o (change) do input

  Ou entao nao...
  */
  increaseOrderedQtd() {
    let quantity = this.detailForm.get('OrderedQuantity').value.toString().revertDecimal();
    let minimumQuantity = this.detailForm.get('MinimumQuantity').value;
    let quantityIncrement = this.detailForm.get('QuantityIncrement').value;

    if (!isNaN(quantity) && !isNaN(minimumQuantity) && !isNaN(quantityIncrement)) {
      let val = quantity + quantityIncrement;
      this.detailForm.controls['OrderedQuantity'].setValue(val);

      this.quantityOrNetUnitPriceChange(val);
      this.recalcDetail();
    }
  }

  reduceOrderedQtd() {
    let quantity = this.detailForm.get('OrderedQuantity').value.toString().revertDecimal();
    let minimumQuantity = this.detailForm.get('MinimumQuantity').value;
    let quantityIncrement = this.detailForm.get('QuantityIncrement').value;

    if (!isNaN(quantity) && !isNaN(minimumQuantity) && !isNaN(quantityIncrement)) {
      let val = quantity - quantityIncrement;
      if (val >= minimumQuantity) {
        this.detailForm.controls['OrderedQuantity'].setValue(val);

        this.quantityOrNetUnitPriceChange(val);
        this.recalcDetail();
      }
    }
  }

  // altera valor da quantidade encomendada caso nao seja divisivel pela qauntidade incrmentável. Ex: se só puder fazer incrementos de 5 em 5;
  checkQuantityIncrement() {
    if (this.productQuantityValidations) {
      let quantity = this.detailForm.get('OrderedQuantity').value.revertDecimal();
      let minimumQuantity = this.detailForm.get('MinimumQuantity').value;
      let quantityIncrement = this.detailForm.get('QuantityIncrement').value;

      if (!isNaN(quantity) && !isNaN(minimumQuantity) && !isNaN(quantityIncrement)) {
        let currentValue = quantity - minimumQuantity;

        if (currentValue <= 0) { // valida se valor <= 0
          currentValue = minimumQuantity;

        } else { // valida se valor > 0

          let isInteger = currentValue % quantityIncrement === 0 ? true : false;

          if (!isInteger) {
            currentValue = (Math.ceil(currentValue / quantityIncrement) * quantityIncrement);
          }
          currentValue = currentValue + minimumQuantity;
        }
        this.detailForm.controls['OrderedQuantity'].setValue(currentValue);
      }
    }

    this.recalcDetail();
  }

  recalcDetail() {
    let detailValues = this.tranfModel.revertObjectDetail(this.detailForm.getRawValue());

    let orderedQuantity = detailValues.OrderedQuantity !== null ? detailValues.OrderedQuantity : 0;
    let packSize = detailValues.PackSize !== null ? detailValues.PackSize : 0;
    let netUnitPrice = detailValues.NetUnitPrice !== null ? detailValues.NetUnitPrice : 0;

    // atualizar o PackQuantity
    if (packSize > 0) {
      this.detailForm.controls['PackQuantity'].setValue((Math.ceil(orderedQuantity / packSize)).formatDecimal(this.currentLanguage, +QUANTITY_DECIMAL));
    } else {
      this.detailForm.controls['PackQuantity'].setValue(1);
    }

    // GM 2018-09-10 - O valor bruto = liq. + descontos e não liq. + taxas
    this.detailForm.controls['TotalNetAmount'].setValue((netUnitPrice * orderedQuantity).formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));
    let totalNetAmount = ((this.detailForm.controls['TotalNetAmount'].value).toString()).revertDecimal();

    // Recalcular taxas
    let environmentalTaxTotal = 0;
    const taxes = <UntypedFormArray>this.detailForm.get('Taxes');
    if (taxes != null && taxes.controls.length > 0) {

      // Eco Taxa
      let environmentalTax = taxes.controls.find(r => r.get('TaxTypeCode').value === 'EnvironmentalTax');
      if (environmentalTax) {
        // Valor da Eco taxa
        environmentalTaxTotal = ((environmentalTax.get('TaxRate').value.toString().revertDecimal() / 100) * totalNetAmount).round(2);
        environmentalTax.get('TaxAmount').setValue(environmentalTaxTotal.formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));
      }

      // IVA e outras taxas (incide também sobre eco taxas)
      taxes.controls.filter(r => r.get('TaxTypeCode').value !== 'EnvironmentalTax').forEach((tax: UntypedFormGroup) => {
        // Valor da taxa
        let taxAmount = (totalNetAmount + environmentalTaxTotal) * (tax.get('TaxRate').value.toString().revertDecimal() / 100);
        tax.get('TaxAmount').setValue(taxAmount.formatDecimal(this.currentLanguage, +UNITARY_PRICE_DECIMAL));
      });
    }

    // Recalcular descontos
    let discountsTotal = 0;
    let discountsUnit = 0;
    const discounts = <UntypedFormArray>this.detailForm.get('Discounts');
    if (discounts != null && discounts.controls.length > 0) {
      discounts.controls.forEach((discount: UntypedFormGroup) => {
        let percentage = discount.get('Percentage').value;

        if (percentage && percentage > 0) { // Se tiver percentagem, recalcula o valor
          // Valor do desconto
          let discountAmount = ((totalNetAmount - discountsTotal) * (discount.get('Percentage').value / 100));
          discount.get('Amount').setValue(discountAmount.formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));
          discountsTotal += discountAmount.round(CURRENCY_DECIMAL);

          // Valor de desconto unitário
          discountsUnit += ((netUnitPrice - discountsTotal) * (discount.get('Percentage').value / 100));
        } else if (discount.get('Amount').value) { // Senão se tiver valor fixo, usa esse valor
          discountsTotal += discount.get('Amount').value;
        }
      });
    }

    this.detailForm.get('GrossUnitPrice').setValue((netUnitPrice + discountsTotal).formatDecimal(this.currentLanguage, +UNITARY_PRICE_DECIMAL));
    this.detailForm.get('TotalGrossAmount').setValue((totalNetAmount + discountsUnit).formatDecimal(this.currentLanguage, +CURRENCY_DECIMAL));

  }
  // #endregion OrderQtdMethods

  // obter horario de entrega possivel para a data de entrega do detalhe da encomenda
  getDeliveryScheduleForDeliveryDate(useDeliveryDateSchedule: boolean, showDeliveryDateOnBasePanel: boolean, isServiceProvider: boolean) {

    if ((useDeliveryDateSchedule && this.model.ProductID && !showDeliveryDateOnBasePanel) ||
      (useDeliveryDateSchedule && this.model.ProductID && showDeliveryDateOnBasePanel && !isServiceProvider)) {
      this.companyDeliveryScheduleService.getByProductAndBuyer(this.model.ProductID, this.authenticationService.session.company.ID).pipe(takeUntil(this.destroy$)).subscribe((resp: ReturnStatusHtml) => {
        if (resp.ReturnStatus.Successfull) {
          let schedule = resp.ReturnStatus.ReturnObject.CompanyDeliverySchedule;

          if (schedule) {

            this.weekDays = []; // colapse das divs
            this.weekDays.push(schedule.Sunday, schedule.Monday, schedule.Tuesday, schedule.Wednesday, schedule.Thursday,
              schedule.Friday, schedule.Saturday); // tem de começar pelo domingo. domingo: 0, (...), sabado: 6

            this.minDays = schedule.RequestMinDays;
            this.minHour = schedule.DeliveryHourStart;
            this.maxHour = schedule.DeliveryHourEnd;

            let element = document.getElementById('datePickerScheduleDetail');
            let lang = this.translateValueService.translateLanguage;
            let type = 3;
            let formatData = DATE_HOUR_FORMAT;
            let stepMinutes = STEP_MIN;
            let tooltipsTrans = this.translateValueService.datepickerTranslates;

            // para voltar atualizar minDays, minHour, maxHour do dataTimePicker
            Functions.dataTimePicker(element, lang, type, formatData, stepMinutes, false, tooltipsTrans, null, null,
              this.minDays, this.minHour, this.maxHour, this.weekDays, element.getAttribute('disabled') !== '');

          }

        } else { // o que acontece se der erro
          this._errorTreat.treatErrorResponse(resp);
        }
      });
    }
  }

  // #endregion Detail

  // #region Distribution
  initOrderCostCenterConfig(costCenterConfig: CostCenterConfig = null) {
    if (costCenterConfig) {

      costCenterConfig = this.tranfModelCC.transformObject(costCenterConfig);
      return this.formBuilder.group({
        'ID': [costCenterConfig.ID],
        'AnalisysAxeID': [costCenterConfig.AnalisysAxeID],
        'Context': [costCenterConfig.Context],
        'CostCenterID': [costCenterConfig.CostCenterID],
        'DebitOrCredit': [costCenterConfig.DebitOrCredit],
        'DivisionKeyID': [costCenterConfig.DivisionKeyID],
        'EntityID': [costCenterConfig.EntityID],
        'Percentage': [costCenterConfig.Percentage_form ? costCenterConfig.Percentage_form : 0],
        'FixedValue': [costCenterConfig.FixedValue_form ? costCenterConfig.FixedValue_form : 0],
        'Incidence': [costCenterConfig.Incidence],
        'IntegrationID': [costCenterConfig.IntegrationID],
        'Type': [costCenterConfig.Type],
        'CostCenterName': [{ value: '', disabled: true }]
      });
    }
  }

  initOrderAccountingConfig(accountingConfig: AccountingConfig = null) {
    if (accountingConfig) {

      let costCenterName: string = null;
      if (accountingConfig.CentroCusto !== null && this.allCostCentersList !== null && this.allCostCentersList.length > 0) {
        let costCenter = this.allCostCentersList.find(x => x.IntegrationID === accountingConfig.CentroCusto);
        costCenterName = costCenter != null ? costCenter.Name : null;
      }

      accountingConfig = this.tranfModelAC.transformObject(accountingConfig);
      return this.formBuilder.group({
        'ID': [accountingConfig.ID],
        'Context': [accountingConfig.Context],
        'EntityID': [accountingConfig.EntityID],
        'Percentagem': [accountingConfig.Percentagem, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'ValorFixo': [accountingConfig.ValorFixo, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 2 })])],
        'CentroCusto': [accountingConfig.CentroCusto],
        'Refaturar': [accountingConfig.Refaturar],
        'Tipo': [accountingConfig.Tipo],
        'ContaGasto': [accountingConfig.ContaGasto],
        'GrupoIVA': [accountingConfig.GrupoIVA],
        'CodAtividade': [accountingConfig.CodAtividade],
        'CodIniciativa': [accountingConfig.CodIniciativa],
        'TipoRegistoImob': [accountingConfig.TipoRegistoImob],
        'CodLivroAmort': [accountingConfig.CodLivroAmort],
        'NomeCentroCusto': [costCenterName],
        'Observacoes': [accountingConfig.Observacoes],
        'CodGrupoContabilizacao': [accountingConfig.CodGrupoContabilizacao],
        'CodRetencao': [accountingConfig.CodRetencao],
        'TipoTratamentoIva': [accountingConfig.TipoTratamentoIva],
        'CodMensualizacao': [accountingConfig.CodMensualizacao]
      });
    }
  }
  // #endregion Distribution

  // #region Taxes
  initTax(tax: OrderTax = null) {
    if (tax) {
      return this.formBuilder.group({
        'AmountCurrencyCode': [tax.AmountCurrencyCode],
        'AmountCurrencyCodeOther': [tax.AmountCurrencyCodeOther],
        'TaxAmount': [tax.TaxAmount, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
        // 'TaxRate': [tax.TaxRate, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: CURRENCY_DECIMAL })])],
        'TaxRate': [tax.TaxRate],
        'TaxTypeCode': [tax.TaxTypeCode],
        'TaxTypeCodeOther': [tax.TaxTypeCodeOther]
      });
    } else {
      return this.formBuilder.group({
        'AmountCurrencyCode': [null],
        'AmountCurrencyCodeOther': [null],
        'TaxAmount': [null, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: UNITARY_PRICE_DECIMAL })])],
        // 'TaxRate': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: CURRENCY_DECIMAL })])],
        'TaxRate': [null],
        'TaxTypeCode': [null],
        'TaxTypeCodeOther': [null]
      });
    }
  }

  addTaxe(newInsert: boolean, objectInsert: OrderTax = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
      const taxCtrl = this.initTax();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(taxCtrl);
      taxCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedTax(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
      if (objectInsert) { // usado quando faz reset
        const taxCtrl = this.initTax(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(taxCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedTax(value);
          });
        }
      }
      if (this.model && this.model.Taxes && this.model.Taxes.length > 0) {
        for (objectInsert of this.model.Taxes) {
          const taxCtrl = this.initTax(objectInsert);
          control.push(taxCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeTaxe(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Taxes'];
    control.removeAt(i);
    // this.checkIfExemption();
  }

  /* tslint:disable:member-ordering */
  formErrorsTaxes: Array<string> = new Array<string>();
  formErrorsTaxesParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedTax(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsTaxes = new Array<string>();
    this.formErrorsTaxes = new Array<string>();
    for (const field in this.validationMessagesTaxes) {
      if (this.validationMessagesTaxes.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Taxes');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesTaxes[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsTaxes.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsTaxesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsTaxesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Taxes

  // #region Allowance
  initAllowance(allowance: OrderAllowance = null) {
    if (allowance) {
      return this.formBuilder.group({
        'AllowanceType': [allowance.AllowanceType],
        'Percentage': [allowance.Percentage, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
        'Amount': [allowance.Amount, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
      });
    } else {
      return this.formBuilder.group({
        'AllowanceType': [''],
        'Percentage': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
        'Amount': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
      });
    }
  }

  addAllowance(newInsert: boolean, objectInsert: OrderAllowance = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
      const allowCtrl = this.initAllowance();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(allowCtrl);
      allowCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedTAllowance(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
      if (objectInsert) { // usado quando faz reset
        const allowCtrl = this.initAllowance(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(allowCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedTAllowance(value);
          });
        }
      }

      if (this.model && this.model.Allowances && this.model.Allowances.length > 0) {
        for (objectInsert of this.model.Allowances) {
          const allowCtrl = this.initAllowance(objectInsert);
          control.push(allowCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeAllowance(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Allowances'];
    control.removeAt(i);
    // this.checkIfExemption();
  }

  /* tslint:disable:member-ordering */
  formErrorsAllowance: Array<string> = new Array<string>();

  formErrorsAllowanceParam: Array<string> = new Array<string>();


  /* tslint:enable:member-ordering */

  onValueChangedTAllowance(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsAllowance = new Array<string>();
    this.formErrorsAllowanceParam = new Array<string>();
    for (const field in this.validationMessagesAllowance) {
      if (this.validationMessagesAllowance.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Allowances');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesAllowance[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsAllowance.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsAllowance.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsAllowance.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Allowance

  // #region Discount
  initDiscount(discount: OrderDiscount = null) {
    if (discount) {
      return this.formBuilder.group({
        'DiscountType': [discount.DiscountType],
        'Percentage': [discount.Percentage, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
        'Amount': [discount.Amount, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
      });
    } else {
      return this.formBuilder.group({
        'DiscountType': [''],
        'Percentage': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
        'Amount': ['', Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: this.decimalPlaces3 })])],
      });
    }
  }

  addDiscount(newInsert: boolean, objectInsert: OrderDiscount = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
      const discountCtrl = this.initDiscount();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(discountCtrl);
      discountCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedDiscount(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
      if (objectInsert) { // usado quando faz reset
        const discountCtrl = this.initDiscount(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(discountCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedDiscount(value);
          });
        }
      }

      if (this.model && this.model.Discounts && this.model.Discounts.length > 0) {
        for (objectInsert of this.model.Discounts) {
          const discountCtrl = this.initDiscount(objectInsert);
          control.push(discountCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeDiscount(i: number) {
    const control = <UntypedFormArray>this.detailForm.controls['Discounts'];
    control.removeAt(i);
    // this.checkIfExemption();
  }

  /* tslint:disable:member-ordering */
  formErrorsDiscount: Array<string> = new Array<string>();
  formErrorsDiscountParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedDiscount(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsDiscount = new Array<string>();
    this.formErrorsDiscountParam = new Array<string>();
    for (const field in this.validationMessagesDiscount) {
      if (this.validationMessagesDiscount.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Discounts');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesDiscount[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsDiscount.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsDiscountParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsDiscountParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Discount

  // #region OtherReferences
  initOtherReference(reference: OrderReference = null) {
    if (reference) {
      return this.formBuilder.group({
        'ReferenceType': [reference.ReferenceType],
        'ReferenceValue': [reference.ReferenceValue],
        'ReferenceDate': [reference.ReferenceDate]
      });
    } else {
      return this.formBuilder.group({
        'ReferenceType': [''],
        'ReferenceValue': [''],
        'ReferenceDate': ['']
      });
    }
  }

  addOtherReference(newInsert: boolean, objectInsert: OrderReference = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      const refeCtrl = this.initOtherReference();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(refeCtrl);
      refeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedOtherReferences(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
      if (objectInsert) { // usado quando faz reset
        const refeCtrl = this.initOtherReference(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(refeCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedOtherReferences(value);
          });
        }
      }
      if (this.model && (<any>this.model).References && (<any>this.model).References.length > 0) {
        for (objectInsert of (<any>this.model).References) {
          const refeCtrl = this.initOtherReference(objectInsert);
          control.push(refeCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeOtherReference(i: number) {
    // se remover uma nota
    const control = <UntypedFormArray>this.detailForm.controls['OtherReferences'];
    control.removeAt(i);
  }

  /* tslint:disable:member-ordering */
  formErrorsOtherReferences: Array<string> = new Array<string>();
  formErrorsOtherReferencesParam: Array<string> = new Array<string>();

  /* tslint:enable:member-ordering */

  onValueChangedOtherReferences(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsOtherReferences = new Array<string>();
    this.formErrorsOtherReferencesParam = new Array<string>();
    for (const field in this.validationMessagesOtherReferences) {
      if (this.validationMessagesOtherReferences.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('OtherReferences');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesOtherReferences[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsOtherReferences.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsOtherReferencesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsOtherReferencesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion OtherReferences

  // #region Notes

  initOtherNotes(otherNotes: any[]) {
    let formArray: UntypedFormArray = this.formBuilder.array([]);

    // iniciar validacao do formulario de notas
    if (otherNotes && otherNotes.length > 0) {
      for (let i = 0; i < otherNotes.length; i++) {
        formArray.push(this.formBuilder.group({
          'NoteType': [otherNotes[i].NoteType],
          'NoteValue': [otherNotes[i].NoteValue]
        }));
      }
    }

    if (!this.allowEdit || !this.isEditable) { // colocar todos como disabled se não tem permissões de edição
      formArray.disable();
    }

    return formArray;
  }

  initNote(note: OrderNote = null) {
    // iniciar validacao do formulario de notsa
    if (note) {
      return this.formBuilder.group({
        'NoteType': [note.NoteType],
        'NoteValue': [note.NoteValue]
      });

    }
    return this.formBuilder.group({
      'NoteType': [''],
      'NoteValue': ['']
    });
  }

  addNote(newInsert: boolean, objectInsert: OrderNote = null) {
    if (newInsert) { // inserir uma linha em branco
      const control = <UntypedFormArray>this.detailForm.controls['Notes'];
      const noteCtrl = this.initNote();
      // if (!this.details_all) {
      //    taxCtrl.disable();
      // }
      control.push(noteCtrl);
      noteCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
        this.onValueChangedNotes(value);
      });
    } else {
      const control = <UntypedFormArray>this.detailForm.controls['Notes'];
      if (objectInsert) { // usado quando faz reset
        const noteCtrl = this.initNote(objectInsert);
        // if (!this.details_all) {
        //    taxCtrl.disable();
        // }
        control.push(noteCtrl);
        return;
      }
      if (control && control.controls) {
        for (let i = 0; control.controls.length > i; i++) {
          control.controls[i].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onValueChangedNotes(value);
          });
        }
      }
      if (this.model && this.model.Notes && this.model.Notes.length > 0) {
        for (objectInsert of this.model.Notes) {
          const noteCtrl = this.initNote(objectInsert);
          control.push(noteCtrl);
        }
      }
      // this.checkIfExemption(); // para saber se coloca motivo de isencao e se iva ja foi utilizado
    }
  }

  removeNote(i: number) {
    // se remover uma nota
    const control = <UntypedFormArray>this.detailForm.controls['Notes'];
    control.removeAt(i);
  }
  /* tslint:disable:member-ordering */
  formErrorsNotes: Array<string> = new Array<string>();

  formErrorsNotesParam: Array<string> = new Array<string>();


  /* tslint:enable:member-ordering */

  onValueChangedNotes(value?: any) {
    if (!this.detailForm) { return; }
    const form = this.detailForm;
    // clear previous error message (if any)
    this.formErrorsNotes = new Array<string>();
    this.formErrorsNotesParam = new Array<string>();
    for (const field in this.validationMessagesNotes) {
      if (this.validationMessagesNotes.hasOwnProperty(field)) {
        const controls = <UntypedFormArray>form.get('Notes');
        if (controls.controls) {
          for (let i = 0; i < controls.controls.length; i++) {
            const controlP = controls.controls[i];
            const control = controlP.get(field);

            if ((this.submitted && (control && !control.valid && control.enabled)) ||
              (!this.submitted && (control && control.dirty && !control.valid))) {
              this.noError = false;
              const messages = this.validationMessagesNotes[field];
              for (const key in control.errors) {
                if (messages.hasOwnProperty(key)) {

                  this.formErrorsNotes.push(messages[key]);

                  let param = 'params';
                  if (control.errors.hasOwnProperty(param)) {
                    this.formErrorsNotesParam.push(JSON.parse(control.errors[param]));
                  } else {
                    this.formErrorsNotesParam.push('');
                  }
                  control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
                }
              }
            }
          }
        }
      }
    }
  }
  // #endregion Notes

  checkSupplier(): void {

    let index = this.providers.findIndex((r: any) => r.InternalID + '' === this.detailForm.controls['SupplierIdentifier'].value + '');
    if (index > -1) {
      this.detailForm.controls['SupplierID'].setValue(this.providers[index].ID);
      this.detailForm.controls['SupplierName'].setValue(this.providers[index].Name);
    } else {
      if (this.detailForm.controls['SupplierIdentifier'].value !== '') {
        Functions.gritter(this.translateValueService.get('PROVIDER_IDENTIFIER_NOT_FOUND', { value: this.detailForm.controls['SupplierIdentifier'].value }), 'danger');
      }
      this.detailForm.controls['SupplierIdentifier'].setValue('');
      this.detailForm.controls['SupplierName'].setValue('');
      this.detailForm.controls['SupplierID'].setValue('');
    }
  }

  // #region Métodos Gerais e Comuns
  resetForm() { // reset do form (quando faz cancelar mantém valores da BD + criados não guardados)
    if (this.model) {
      this.detailForm.reset(this.model);

      // colocar os arrays com os valores iniciais
      // taxas
      const controlsT = <UntypedFormArray>this.detailForm.get('Taxes');
      while (controlsT.length) { // remover todos(as)
        controlsT.removeAt(controlsT.length - 1);
      }
      if (this.model.Taxes != null) { // adicionar
        this.model.Taxes.forEach((tax: OrderTax) => {
          this.addTaxe(false, tax);
        });
      }

      // abonos
      const controlsA = <UntypedFormArray>this.detailForm.get('Allowances');
      while (controlsA.length) { // remover todos(as)
        controlsA.removeAt(controlsA.length - 1);
      }
      if (this.model.Allowances != null) { // adicionar
        this.model.Allowances.forEach((allowance: OrderAllowance) => {
          this.addAllowance(false, allowance);
        });
      }

      // descontos
      const controlsD = <UntypedFormArray>this.detailForm.get('Discounts');
      while (controlsD.length) { // remover todos(as)
        controlsD.removeAt(controlsD.length - 1);
      }
      if (this.model.Discounts != null) { // adicionar
        this.model.Discounts.forEach((discount: OrderDiscount) => {
          this.addDiscount(false, discount);
        });
      }

      // outras referências
      const controlsO = <UntypedFormArray>this.detailForm.get('OtherReferences');
      while (controlsO.length) { // remover todos(as)
        controlsO.removeAt(controlsO.length - 1);
      }
      if (this.model.OtherReferences != null) { // adicionar
        this.model.OtherReferences.forEach((reference: OrderReference) => {
          this.addOtherReference(false, reference);
        });
      }

      // notas
      const controlsN = <UntypedFormArray>this.detailForm.get('Notes');
      while (controlsN.length) { // remover todos(as)
        controlsN.removeAt(controlsN.length - 1);
      }
      if (this.model.Notes != null) { // adicionar
        this.model.Notes.forEach((note: OrderNote) => {
          this.addNote(false, note);
        });
      }
    }
  }
  // #endregion Métodos Gerais e Comuns

  //Quando se muda o tipo de Produto/Serviço
  onChangeAccountProductType(value: number) {
    this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
    this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
    let tipoTratamentoIVA = this.detailForm.controls['VatTreatmentType'].value;

    //se foi selecionado algum tipo de produto/serviço e um tipo de tratameno de iva
    if (value != null && tipoTratamentoIVA != null) {
      //temos de ir buscar os subtipos para estes dois tipos
      this.getChildsList(value, tipoTratamentoIVA);
    }
  }

  //Quando se muda o tipo de tratamento de IVA
  onChangeVatTreatmentType($event: any) {
    this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
    this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
    let accountProductType = this.detailForm.controls['AccountProductType'].value;

    //se foi selecionado algum tipo de produto/serviço e um tipo de tratameno de iva
    if ($event.value != null && accountProductType != null) {
      //vamos buscar a lista de subtipos com base nos dois tipos selecionados
      this.getChildsList(accountProductType, $event.value);
    }
  }

  getChildsList(parentID: number, tipoTratamentoIVA: string) {
    let accountProductSubtype: number = 0;
    let accountProductSubtypeName: string = null;
    let accountProductSubtypeEntity: TipologiaProdutoServico = null;

    //vamos buscar a lista de subtipos consoante o pai e o tipo de tratamento de inva selecionados
    this.orderService.GetChildsList(parentID, tipoTratamentoIVA).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.ReturnStatus.Successfull && response.ReturnStatus.ReturnObject != null) {
        this.accountProductSubTypes = response.ReturnStatus.ReturnObject;
        //se a lista de subtipos for diferente de null ou undefined
        if (this.accountProductSubTypes) {
          //vamos obter o id do subtipo (filho)
          accountProductSubtype = this.detailForm.controls['TipologiaProdutoServicoID'].value ? this.detailForm.controls['TipologiaProdutoServicoID'].value : 0;
          //vamos tentar obter a entidade através do id do filho
          accountProductSubtypeEntity = this.accountProductSubTypes.find(x => x.ID === accountProductSubtype);
          //se for diferente de null
          if (accountProductSubtypeEntity) {
            //obtemos o nome (descrição) do subtipo
            accountProductSubtypeName = accountProductSubtypeEntity.Name;
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(accountProductSubtypeName ? accountProductSubtypeName : null);
          }
        }
      }
    });
  }

  setAccountProductType() {

    this.translateService.get(['SELECT_ACCOUNT_PRODUCT_TYPE', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.accountProductTypes, null, response['SELECT_ACCOUNT_PRODUCT_TYPE'], aoColumns, columnDefs, null, 1, null, null, null, true),
          disableClose: false, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          let resultID = parseInt(result, null);
          if ((resultID != null || resultID.toString().length > 0) && resultID > 0) {
            let index = this.accountProductTypes.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['AccountProductType'].setValue(this.accountProductTypes[index].ID);
            this.detailForm.controls['AccountProductTypeDescription'].setValue(this.accountProductTypes[index].Name);
            this.onChangeAccountProductType(this.accountProductTypes[index].ID);
          } else if (resultID != null && resultID === -1) {
            this.detailForm.controls['AccountProductType'].setValue(null);
            this.detailForm.controls['AccountProductTypeDescription'].setValue(null);
            this.onChangeAccountProductType(null);
          }
        }
      });

    });
  }


  setAccountProductSubtype() {
    this.translateService.get(['SELECT_ACCOUNT_PRODUCT_SUBTYPE', 'CODE', 'NAME']).subscribe(response => {
      let aoColumns = [
        { 'data': 'ID' }, // 0
        { 'data': 'IntegrationID', 'class': 'verticalMiddle', 'title': response['CODE'], }, // 1 - código
        { 'data': 'Name', 'class': 'verticalMiddle', 'title': response['NAME'] }, // 2 - nome
      ];

      let columnDefs = [
        { 'targets': [0], 'visible': false }, // colocar como hidden
        { 'targets': [-1], 'orderable': false }, // nao permitir ordenar pelas colunas
      ],

        dialogRef = this.dialog.open(ChooseModalComponent, {
          data: // dados que vai enviar para o componente da modal
            new ChooseModalParam(this.accountProductSubTypes, null, response['SELECT_ACCOUNT_PRODUCT_SUBTYPE'], aoColumns, columnDefs, null, 1, null, null, null, true),
          disableClose: false, // nao permitir fechar modal com escape ou clique fora
        });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result != null) {
          let resultID = parseInt(result, null);
          if ((resultID != null || resultID.toString().length > 0) && resultID > 0) {
            let index = this.accountProductSubTypes.findIndex((r: any) => r.ID === resultID);
            this.detailForm.controls['AccountProductSubtype'].setValue(this.accountProductSubTypes[index].ID);
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(this.accountProductSubTypes[index].Name);
            this.detailForm.controls['TipologiaProdutoServicoID'].setValue(this.accountProductSubTypes[index].ID);
          } else if (resultID != null && resultID === -1) {
            this.detailForm.controls['AccountProductSubtype'].setValue(null);
            this.detailForm.controls['AccountProductSubtypeDescription'].setValue(null);
            this.detailForm.controls['TipologiaProdutoServicoID'].setValue(null);
          }
        }
      });

    });
  }

  ngOnDestroy() { }
}
