// bibliotecas
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormGroup } from '@angular/forms';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { DigitalArchiveTabModalComponent } from './digArc-modal.component';
import { TranslateValueService } from 'src/app/services/translate-value.service';


// servicos
import { DigitalArchiveService } from '../../../services/digitalArchive.service';
import { AuthenticationService } from '../../../services/authentication.service';

// modelos
import { ReturnStatusHtml } from '../../../models/returnStatus';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

// constantes
import { ALIAS, LANGUAGE } from '../../../constants/global';

// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions, FileTreatmentFunctions } from '../../../modules/treatments.module';
import { DateTimePickerDirective } from 'src/app/directives/datepicker.directive';
import { functions } from 'lodash';
import { Subject } from 'rxjs';
import { GenericType } from 'src/app/models/generic-type';
import { outputAst } from '@angular/compiler';
import { RepositoryDiskFile } from 'src/app/models/repository-disk-file';

declare var Functions: any;

@Component({
  selector: 'app-digarc-tab',
  templateUrl: './digArc.html'
})
export class DigitalArchiveTabComponent implements OnInit, OnDestroy, OnChanges {

  @Input() context: string = null; // Context
  @Input() contextID: number = null; // EntityID
  @Input() contextStateID: number = null; // ID do Estado da Entidade
  @Input() contextGenericType: string = null; // Se dif de null, é porque o tipo de documentos (genericType) é algo especifico se nao vai buscar do tipo 'RepositoryDiskFiles'
  @Input() allowDelete: boolean = false; // se vai permitir que o utilizador que criou elimine as mensagens
  @Input() allowCreate: boolean = false; // se vai permitir criar novas mensagens
  @Input() allowDeleteByAllUsers: boolean = false; // qualquer utilizador tem previlegios para eliminar ficheiros e não apenas o que o carregou
  @Input() noSession: boolean = false; // Se é um ecrã sem sessão
  @Input() showCertificateButton: boolean = false; // mostrar botões de certificação de pdf. 
  @Input() enableManageAttaches: boolean = true;
  @Input() showIsPublic: boolean = false;
  @Input() showExpiredDate: boolean = false;
  @Input() limitNumberOfFiles: boolean = false;
  @Input() additionalContexts: any[] = []; //Array de objetos que armazena os contextos adicionais a pesquisar (composto por contexto e referenceID)
  @Input() tableIndex: number = 1; //Index que permite distinguir a instância do componente
  @Input() maxFiles: number = null; // numero maximo de ficheiros permitidos
  @Input() allowedFileTypes: Array<string> = []; // tipos de ficheiros aceites
  @Input() allowedFileSize: number = 0 //tamanho do total de ficheiros permitido por registo 
  @Input() totalFilesSize: number = 0 //tamanho total usado de ficheirod por registo
  @Input() hideDescription: boolean = false; // se é para esconder a descrição
  @Input() hideType: boolean = false; // se é para esconder o tipo
  @Input() listFilesFromQuotation: any[] = []; // ficheiros da cotação
  @Input() validateDuplicatedFileTypes: Array<string> = []; // caso seja para verificar se o tipo de ficheiros é repetido
  @Input() onFormSave: boolean = false;//Guardar ficheiros apenas quando se fizer submit do form

  @Output() afterAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() addFile: EventEmitter<File> = new EventEmitter<File>();
  @Output() removeFile: EventEmitter<number> = new EventEmitter<number>();

  listFiles: any[] = [];
  /*
  users: any = null; // para utilizar caso a mensagem se destine apenas a um utilizador
  */
  tableFiles: any = null;

  translateValues: Array<string> = null;
  filesTable: RepositoryDiskFile[] = []
  form: UntypedFormGroup;
  noError: boolean = true; // saber se o formulario tem erros

  tableID: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private _translateService: TranslateService,
    private _authenticationService: AuthenticationService,
    private _digArcService: DigitalArchiveService,
    private _dialog: MatDialog,
    private _errorTreat: ErrorTreatmentFunctions,
    private _fileTreat: FileTreatmentFunctions,
    private translateValueService: TranslateValueService,
    ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listFilesFromQuotation']) {
      if(this.listFilesFromQuotation.length > 0)
      {
        this.tableFiles.clear(); // limpar a tabela
        this.tableFiles.rows.add(this.listFilesFromQuotation).draw(); // preencher tabela com novos dados
        this.listFiles = this.listFilesFromQuotation;

      }
    }
  }


  ngOnInit() {
    this._translateService.get(['BUTTON.DELETE', 'DOWNLOAD', 'BUTTON.SIGN', 'SERVER_TIMEOUT', 'SERVER_ERROR', 'PERMISSIONS_ERROR', 'OCCURRED_ERROR', 'DELETE_ATTACH_ERROR']).subscribe((response: string[]) => {
      this.translateValues = response;

      let that = this;

      this.tableID = 'tableFiles-' + this.tableIndex; //Cria o ID da Tabela de anexos
      
      this._digArcService.getAll(this.context, this.contextID, this.noSession, this.showIsPublic, 
        (this.additionalContexts.length == 0 ? null : this.additionalContexts)).pipe(takeUntil(this.destroy$)).subscribe((responseService: ReturnStatusHtml) => {
        if (responseService.ReturnStatus.Successfull) {
          
          that.listFiles = responseService.ReturnStatus.ReturnObject.RepositoryDiskFiles;

          that.listFiles.forEach(file => {
            if (file.Size) {
              that.totalFilesSize += file.Size;
            }
          });

          //Apenas para a Altice que quer o campo descrição para a sua Referencia de Arquivos Fisicos.
          if (this.context == "AlticeFine") {
            that.listFiles.forEach(element => { 
              element.Description != null ? element.Description = "Ref.Arquivo: " + element.Description : "";
            });
          }

          //transforma a password em pontos
          if (this.context == "MyCompany") {
            that.listFiles.forEach(element => {
              if (element.DiskFileTypeID == 184 && element.Description != null) {
                let dots: string = "";
                for (let index = 0; index < element.Description.length; index++) {
                  dots = dots + "•"
                }
                element.Description = "Password: " + dots;
              }
            });
          }



          let buttons = function (id: string, filename: string, createdBy?: number, typeID?: number, FileType?: string): string { // o que vai ser inserido na coluna de botoes
            let aux = '';
            let idUser = that._authenticationService.session.user ? that._authenticationService.session.user.ID : null;
            let isAdmin = that._authenticationService.session.roles ? that._authenticationService.session.roles.indexOf(1) !== -1 : false;
            let isCentralCompras = that._authenticationService.session.roles ? that._authenticationService.session.roles.indexOf(211) !== -1 : false;
            let isSCCORCertificador = that._authenticationService.session.roles ? that._authenticationService.session.roles.indexOf(408) !== -1 : false;

            /* GM 2020-10-26 AlticeFine
            Qualquer perfil deve poder eliminar anexos (independentemente de quem anexou) excepto se:
            - 419-Estado Arquivado nunca pode ser eliminado  
            - 150-Tipo Auto de Contraordenação
                Apenas pode ser eliminado nos estados: 
                  - 400-Receção Documentos
                  - 420-Receção Documentos Cliente
                  - 421-Doc. Completo 
                  - 405-Controlo de Qualidade e Adm
            - Tipos: 
              - 157-Decisão Condenatória (minuta), 
              - 158-Decisão Absolutória (minuta), 
              - 161-Diligência de Prova (minuta) 
              - 168-Questionário de Audição de Testemunhas 
              Apenas pode ser eliminado nos estados:
                - 404-Análise Administrativa
                - 406-Análise Jurídica
                - 409-Diligência de Prova
                - 410-Audição de Testemunhas
            */
            let allowEditByStateAndFileType = true;
            if (that.contextStateID) {             

              //DS: 26/01/2022

              // 1. Em qualquer estado, o utilizador pode eliminar anexos que adicionou, com exceção do estado Arquivado (em que não é possível eliminar nenhum tipo de anexo)

              // 2.  Nestes estados, qualquer utilizador pode eliminar qualquer anexo
              // Receção Documentos
              // Controlo de Qualidade e Adm.
              // Receção Documentos Cliente
              // Doc. Completo

              // 3. Nestes estados, qualquer utilizador pode eliminar anexos do tipo Decisão Condenatória (minuta), Decisão Absolutória (minuta), Diligência de Prova (minuta) e Questionário de Audição de Testemunhas 
              // Análise Administrativa
              // Análise Jurídica
              // Diligência de Prova
              // Audição de Testemunhas

              // Qualquer utilizador pode ...
              const podeEliminarNestesEstados: number[] = [400, 420, 421, 405];
              const podeEliminarNestesEstadosAnexosDoTipo: number[] = [404, 406, 409, 410];
              const anexosDoTipo: number[] = [157, 158, 161, 168];

              //419 - Arquivado
              if (that.contextStateID === 419) {
                allowEditByStateAndFileType = false;
              } else if (podeEliminarNestesEstados.filter(x => x === that.contextStateID).length > 0) {
                that.allowDeleteByAllUsers = true;
              } else if (podeEliminarNestesEstadosAnexosDoTipo.filter(x => x === that.contextStateID).length > 0) {
                if (anexosDoTipo.filter(x => x === typeID).length > 0) {
                  that.allowDeleteByAllUsers = true;
                } else {
                  that.allowDeleteByAllUsers = false;
                }
              } else {
                if (createdBy != null && createdBy === idUser) {
                  allowEditByStateAndFileType = true;
                } else {
                  allowEditByStateAndFileType = false;
                }
              }

              //Para testar
              //console.log("O estado é: "+that.contextStateID+" O tipo é: "+typeID+" o utilizador que criou é: "+createdBy);

            }

            if (that.allowDelete
              && allowEditByStateAndFileType
              && (
                (createdBy != null && createdBy === idUser)
                || isAdmin
                || isCentralCompras
                || that.allowDeleteByAllUsers
                || (that.noSession && createdBy === null)
              )
              && that.enableManageAttaches
            ) {
              // só aparece o botao de apagar se forem enviadas as permissoes de apagar
              // além disso o utilizador tem de ser administrador ou quem criou o ficheiro (se nao houver criador é só o admin que pode apagar)
              if(typeID != GenericType.EXPENSE_SCAN)
              {
                aux += '<a class="btn btn-sm table-delete_arqDig" data-atrrid="' + id + '"><span title="' + that.translateValues['BUTTON.DELETE'] + '"><i class="far fa-trash-alt color-danger backwhite"></i></span></a>';
              }
            }

            //Altice
            // Só aparece o botao de certificar pdf se: 
            // Contexto = "AlticeFine" // Tipo de ficheiro for pdf
            // Estado do processo estiver em: 

            //411 - Decisão ou DP em Validação Cliente.
            //431 - ECC Validação Cliente.
            //427 - IJ Validação Cliente.

            // O Documento for um destes 3 tipos:
            //-157 Decisão Condenatória (minuta)
            //-158 Decisão Absolutória (minuta)
            //-161 Diligência de Prova (minuta)
            
            if ((that.context == "AlticeFine" && FileType == "pdf" && isSCCORCertificador) && 
            (that.contextStateID === 411 || that.contextStateID === 431 || that.contextStateID === 427 ) && 
            (typeID == 157 || typeID == 158 || typeID == 161 || typeID == 188)) {
              aux += '<a class="btn btn-sm table-sign_arqDig" data-atrrid="' + id + '"><span title="' + that.translateValues['BUTTON.SIGN'] + '"><i class="fas fa-file-signature"></i></span></a>';
            }

            aux += '<a class="btn btn-sm table-download_arqDig" data-atrrid="' + id + '" download="' + filename + '"><span title="' + that.translateValues['DOWNLOAD'] + '"><i class="fas fa-download color-success backwhite"></i></span></a>';

            return aux;
          };

          let translates = {
            serverTimeout: that.translateValues['SERVER_TIMEOUT'],
            serverError: that.translateValues['SERVER_ERROR']
          }, aoColumnsMsg = [
            { 'data': 'OriginalFileName', 'class': 'verticalMiddle'}, // 0
            { 'data': 'GenericType', 'class': 'verticalMiddle','visible': !that.hideType }, // 1
            { 'data': 'Description', 'class': 'verticalMiddle','visible': !that.hideDescription }, // 2
            {
              'data': 'CreationDate', 'class': 'verticalMiddle', 'render': function (data: any, type: string, full: any, meta: any) {
                return DateTimePickerDirective.convertToString(data, true);
              }
            },
            {
              'data': 'IsPublic', 'class': 'verticalMiddle text-center', 'visible': that.showIsPublic, 'render': function (data: any, type: string, full: any, meta: any) {
                return '<div class="md-checkbox" style="height: 17px"><input id="' + full.ID + '" type="checkbox" class="tableFiles-isPublic"' + (data ? 'checked' : '') + ' disabled ><label for="' + full.ID + '"></label></div>';
              }
            },
            { 'data': 'CreatedBy', 'class': 'verticalMiddle' }, // 3
            { 'data': 'DiskFileTypeID', 'class': 'verticalMiddle' }, // 3
            { 'data': 'ID', 'class': 'verticalMiddle text-center', 'render': function (data: any, type: string, full: any, meta: any) { return buttons(data, full.OriginalFileName, full.CreatedBy, full.DiskFileTypeID, full.FileType); } } // 4
          ],
            columnDefsMsg = [
              { 'targets': [-2, -3], 'visible': false }, // colocar como hidden o que vier do ficheiro de configuracoes
              { 'targets': [-1, -2, -3], 'orderable': false }, // nao permitir ordenar pelas colunas
              { 'targets': [-1], 'width': '80px' }, // colocar a ultima coluna mais pequena
            ];
          
          
          that.tableFiles = Functions.datatablesWithDataSet('#' + that.tableID, that.listFiles, translates, aoColumnsMsg, columnDefsMsg,
            ALIAS + 'assets/resources/datatables-' + LANGUAGE + '.json', 1, false, false, false);
          
          that.tableFiles.on('draw', function () { // accao dos botoes
            // quando carrega para eliminar linha
            let btnsDelete = document.getElementsByClassName('table-delete_arqDig');
            for (let btnDelete of Array.from(btnsDelete)) {
              $(btnDelete).off('click');
              $(btnDelete).on('click', function (ev: any) {
                that.onRemoveFile(+$(this).attr('data-atrrid'));
              });
            }
            // quando carrega para fazer o download da linha
            let btnsDownload = document.getElementsByClassName('table-download_arqDig');
            for (let btnDownload of Array.from(btnsDownload)) {
              $(btnDownload).off('click');
              $(btnDownload).on('click', function (ev: any) {
                that.onDownloadFile(+$(this).attr('data-atrrid'), $(this).attr('download'));
              });
            }
            let btnsSign = document.getElementsByClassName('table-sign_arqDig');
            for (let btnSign of Array.from(btnsSign)) {
              $(btnSign).off('click');
              $(btnSign).on('click', function (ev: any) {
                that.onSignPdf(+$(this).attr('data-atrrid'));
              });
            }
          });
          this.updateAttachmentsCounter();
        } else { // o que acontece se der erro
          this._errorTreat.treatErrorResponse(responseService);
        }
        

      }); // fim do pedido do servico

    }); // fim do translate
  }

  onRemoveFile(id: number) {
    // abre a modal para pedir a confirmação
    let dialogRef = this._dialog.open(DeleteModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) { // confirmou que quer eliminar

        if (this.onFormSave && this.contextID == 0) {
          let index: number = this.filesTable.map(function (f: any) { return f.ID; }).indexOf(Number(id));
          this.filesTable.splice(index , 1);
          this.tableFiles.clear(); // limpar a tabela
          this.tableFiles.rows.add(this.filesTable).draw(); // preencher tabela com novos dados
          this.removeFile.emit(index);
          this.updateAttachmentsCounter();
        } else {
          // chamar o servico para eliminar
            this._digArcService.delete(id, this.noSession).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
              if (response.ReturnStatus.Successfull) {
                // retirar da lista de ficheiros
                let index: number = this.listFiles.map(function (f: any) { return f.ID; }).indexOf(Number(id));
                if (index !== -1) {
                  this.totalFilesSize -= this.listFiles[index].Size;
                  this.listFiles.splice(index, 1);
                }
                this.tableFiles.clear(); // limpar a tabela
                this.tableFiles.rows.add(this.listFiles).draw(); // preencher tabela com novos dados
                this.afterAction.emit(response.ReturnStatus.ReturnObject);
                this.updateAttachmentsCounter();
                Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
              } else {
                this._errorTreat.treatErrorResponse(response);
              }
            });
        }
      }
    });
  }

  /**
   * Fazer o download do ficheiro
   * @param  {number} id
   * @param  {string} filename
   */
  onDownloadFile(id: number, filename: string) {
    return this._digArcService.get(id, this.noSession).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if(filename.substr(filename.lastIndexOf('.')+1) == 'pdf'){
        const fileURL = URL.createObjectURL(response);
        window.open(fileURL, '_blank');
      }
      else{
        this._fileTreat.afterDownloadFile(response, filename);
      }
    });
  }

  onSignPdf(id: number) {
    //TODO FILTRAR POR ID DO CLIENTE
    return this._digArcService.CertificatePDF(id).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.ReturnStatus.Successfull) {
        Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');

        this._digArcService.getAll(this.context, this.contextID, this.noSession, null, (this.additionalContexts.length == 0 ? null : this.additionalContexts)).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {

          this.listFiles = response.ReturnStatus.ReturnObject.RepositoryDiskFiles;
          this.tableFiles.clear(); // limpar a tabela
          this.tableFiles.rows.add(this.listFiles).draw(); // preencher tabela com novos dados
          //this.afterAction.emit(models[0]);
        });

      } else {
        Functions.gritter(response.ReturnStatus.ErrorMessage, 'danger');
      }

    });
  }

  /**
   * Carrega no botao para adicionar novo ficheiro
   */
  addFileOpen() {
    if (!this.allowCreate) { // valida se permite a criação
      Functions.gritter(this.translateValues['PERMISSIONS_ERROR'], 'danger');
      return;
    }
    // abrir modal de preenchimento do ficheiro
    let objectParam = {
      width: '600px',
      data: {
        entityContext: this.context,
        entityID: this.contextID,
        noSession: this.noSession,
        showIsPublic: this.showIsPublic,
        showExpiredDate: this.showExpiredDate,
        allowedFileTypes: this.allowedFileTypes,
        allowedFileSize: this.allowedFileSize,
        totalFilesSize: this.totalFilesSize,
        hideDescription: this.hideDescription,
        hideType: this.hideType,
        validateDuplicatedFileTypes: this.validateDuplicatedFileTypes,
        onFormSave:this.onFormSave
      }
    };

    if (this.contextGenericType) {
      objectParam.data['context'] = this.contextGenericType;
    }

    let dialogRef = this._dialog.open(DigitalArchiveTabModalComponent, objectParam);
    let that = this;
    dialogRef.afterClosed().subscribe(result => { // o que acontece quando fecha a modal
      if (this.onFormSave && this.contextID == 0 && result && result.models && result.models.length != 0) {
        //Criar Array de Repositories Sem IDs
        //For Each sobre todos os Ficheiros

        result.models.forEach(file => {
          let i: number = this.filesTable.length;
          let repoFile : any = 
          {
            ByteContent: null,
            Context: this.context,
            CreatedBy: this._authenticationService.session.user.ID,
            CreationDate: file.CreationDate,
            Description: file.Description ? file.Description : '',
            DiskFileTypeID: file.DiskFileTypeID ? file.DiskFileTypeID : '',
            EntityID: 0,
            ExpiredDate: null,
            FileContent: null,
            FileType: file.FileType,
            GenericType: file.ContextDescription ? file.ContextDescription : '',
            ID: i+1,
            IsPublic: file.IsPublic,
            OriginalFileName: file.OriginalFileName,
            State: null,
            Size: file.Size
          }
          this.totalFilesSize += repoFile.Size;
          this.filesTable.push(repoFile)
        });
        //Preencher a Entidade RepositoryDiskFiles para que seja apresentado na table
        that.tableFiles.clear();
        that.tableFiles.rows.add(this.filesTable).draw();
        this.updateAttachmentsCounter();
        Functions.gritter('File uploaded successfully', 'success');
        //Envia os Ficheiros carregados para o Componente Pai, para serem tratados la
        this.addFile.emit(result);
      } else {
        if (result && result.constructor && result.constructor.name === 'FormData') { // existem dados para inserir
          // // adicionar contexto
          // result.append('Context', this.context);
          // // id da entidade
          // result.append('EntityID', this.contextID);

          let models = JSON.parse(result.get('models'));
          let errorDuplicatedFileTypes = false;

          if(this.validateDuplicatedFileTypes.length > 0)
          {
            if(this.validateDuplicatedFileTypes.includes(""+GenericType.FISCAL_RESIDENCY_CERTIFICATE) && this.validateDuplicatedFileTypes.includes(""+GenericType.RFI_DOCUMENT))
            {
              if(that.listFiles.length > 0)
              {
                that.listFiles.forEach(file => {

                  // Verificar se o tipo de ficheiro inserido já se encontra submetido para mandar alerta de duplicado
      
                  models.forEach(model => {
                    if((file.DiskFileTypeID == GenericType.FISCAL_RESIDENCY_CERTIFICATE && file.DiskFileTypeID == model.DiskFileTypeID) || (file.DiskFileTypeID == GenericType.RFI_DOCUMENT && file.DiskFileTypeID == model.DiskFileTypeID))
                    {
                      errorDuplicatedFileTypes = true;
                    }
                  });
      
                });
              }
              //Verificar se ao submeter dois/ou mais ficheiros se são ambos 403 ou 404
              if(models.length > 1)
              {
                //Como os ficheiros que se inserem são sempre do mesmo tipo, só preciso de validar um ficheiro
                if(models[0].DiskFileTypeID == GenericType.FISCAL_RESIDENCY_CERTIFICATE || models[0].DiskFileTypeID == GenericType.RFI_DOCUMENT)
                {
                  errorDuplicatedFileTypes = true;
                }
              }
              //Enviar mensagem de erro caso um dos ficheiros duplicados sejam do tipo definido
              if(errorDuplicatedFileTypes)
              {
                Functions.gritter(this.translateValueService.get('DUPLICATED_RFI_OR_TAX_RESIDENCY_CERTIFICATE'), 'danger');
                return;
              }
            }
          }

          if(this.additionalContexts.length > 0){
            result.append("additionalContexts", JSON.stringify(this.additionalContexts));
          }

  
          this._digArcService.add(result).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
            if (response.ReturnStatus.Successfull) {
              if (response.ReturnStatus.ReturnObject) {
                that.listFiles = response.ReturnStatus.ReturnObject.Files;
                that.tableFiles.clear(); // limpar a tabela
  
                //Altice Overwrite data
                if (this.context == "MyCompany") {
                  that.listFiles.forEach(element => {
                    if (element.DiskFileTypeID == 184 && element.Description != null) {
                      let dots: string = "";
                      for (let index = 0; index < element.Description.length; index++) {
                        dots = dots + "•"
                      }
                      element.Description = "Password: " + dots;
                    }
                  });
                }
  
                that.tableFiles.rows.add(that.listFiles).draw(); // preencher tabela com novos dados
                models.forEach(element => {
                  this.totalFilesSize += element.Size;
                });
                this.afterAction.emit(models[0]);        
              }
              Functions.gritter(response.ReturnStatus.SuccessMessage, 'success');
              this.updateAttachmentsCounter();
            } else {
              this._errorTreat.treatErrorResponse(response);
            }
          });
        }
      }
    });
  }

  /**
   * Atualizar o contador de anexos
   */
  updateAttachmentsCounter() {
    let element = document.getElementById('AttachmentsTitle');

    // Verifica se é para colocar contador de mensagens
    if (element) {
      // Limpa o contador
      // element.innerText = element.innerText.split('(')[0].trim();
      element.innerHTML = element.innerHTML.split(' <span class="badge">')[0];

      // Adiciona o contador se existirem mensagens
      if (this.listFiles.length > 0) {
        // element.innerText = element.innerText + ' (' + this.listAttachments.length + ')';
        element.innerHTML = element.innerHTML + ' <span class="badge">' + this.listFiles.length + '</span>';
      }
    }
  }

  ngOnDestroy(): void {
    if(this.tableFiles){
      this.tableFiles.destroy();
    }
  }

}
