import { DecimalPipe } from "@angular/common";
import {
  Component,
  OnInit,
  Inject,
  NgZone,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
} from "@angular/forms";
import { MatLegacyDialog, MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { TranslateService } from "@ngx-translate/core";
import { Expense } from "src/app/models/expenseNotes";
import { ErrorTreatmentFunctions, FileTreatmentFunctions } from "src/app/modules/treatments.module";

// ***__***_________  VARIAVEIS GLOBAIS _________ ***__***
import {
  UNITARY_PRICE_DECIMAL,
  CURRENCY_DECIMAL,
  CURRENCY_SYMBOL,
  QUANTITY_DECIMAL,
} from "../../constants/global";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { ChooseModalComponent } from "../shared/choose-modal/choose-modal.component";
import { ChooseModalParam } from "src/app/models/choose-modal-param";
import { TranslateValueService } from "src/app/services/translate-value.service";
import { ValueAddedTax } from "src/app/models/value-added-tax";
import { UnitOfMeasure } from "src/app/models/unit-of-measure";
import { FileItem, FileUploader } from "ng2-file-upload";
import { DigitalArchiveService } from "src/app/services/digitalArchive.service";
import { Subject } from "rxjs";

declare var Functions: any;

@Component({
  templateUrl: "./expense-modal.component.html",
})
export class ExpenseModalComponent implements OnInit, OnDestroy {
  public expenseForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  formDisabled: boolean; // para saber se os detalhes sales se podem editar ou sao so de consulta
  submitted: boolean = false;

  allowEdit: boolean = false; // se tem permissoes de edicao
  model: Expense = null; // valores inseridos na bd
  showArqTab_open: boolean = false;

  expenseValueAddedTaxes: Array<ValueAddedTax> = new Array<ValueAddedTax>();
  expenseValueAddedTaxes_all: Array<ValueAddedTax> = new Array<ValueAddedTax>();

  expenseUnMeasureG: Array<UnitOfMeasure>;

  uploader: FileUploader = new FileUploader({});

  _cur_symbol: string = CURRENCY_SYMBOL;

  private _decimalPipe: DecimalPipe;

  //#region Expenses Arrays
  expensesTypes: Array<any>;
  expensesStates: Array<any>;
  suppliersList: Array<any>; //Armazena o Array de Fornecedores
  //#endregion Expenses Arrays
  currentLanguage: any;
  UnitPrice_Form: number = 2;
  currentTaxesArrayKeyValue = [];
  errorDuplicateTaxes: boolean = false;
  validationMessagesExpenses: any;
  fieldNamesExpenses: any;
  parameterValuesExpenses: any;

  constructor(
    public _dialogRef: MatLegacyDialogRef<ExpenseModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) data: any,
    private _translateService: TranslateService,
    private translateValueService: TranslateValueService,
    private _dialog: MatLegacyDialog,
    private _digArcService: DigitalArchiveService,
    private _fileTreat: FileTreatmentFunctions
  ) {
    this._decimalPipe = new DecimalPipe(_translateService.currentLang);
    this.currentLanguage = translateValueService.translateLanguage;

    // ir buscar os dados que sao enviados no open da model
    this.expenseForm = data.expenseForm;
    this.model = data.model;
    this.allowEdit = data.allowEdit;
    this._cur_symbol = data._cur_symbol;
    this.formDisabled = data.formDisabled;
    this.expensesStates = data.expensesStates;
    this.expensesTypes = data.expensesTypes;
    this.suppliersList = data.suppliersList;
    this.expenseValueAddedTaxes = data.expenseValueAddedTaxes;
    this.expenseValueAddedTaxes_all = data.expenseValueAddedTaxes_all;
    this.expenseUnMeasureG = data.expenseUnMeasureG;
    this.fieldNamesExpenses = data.fieldNamesExpenses;
    this.parameterValuesExpenses = data.parameterValuesExpenses;

    // Se o formulário não for editável, então os detalhes também não são
    if (this.formDisabled) {
      this.expenseForm.disable();
    }
  }

  ngOnInit(): void {
    let that = this;

    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 27) {
        // escape
        that._dialogRef.close(null);
      }
    });
  }

  save() {
    this.expenseForm.markAllAsTouched();
    if (this.expenseForm.valid) {
      if (this.allowEdit) {
        // verificar se permissoes
        this.submitted = true;

        if (this.expenseForm.get("Quantity").value) {
          if (this.expenseForm.valid) {
            this._dialogRef.close({expenseForm: this.expenseForm}); // nao pode enviar so o value porcausa dos campos disabled
          }
          this.submitted = false;
        } else {
          this._dialogRef.close({expenseForm: this.expenseForm});
        }
      }
    } 
  }

  
  resetForm() {
    this.expenseForm.reset(this.model);
    let initialValues = null;
    // colocar os arrays com os valores iniciais

    // -Impostos (Taxas)
    const controlsT = <FormArray>this.expenseForm.get("Taxes");
    // remover todos
    while (controlsT.length) {
      controlsT.removeAt(controlsT.length - 1);
    }
  }

  formatCurrency(value: number) {
    // colocar em formato de moeda ( separacao de milheres e casas decimais de acordo com o ficheiro de conf)
    return this._decimalPipe.transform(
      value,
      "1." + CURRENCY_DECIMAL + "-" + CURRENCY_DECIMAL
    );
  }

  addToFormField(
    add: boolean,
    control: AbstractControl,
    addValue: number,
    decimalType: number
  ) {
    // add- se é para adicionar ao valor que o form ja tem/ control- controlForm/ addValue - valor a adicionar
    // decimalType -> 1 - formatCurrency
    if ((addValue === 0 && add) || addValue == null) {
      // se nao tiver valores a adicionar
      return;
    }
    let origValue = control.value ? control.value : 0;
    // origValue = add ? this.tranfModel.transformNumber(control.value) : 0; // ir buscar o valor que esta anteriormente, se nao for para adicionar comeca com zero
    origValue = add ? control.value.toString().revertDecimal() : 0; // ir buscar o valor que esta anteriormente, se nao for para adicionar comeca com zero
    let value: string;
    // transformar o valor para ser considerado um number
    // addValue = this.tranfModel.transformNumber(addValue.toString());
    addValue = addValue.toString().revertDecimal();

    if (!isNaN(origValue) && !isNaN(addValue)) {
      switch (decimalType) {
        case 0: {
          value = (+origValue + addValue).toString();
          break;
        }
        case 1: {
          value = this.formatCurrency(+origValue + addValue);
          break;
        }
        default:
          break;
      }
    }
    control.setValue(value);
  }

/** quando o form do NetUnitPrice ou da Quantity é alterado calcula os valores da TaxAmount, TotalNetAmount e TotalPayableAmount
   * @param val valor do input
   * @param formControlIndex index do form
   */
quantityOrNetUnitPriceChange(val: any) {
  let value = val != null ? (val).toString().revertDecimal() : null;
  
  if (value !== null) {
    
    let quantity = this.expenseForm.get('Quantity').value ? (this.expenseForm.get('Quantity').value).toString().revertDecimal() : 0;
    let price = this.expenseForm.get('NetUnitPrice').value ? (this.expenseForm.get('NetUnitPrice').value).toString().revertDecimal() : 0;
    let result = quantity * price;
    this.expenseForm.get('TotalNetAmount').setValue(result.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)));

    if(this.expenseForm.get('TaxRate').value){
      let tax = this.expenseForm.get('TaxRate').value; // percentagem
      //detailForm.get('TaxableAmount').setValue(result);
      if (result > 0 && tax > 0) {
        this.addToFormField(false, this.expenseForm.get('TaxAmount'), result * tax / 100, this.UnitPrice_Form);
        let taxAmount = result * tax / 100;

        this.expenseForm.patchValue({
          TaxAmount: taxAmount.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)),
          TotalPayableAmount: this._decimalPipe.transform((result + taxAmount), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL)
        })

      } else {
        this.expenseForm.patchValue({
          TaxAmount: '',
          TotalPayableAmount: result.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)),
        })
      }
    }
    this.isTaxRateEnabled(this.expenseForm as FormGroup);
  }

}

  /**
   * Valida se a taxa de iva deve estar editável consoante se o preço unitário e quantidade estão validos
   * @param expenseForm 
   */
  isTaxRateEnabled(expenseForm: FormGroup){
    // apenas deixa mexer no campo do iva caso a quantidade e o preco esteja corretos
    if(expenseForm.get('TotalNetAmount').invalid){
      expenseForm.get('TaxRate').disable();
      expenseForm.get('TaxRate').setValue('');
    }
    else
      expenseForm.get('TaxRate').enable();
  }

  // quando altera valor da percentagem na tabela de taxas
  onChangePercentage(val: any, index: any) {
    if (!isNaN(val)) {
      this.currentTaxesArrayKeyValue = this.currentTaxesArrayKeyValue.filter(obj => obj.indexFormArray != index); // sempre que entra => limpa os valores de impostos associados à Linha da Despesa
      
      let valueType = this.expenseForm.get('TaxTypeCode').value;
      let valueTax = this.expenseForm.get('TaxRate').value;
      // o atributo indexFormArray utilizado p/ quando se apaga uma taxa (p/ atualizar lista)
      this.currentTaxesArrayKeyValue.push({ key: valueType, value: valueTax, indexFormArray: index });

      let taxRate = this.expenseForm.get('TaxRate').value ? (this.expenseForm.get('TaxRate').value).toString().revertDecimal() : 0;

      if(this.expenseForm.get('TaxRate').value != null){
          let totalNetAmount = this.expenseForm.get('TotalNetAmount').value ? this.expenseForm.get('TotalNetAmount').value.toString().revertDecimal() : 0;
          let taxAmount = (taxRate/100)*totalNetAmount;
          this.addToFormField(false, this.expenseForm.get('TaxAmount'), taxAmount, this.UnitPrice_Form);
          this.expenseForm.get('TaxAmount').setValue(this._decimalPipe.transform((taxAmount), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL));
          this.expenseForm.get('TotalPayableAmount').setValue(this._decimalPipe.transform((totalNetAmount + taxAmount), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL));
      }   
      this.validateCurrentTaxesFormTable();
    }
  }

  /** valida info da tabela de taxas
   */ 
  validateCurrentTaxesFormTable() {
    let valoresIVAS = [];
    let valoresIVA_auto = [];
    let valoresD_autor = [];
    let valoresIEC = [];
    let valoresEcoTaxa = [];
    let valoresOther = [];
    this.errorDuplicateTaxes = false; // (limpa variavel) para impedir ação de replicar e adcionar linha

    /**
     * Iva
     */

    /**
     * 1º Dividir as taxas por tipos
     */
    for (let j = 0; j < this.currentTaxesArrayKeyValue.length; j++) {
      let objTax = this.currentTaxesArrayKeyValue[j];
      if (objTax.key === "ValueAddedTax") {
        valoresIVAS.push(objTax.value);
      }
    }

    /**
     * 2º Por cada tipo, agrupar valores de taxas repetidos.
     */
    let occurrencesIvas = {};
    for (let i = 0, j = valoresIVAS.length; i < j; i++) {
      occurrencesIvas[valoresIVAS[i]] =
        (occurrencesIvas[valoresIVAS[i]] || 0) + 1;
    }

    /**
     * 3º Validar (já agrupado por % de tipo de taxa) a existencia de %'s repetidas
     */
    Object.keys(occurrencesIvas).forEach((key) => {
      if (occurrencesIvas[key] > 1) {
        this.errorDuplicateTaxes = true;
        // document.getElementById('lineTax-' + index).classList.add('backgroundColorError');
      }
    });

    /**
     * Iva Autoliquidação
     */
    for (let j = 0; j < this.currentTaxesArrayKeyValue.length; j++) {
      let objTax = this.currentTaxesArrayKeyValue[j];

      if (objTax.key === "VATReverseCharge") {
        valoresIVA_auto.push(objTax.value);
      }
    }
    let occurrencesIvas_auto = {};
    for (let i = 0, j = valoresIVA_auto.length; i < j; i++) {
      occurrencesIvas_auto[valoresIVA_auto[i]] =
        (occurrencesIvas_auto[valoresIVA_auto[i]] || 0) + 1;
    }
    Object.keys(occurrencesIvas_auto).forEach((key) => {
      if (occurrencesIvas_auto[key] > 1) {
        this.errorDuplicateTaxes = true;
      }
    });

    /**
     * D. Autor
     */
    for (let j = 0; j < this.currentTaxesArrayKeyValue.length; j++) {
      let objTax = this.currentTaxesArrayKeyValue[j];
      if (objTax.key === "CopyrightTax") {
        valoresD_autor.push(objTax.value);
      }
    }
    let occurrences_autor = {};
    for (let i = 0, j = valoresD_autor.length; i < j; i++) {
      occurrences_autor[valoresD_autor[i]] =
        (occurrences_autor[valoresD_autor[i]] || 0) + 1;
    }
    Object.keys(occurrences_autor).forEach((key) => {
      if (occurrences_autor[key] > 1) {
        this.errorDuplicateTaxes = true;
      }
    });

    /**
     * IEC
     */
    for (let j = 0; j < this.currentTaxesArrayKeyValue.length; j++) {
      let objTax = this.currentTaxesArrayKeyValue[j];
      if (objTax.key === "AlcoholMarkTax") {
        valoresIEC.push(objTax.value);
      }
    }
    let occurrences_iec = {};
    for (let i = 0, j = valoresIEC.length; i < j; i++) {
      occurrences_iec[valoresIEC[i]] =
        (occurrences_iec[valoresIEC[i]] || 0) + 1;
    }
    Object.keys(occurrences_iec).forEach((key) => {
      if (occurrences_iec[key] > 1) {
        this.errorDuplicateTaxes = true;
      }
    });

    /**
     * Eco Taxa
     */
    for (let j = 0; j < this.currentTaxesArrayKeyValue.length; j++) {
      let objTax = this.currentTaxesArrayKeyValue[j];
      if (objTax.key === "EnvironmentalTax") {
        valoresEcoTaxa.push(objTax.value);
      }
    }
    let occurrences_eco = {};
    for (let i = 0, j = valoresEcoTaxa.length; i < j; i++) {
      occurrences_eco[valoresEcoTaxa[i]] =
        (occurrences_eco[valoresEcoTaxa[i]] || 0) + 1;
    }
    Object.keys(occurrences_eco).forEach((key) => {
      if (occurrences_eco[key] > 1) {
        this.errorDuplicateTaxes = true;
      }
    });

    /**
     * Other
     */
    for (let j = 0; j < this.currentTaxesArrayKeyValue.length; j++) {
      let objTax = this.currentTaxesArrayKeyValue[j];
      if (objTax.key === "Other") {
        valoresOther.push(objTax.value);
      }
    }
    let occurrences_other = {};
    for (let i = 0, j = valoresOther.length; i < j; i++) {
      occurrences_other[valoresOther[i]] =
        (occurrences_other[valoresOther[i]] || 0) + 1;
    }
    Object.keys(occurrences_other).forEach((key) => {
      if (occurrences_other[key] > 1) {
        this.errorDuplicateTaxes = true;
      }
    });
  }

  /*
   * Altera/Reverte a Despesa para Despesa Pontal
   */
  toggleRefund() {
    this.expenseForm
      .get("Refund")
      .setValue(!this.expenseForm.get("Refund").value);

    //Validações se for uma Despesa Pontual
    if (this.expenseForm.get("Refund").value === true) {
    }
  }

  onSelectTab(event: any): void {
    // Fecha ou minimiza todos os DockModals
    // verificar qual a tab selecionada, tem de ser por nome devidos as tabs que podem estar ocultas
    let tab =
      event.tab.content.viewContainerRef.element.nativeElement.getAttribute(
        "id"
      );

    this.showArqTab_open = false;

    if (tab) {
      switch (tab) {
        case "tabDigitalArchive":
          this.showArqTab_open = true;
          break;
        default:
          break;
      }
    }
  }

  // Abre modal para obter listagem de fornecedores para colocar no campo BuyerIdentifier
  openSupplier() {
    this._translateService
      .get(["SUPPLIERS", "NIF", "NAME"])
      .subscribe((response) => {
        let aoColumns = [
          { data: "ID" },
          {
            data: "TaxNumber",
            class: "verticalMiddle",
            title: response["NIF"],
          },
          { data: "Name", class: "verticalMiddle", title: response["NAME"] },
        ];

        let columnDefs = [
            { targets: [0], visible: false }, // colocar como hidden
            { targets: [-1], orderable: false }, // nao permitir ordenar pelas colunas
          ],
          dialogRef = this._dialog.open(ChooseModalComponent, {
            // dados que vai enviar para o componente da modal
            data: new ChooseModalParam(
              this.suppliersList,
              null,
              response["SUPPLIERS"],
              aoColumns,
              columnDefs,
              null,
              1,
              null
            ),
            disableClose: true, // nao permitir fechar modal com escape ou clique fora
          });

        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            let index = this.suppliersList.findIndex(
              (r: any) => +r.ID === +result
            );
            this.setSupplier(index);
          }
        });
      });
  }

  formatValue(control:any, controlName: string, formatType?: string) {
    control.get(controlName).setValue(this.format(this.revert(control.get(controlName).value),formatType));
  }

  revert(value: string): number {
    return value ? value.toString().revertDecimal() : 0;
  }

  format(value: number, formatType?: string): string {
    if(!formatType)
    {
      return value.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL));
    }
    if(formatType && formatType == 'Quantity')
    {
      return value.formatDecimal(this.currentLanguage, (+QUANTITY_DECIMAL));
    }
  }

  //Quando altera o valor da despesa, fazer efeito no calculo da despesa e nota de despesa
  onChangeTotalNetAmount(val: any, index: any) {
    let totalNetAmount = Number(val);

    if(typeof Number(totalNetAmount))
    {
      let taxRate = this.expenseForm.get('TaxRate').value ? this.expenseForm.get('TaxRate').value.toString().revertDecimal() : 0;
      this.expenseForm.get('TotalNetAmount').setValue(totalNetAmount)
      let newTaxAmount = (taxRate/100)*totalNetAmount;
      this.expenseForm.get('TaxAmount').setValue(newTaxAmount.formatDecimal(this.currentLanguage, (+CURRENCY_DECIMAL)))
      let totalPayableAmount = totalNetAmount + newTaxAmount;
      (this.expenseForm.get('TotalPayableAmount').setValue(this._decimalPipe.transform((totalPayableAmount), '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL)));
      this.isTaxRateEnabled(this.expenseForm);

    }

  }


  // Definir o fornecedor
  setSupplier(index: number) {
    this.expenseForm.get("SupplierID").setValue(this.suppliersList[index].ID);
    this.expenseForm
      .get("SupplierIdentifier")
      .setValue(this.suppliersList[index].InternalID);
    this.expenseForm
      .get("SupplierName")
      .setValue(this.suppliersList[index].Name);
    this.expenseForm
      .get("SupplierTaxNumber")
      .setValue(this.suppliersList[index].TaxNumber);
  }

  /**
   * Quando altera o campo do ficheiro, para adicionar o novo ficheiro ao modelo ( se já tiver vai ser substituido)
   * @param event
   */
  addFile(event: any, fileItem: FileItem = null) {
    let file: File;
    if (event) { // chegou aqui atraves da janela normal de adição do ficheiro
      let listFiles: FileList = event.target.files;
      if (listFiles.length > 0) { // se tiver algum ficheiro
        file = listFiles[0]; // so queremos o primeiro
      }
    } else if (fileItem) { // chegou aqui porque fez um drop do ficheiro
      file = fileItem._file;
    }
    if (file) { // se tiver algum ficheiro

      this.expenseForm.controls.FileName.setValue(file.name);
      this.expenseForm.controls.FileType.setValue(file.name.split('.')[1]);
      this.expenseForm.controls.File.setValue(file);

      this.expenseForm.markAsDirty();
      this.expenseForm.updateValueAndValidity();
    }
  }
  /**
   * Quando clica no botao de eliminar o ficheiro introduzido
   * @returns void
   */
  removeFile(): void {
    this.expenseForm.controls.FileID.setValue(0);
    this.expenseForm.get('FileName').setValue(null);
    this.expenseForm.get('FileType').setValue(null);
    this.expenseForm.get('File').setValue(null);
  }

    /**
   * Fazer o download do ficheiro
   * @param  {number} id
   * @param  {string} filename
   */
    onDownloadFile(id: number, filename: string) {
      return this._digArcService.get(id).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        this._fileTreat.afterDownloadFile(response, filename);
      });
    }
  
  ngOnDestroy() {}
}
