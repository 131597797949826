import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL, CONTEXT_TRANSPORT_REQUEST, CONTEXT_TRANSPORT_PLANNING, CONTEXT_TRANSPORT_PLANNING_POINT_DETAIL } from '../constants/global';
import { TransportRequest } from '../models/transport-request';
import { ReturnStatusHtml } from '../models/returnStatus';
import { MasterService } from './master.service';
import { DatatableParameters } from '../models/datatable-parameters';
import { GenericType } from '../models/generic-type';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';
import { WorkflowStep } from '../models/workflow-step';
import { TransportUnit } from '../models/transport-unit';
import { TransportType } from '../models/transport-type';
import { TransportPlanning } from '../models/transport-planning';
import { TransportRequestTypeToTransportPointMapping } from '../models/transport-request-type-to-transport-point-mapping';
import { Dropdown } from '../models/dropdown';
import { TransportIncidence } from '../models/transport-incidence';
import { TransportVolumeAction } from '../models/transport-volume-action';
import { TransportCost } from '../models/transport-cost';
import { AuthenticationService } from './authentication.service';
import { TransportRouting } from '../models/transport-routing';
import { TransportOrderControl } from '../models/transport-order-control';
import { TransportVolume } from '../models/transport-volume';
import { TransportOrderRouting } from '../models/transport-order-routing';
import { TransportUnitsIntegrationCodes } from '../models/transport-units-integration-codes';
import { TransportOrderControlType } from '../models/transport-order-control-types';

@Injectable()
export class TransportService {
  private _controller: string = 'Transport';
  private _requestTypes: GenericType[];
  private _planningTypes: GenericType[];
  private _planningDetailTypes: GenericType[];
  private _transportTypes: TransportType[];
  private _transportUnits: TransportUnit[];
  private _typeMappings: TransportRequestTypeToTransportPointMapping[];
  private _incidenceTypes: Dropdown[];
  private _circuits: Dropdown[];

  // Settings
  private orderNumberLabel: string;
  private originLabel: string;
  private deliveryLabel: string;

  // Filtros
  datatableParametersOrderRouting: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  datatableParametersRoutings: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  datatableParametersRequests: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[3, 'desc']], 0);
  filterRequests: any = new Object();
  filterVolumes: any = new Object();
  datatableParametersPlannings: DatatableParameters[] = [];
  filterPlannings: any[] = [];
  datatableParametersSchedules: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
  filterSchedules: any = new Object();
  datatableParametersIncidences: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
  filterIncidences: any = new Object();
  datatableParametersTransportCost: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);
  datatableParametersRequestOrders: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[3, 'asc']], 0);
  datatableParametersRequestProducts: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  datatableParametersReceptions: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
  filterReceptions: any = new Object();
  datatableParametersReceptionDetails: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[0, 'asc']], 0);
  firstTimeReception: boolean = true;
  datatableParametersVolumes: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[0, 'desc']], 0);
  datatableParametersOrdersControl: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[1, 'asc']], 0);
  filterOrdersControl: any = new Object();
  datatableParametersTransportUnitsIntegrationCodes: DatatableParameters[] = [];
  datatableParameterstransportOrdersControlsTypes: DatatableParameters = new DatatableParameters([null, null, null, null, null], 10, [[1, 'asc']], 0);
  datatableParametersPlanningTransportRequests: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null], 50, null, 0);
  filterTransportUnitsIntegrationCodes: any[] = [];
  
  // Settings
  classificationRole?: number;

  constructor(private http: HttpClientCustom, private _masterService: MasterService, private _errorTreat: ErrorTreatmentFunctions,
    private authenticationService: AuthenticationService) {
    // Carrega as posições e os valores dos filtros
    this.datatableParametersPlannings[''] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[''] = new Object();
    this.datatableParametersPlannings[GenericType.TRANSPORT_REQUEST_ENTREGA_INTEGRATION] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[GenericType.TRANSPORT_REQUEST_ENTREGA_INTEGRATION] = new Object();
    this.datatableParametersPlannings[GenericType.TRANSPORT_REQUEST_RECOLHA_INTEGRATION] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[GenericType.TRANSPORT_REQUEST_RECOLHA_INTEGRATION] = new Object();
    this.datatableParametersPlannings[GenericType.TRANSPORT_REQUEST_INTERNAL_INTEGRATION] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[GenericType.TRANSPORT_REQUEST_INTERNAL_INTEGRATION] = new Object();

    this.datatableParametersPlannings[GenericType.TRANSPORT_REQUEST_INBOUND_INTEGRATION] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[GenericType.TRANSPORT_REQUEST_INBOUND_INTEGRATION] = new Object();
    this.datatableParametersPlannings[GenericType.TRANSPORT_REQUEST_OUTBOUND_INTEGRATION] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[GenericType.TRANSPORT_REQUEST_OUTBOUND_INTEGRATION] = new Object();

    this.datatableParametersPlannings[GenericType.TRANSPORT_REQUEST_Groupage_INTEGRATION] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[GenericType.TRANSPORT_REQUEST_Groupage_INTEGRATION] = new Object();

    this.datatableParametersPlannings[GenericType.TRANSPORT_REQUEST_INTEGRATED_INTEGRATION] = new DatatableParameters([null, null, null, null, null, null, null, null, null, null, null, null, null, null], 10, [[2, 'desc']], 0);
    this.filterPlannings[GenericType.TRANSPORT_REQUEST_INTEGRATED_INTEGRATION] = new Object();


    this.datatableParametersTransportUnitsIntegrationCodes['TransportUnitsIntegrationCodes'] = new DatatableParameters([null, null], 2, [[0, 'desc']], 0);
    this.filterTransportUnitsIntegrationCodes['TransportUnitsIntegrationCodes'] = new Object();


  }

  //#region Settings
  get_OrderNumberLabel(defaultValue: string): string {
    if (typeof this.orderNumberLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Transports', 'OrderNumberLabel');
      if (settingValue != null) {
        this.orderNumberLabel = settingValue;
      } else {
        this.orderNumberLabel = defaultValue;
      }
    }
    return this.orderNumberLabel;
  }
  get_OriginLabel(defaultValue: string): string {
    if (typeof this.originLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Transports', 'OriginLabel');
      if (settingValue != null) {
        this.originLabel = settingValue;
      } else {
        this.originLabel = defaultValue;
      }
    }
    return this.originLabel;
  }
  get_DeliveryLabel(defaultValue: string): string {
    if (typeof this.deliveryLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Transports', 'DeliveryLabel');
      if (settingValue != null) {
        this.deliveryLabel = settingValue;
      } else {
        this.deliveryLabel = defaultValue;
      }
    }
    return this.deliveryLabel;
  }
  // #endregion Settings

  //#region Pedidos de Transporte
  // Devolver um documento de transporte
  getRequest(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetRequest?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  // Adicionar um documento de transporte
  addRequest(model: TransportRequest): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/AddRequest', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getTrasnport(id: number, getPoints: boolean = true, getDetails: boolean = true, deliveryNoteID: number = 0, filterByCompany: number = 0): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransport?id=' + id + '&getPoints=' + getPoints + '&getDetails=' + getDetails + '&getDeliveryNoteState=' + true + '&deliveryNoteID=' + deliveryNoteID + '&filterByCompany=' + filterByCompany)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar um documento de transporte
  updateRequest(model: TransportRequest): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateRequest', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar os produtos das encomendas associadas a um pedido de transporte
  updateRequestProductsQuantities(requestid: number, quantitiesList: any): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('requestID', JSON.stringify(requestid));
    formData.append('productQuantities', JSON.stringify(quantitiesList));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateRequestProductsQuantities', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Eliminar um documento de transporte
  deleteRequest(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteRequest?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Submeter o pedido de transporte para workflow
  submitRequest(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitRequest', { id: id })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getRouting(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetRouting?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addRouting(model: TransportRouting): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/AddRouting', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateRouting(model: TransportRouting): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateRouting', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteRouting(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteRouting?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getOrderControl(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrderControl?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addOrderControl(model: TransportOrderControl): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/AddOrderControl', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateOrderControl(model: TransportOrderControl): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateOrderControl', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteOrderControl(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteOrderControl?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver o próximo número de pedido de transporte
  getNextRequestNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextRequestNumber')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver as transições disponíveis para o estado corrente da entidade
  getWorkflowStepsRequest(id: number): Observable<WorkflowStep[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowStepsRequest?id=' + id)
      .pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  // Alterar a transição atual da entidade para uma outra transição
  applyWorkflowStepRequest(id: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStepRequest', { id: id, stepID: stepID, notes: notes })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Alterar o estado de um planeamento de transporte
  changeRequestState(id: number, stateID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangeRequestState', { id: id, stateID: stateID, notes: notes })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atribuir privilégios de workflow de uma entidade a um dado utilizador
  addStepsApproverRequest(entityID: number, userID: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddStepsApproverRequest', { entityID: entityID, userID: userID })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Notificar os fornecedores e mudar o estado do workflow
  confirmEvaluation(ids: number[]): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ConfirmEvaluation', { ids: ids })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver excel dos pedidos de transporte
  exportRequestsListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelRequestsList',
      { parameters: params, beginDate: this.filterRequests.BeginDate, endDate: this.filterRequests.EndDate, extraFilters: this.filterRequests.ExtraFilters });
  }

  exportOrderRoutingToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelOrderRouting',
      { parameters: params });
  }

  

    // Devolver excel das dimensaos de dados de transporte
    exportDimensionsRequestsListToExcel(params: any): Observable<any> {
      return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelDimensionsRequestsList',
        { parameters: params});
    }
  

  // Devolver excel de um pedido de transporte
  exportRequestToExcel(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelRequest?id=' + id);
  }

  // Devolver excel dos produtos de um pedido de transporte
  exportRequestProductsToExcel(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelRequestsProductsList?id=' + id);
  }

  // Devolver pdf de um pedido de transporte
  exportRequestToPDF(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadRequestPDF?id=' + id);
  }

  // Devolver os tipos de documento de transporte
  get requestTypes(): Observable<GenericType[]> {
    if (this._requestTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_TRANSPORT_REQUEST)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._requestTypes = responseReturn.ReturnStatus.ReturnObject as GenericType[];
              return this._requestTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._requestTypes);
    }
  }
  //#endregion Pedidos de Transporte

  // Devolver o planeamento de transporte
  getTransportPointInfo(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportPointInfo?pointid=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  //#region Planeamentos de Transporte
  // Devolver o planeamento de transporte
  getPlanning(id: number, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetPlanning?id=' + id + (context ? '&context=' + context : ''))
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Adicionar o planeamento de transporte
  addPlanning(model: TransportPlanning, context: string = null, actionType: number = null): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    formData.append('context', JSON.stringify(context));
    if (actionType) {
      formData.append('actionType', JSON.stringify(actionType));
    }

    return this.http.put(SERVICE_URL + this._controller + '/AddPlanning', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar o planeamento de transporte
  updatePlanning(model: TransportPlanning, context: string = null): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));
    formData.append('context', JSON.stringify(context));

    return this.http.post(SERVICE_URL + this._controller + '/UpdatePlanning', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Eliminar um planeamento de transporte
  deletePlanning(id: number, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeletePlanning?id=' + id + '&context=' + context)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Submeter o planeamento de transporte para workflow
  submitPlanning(id: number, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/SubmitPlanning', { id: id, context: context })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Submeter o planeamento de transporte para workflow
  addLocalizationVolumes(id: number, transportNumber: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddWarehouseLocationVolumesToPlanning', { id: id, transportNumber: transportNumber })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver o próximo número de planeamento de transporte
  getNextPlanningNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextPlanningNumber')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getWmsExpedition(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetWmsExpedition', { ID: id })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetNextSSCCNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextSSCCNumber')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver as transições disponíveis para o estado corrente da entidade
  getWorkflowStepsPlanning(id: number, context: string = null): Observable<WorkflowStep[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowStepsPlanning?id=' + id + '&context=' + context)
      .pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }


  // Alterar a transição atual da entidade para uma outra transição
  applyWorkflowStepPlanning(id: number, stepID: number, notes: string, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStepPlanning', { id: id, stepID: stepID, notes: notes, context: context })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Alterar o estado de um planeamento de transporte
  changePlanningState(id: number, stateID: number, notes: string, vehicleNumber: string = null, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangePlanningState', { id: id, stateID: stateID, notes: notes, vehicleNumber: vehicleNumber, context: context })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atribuir privilégios de workflow de uma entidade a um dado utilizador
  addStepsApproverPlanning(entityID: number, userID: number, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/AddStepsApproverPlanning', { entityID: entityID, userID: userID, context: context })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver excel dos planeamentos de transporte
  exportPlanningsListToExcel(params: any, context: string = null): Observable<any> {
    let filterPosition = context ? context : '';

    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelPlanningsList',
      {
        parameters: params,
        beginDate: this.filterPlannings[filterPosition].BeginDate,
        endDate: this.filterPlannings[filterPosition].EndDate,
        extraFilters: this.filterPlannings[filterPosition].ExtraFilters,
        context: context
      });
  }

  // Devolver excel de um planeamento de transporte
  exportPlanningToExcel(id: number, context: string = null): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelPlanning?id=' + id + '&context=' + context);
  }

  // Devolver pdf de um planeamento de transporte
  exportPlanningToPDF(id: number, context: string = null): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPlanningPDF?id=' + id + '&context=' + context);
  }

  // Devolver a localização do ponto por base no pedido de transporte e o tipo de localização
  getTransportDeliveryLocation(requestDetailIDs: number[], locationType: string, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportDeliveryLocation?requestDetailIDs=' + requestDetailIDs.join(';') + '&locationType=' + locationType
      + (context ? '&context=' + context : '')).pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Retornar os volumes de uma lista de detalhes de pedidos de transporte
  getTransportRequestsDetailsVolumes(requestDetailIDs: number[], context: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetTransportRequestsDetailsVolumes', { detailsIDs: requestDetailIDs, context: context })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getTransportDetailsVolumes(requestDetailIDs: number[], context: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GetTransportDetailsVolumes', { detailsIDs: requestDetailIDs, context: context })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  // Devolver os tipos de planeamento de transporte
  get planningTypes(): Observable<GenericType[]> {
    if (this._planningTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_TRANSPORT_PLANNING)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._planningTypes = responseReturn.ReturnStatus.ReturnObject as GenericType[];
              return this._planningTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._planningTypes);
    }
  }

  // Devolver os tipos de detalhes de planeamento de transporte
  get planningDetailTypes(): Observable<GenericType[]> {
    if (this._planningDetailTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetGenericTypes?context=' + CONTEXT_TRANSPORT_PLANNING_POINT_DETAIL)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._planningDetailTypes = responseReturn.ReturnStatus.ReturnObject as GenericType[];
              return this._planningDetailTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._planningDetailTypes);
    }
  }

  // Devolver os tipos de documento de transporte
  get transportTypes(): Observable<TransportType[]> {
    if (this._transportTypes === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetTransportTypes')
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._transportTypes = responseReturn.ReturnStatus.ReturnObject as TransportType[];
              return this._transportTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._transportTypes);
    }
  }

  // Devolver os mapeamentos de Pedidos de Transporte para Pontos de Planeamento de Transporte
  get typeMappings(): Observable<TransportRequestTypeToTransportPointMapping[]> {
    if (this._typeMappings === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetTransportRequestsTypesToTransportsPointsMappings')
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._typeMappings = responseReturn.ReturnStatus.ReturnObject as TransportRequestTypeToTransportPointMapping[];
              return this._typeMappings;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._typeMappings);
    }
  }
  //#endregion Planeamentos de Transporte

  //#region Agendamentos de Transporte
  // Devolver os volumes de um dado ponto
  getTransportPointVolumes(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportPointVolumes?pointID=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver o detalhe do agendamento de transporte
  getTransportScheduleDetail(pointID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportPoint?pointID=' + pointID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Alterar o estado de um ponto de transporte
  changeScheduleState(id: number, stateID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ChangePointState', { pointID: id, stateID: stateID, notes: notes })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Alterar o estado de um ponto de transporte e trata os volumes
  addTransportPointAction(id: number, stateID: number, notes: string, volumes: TransportVolumeAction[]): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/AddTransportPointAction', { pointID: id, pointStateID: stateID, notes: notes, volumesActions: volumes })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver excel dos agendamentos de transporte
  exportSchedulesListToExcel(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelSchedulesList',
      { parameters: params, beginDate: this.filterSchedules.BeginDate, endDate: this.filterSchedules.EndDate, extraFilters: this.filterSchedules.ExtraFilters });
  }

  // Devolver excel de um agendamento de transporte
  exportScheduleToExcel(pointID: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelSchedule?pointID=' + pointID);
  }

  // Devolver pdf de um agendamento de transporte
  exportScheduleToPDF(pointID: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadSchedulePDF?pointID=' + pointID);
  }
  //#endregion Agendamentos de Transporte

  //#region Incidências de Transporte
  // Devolver os tipos de incidência de transporte
  get incidenceTypes(): Observable<Dropdown[]> {
    if (this._incidenceTypes === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.INCIDENCE_TYPE)
        .pipe(map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._incidenceTypes = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._incidenceTypes;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._incidenceTypes);
    }
  }

  // Devolver a incidência de transporte
  getIncidence(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetIncidence?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Adicionar a incidência de transporte
  addIncidence(model: TransportIncidence): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/AddIncidence', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar a incidência de transporte
  updateIncidence(model: TransportIncidence): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateIncidence', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Eliminar uma incidência de transporte
  deleteIncidence(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteIncidence?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver o próximo número de incidência de transporte
  getNextIncidenceNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextIncidenceNumber')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Incidências de Transporte

  //#region Localizar Encomenda/Volume
  // Localizar todas as relações da encomenda/volume nos transportes
  getTransportOrderInfo(orderNumber: string, volume: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportOrderInfo?' + (orderNumber ? 'orderNumber=' + orderNumber : volume ? 'standardCode=' + volume : ''))
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver o histórico de um volume
  getTransportVolumeHistory(volume: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportVolumeHistory?standardCode=' + volume)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Localizar Encomenda/Volume

  //#region Calendário
  getTransportCalendar(locationID: number, dates: string[]): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('locationID', JSON.stringify(locationID));
    formData.append('dates', JSON.stringify(dates));

    return this.http.post(SERVICE_URL + this._controller + '/GetTransportCalendar', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  //#endregion Calendário

  get transportUnits(): Observable<TransportUnit[]> {
    if (this._transportUnits === undefined) {
      return this.http.get(SERVICE_URL + this._controller + '/GetTransportUnits').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._transportUnits = responseReturn.ReturnStatus.ReturnObject as TransportUnit[];
              return this._transportUnits;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._transportUnits);
    }
  }

  //A Pedido do cliente Todas as Units que nao sao de Sistema Mas vai buscar a SSCCChild
  gettransportUnitsWithChild(): Observable<TransportUnit[]> {
      return this.http.get(SERVICE_URL + this._controller + '/GetNoSystemWithChild').pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._transportUnits = responseReturn.ReturnStatus.ReturnObject as TransportUnit[];
              return this._transportUnits;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
  }

  /**
   * Obter todas as localizações de um determinado armazém
   * @param  {number} locationID
   * @returns Observable
   */
  getListByLocation(locationID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'WarehouseLocation/GetListByLocation?locationID=' + locationID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver um custo de transporte
  getTransportCost(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'TransportCost/Get?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Adicionar um custo de transporte
  addTransportCost(model: TransportCost): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + 'TransportCost/Add', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar um custo de transporte
  updateTransportCost(model: TransportCost): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + 'TransportCost/Update', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Eliminar um custo de transporte
  deleteTransportCost(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + 'TransportCost/Delete?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
     * Adicionar um novo ficheiro
     * @param  {FormData} formData
     * @param  {boolean} noSession
     * @returns Promise
     */
  uploadRequestProductsFile(formData: FormData): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UploadRequestProductsFile', formData).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  /**
     * Adicionar um novo ficheiro
     * @param  {FormData} formDataSSCCs
     * @param  {boolean} noSessionSSCCs
     * @returns Promise
     */
  uploadSSCCsFile(formDataSSCCs: FormData): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UploadSSCCsFile', formDataSSCCs).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  // Atualizar os produtos das encomendas associadas a um pedido de transporte
  updateTransportDetailOrdersProductsQuantities(detailid: number, quantitiesList: any,
    pointid: number,
    detailsids: any,
    type: string): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    if (detailid) {
      formData.append('detailID', JSON.stringify(detailid));
    }
    if (quantitiesList) {
      formData.append('productQuantities', JSON.stringify(quantitiesList));
    }
    if (pointid) {
      formData.append('pointID', JSON.stringify(pointid));
    }
    if (detailsids) {
      formData.append('detailsIDs', JSON.stringify(detailsids));
    }
    if (type) {
      formData.append('type', JSON.stringify(type));
    }

    return this.http.post(SERVICE_URL + this._controller + '/UpdateTransportDetailOrdersProductQuantities', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver pdf de um agendamento de transporte
  exportPointReceptionToPDF(pointID: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPointReceptionPDF?pointID=' + pointID);
  }

  get circuits(): Observable<Dropdown[]> {
    if (this._circuits === undefined) {
      return this.http.get(SERVICE_URL + 'Common/GetDropdownData?type=' + Dropdown.TRANSPORT_CIRCUITS).pipe(
        map(response => {
          try {
            let responseReturn = this._masterService.convertToReturnStatusHtml(response);
            if (responseReturn.ReturnStatus.Successfull) {
              this._circuits = responseReturn.ReturnStatus.ReturnObject as Dropdown[];
              return this._circuits;
            } else {
              this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
            }
          } catch (error) {
            this._masterService.handleError(error);
          }
        }));
    } else {
      return observableOf(this._circuits);
    }
  }

  // Fazer update aos estado do SSCC
  updateSSCC(model: TransportVolume): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/UpdateDeliveryNoteSSCC', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  // Devolver o planeamento de transporte
  getPointCodes(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetPointCodes?pointID=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar os produtos das encomendas associadas a um pedido de transporte
  updatePointCodes(pointid: number, pointCodes: any): Observable<ReturnStatusHtml> {

    let formData: FormData = new FormData();
    if (pointCodes) {
      formData.append('pointCodes', JSON.stringify(pointCodes));
    }
    if (pointid) {
      formData.append('pointID', JSON.stringify(pointid));
    }

    return this.http.post(SERVICE_URL + this._controller + '/AddOrUpdatePointCodes', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  // Devolver as transições disponíveis para o estado corrente da entidade
  getWorkflowStepsPoints(id: number, context: string = null): Observable<WorkflowStep[]> {
    return this.http.get(SERVICE_URL + this._controller + '/GetWorkflowStepsTransportPoint?pointID=' + id + '&context=' + context)
      .pipe(map(response => {
        try {
          let responseReturn = this._masterService.convertToReturnStatusHtml(response);
          if (responseReturn.ReturnStatus.Successfull) {
            return responseReturn.ReturnStatus.ReturnObject;
          } else {
            this._errorTreat.treatErrorResponseSendEmpty(responseReturn);
          }
        } catch (error) {
          this._masterService.handleError(error);
        }
      }));
  }

  // Alterar a transição atual da entidade para uma outra transição
  applyWorkflowStepPoints(id: number, stepID: number, notes: string, context: string = null): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStepPoints', { id: id, stepID: stepID, notes: notes, context: context })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  changePointState(pointID: number) {
    return this.http.post(SERVICE_URL + this._controller + '/ChangePointState', { pointID: pointID, stateID: 342 })
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getVolume(VolumeID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetVolume?id=' + VolumeID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Adicionar a volume de transporte
  addVolume(model: TransportVolume): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/AddVolume', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Atualizar a volume de transporte
  updateVolume(model: TransportVolume): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/UpdateVolume', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getVolumesByDeliveryNoteID(deliveryNoteID: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetVolumesByDeliveryNoteID?deliveryNoteId=' + deliveryNoteID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  // Devolver todos os TransportUnitsIntegrationCodes
  getTransportUnitsIntegrationCodes(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportUnitsIntegrationCodes')
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  updateTransportUnitCode(model: TransportUnit): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateTransportUnitCode', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Eliminar um documento de transporte
  deleteTransportUnitIntegrationCodes(id: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteTransportUnitIntegrationCodes?entityCode=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addTransportUnit(entity: TransportUnit): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(entity));

    return this.http.put(SERVICE_URL + this._controller + '/AddTransportUnit', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateTransportUnit(entity: TransportUnit): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(entity));

    return this.http.put(SERVICE_URL + this._controller + '/UpdateTransportUnit', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Devolver todos os TransportUnitsIntegrationCodes
  getTransportUnits(code: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportUnit?code=' + code)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getTransportIntegrationCodeUnits(code: string, detailID :string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportIntegrationCodeUnit?unitCode=' + code + '&detailID='+ detailID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  createTransportIntegrationCodeUnits(entity: TransportUnitsIntegrationCodes): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(entity));

    return this.http.post(SERVICE_URL + this._controller + '/AddTransportIntegrationCodeUnit',formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }
  updateTransportIntegrationCodeUnits(entity: TransportUnitsIntegrationCodes): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(entity));

    return this.http.put(SERVICE_URL + this._controller + '/UpdateTransportIntegrationCodeUnit',formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteTransportIntegrationCodeUnits(id: string): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteTransportIntegrationCodeUnit?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  /***** OrderRouting *****/
  getOrderRouting(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetOrderRouting?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  addOrderRouting(model: TransportOrderRouting): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/AddOrderRouting', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateOrderRouting(model: TransportOrderRouting): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/UpdateOrderRouting', formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteOrderRouting(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteOrderRouting?id=' + id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  
  /* TransportOrdersControlTypes */
  getTransportOrderControlTypes(locationID : number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetTransportOrderControlTypesByLocation?locationID='+locationID)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  createTransportOrderControlTypes(model: TransportOrderControlType): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.post(SERVICE_URL + this._controller + '/InsertTransportOrderControlTypes',formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateTransportOrderControlTypes(model: TransportOrderControlType): Observable<ReturnStatusHtml> {
    let formData: FormData = new FormData();
    formData.append('entity', JSON.stringify(model));

    return this.http.put(SERVICE_URL + this._controller + '/UpdateTransportOrderControlTypes',formData)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteTransportOrderControlTypes(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteTransportOrderControlTypes?id='+id)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }


  getWMSVolumesNotInTransport(wmsExpeditionCode : string, startDate : Date, endDate : Date): Observable<ReturnStatusHtml> {
    let string = SERVICE_URL + this._controller + '/WMSVolumesNotInTransport?wmsExpeditionCode='+wmsExpeditionCode+'&startDate='+startDate+'&endDate='+endDate
    return this.http.get(string)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getOrderTransportAndDeliveryNoteTracking(orderID: number) : Observable<ReturnStatusHtml> 
  {
    let string = SERVICE_URL + this._controller + '/GetOrderTransportAndDeliveryNoteTracking?orderID='+orderID
    return this.http.get(string)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

}
