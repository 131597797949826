
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientCustom } from './http-client';
import { SERVICE_URL } from '../constants/global';
import { of as observableOf, Observable } from 'rxjs';
import { MasterService } from './master.service';
import { ReturnStatusHtml } from '../models/returnStatus';
import { ContractType } from '../models/contractType';
import { DatatableParameters } from '../models/datatable-parameters';
import { AuthenticationService } from './authentication.service';
import { TipologiaProdutoServico } from '../models/tipologia-produto-servico';
import { ErrorTreatmentFunctions } from '../modules/treatments.module';


@Injectable()
export class ContractService {

  private _controller: string = 'Contract';
  private _contracts: Array<any>;

  //variáveis para guardar os valores dos filtros - ecrã Contratos
  signatory: string = null;
  interlocutor: string = null;

  //variáveis para guardar os valores dos filtros - ecrã Contratos Todas Emp.
  signatoryAllCompanies: string = null;
  interlocutorAllCompanies: string = null;

  datatableParameters: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null], 10, [[2, 'asc']], 0);
  datatableParametersForAllCompanies: DatatableParameters = new DatatableParameters([null, null, null, null, null, null, null, null, null], 10, [[2, 'asc']], 0);
  datatableParametersTypes: DatatableParameters = new DatatableParameters([null, null, null, null], 10, [[1, 'asc']], 0);

  // Settings
  loadDefaultSH: boolean;
  showVerifyReception: boolean;
  showValidateDetails: boolean;
  defaultValidateDetails: boolean;
  showPaymentMethod: boolean;
  showTotalRetencaoIrsPercentage: boolean;
  showDistribution: boolean;
  showInvoicesTab: boolean;
  // Obter os Extended Fields associados ao Contrato
  getExtendedFields: boolean;
  useAccountingConfig: boolean;
  //esconde o painel de contabilização e o painel de configurações dos centros de custo
  ignoreAccountingConfigAndDistribution: boolean;
  showDetailsStartDate: boolean;
  showDetailsEndDate: boolean;
  showPanelInterlocutors: boolean;
  showPanelSignatories: boolean;
  distributionButtonLabel: string;
  showProductsWithImage: boolean;
  getProductServiceType: boolean;
  showContractDetailsModal: boolean;
  defaultUnitMeasure: string;
  showReceptionUser: boolean;
  showPurchaseProductsUomCode: boolean;
  showDetailsApprovedProposalNumber: boolean;
  showColumnProductNotIdentified: boolean;
  contractGenerationDaysLabel: string;
  posGeradasTabLabel: string;
  showProductsAdditionalInfo: boolean;
  allowRepetedProducts: boolean;
  allowDuplicateDetails: boolean;
  enableSavingWhenChangingForm: boolean;
  hideButtonAddNewArticle: boolean;
  loadTaxesAmount: boolean;
  requiredTaxesOnAllDetails: boolean;
  showTerminationPeriod: boolean;
  showAlertPeriod: boolean;
  showPublishToSupplier: boolean;
  mandatoryCostCentersInDetails: boolean;
  showContractVersions: boolean;
  showCentralPayment: boolean;
  warnUserWhenUnsavedChanges: boolean;
  private contractStates: Array<number>;

  showSignatoryInterlocutorFilters: boolean;
  contractNumberLabel: string;

  afterSaveRemainOnSamePage: boolean;
  redirectOnUpdate: boolean;
  getNextContractTypeNumberButton: boolean;

  private useProductCostCenter: boolean; //Setting que define se o Campo Opcional 'CostCenter' é utilizado
  private useProductReclassificationCode: boolean; //Setting que define se o Campo Opcional 'ReclassificationCode' é utilizado
  private useProductNetUnitBuyPrice: boolean; //Preenche a Linha do Contrato com o Preço de Unitário de Compra associado ao Artigo


  constructor(private http: HttpClientCustom, private _masterService: MasterService, private authenticationService: AuthenticationService,private _errorTreat: ErrorTreatmentFunctions) { }


  GetChildsList(parentID: number, tipoTratamentoIva: string): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + 'TipologiaProdutoServico/GetChildsList?parentID=' + parentID + '&tipoTratamentoIva=' + tipoTratamentoIva)
      .pipe(map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetContractTypeByID(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetContractTypeByID?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetContractByID(id: number, useAccountingConfig: boolean = false, getProductServiceType: boolean = false, 
    getExtendedFields: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetContractByID?id=' + id + '&useAccountingConfig=' + useAccountingConfig + 
    '&getProductServiceType=' + getProductServiceType +  
    '&getExtendedFields=' + getExtendedFields).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getNextNumber(): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetNextNumber').pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  getByStateID(stateID: number,supplierID: number = 0): Observable<any> {
    return this.http.get(SERVICE_URL + this._controller + '/GetByStateID?stateId=' + stateID + '&supplierId='+supplierID).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetContractByIDAndVersion(id: number, version: number, useAccountingConfig: boolean = false, getProductServiceType: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetContractByIDAndVersion?contractID=' + id + '&version=' + version + '&useAccountingConfig=' + useAccountingConfig + '&getProductServiceType=' + getProductServiceType).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetContractByIDForAllCompanies(id: number, useAccountingConfig: boolean = false, getProductServiceType: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetContractByIDForAllCompanies?id=' + id + '&useAccountingConfig=' + useAccountingConfig + '&getProductServiceType=' + getProductServiceType).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetContractOrders(id: number): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetContractOrders?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GetStatesAndTypes(forAllCompanies: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.get(SERVICE_URL + this._controller + '/GetStatesAndTypes?forAllCompanies=' + forAllCompanies).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  deleteTypes(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/DeleteTypes?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  delete(id: number): Observable<ReturnStatusHtml> {
    return this.http.delete(SERVICE_URL + this._controller + '/Delete?id=' + id).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  updateType(contract: ContractType): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateContractType', contract).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  update(contract: any, getProductServiceType: boolean = false, companyID: number = 0): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/UpdateContract', { contract: contract, getProductServiceType: getProductServiceType, companyID: companyID }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  createType(contract: ContractType): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/CreateContractType', contract).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  create(contract: any, useAccountingConfig: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/CreateContract', { contract: contract, useAccountingConfig: useAccountingConfig }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  generateVersion(contract: any, useAccountingConfig: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.put(SERVICE_URL + this._controller + '/GenerateVersion', { contract: contract, useAccountingConfig: useAccountingConfig }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  submit(id: number): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/Submit', { id: id }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  GenerateOrders(contractID: number, numOrdersToCreate: number, useAccountingConfig: boolean = false, companyID: number = 0, useExtendedFields: boolean = false): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/GenerateOrders', { contractID: contractID, numOrdersToCreate: numOrdersToCreate, useAccountingConfig: useAccountingConfig, companyID: companyID, useExtendedFields: useExtendedFields }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  downloadExcelContractTypesList(params: any): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelContractTypesList', { parameters: params });
  }

  downloadExcelContractsList(params: any, forAllCompanies: boolean = false): Observable<any> {
    return this.http.postFile(SERVICE_URL + this._controller + '/DownloadExcelContractsList', { parameters: params, forAllCompanies: forAllCompanies });
  }

  /**
 * Download de PDF da encomenda
 * @param  {number} id
 * @returns Observable
 */
  downloadPDFContract(id: number, allCompanies: boolean = false): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadPDFContract?id=' + id + '&allCompanies=' + allCompanies);
  }

  /**
* Download de Excel da encomenda
* @param  {number} id
* @returns Observable
*/
  downloadExcelContract(id: number): Observable<any> {
    return this.http.getFile(SERVICE_URL + this._controller + '/DownloadExcelContract?id=' + id);
  }

  applyWorkflowStep(docID: number, stepID: number, notes: string): Observable<ReturnStatusHtml> {
    return this.http.post(SERVICE_URL + this._controller + '/ApplyWorkflowStep', { docID: docID, stepID: stepID, notes: notes }).pipe(
      map((response: any) => this._masterService.convertToReturnStatusHtml(response)));
  }

  // Settings
  get_loadDefaultSH(defaultValue: boolean): boolean {
    if (typeof this.loadDefaultSH === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'LoadDefaultSH');
      if (settingValue != null) {
        this.loadDefaultSH = settingValue;
      } else {
        this.loadDefaultSH = defaultValue;
      }
    }
    return this.loadDefaultSH;
  }

  get_showVerifyReception(defaultValue: boolean): boolean {
    if (typeof this.showVerifyReception === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowVerifyReception');
      if (settingValue != null) {
        this.showVerifyReception = settingValue;
      } else {
        this.showVerifyReception = defaultValue;
      }
    }
    return this.showVerifyReception;
  }

  get_showValidateDetails(defaultValue: boolean): boolean {
    if (typeof this.showValidateDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowValidateDetails');
      if (settingValue != null) {
        this.showValidateDetails = settingValue;
      } else {
        this.showValidateDetails = defaultValue;
      }
    }
    return this.showValidateDetails;
  }

  /*get_ContractStates_S(defaultValue: Array<number>, columnsNames: Array<string>): Array<number> {
    if (typeof this.contractStates === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('OrderPurchase', 'ContractStates');
      let settingValueSP = this.authenticationService.getSettingPortal('OrderPurchase', 'ContractStatesSP');
      this.columnInVisible_S = this._dataTableF.getInvColumns(defaultValue, columnsNames,
        this.authenticationService.session.company.ServiceProvider && settingValueSP && settingValueSP.length > 0 ? settingValueSP : settingValue);
    }
    return this.columnInVisible_S;
  }*/

  get_defaultValidateDetails(defaultValue: boolean): boolean {
    if (typeof this.defaultValidateDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'DefaultValidateDetails');
      if (settingValue != null) {
        this.defaultValidateDetails = settingValue;
      } else {
        this.defaultValidateDetails = defaultValue;
      }
    }
    return this.defaultValidateDetails;
  }

  get_showPaymentMethod(defaultValue: boolean): boolean {
    if (typeof this.showPaymentMethod === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowPaymentMethod');
      if (settingValue != null) {
        this.showPaymentMethod = settingValue;
      } else {
        this.showPaymentMethod = defaultValue;
      }
    }
    return this.showPaymentMethod;
  }

  get_showTotalRetencaoIrsPercentage(defaultValue: boolean): boolean {
    if (typeof this.showTotalRetencaoIrsPercentage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowTotalRetencaoIrsPercentage');
      if (settingValue != null) {
        this.showTotalRetencaoIrsPercentage = settingValue;
      } else {
        this.showTotalRetencaoIrsPercentage = defaultValue;
      }
    }
    return this.showTotalRetencaoIrsPercentage;
  }

  get_showDistribution(defaultValue: boolean): boolean {
    if (typeof this.showDistribution === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowDistribution');
      if (settingValue != null) {
        this.showDistribution = settingValue;
      } else {
        this.showDistribution = defaultValue;
      }
    }
    return this.showDistribution;
  }

  get_showInvoicesTab(defaultValue: boolean): boolean {
    if (typeof this.showInvoicesTab === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowInvoicesTab');
      if (settingValue != null) {
        this.showInvoicesTab = settingValue;
      } else {
        this.showInvoicesTab = defaultValue;
      }
    }
    return this.showInvoicesTab;
  }

  get_getExtendedFields(defaultValue: boolean): boolean {
    if (typeof this.getExtendedFields === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'GetExtendedFields');
      if (settingValue != null) {
        this.getExtendedFields = settingValue;
      } else {
        this.getExtendedFields = defaultValue;
      }
    }
    return this.getExtendedFields;
  }

  get_useAccountingConfig(defaultValue: boolean): boolean {
    if (typeof this.useAccountingConfig === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'UseAccountingConfig');
      if (settingValue != null) {
        this.useAccountingConfig = settingValue;
      } else {
        this.useAccountingConfig = defaultValue;
      }
    }
    return this.useAccountingConfig;
  }

  get_ignoreAccountingConfigAndDistribution(defaultValue: boolean): boolean {
    if (typeof this.ignoreAccountingConfigAndDistribution === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'IgnoreAccountingConfigAndDistribution');
      if (settingValue != null) {
        this.ignoreAccountingConfigAndDistribution = settingValue;
      } else {
        this.ignoreAccountingConfigAndDistribution = defaultValue;
      }
    }
    return this.ignoreAccountingConfigAndDistribution;
  }

  get_showDetailsStartDate(defaultValue: boolean): boolean {
    if (typeof this.showDetailsStartDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowDetailsStartDate');
      if (settingValue != null) {
        this.showDetailsStartDate = settingValue;
      } else {
        this.showDetailsStartDate = defaultValue;
      }
    }
    return this.showDetailsStartDate;
  }

  get_showDetailsEndDate(defaultValue: boolean): boolean {
    if (typeof this.showDetailsEndDate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowDetailsEndDate');
      if (settingValue != null) {
        this.showDetailsEndDate = settingValue;
      } else {
        this.showDetailsEndDate = defaultValue;
      }
    }
    return this.showDetailsEndDate;
  }

  get_showPanelInterlocutors(defaultValue: boolean): boolean {
    if (typeof this.showPanelInterlocutors === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowPanelInterlocutors');
      if (settingValue != null) {
        this.showPanelInterlocutors = settingValue;
      } else {
        this.showPanelInterlocutors = defaultValue;
      }
    }
    return this.showPanelInterlocutors;
  }

  get_showPanelSignatories(defaultValue: boolean): boolean {
    if (typeof this.showPanelSignatories === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowPanelSignatories');
      if (settingValue != null) {
        this.showPanelSignatories = settingValue;
      } else {
        this.showPanelSignatories = defaultValue;
      }
    }
    return this.showPanelSignatories;
  }

  get_distributionButtonLabel(defaultValue: string): string {
    if (typeof this.distributionButtonLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'DistributionButtonLabel');
      if (settingValue != null) {
        this.distributionButtonLabel = settingValue;
      } else {
        this.distributionButtonLabel = defaultValue;
      }
    }
    return this.distributionButtonLabel;
  }

  get_showProductsWithImage(defaultValue: boolean): boolean {
    if (typeof this.showProductsWithImage === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowProductsWithImage');
      if (settingValue !== null) {
        this.showProductsWithImage = settingValue;
      } else {
        this.showProductsWithImage = defaultValue;
      }
    }
    return this.showProductsWithImage;
  }

  get_getProductServiceType(defaultValue: boolean): boolean {
    if (typeof this.getProductServiceType === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'GetProductServiceType');
      if (settingValue !== null) {
        this.getProductServiceType = settingValue;
      } else {
        this.getProductServiceType = defaultValue;
      }
    }
    return this.getProductServiceType;
  }

  get_showContractDetailsModal(defaultValue: boolean): boolean {
    if (typeof this.showContractDetailsModal === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowContractDetailsModal');
      if (settingValue !== null) {
        this.showContractDetailsModal = settingValue;
      } else {
        this.showContractDetailsModal = defaultValue;
      }
    }
    return this.showContractDetailsModal;
  }

  get_defaultUnitMeasure(defaultValue: string): string {
    if (typeof this.defaultUnitMeasure === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'DefaultUnitMeasure');
      if (settingValue !== null) {
        this.defaultUnitMeasure = settingValue;
      } else {
        this.defaultUnitMeasure = defaultValue;
      }
    }
    return this.defaultUnitMeasure;
  }

  get_showReceptionUser(defaultValue: boolean): boolean {
    if (typeof this.showReceptionUser === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowReceptionUser');
      if (settingValue !== null) {
        this.showReceptionUser = settingValue;
      } else {
        this.showReceptionUser = defaultValue;
      }
    }
    return this.showReceptionUser;
  }

  get_showPurchaseProductsUomCode(defaultValue: boolean): boolean {
    if (typeof this.showPurchaseProductsUomCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowPurchaseProductsUomCode');
      if (settingValue !== null) {
        this.showPurchaseProductsUomCode = settingValue;
      } else {
        this.showPurchaseProductsUomCode = defaultValue;
      }
    }
    return this.showPurchaseProductsUomCode;
  }

  get_showDetailsApprovedProposalNumber(defaultValue: boolean): boolean {
    if (typeof this.showDetailsApprovedProposalNumber === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowDetailsApprovedProposalNumber');
      if (settingValue !== null) {
        this.showDetailsApprovedProposalNumber = settingValue;
      } else {
        this.showDetailsApprovedProposalNumber = defaultValue;
      }
    }
    return this.showDetailsApprovedProposalNumber;
  }

  get_showColumnProductNotIdentified(defaultValue: boolean): boolean {
    if (typeof this.showColumnProductNotIdentified === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowColumnProductNotIdentified');
      if (settingValue !== null) {
        this.showColumnProductNotIdentified = settingValue;
      } else {
        this.showColumnProductNotIdentified = defaultValue;
      }
    }
    return this.showColumnProductNotIdentified;
  }

  get_contractGenerationDaysLabel(defaultValue: string): string {
    if (typeof this.contractGenerationDaysLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ContractGenerationDaysLabel');
      if (settingValue !== null) {
        this.contractGenerationDaysLabel = settingValue;
      } else {
        this.contractGenerationDaysLabel = defaultValue;
      }
    }
    return this.contractGenerationDaysLabel;
  }

  get_posGeradasTabLabel(defaultValue: string): string {
    if (typeof this.posGeradasTabLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'PosGeradasTabLabel');
      if (settingValue !== null) {
        this.posGeradasTabLabel = settingValue;
      } else {
        this.posGeradasTabLabel = defaultValue;
      }
    }
    return this.posGeradasTabLabel;
  }

  get_showProductsAdditionalInfo(defaultValue: boolean): boolean {
    if (typeof this.showProductsAdditionalInfo === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowProductsAdditionalInfo');
      if (settingValue != null) {
        this.showProductsAdditionalInfo = settingValue;
      } else {
        this.showProductsAdditionalInfo = defaultValue;
      }
    }
    return this.showProductsAdditionalInfo;
  }

  get_allowRepetedProducts(defaultValue: boolean): boolean {
    if (typeof this.allowRepetedProducts === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'AllowRepetedProducts');
      if (settingValue != null) {
        this.allowRepetedProducts = settingValue;
      } else {
        this.allowRepetedProducts = defaultValue;
      }
    }
    return this.allowRepetedProducts;
  }

  get_allowDuplicateDetails(defaultValue: boolean): boolean {
    if (typeof this.allowDuplicateDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'AllowDuplicateDetails');
      if (settingValue != null) {
        this.allowDuplicateDetails = settingValue;
      } else {
        this.allowDuplicateDetails = defaultValue;
      }
    }
    return this.allowDuplicateDetails;
  }

  getEnableSavingWhenChangingForm(defaultValue: boolean): boolean {
    if (typeof this.enableSavingWhenChangingForm === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'EnableSavingWhenChangingForm');
      if (settingValue != null) {
        this.enableSavingWhenChangingForm = settingValue;
      } else {
        this.enableSavingWhenChangingForm = defaultValue;
      }
    }
    return this.enableSavingWhenChangingForm;
  }

  get_hideButtonAddNewArticle(defaultValue: boolean): boolean {
    if (typeof this.hideButtonAddNewArticle === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contracts', 'HideButtonAddNewArticle');
      if (settingValue != null) {
        this.hideButtonAddNewArticle = settingValue;
      } else {
        this.hideButtonAddNewArticle = defaultValue;
      }
    }
    return this.hideButtonAddNewArticle;
  }

  get_loadTaxesAmount(defaultValue: boolean): boolean {
    if (typeof this.loadTaxesAmount === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'LoadTaxesAmount');
      if (settingValue != null) {
        this.loadTaxesAmount = settingValue;
      } else {
        this.loadTaxesAmount = defaultValue;
      }
    }
    return this.loadTaxesAmount;
  }

  get_requiredTaxOnAllDetails(defaultValue: boolean): boolean {
    if (typeof this.requiredTaxesOnAllDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'RequiredTaxesOnAllDetails');
      if (settingValue != null) {
        this.requiredTaxesOnAllDetails = settingValue;
      } else {
        this.requiredTaxesOnAllDetails = defaultValue;
      }
    }
    return this.requiredTaxesOnAllDetails;
  }

  get_showAlertPeriod(defaultValue: boolean): boolean {
    if (typeof this.showAlertPeriod === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowAlertPeriod');
      if (settingValue != null) {
        this.showAlertPeriod = settingValue;
      } else {
        this.showAlertPeriod = defaultValue;
      }
    }
    return this.showAlertPeriod;
  }

  get_showTerminationPeriod(defaultValue: boolean): boolean {
    if (typeof this.showTerminationPeriod === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowTerminationPeriod');
      if (settingValue != null) {
        this.showTerminationPeriod = settingValue;
      } else {
        this.showTerminationPeriod = defaultValue;
      }
    }
    return this.showTerminationPeriod;
  }

  get_showPublishToSupplier(defaultValue: boolean): boolean {
    if (typeof this.showPublishToSupplier === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowPublishToSupplier');
      if (settingValue != null) {
        this.showPublishToSupplier = settingValue;
      } else {
        this.showPublishToSupplier = defaultValue;
      }
    }
    return this.showPublishToSupplier;
  }

  get_mandatoryCostCentersInDetails(defaultValue: boolean): boolean {
    if (typeof this.mandatoryCostCentersInDetails === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'MandatoryCostCentersInDetails');
      if (settingValue != null) {
        this.mandatoryCostCentersInDetails = settingValue;
      } else {
        this.mandatoryCostCentersInDetails = defaultValue;
      }
    }
    return this.mandatoryCostCentersInDetails;
  }

  get_showContractVersions(defaultValue: boolean): boolean {
    if (typeof this.showContractVersions === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowContractVersions');
      if (settingValue != null) {
        this.showContractVersions = settingValue;
      } else {
        this.showContractVersions = defaultValue;
      }
    }
    return this.showContractVersions;
  }

  get_showCentralPayment(defaultValue: boolean): boolean {
    if (typeof this.showCentralPayment === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'ShowCentralPayment');
      if (settingValue != null) {
        this.showCentralPayment = settingValue;
      } else {
        this.showCentralPayment = defaultValue;
      }
    }
    return this.showCentralPayment;
  }

  get_warnUserWhenUnsavedChanges(defaultValue: boolean): boolean {
    if (typeof this.warnUserWhenUnsavedChanges === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'WarnUserWhenUnsavedChanges');
      if (settingValue != null) {
        this.warnUserWhenUnsavedChanges = settingValue;
      } else {
        this.warnUserWhenUnsavedChanges = defaultValue;
      }
    }
    return this.warnUserWhenUnsavedChanges;
  }
  

  get_useProductCostCenter(defaultValue: boolean): boolean {
    if (typeof this.useProductCostCenter === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'UseProductCostCenter');
      if (settingValue != null) {
        this.useProductCostCenter = settingValue;
      } else {
        this.useProductCostCenter = defaultValue;
      }
    }
    return this.useProductCostCenter;
  }

  get_useProductReclassificationCode(defaultValue: boolean): boolean {
    if (typeof this.useProductReclassificationCode === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'UseProductReclassificationCode');
      if (settingValue != null) {
        this.useProductReclassificationCode = settingValue;
      } else {
        this.useProductReclassificationCode = defaultValue;
      }
    }
    return this.useProductReclassificationCode;
  }

  get_useProductNetUnitBuyPrice(defaultValue: boolean): boolean {
    if (typeof this.useProductNetUnitBuyPrice === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'UseProductNetUnitBuyPrice');
      if (settingValue != null) {
        this.useProductNetUnitBuyPrice = settingValue;
      } else {
        this.useProductNetUnitBuyPrice = defaultValue;
      }
    }
    return this.useProductNetUnitBuyPrice;
  }

  //CONTRACTS - LISTA
  get_showSignatoryInterlocutorFilters(defaultValue: boolean): boolean {
    if (typeof this.showSignatoryInterlocutorFilters === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contracts', 'ShowSignatoryInterlocutorFilters');
      if (settingValue != null) {
        this.showSignatoryInterlocutorFilters = settingValue;
      } else {
        this.showSignatoryInterlocutorFilters = defaultValue;
      }
    }
    return this.showSignatoryInterlocutorFilters;
  }

  get_contractNumberLabel(defaultValue: string): string {
    if (typeof this.contractNumberLabel === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contracts', 'ContractNumberLabel');
      if (settingValue != null) {
        this.contractNumberLabel = settingValue;
      } else {
        this.contractNumberLabel = defaultValue;
      }
    }
    return this.contractNumberLabel;
  }

  get_afterSaveRemainOnSamePage(defaultValue: boolean): boolean {
    if (typeof this.afterSaveRemainOnSamePage === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'AfterSaveRemainOnSamePage');
      if (settingValue != null) {
        this.afterSaveRemainOnSamePage = settingValue;
      }
      else {
        this.afterSaveRemainOnSamePage = defaultValue;
      }
    }
    return this.afterSaveRemainOnSamePage;
  }

  get_getNextContractTypeNumberButton(defaultValue: boolean): boolean {
    if (typeof this.getNextContractTypeNumberButton === 'undefined') {
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'GetNextContractTypeNumberButton');
      if (settingValue != null) {
        this.getNextContractTypeNumberButton = settingValue;
      }
      else {
        this.getNextContractTypeNumberButton = defaultValue;
      }
    }
    return this.getNextContractTypeNumberButton;
  }

  get_redirectOnUpdate(defaultValue: boolean): boolean {
    if (typeof this.redirectOnUpdate === 'undefined') { // verificar se ainda nao tem valor
      let settingValue = this.authenticationService.getSettingPortal('Contract', 'RedirectOnUpdate');
      if (settingValue != null) {
        this.redirectOnUpdate = settingValue;
      } else {
        this.redirectOnUpdate = defaultValue;
      }
    }
    return this.redirectOnUpdate;
  }
  // FIM - Settings
}
