// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

// ***__***_________  SERVICOS _________ ***__***
import { OrderService } from '../../services/order.service';

// ***__***_________  MODALS _________ ***__***
import { ReceptionModalComponent } from './reception-modal.component';

// ***__***_________  MODELOS _________ ***__***
import { Order, OrderDetail, OrderPod } from '../../models/order';
import { ReturnStatusHtml } from '../../models/returnStatus';

// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../../modules/treatments.module';

// ***__***_________  PIPE _________ ***__***
import { DateTimePickerDirective } from '../../directives/datepicker.directive';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './viewReceptions-modal.html'
})
export class ViewReceptionsModalComponent implements OnInit, OnDestroy {

  /* ***************************************************
 * *********** MODAL --> Listagem de receções ***********
 * ***************************************************
 * */
  destroy$: Subject<boolean> = new Subject<boolean>();
  // 3 avaliações p/ Otis
  deliveryDateEvalList: Array<any> = new Array<any>();
  receivedQuantityEvalList: Array<any> = new Array<any>();
  productEvalList: Array<any> = new Array<any>();
  // avaliação por defeito
  evaluationEvalList: Array<number> = new Array<number>();

  receptionStates: Array<number> = new Array<number>();
  model: Array<OrderPod> = new Array<OrderPod>();
  receptions: Array<any> = new Array<any>();
  openArrays: Array<boolean> = new Array<boolean>();
  allowEdit: boolean; // se tem permissoes de edicao
  // open: boolean = true;
  isDelegation: boolean;
  isUserRole: boolean;
  receptionRole: number;
  order: Order = new Order();

  isReceptionHasExtendedEvals: boolean = false;
  otisReceptionNumberValidation: boolean = false;
  showReturnedQuantity: boolean = false;
  hideReceptionNumber: boolean = false;
  guideNotMandatory: boolean = false;
  parent: any = null;

  constructor(public dialogRef: MatDialogRef<ViewReceptionsModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private orderService: OrderService,
    private dialog: MatDialog, private _errorTreat: ErrorTreatmentFunctions) {

    this.allowEdit = data.allowEdit;
    this.receptions = data.receptions; // lista de receções a apresentar
    this.order = data.order;
    this.deliveryDateEvalList = data.deliveryDateEvalList;
    this.receivedQuantityEvalList = data.receivedQuantityEvalList;
    this.productEvalList = data.productEvalList;
    this.receptionStates = JSON.parse(JSON.stringify(data.receptionStates)); // lista de estados de receção
    this.isUserRole = data.isUserRole;
    this.receptionRole = data.receptionRole;
    this.isDelegation = data.isDelegation;
    this.isReceptionHasExtendedEvals = data.isReceptionHasExtendedEvals;
    this.otisReceptionNumberValidation = data.otisReceptionNumberValidation;
    this.evaluationEvalList = data.evaluationEvalList;
    this.showReturnedQuantity = data.showReturnedQuantity;
    this.hideReceptionNumber = data.hideReceptionNumber;
    this.guideNotMandatory = data.guideNotMandatory;
    this.parent = data.parent;
  }

  ngOnInit(): void {

    let that = this;
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    this.receptions.forEach((item: any) => {
      let orderPod = new OrderPod();
      orderPod.ID = item.ID;
      orderPod.OrderID = item.OrderID;
      orderPod.OrderDetailID = item.OrderDetailID;
      orderPod.OrderStatusReportNumber = item.OrderStatusReportNumber;
      orderPod.OrderStatusReportDate = DateTimePickerDirective.convertToString(item.OrderStatusReportDate, true);
      orderPod.BuyerReceptionNumber = item.BuyerReceptionNumber;
      orderPod.TransportDocumentNumber = item.TransportDocumentNumber;
      orderPod.OriginalLineNumber = item.OriginalLineNumber;
      orderPod.BuyerProductCode = item.BuyerProductCode ? item.BuyerProductCode :
        item.StandardProductCode ? item.StandardProductCode :
          item.SupplierProductCode ? item.SupplierProductCode : '';
      orderPod.ProductDescription = item.ProductDescription;
      orderPod.Notes = item.Notes;
      orderPod.RequestedQuantity = item.RequestedQuantity;
      orderPod.DeliveredQuantity = item.DeliveredQuantity;
      orderPod.ReturnedQuantity = item.ReturnedQuantity;
      orderPod.DeliveryDateEval = item.DeliveryDateEval;
      orderPod.ReceivedQuantityEval = item.ReceivedQuantityEval;
      orderPod.ProductEval = item.ProductEval;
      orderPod.Evaluation = item.Evaluation;
      orderPod.Editable = this.isEditable(this.order, orderPod);
      this.openArrays.push(false);
      this.model.push(orderPod);
    });
    this.model.sort((a, b) => a.OrderStatusReportDate.localeCompare(b.OrderStatusReportDate));
  }

  // verifica se é editável
  isEditable(order: Order, orderPod: OrderPod): boolean {
    let orderDetail: OrderDetail = null;

    if (order === null || order.Details === null || orderPod === null) {
      return false;
    }

    // GM 2019-03-19 se for otis e a receção tiver quantidade recebida == 0 não pode ser editada
    if (this.otisReceptionNumberValidation && orderPod.DeliveredQuantity === 0) {
      return false;
    }

    // obter o detalhe da encomenda a que a recepção faz referencia
    for (let i = 0; i < order.Details.length; i++) {
      if (order.Details[i].ID === orderPod.OrderDetailID) {
        orderDetail = order.Details[i] as OrderDetail;
        break;
      }
    }

    if (orderDetail === null) {
      return false;
    }

    // a linha da encomenda não pode ter quantidades facturadas
    // o estado da encomenda tem de permitir recepções - incluir recebida
    // o perfil do user tem de ser um em particular se especificado

    if (this.receptionStates.length === 0 || this.receptionStates === null) {
      this.receptionStates.push(0);
    }

    // incluir o estado recebida 41
    this.receptionStates.push(41);

    // incluir o estado faturada 42
    this.receptionStates.push(42);

    let findState = this.receptionStates.find((x: any) => x === order.DocumentState) ? true : false;
    // GM 2018-10-11 Permitir alterar apenas o Nº da Guia em receções que já foram faturadas
    if (this.receptionRole > 0) {
      // return (orderDetail.InvoiceToQuantity === 0 || orderDetail.InvoiceToQuantity === null) && findState && this.isUserRole;
      return findState && this.isUserRole;
    } else {
      // return (orderDetail.InvoiceToQuantity === 0 || orderDetail.InvoiceToQuantity === null) && findState;
      return findState;
    }
  }

  editOrConsultReception(orderID: number, orderPodIndex: number, orderDetailID: number) {
    this.orderService.get(orderID, false, true, false, false, false, null).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
      if (response.ReturnStatus.Successfull) {
        if (response.ReturnStatus.ReturnObject.Order) {
          let orderResp = response.ReturnStatus.ReturnObject.Order as Order;
          // this.order.Details = orderResp.Details;

          let orderPod: OrderPod = this.model[orderPodIndex];
          let detail: OrderDetail = orderResp.Details.find((x: any) => (+x.ID) === orderDetailID);

          // enviar detalhes para a Modal receção
          let dialogRef = this.dialog.open(ReceptionModalComponent, {
            data: {
              allowEdit: orderPod.Editable,
              deliveryDateEvalList: this.deliveryDateEvalList,
              receivedQuantityEvalList: this.receivedQuantityEvalList,
              productEvalList: this.productEvalList,
              orderPod: orderPod,
              detail: detail,
              documentNumber: orderResp.DocumentNumber,
              documentState: orderResp.DocumentState,
              receptionStates: this.receptionStates,
              isDelegation: this.isDelegation,
              documentType: orderResp.DocumentType,
              deliveryLocationIdentifier: orderResp.DeliveryLocationIdentifier,
              isReceptionHasExtendedEvals: this.isReceptionHasExtendedEvals,
              evaluationEvalList: this.evaluationEvalList,
              showReturnedQuantity: this.showReturnedQuantity,
              hideReceptionNumber: this.hideReceptionNumber,
              guideNotMandatory: this.guideNotMandatory
            },
            disableClose: true, // nao permitir fechar modal com escape ou clique fora
          });

          dialogRef.afterClosed().subscribe((result: any) => {
            if (result != null) {
              /*
              let orderPodResult = result as OrderPod;

              let orderPodUpdated: OrderPod = this.model.find((x: OrderPod) => x.ID === orderPodResult.ID);
              orderPodUpdated.BuyerReceptionNumber = orderPodResult.BuyerReceptionNumber;
              orderPodUpdated.TransportDocumentNumber = orderPodResult.TransportDocumentNumber;
              orderPodUpdated.DeliveredQuantity = orderPodResult.DeliveredQuantity;
              orderPodUpdated.Notes = orderPodResult.Notes;
              orderPodUpdated.DeliveryDateEval = orderPodResult.DeliveryDateEval;
              orderPodUpdated.ProductEval = orderPodResult.ProductEval;
              orderPodUpdated.ReceivedQuantityEval = orderPodResult.ReceivedQuantityEval;
              orderPodUpdated.Evaluation = orderPodResult.Evaluation;
              */
              // GM 2018-10-11 O metodo pode alterar várias receções e não só esta em particular
              this.refreshReceptions();
              //o this.parent refere-se ao componente das encomendas (lista)
              //chamamos o método reloadOrders() desse componente, para que cada vez que se edite a receção, as alterações possam ser refletidas na lista
              this.parent.reloadOrders();
            }
          });
        }
      } else { // o que acontece que der erro
        this._errorTreat.treatErrorResponse(response);
      }
    });
  }

  // ver lista receções
  refreshReceptions(): void {
    if (this.order) {
      this.orderService.getAllPODsByID(this.order.ID).pipe(takeUntil(this.destroy$)).subscribe((response: ReturnStatusHtml) => {
        if (response.ReturnStatus.Successfull) {
          if (response.ReturnStatus.ReturnObject.Receptions) {
            let receptions = response.ReturnStatus.ReturnObject.Receptions;
            // limpar o modelo para carregar com a lista atualizada
            this.model = [];
            receptions.forEach((item: any) => {
              let orderPod = new OrderPod();
              orderPod.ID = item.ID;
              orderPod.OrderID = item.OrderID;
              orderPod.OrderDetailID = item.OrderDetailID;
              orderPod.OrderStatusReportNumber = item.OrderStatusReportNumber;
              orderPod.OrderStatusReportDate = DateTimePickerDirective.convertToString(item.OrderStatusReportDate, true);
              orderPod.BuyerReceptionNumber = item.BuyerReceptionNumber;
              orderPod.TransportDocumentNumber = item.TransportDocumentNumber;
              orderPod.OriginalLineNumber = item.OriginalLineNumber;
              orderPod.BuyerProductCode = item.BuyerProductCode ? item.BuyerProductCode :
                item.StandardProductCode ? item.StandardProductCode :
                  item.SupplierProductCode ? item.SupplierProductCode : '';
              orderPod.ProductDescription = item.ProductDescription;
              orderPod.Notes = item.Notes;
              orderPod.RequestedQuantity = item.RequestedQuantity;
              orderPod.DeliveredQuantity = item.DeliveredQuantity;
              orderPod.ReturnedQuantity = item.ReturnedQuantity;
              orderPod.DeliveryDateEval = item.DeliveryDateEval;
              orderPod.ReceivedQuantityEval = item.ReceivedQuantityEval;
              orderPod.ProductEval = item.ProductEval;
              orderPod.Evaluation = item.Evaluation;
              orderPod.Editable = this.isEditable(this.order, orderPod);
              this.openArrays.push(false);
              this.model.push(orderPod);
            });
            this.model.sort((a, b) => a.OrderStatusReportDate.localeCompare(b.OrderStatusReportDate));
          }
        } else { // o que acontece que der erro
          this._errorTreat.treatErrorResponse(response);
        }
      });
    }
  }

  // showObservations(position: number, open: boolean) {
  showObservations(position: number) {
    // quando clico em adicionar linha retirar class hide p/ mostrar
    document.getElementById('obs-' + position).classList.remove('hide');

    let open = (!this.openArrays[position].valueOf());

    if (open) { // se for abrir
      document.getElementById('obs-icon-' + position).classList.remove('fa-folder');
      document.getElementById('obs-icon-' + position).classList.add('fa-folder-open');
      this.openArrays[position] = true;
    } else { // se for fechar
      document.getElementById('obs-icon-' + position).classList.remove('fa-folder-open');
      document.getElementById('obs-icon-' + position).classList.add('fa-folder');
      document.getElementById('obs-' + position).classList.add('hide');
      this.openArrays[position] = false;
    }
  }

  ngOnDestroy() { }
}
