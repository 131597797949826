import { Optional } from '@angular/core';
import { CostCenterConfig, TransformModelCC } from './costCenterConfig';
import { DecimalPipe } from '@angular/common';
import { CURRENCY_DECIMAL, QUANTITY_DECIMAL, UNITARY_PRICE_DECIMAL } from '../constants/global';
import { DateTimePickerDirective } from '../directives/datepicker.directive';
import { AccountingConfig, TransformModelAC } from './accountingConfig';
import { TipologiaProdutoServico } from './tipologia-produto-servico';
import { GenericFieldExtension } from './generic-field-extension';

export const enum LogisticOrderType { PREPARATION = 'preparation', RECEPTION = 'reception' }

export const enum LogisticOrderContext { SHIPPINGS = 'shippings', RECEPTIONS = 'receptions' }

export const enum OrderDocumentState { STATE_SUBMETIDA = 1, STATE_EXPORTADA = 5, STATE_GRAVADA = 8, 
  STATE_VALIDADA = 10, STATE_SUBMETIDA_3PL = 20, STATE_REJEITADA = 32, STATE_GERADA = 44, STATE_EMAPROVACAO = 3, STATE_PARCIALMENTE_ENCOMENDADA = 50, STATE_NOTIFICADA = 54, STATE_POR_COTAR = 73, 
  STATE_CONTROLO_ORÇAMENTAL = 74, STATE_CONFIRMADA_FORNECEDOR = 81, STATE_ALTERADA_FORNECEDOR = 82, STATE_ALTERACAO_CONFIRMADA = 83, STATE_ALTERACAO_REJEITADA = 84, STATE_ERRO = 99 }

export const enum OrderDocumentType { ORDER = 'Order', ORDER_CONTRACT = 'OrderContract', REQUEST_EXTERNAL = 'RequestExternal', REQUEST_INTERNAL = 'RequestInternal', ORDER_CREDIT_NOTICE = 'NCOrder', ORDER_CREDIT_NOTICE_PROPERTIES = 'NCPropOrder', PROPERTIES_ORDER = 'PropertiesOrder', REQUEST = 'Request', ASSET_ORDER = 'AssetOrder' }

export const enum OrderDetailsLineStates {STATE_REGISTADO = 'Registado', STATE_ENCOMENDADO = 'Encomendado', STATE_RECEBIDO = 'Recebido',STATE_RECEBIDO_PARCIALMENTE = 'Parcialmente Recebido', STATE_CANCELADO = 'Cancelado',STATE_FACTURADO = 'Facturado',STATE_REJEITADO = 'Rejeitado',STATE_ELIMINADO = 'Eliminado'}

export class Order {
  constructor(
    @Optional() public dataInicio: string = null,
    @Optional() public dataFim: string = null,
    // Alguns dos campos obrigatórios
    public ID: number = 0,
    public OwnerID: number = 0,
    public SupplierID: number = 0,
    public BuyerID: number = 0,
    public DocumentState: number = 0,
    public DocumentYear: number = 0,

    @Optional() public EDIMessageSenderID: number = null,
    @Optional() public EDIMessageReceiverID: number = null,
    @Optional() public DaysDue: number = null,
    @Optional() public DeliveryLocationID: number = null,
    @Optional() public LastUpdatedBy: number = null,
    @Optional() public BuyerWorkflowID: number = 0,
    @Optional() public InvoiceToID: number = null,

    @Optional() public CreatedBy: number = null,
    @Optional() public CostCenterID: number = null,
    @Optional() public MasterOrderID: number = null,
    @Optional() public SupplierWorkflowID: number = null,
    @Optional() public ShipFromID: number = null,
    @Optional() public ContractID: number = null,
    @Optional() public TotalBilledAmount: number = null,
    @Optional() public OriginLocationID: number = null,
    @Optional() public BlockedBy: number = null,
    @Optional() public TotalWeight: number = null,
    @Optional() public TotalVolume: number = null,
    @Optional() public CrossDockingID: number = null,

    @Optional() public Source: string = null,
    @Optional() public LastUpdated: string = null,
    @Optional() public Aggregate: boolean = null,
    @Optional() public IsMaster: boolean = null,
    @Optional() public HasOstrptPDF: boolean = null,
    @Optional() public DirectPurchase: boolean = null,
    @Optional() public BuyerIdentifier: string = null,
    // Identificação do documento
    @Optional() public DocumentType: string = null,
    @Optional() public CreationDate: string = null,
    @Optional() public DocumentNumber: string = null,
    @Optional() public DocumentDate: string = null,
    public TotalNetAmount: number = 0,
    @Optional() public CurrencyCode: string = null,
    @Optional() public CurrencyExchange: number = null, // Câmbio
    @Optional() public OrderedBy: string = null,
    @Optional() public CostCenterName: string = null,
    // Fornecedor
    @Optional() public SupplierInternalID: string = null,
    @Optional() public SupplierIdentifier: string = null,
    @Optional() public SupplierName: string = null,
    @Optional() public SupplierStreet: string = null,
    @Optional() public SupplierPostalCode: string = null,
    @Optional() public SupplierCity: string = null,
    @Optional() public SupplierCountryCode: string = null,
    @Optional() public SupplierTaxNumber: string = null,
    @Optional() public EmailsTo: string = null,
    // Origem
    @Optional() public OriginLocationIdentifier: string = null,
    @Optional() public OriginLocationName: string = null,
    @Optional() public OriginLocationStreet: string = null,
    @Optional() public OriginLocationPostalCode: string = null,
    @Optional() public OriginLocationCity: string = null,
    @Optional() public OriginLocationCountryCode: string = null,
    @Optional() public EarliestReceptionDate: string = null,
    @Optional() public LatestReceptionDate: string = null,
    // Entrega
    @Optional() public DeliveryLocationInternalID: string = null,
    @Optional() public DeliveryLocationIdentifier: string = null,
    @Optional() public DeliveryLocationName: string = null,
    @Optional() public DeliveryLocationStreet: string = null,
    @Optional() public DeliveryLocationCountryCode: string = null,
    @Optional() public DeliveryLocationPostalCode: string = null,
    @Optional() public DeliveryLocationCity: string = null,
    @Optional() public BuyerDepartmentCode: string = null,
    @Optional() public BuyerDepartmentName: string = null,
    @Optional() public BuyerDivisionCode: string = null,
    @Optional() public BuyersTransportDocumentNumber: string = null,
    @Optional() public EarliestAcceptableDate: string = null,
    @Optional() public LatestAcceptableDate: string = null,
    @Optional() public DeliveryTerms: string = null,
    // Faturar a
    @Optional() public InvoiceToInternalID: string = null,
    @Optional() public InvoiceToIdentifier: string = null,
    @Optional() public InvoiceToName: string = null,
    @Optional() public InvoiceToStreet: string = null,
    @Optional() public InvoiceToCountryCode: string = null,
    @Optional() public InvoiceToPostalCode: string = null,
    @Optional() public InvoiceToCity: string = null,
    @Optional() public InvoiceToTaxNumber: string = null,
    @Optional() public MeansOfPayment: string = null,
    // Obervações
    @Optional() public Notes: OrderNote[] = null,
    // Referências
    @Optional() public OtherReferences: OrderReference[] = null,
    // Mensagem EDI
    @Optional() public EDIMessageID: string = null,
    @Optional() public EDIMessageDate: string = null,
    @Optional() public EDIMessageSender: string = null,
    @Optional() public EDIMessageReceiver: string = null,
    // Outros
    @Optional() public Details: OrderDetail[] = null,


    @Optional() public CurrencyCodeOther: string = null,
    @Optional() public TradeDiscountCSV: string = null,
    @Optional() public BuyerTaxNumber: string = null,
    @Optional() public BuyerInternalID: string = null,
    @Optional() public BuyerName: string = null,
    @Optional() public BuyerStreet: string = null,
    @Optional() public BuyerCity: string = null,
    @Optional() public BuyerPostalCode: string = null,
    @Optional() public BuyerCountryCode: string = null,
    @Optional() public SupplierIntegrationID: string = null,
    @Optional() public AggregateCode: string = null,
    @Optional() public SupplierDeliveryNoteNumber: string = null,
    @Optional() public IsHistory: boolean = null,
    @Optional() public Urgent: number = null,
    @Optional() public IgnoreAggregate: string = null,
    @Optional() public SupplierReadDate: string = null,

    // Outros campos
    public NotesCSV: string = null,
    @Optional() public StateIsPending: boolean = null,
    @Optional() public StateIsEditable: boolean = null,
    @Optional() public UserHasActions: boolean = null,

    // Distribuicao
    @Optional() public CostCentersConfig: CostCenterConfig[] = null,


    // *********** VARIAVEIS DO FORM (AUXILIARES) ***********
    @Optional() public TotalNetAmount_form: string = null, // liquido
    @Optional() public CurrencyExchange_form: string = null, // Câmbio
    @Optional() public TotalWeight_form: string = null, // peso
    @Optional() public TotalVolume_form: string = null, // volume

    @Optional() public HasCostCenterInDetails: boolean = null,
    @Optional() public WmsControlCode: string = null,

    @Optional() public AccountingConfigList: AccountingConfig[] = null,

    //Condições de Pagamento
    @Optional() public Paid: boolean = false, // pago,
    @Optional() public ContractVerifyReception: boolean = null,
    @Optional() public ExcessAmount: number = null,
    @Optional() public ContractNumber: string = null,
    @Optional() public CentralPayment: boolean = null,

    // ID do Grupo de Utilizador
    @Optional() public UserGroupID: number = null,

    // ID do Pedido de Cotação associado
    @Optional() public QuotationID: number = null,
    
    //Array de ExtendedFields
    @Optional() public FieldsExtensions: GenericFieldExtension[] = [],

    @Optional() public PaymentCondition: string = null,

    //Armazém de cross docking
    @Optional() public CrossDockingIdentifier: string = null,
    @Optional() public CrossDockingName: string = null,
    @Optional() public CrossDockingStreet: string = null,
    @Optional() public CrossDockingPostalCode: string = null,
    @Optional() public CrossDockingCity: string = null,
    @Optional() public CrossDockingCountryCode: string = null,

    @Optional() public InvoiceID: number = null,
    @Optional() public InvoiceNumber: string = null
  ) { }
}
export class OrderNote {
  constructor(
    @Optional() public NoteType: string = null,
    @Optional() public NoteValue: string = null,
  ) { }
}
export class OrderDetail {
  constructor(
    public ID: number = 0,
    @Optional() public OrderID: number = null,
    @Optional() public OrderDetailID: number = null,
    @Optional() public FamilyID: number = null,
    @Optional() public CreatedBySupplier: number = null,
    // Identificação da linha
    @Optional() public LineNumber: number = null,
    @Optional() public LineType: string = null,
    @Optional() public LineTypeOther: string = null,
    @Optional() public BuyerProductCode: string = null,
    @Optional() public SupplierProductCode: string = null,
    @Optional() public StandardProductCode: string = null,
    @Optional() public ProductDescription: string = null,
    @Optional() public ProductID: number = null,
    // Quantidades e preços
    @Optional() public OrderedQuantity: number = null,
    @Optional() public OrderedQuantityUOMCode: string = null,
    @Optional() public OrderedQuantityUOMCodeOther: string = null,
    @Optional() public UOMCodesCSV: string = null,
    @Optional() public BonusQuantity: number = null,
    @Optional() public BonusQuantityUOMCode: string = null,
    @Optional() public NetUnitPrice: number = null,
    @Optional() public GrossUnitPrice: number = null,
    @Optional() public TotalNetAmount: number = null,
    @Optional() public TotalGrossAmount: number = null,
    @Optional() public BuyersGlobalOrderNumber: number = null,
    @Optional() public BuyersGlobalOrderType: number = null,
    // Local de entrega por linha
    @Optional() public DeliverLocationID: string = null,
    @Optional() public DeliveryLocationInternalID: string = null,
    @Optional() public DeliveryLocationIdentifier: string = null,
    @Optional() public DeliveryLocationName: string = null,
    @Optional() public DeliveryLocationStreet: string = null,
    @Optional() public DeliveryLocationCountryCode: string = null,
    @Optional() public DeliveryLocationPostalCode: string = null,
    @Optional() public DeliveryLocationCity: string = null,
    @Optional() public DeliveryEarliestAcceptableDate: string = null,
    @Optional() public DeliveryLatestAcceptableDate: string = null,
    // Embalagens
    @Optional() public PackQuantity: number = null,
    @Optional() public PackQuantityUOM: string = null,
    @Optional() public PackSize: number = null,
    @Optional() public Quantity: number = null,
    @Optional() public PackQuantityUOMCode: string = null,
    @Optional() public PackQuantityUOMCodeOther: string = null,
    @Optional() public PackUnitsPerLayer: number = null,
    @Optional() public PackAverageGrossWeight: number = null,
    // Fornecedor
    @Optional() public SupplierID: number = null,
    @Optional() public SupplierIdentifier: string = null,
    @Optional() public SupplierName: string = null,
    // Taxas
    @Optional() public Taxes: OrderTax[] = null,
    // Abonos
    @Optional() public Allowances: OrderAllowance[] = null,
    // Descontos
    @Optional() public Discounts: OrderDiscount[] = null,
    // Referências
    @Optional() public OtherReferences: OrderReference[] = null,
    // Observações
    @Optional() public Notes: OrderNote[] = null,
    @Optional() public NotesCSV: string = null,
    @Optional() public NoteToShow: string = null,
    // Estado
    @Optional() public LineState: string = null,
    @Optional() public RevisedQuantity: number = null,
    @Optional() public DespatchedQuantity: number = null,
    @Optional() public SatisfiedQuantity: number = null,
    @Optional() public ReceivedQuantity: number = null,
    // Bloquedo por
    @Optional() public OrderedBy: string = null,

    // Campos usados na modal receção
    @Optional() public DeliveryDateEval: string = null,
    @Optional() public ReceivedQuantityEval: string = null,
    @Optional() public ProductEval: string = null,
    @Optional() public Evaluation: string = null,
    @Optional() public InvoiceToQuantity: number = null,

    @Optional() public MasterOrderDetailID: number = null,
    @Optional() public ChildCreatedBy: number = null,
    @Optional() public CostCenterID: number = null,
    @Optional() public ProductPackID: number = null,
    @Optional() public PriceListProductStepID: number = null,
    @Optional() public UnitAmountMaxDiference: number = null,
    @Optional() public PercentageMaxDiference: number = null,
    @Optional() public TotalWeight: number = null,
    @Optional() public TotalVolume: number = null,
    @Optional() public HandlerUserID: number = null,

    // Distribuicao
    @Optional() public CostCentersConfig: CostCenterConfig[] = null,


    // *********** VARIAVEIS DO FORM (AUXILIARES) ***********
    @Optional() public OrderedQuantity_form: string = null,
    @Optional() public BonusQuantity_form: string = null,
    @Optional() public NetUnitPrice_form: string = null,
    @Optional() public GrossUnitPrice_form: string = null,
    @Optional() public TotalNetAmount_form: string = null,
    @Optional() public TotalGrossAmount_form: string = null,
    @Optional() public PackQuantity_form: string = null,
    @Optional() public PackSize_form: string = null,
    @Optional() public Quantity_form: string = null,
    @Optional() public PackUnitsPerLayer_form: string = null,
    @Optional() public PackAverageGrossWeight_form: string = null,

    @Optional() public RevisedQuantity_form: string = null,
    @Optional() public DespatchedQuantity_form: string = null,
    @Optional() public SatisfiedQuantity_form: string = null,
    @Optional() public ReceivedQuantity_form: string = null,

    // necessaria para enviar para o form
    @Optional() public QuantityIncrement: number = null,
    @Optional() public MinimumQuantity: number = null,

    @Optional() public InvoiceToQuantity_form: string = null,

    @Optional() public ReturnedQuantity: number = null,
    @Optional() public ReturnedQuantity_form: string = null,
    @Optional() public AccountingConfigList: AccountingConfig[] = null,
    @Optional() public TipologiaProdutoServicoID: number = null,
    @Optional() public TipologiaProdutoServico: TipologiaProdutoServico = null,

    @Optional() public ApprovedProposalNumber: string = null,
    @Optional() public TotalValue: number = null,
    @Optional() public IntegrationState: string = null,

    //Campos usados na Resposta do Fornecedor
    @Optional() public SupplierNetUnitPrice: number = null,
    @Optional() public SupplierDeliveryDate: string = null,
    
    @Optional() public FieldsExtensions: GenericFieldExtension[] = [],

    //Unidade de preço
    @Optional() public PriceUnit: number = null,

    //Código contabilístico
    @Optional() public AccountingCode: string = null,
    
    /** Numero de documento da requisição de onde este detalhe foi criado */
    @Optional() public RequestDocumentNumber: string = null,

    /** Numero da linha do detalhe da requisição de onde este detalhe foi criado */
    @Optional() public RequestLineNumber: number = null,

    //Nome do centro de custo
    @Optional() public CostCenterName: string = null,
    @Optional() public ID_unic: number = null,

    @Optional() public Close: boolean = null,
    @Optional() public InternalNoteToShow: string = null,
    
    @Optional() public ProductFoundCode: string = null,
    @Optional() public ProductFoundDescription: string = null,

  ) { }
}
export class OrderTax {
  constructor(
    @Optional() public AmountCurrencyCode: string = null,
    @Optional() public AmountCurrencyCodeOther: string = null,
    @Optional() public TaxAmount: number = null,
    @Optional() public TaxRate: number = null,
    @Optional() public TaxTypeCode: string = null,
    @Optional() public TaxTypeCodeOther: string = null,
    @Optional() public TaxTypeOther: string = null,
    @Optional() public TaxCurrency: string = null,

    // VARIAVEIS FORM
    @Optional() public TaxAmount_form: string = null, // valor
    @Optional() public TaxRate_form: string = null,

  ) { }
}
export class OrderAllowance {
  constructor(
    @Optional() public AllowanceType: string = null, // tipo
    @Optional() public Percentage: number = null, // percentagem
    @Optional() public Amount: number = null, // valor
    @Optional() public AmountCurrencyCode: string = null,
    @Optional() public AmountCurrencyCodeOther: string = null
  ) { }
}
export class OrderDiscount {
  constructor(
    @Optional() public DiscountType: string = null, // tipo
    @Optional() public Percentage: number = null, // percentagem
    @Optional() public Amount: number = null, // valor
    @Optional() public AmountCurrencyCode: string = null, // codigo
    @Optional() public AmountCurrencyCodeOther: string = null, // codigo

    // VARIAVEIS FORM
    @Optional() public Amount_form: string = null, // valor
    @Optional() public Percentage_form: string = null // percentagem
  ) { }
}
export class OrderReference {
  constructor(
    @Optional() public ReferenceType: string = null,
    @Optional() public ReferenceValue: string = null,
    @Optional() public ReferenceDate: string = null
  ) { }
}
export class OrderRepositoryDiscFile {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public Context: string = null,
    @Optional() public EntityID: number = null,
    @Optional() public FileType: string = null,
    @Optional() public OriginalFilename: string = null,
    @Optional() public IsPublic: string = null,
    @Optional() public DiskFileTypeID: string = null,
    @Optional() public Description: string = null,
  ) { }
}
export class OrderBudget {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public OwnerID: number = null,
    @Optional() public Name: string = null,
    @Optional() public StartDate: string = null,
    @Optional() public PeriodInterval: number = null,
    @Optional() public AuthorizedValue: number = null,
    @Optional() public ActualValue: number = null,
    @Optional() public LastUpdated: string = null,
    @Optional() public IsSupplier: number = null,
    @Optional() public IsWorkflowBudget: number = null

  ) { }
}
export class OrderPod {
  constructor(
    @Optional() public ID: number = null,
    @Optional() public OrderID: number = null,
    @Optional() public OrderDetailID: number = null,
    @Optional() public OrderStatusReportNumber: string = null,
    @Optional() public OrderStatusReportDate: string = null,
    @Optional() public BuyerReceptionNumber: string = null,
    @Optional() public TransportDocumentNumber: string = null,
    @Optional() public OriginalLineNumber: string = null,
    @Optional() public BuyerProductCode: string = null,
    @Optional() public StandardProductCode: string = null,
    @Optional() public SupplierProductCode: string = null,
    @Optional() public ProductDescription: string = null,
    @Optional() public Notes: string = null,
    @Optional() public RequestedQuantity: number = null,
    @Optional() public DeliveredQuantity: number = null,
    @Optional() public Editable: boolean = null,
    @Optional() public DeliveryDateEval: string = null,
    @Optional() public ReceivedQuantityEval: string = null,
    @Optional() public ProductEval: string = null,
    @Optional() public Evaluation: string = null,
    @Optional() public ReturnedQuantity: number = null
  ) { }
}

export class TransformModel {

  tranfModelCC = new TransformModelCC(); // necessario para usar as funcoes de configuração de numeros
  tranfModelAC = new TransformModelAC(); // necessario para usar as funcoes de configuração de numeros

  /**
  * Transforma a(s) ENTIDADE(S) conforme settings
  *
  */
  transformObject(orderObject: Order = null, translLanguage: string = 'pt') {
    let decimalPipe = new DecimalPipe(translLanguage);

    // datas
    orderObject.DocumentDate = orderObject.DocumentDate ? DateTimePickerDirective.convertToString(orderObject.DocumentDate, true) : null;
    orderObject.EarliestReceptionDate = orderObject.EarliestReceptionDate ? DateTimePickerDirective.convertToString(orderObject.EarliestReceptionDate, true) : null;
    orderObject.LatestReceptionDate = orderObject.LatestReceptionDate ? DateTimePickerDirective.convertToString(orderObject.LatestReceptionDate, true) : null;
    orderObject.EarliestAcceptableDate = orderObject.EarliestAcceptableDate ? DateTimePickerDirective.convertToString(orderObject.EarliestAcceptableDate, true) : null;
    orderObject.LatestAcceptableDate = orderObject.LatestAcceptableDate ? DateTimePickerDirective.convertToString(orderObject.LatestAcceptableDate, true) : null;
    orderObject.EDIMessageDate = orderObject.EDIMessageDate ? DateTimePickerDirective.convertToString(orderObject.EDIMessageDate, true) : null;
    orderObject.CreationDate = orderObject.CreationDate ? DateTimePickerDirective.convertToString(orderObject.CreationDate, true) : null;

    // €€€€€
    orderObject.TotalNetAmount_form = orderObject.TotalNetAmount !== null ? decimalPipe.transform(orderObject.TotalNetAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    orderObject.TotalWeight_form = orderObject.TotalWeight !== null ? decimalPipe.transform(orderObject.TotalWeight, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
    orderObject.TotalVolume_form = orderObject.TotalVolume !== null ? decimalPipe.transform(orderObject.TotalVolume, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;

    return orderObject;
  }
  transformObjectDetail(detail: OrderDetail, translLanguage: string): OrderDetail {
    if (detail) {
      let decimalPipe = new DecimalPipe(translLanguage);

      // datas
      detail.DeliveryEarliestAcceptableDate = detail.DeliveryEarliestAcceptableDate ? DateTimePickerDirective.convertToString(detail.DeliveryEarliestAcceptableDate, true) : null;
      detail.DeliveryLatestAcceptableDate = detail.DeliveryLatestAcceptableDate ? DateTimePickerDirective.convertToString(detail.DeliveryLatestAcceptableDate, true) : null;
      detail.SupplierDeliveryDate = detail.SupplierDeliveryDate ? DateTimePickerDirective.convertToString(detail.SupplierDeliveryDate, true) : null;

      // €€€€€
      if (QUANTITY_DECIMAL === '0') {
        detail.OrderedQuantity_form = detail.OrderedQuantity !== null ? detail.OrderedQuantity.toString() : null;
      } else {
        detail.OrderedQuantity_form = detail.OrderedQuantity !== null ? decimalPipe.transform(detail.OrderedQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      }
      detail.BonusQuantity_form = detail.BonusQuantity !== null ? decimalPipe.transform(detail.BonusQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.NetUnitPrice_form = detail.NetUnitPrice !== null ? decimalPipe.transform(detail.NetUnitPrice, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      detail.GrossUnitPrice_form = detail.GrossUnitPrice !== null ? decimalPipe.transform(detail.GrossUnitPrice, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      detail.TotalNetAmount_form = detail.TotalNetAmount !== null ? decimalPipe.transform(detail.TotalNetAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      detail.TotalGrossAmount_form = detail.TotalGrossAmount !== null ? decimalPipe.transform(detail.TotalGrossAmount, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null;
      detail.PackQuantity_form = detail.PackQuantity !== null ? decimalPipe.transform(detail.PackQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.PackSize_form = detail.PackSize !== null ? decimalPipe.transform(detail.PackSize, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.Quantity_form = detail.Quantity !== null ? decimalPipe.transform(detail.Quantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.PackUnitsPerLayer_form = detail.PackUnitsPerLayer !== null ? decimalPipe.transform(detail.PackUnitsPerLayer, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.PackAverageGrossWeight_form = detail.PackAverageGrossWeight !== null ? decimalPipe.transform(detail.PackAverageGrossWeight, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;

      detail.RevisedQuantity_form = detail.RevisedQuantity !== null ? decimalPipe.transform(detail.RevisedQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.DespatchedQuantity_form = detail.DespatchedQuantity !== null ? decimalPipe.transform(detail.DespatchedQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.SatisfiedQuantity_form = detail.SatisfiedQuantity !== null ? decimalPipe.transform(detail.SatisfiedQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.ReceivedQuantity_form = detail.ReceivedQuantity !== null ? decimalPipe.transform(detail.ReceivedQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.InvoiceToQuantity_form = detail.InvoiceToQuantity !== null ? decimalPipe.transform(detail.InvoiceToQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;
      detail.ReturnedQuantity_form = detail.ReturnedQuantity !== null ? decimalPipe.transform(detail.ReturnedQuantity, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null;

      //JJ 30/05/2022 Foi adicionada a validação para não rebentar ao criar uma encomenda através de um Pedido de Cotação
      if(detail.Taxes){
        detail.Taxes.forEach((reference: OrderTax) => {
          this.transformObjectTax(reference, translLanguage);
        });
      }

      if (detail.Discounts) {
        detail.Discounts.forEach((reference: OrderDiscount) => {
          this.transformObjectDiscount(reference, translLanguage);
        });
      }

      return detail;
    }
    return null;
  }
  transformObjectTax(taxObj: OrderTax, translLanguage: string): OrderTax {
    if (taxObj) {
      let decimalPipe = new DecimalPipe(translLanguage);
      taxObj.TaxRate_form = taxObj.TaxRate !== null ? typeof taxObj.TaxRate === 'string' ? taxObj.TaxRate : decimalPipe.transform(taxObj.TaxRate, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      taxObj.TaxAmount_form = taxObj.TaxAmount !== null ? typeof taxObj.TaxAmount === 'string' ? taxObj.TaxAmount : decimalPipe.transform(taxObj.TaxAmount, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      return taxObj;
    }
    return null;
  }
  transformObjectDiscount(discountObj: OrderDiscount, translLanguage: string): OrderDiscount {
    if (discountObj) {
      let decimalPipe = new DecimalPipe(translLanguage);
      discountObj.Percentage_form = discountObj.Percentage !== null ? typeof discountObj.Percentage === 'string' ? discountObj.Percentage : decimalPipe.transform(discountObj.Percentage, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      discountObj.Amount_form = discountObj.Amount !== null ? typeof discountObj.Amount === 'string' ? discountObj.Amount : decimalPipe.transform(discountObj.Amount, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null;
      return discountObj;
    }
    return null;
  }
  /**
  * Transforma o FORMULÁRIO conforme settings
  *
  */
  transformFormDetail(detail: any, translLanguage: string): any {
    if (detail) {

      let decimalPipe = new DecimalPipe(translLanguage);
      detail.get('DeliveryEarliestAcceptableDate').setValue(detail.get('DeliveryEarliestAcceptableDate').value ? DateTimePickerDirective.convertToString(detail.get('DeliveryEarliestAcceptableDate').value, true) : null);
      detail.get('DeliveryLatestAcceptableDate').setValue(detail.get('DeliveryLatestAcceptableDate').value ? DateTimePickerDirective.convertToString(detail.get('DeliveryLatestAcceptableDate').value, true) : null);

      if (QUANTITY_DECIMAL === '0') {
        detail.get('OrderedQuantity').setValue(detail.get('OrderedQuantity').value != null ? detail.get('OrderedQuantity').value.toString() : null);
      } else {
        detail.get('OrderedQuantity').setValue(detail.get('OrderedQuantity').value != null ? decimalPipe.transform(detail.get('OrderedQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      }
      detail.get('BonusQuantity').setValue(detail.get('BonusQuantity').value != null ? decimalPipe.transform(detail.get('BonusQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('NetUnitPrice').setValue(detail.get('NetUnitPrice').value != null ? decimalPipe.transform(detail.get('NetUnitPrice').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null);
      detail.get('GrossUnitPrice').setValue(detail.get('GrossUnitPrice').value != null ? decimalPipe.transform(detail.get('GrossUnitPrice').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null);
      detail.get('TotalNetAmount').setValue(detail.get('TotalNetAmount').value != null ? decimalPipe.transform(detail.get('TotalNetAmount').value, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null);
      detail.get('TotalGrossAmount').setValue(detail.get('TotalGrossAmount').value != null ? decimalPipe.transform(detail.get('TotalGrossAmount').value, '1.' + CURRENCY_DECIMAL + '-' + CURRENCY_DECIMAL) : null);
      detail.get('PackQuantity').setValue(detail.get('PackQuantity').value != null ? decimalPipe.transform(detail.get('PackQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('PackSize').setValue(detail.get('PackSize').value != null ? decimalPipe.transform(detail.get('PackSize').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('Quantity').setValue(detail.get('Quantity').value != null ? decimalPipe.transform(detail.get('Quantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('PackUnitsPerLayer').setValue(detail.get('PackUnitsPerLayer').value != null ? decimalPipe.transform(detail.get('PackUnitsPerLayer').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('PackAverageGrossWeight').setValue(detail.get('PackAverageGrossWeight').value != null ? decimalPipe.transform(detail.get('PackAverageGrossWeight').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);

      detail.get('RevisedQuantity').setValue(detail.get('RevisedQuantity').value != null ? decimalPipe.transform(detail.get('RevisedQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('DespatchedQuantity').setValue(detail.get('DespatchedQuantity').value != null ? decimalPipe.transform(detail.get('DespatchedQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('SatisfiedQuantity').setValue(detail.get('SatisfiedQuantity').value != null ? decimalPipe.transform(detail.get('SatisfiedQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);
      detail.get('ReceivedQuantity').setValue(detail.get('ReceivedQuantity').value != null ? decimalPipe.transform(detail.get('ReceivedQuantity').value, '1.' + QUANTITY_DECIMAL + '-' + QUANTITY_DECIMAL) : null);

      detail.get('Taxes').controls.forEach((reference: any) => {
        reference.get('TaxAmount').setValue(reference.get('TaxAmount').value != null ? decimalPipe.transform(reference.get('TaxAmount').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null);
      });

      if (detail.get('Discounts') && detail.get('Discounts').controls) {
        detail.get('Discounts').controls.forEach((reference: any) => {
          reference.get('Amount').setValue(reference.get('Amount').value != null ? decimalPipe.transform(reference.get('Amount').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null);
        });
      }
      return detail;
    }
    return null;
  }

  transformFormTaxesDetail(detail: any, translLanguage: string): any {
    if (detail) {
      let decimalPipe = new DecimalPipe(translLanguage);
      detail.get('Taxes').controls.forEach((reference: any) => {
        reference.get('TaxAmount').setValue(reference.get('TaxAmount').value != null ? decimalPipe.transform(reference.get('TaxAmount').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null);
      });
      return detail;
    }
    return null;
  }

  transformFormDiscountsDetail(detail: any, translLanguage: string): any {
    if (detail) {
      let decimalPipe = new DecimalPipe(translLanguage);
      detail.get('Discounts').controls.forEach((reference: any) => {
        reference.get('Amount').setValue(reference.get('Amount').value != null ? decimalPipe.transform(reference.get('Amount').value, '1.' + UNITARY_PRICE_DECIMAL + '-' + UNITARY_PRICE_DECIMAL) : null);
      });
      return detail;
    }
    return null;
  }

  /**
  * Reverte a(s) ENTIDADE(S) para permitir fazer cálculos matemáticos em ts
  *
  */
  revertObject(orderObj: any = null) {
    if (!orderObj) {
      return;
    }
    let orderObject: Order = orderObj;

    // €€€€€
    orderObject.TotalNetAmount = orderObj.TotalNetAmount ? ((orderObj.TotalNetAmount).toString()).revertDecimal() : null;
    // peso
    orderObject.TotalWeight = orderObj.TotalWeight ? ((orderObj.TotalWeight).toString()).revertDecimal() : null;
    // volume
    orderObject.TotalVolume = orderObj.TotalVolume ? ((orderObj.TotalVolume).toString()).revertDecimal() : null;

    // detalhes
    orderObject.Details = this.revertObjectDetails(orderObj.Details);

    // distribuição cabeçalho
    orderObject.CostCentersConfig = this.tranfModelCC.revertObjects(orderObj.CostCentersConfig);

    //contabilização cabeçalho
    orderObject.AccountingConfigList = this.tranfModelAC.revertObjects(orderObj.AccountingConfigList);

    return orderObject;
  }
  revertObjectDetails(detailsObj: any): OrderDetail[] {
    if (detailsObj && detailsObj.length > 0) {
      detailsObj.forEach((detail: any) => {
        detail = this.revertObjectDetail(detail);
      });
    }
    return detailsObj;
  }
  revertObjectDetail(detailObj: any): OrderDetail {
    let detailObject: OrderDetail = detailObj;

    detailObject.OrderedQuantity = detailObj.OrderedQuantity !== null ? ((detailObj.OrderedQuantity).toString()).revertDecimal() : null;
    detailObject.BonusQuantity = detailObj.BonusQuantity !== null ? ((detailObj.BonusQuantity).toString()).revertDecimal() : null;
    detailObject.NetUnitPrice = detailObj.NetUnitPrice !== null ? ((detailObj.NetUnitPrice).toString()).revertDecimal() : null;
    detailObject.GrossUnitPrice = detailObj.GrossUnitPrice !== null ? ((detailObj.GrossUnitPrice).toString()).revertDecimal() : null;
    detailObject.TotalNetAmount = detailObj.TotalNetAmount !== null ? ((detailObj.TotalNetAmount).toString()).revertDecimal() : null;
    detailObject.TotalGrossAmount = detailObj.TotalGrossAmount !== null ? ((detailObj.TotalGrossAmount).toString()).revertDecimal() : null;
    detailObject.PackQuantity = detailObj.PackQuantity !== null ? ((detailObj.PackQuantity).toString()).revertDecimal() : null;
    detailObject.PackSize = detailObj.PackSize !== null ? ((detailObj.PackSize).toString()).revertDecimal() : null;
    detailObject.Quantity = detailObj.Quantity !== null ? ((detailObj.Quantity).toString()).revertDecimal() : null;
    detailObject.PackUnitsPerLayer = detailObj.PackUnitsPerLayer !== null ? ((detailObj.PackUnitsPerLayer).toString()).revertDecimal() : null;
    detailObject.PackAverageGrossWeight = detailObj.PackAverageGrossWeight !== null ? ((detailObj.PackAverageGrossWeight).toString()).revertDecimal() : null;
    detailObject.RevisedQuantity = detailObj.RevisedQuantity !== null ? ((detailObj.RevisedQuantity).toString()).revertDecimal() : null;
    detailObject.DespatchedQuantity = detailObj.DespatchedQuantity !== null ? ((detailObj.DespatchedQuantity).toString()).revertDecimal() : null;
    detailObject.SatisfiedQuantity = detailObj.SatisfiedQuantity !== null ? ((detailObj.SatisfiedQuantity).toString()).revertDecimal() : null;
    detailObject.ReceivedQuantity = detailObj.ReceivedQuantity !== null ? ((detailObj.ReceivedQuantity).toString()).revertDecimal() : null;
    detailObject.ReturnedQuantity = detailObj.ReturnedQuantity ? ((detailObj.ReturnedQuantity).toString()).revertDecimal() : null;
    detailObject.TotalValue = detailObj.TotalValue ? ((detailObj.TotalValue).toString()).revertDecimal() : null;

    detailObject.CostCentersConfig = this.tranfModelCC.revertObjects(detailObj.CostCentersConfig);
    detailObject.AccountingConfigList = this.tranfModelAC.revertObjects(detailObj.AccountingConfigList);
    detailObject.Taxes = this.revertDetailTaxes(detailObj.Taxes);
    detailObject.Allowances = this.revertDetailAllowances(detailObj.Allowances);
    detailObject.Discounts = this.revertDetailDiscounts(detailObj.Discounts);

    return detailObject;
  }

  revertDetailTaxes(taxes: OrderTax[]): OrderTax[] {
    if (taxes && taxes.length > 0) {
      taxes.forEach((tax: OrderTax) => {
        tax.TaxAmount = tax.TaxAmount !== null ? ((tax.TaxAmount).toString()).revertDecimal() : null;
        tax.TaxRate = tax.TaxRate !== null ? ((tax.TaxRate).toString()).revertDecimal() : null;
      });
    }
    return taxes;
  }

  revertDetailAllowances(allowances: OrderAllowance[]): OrderAllowance[] {
    if (allowances && allowances.length > 0) {
      allowances.forEach((allowance: OrderAllowance) => {
        allowance.Percentage = allowance.Percentage !== null ? ((allowance.Percentage).toString()).revertDecimal() : null;
        allowance.Amount = allowance.Amount !== null ? ((allowance.Amount).toString()).revertDecimal() : null;
      });
    }
    return allowances;
  }

  revertDetailDiscounts(discounts: OrderDiscount[]): OrderDiscount[] {
    if (discounts && discounts.length > 0) {
      discounts.forEach((discount: OrderDiscount) => {
        discount.Percentage = discount.Percentage !== null ? ((discount.Percentage).toString()).revertDecimal() : null;
        discount.Amount = discount.Amount !== null ? ((discount.Amount).toString().revertDecimal()) : null;
      });
    }
    return discounts;
  }
}


