// ***__***_________  BIBLIOTECAS _________ ***__***
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

// ***__***_________  SERVICOS _________ ***__***
import { OrderService } from '../../services/order.service';
import { TranslateValueService } from '../../services/translate-value.service';

// ***__***_________  MODELOS _________ ***__***
import { ModelValidators } from '../../models/validators/validators';
import { OrderPod, OrderDetail } from '../../models/order';
import { ReturnStatusHtml } from '../../models/returnStatus';

// ***__***_________  MODULOS _________ ***__***
import { ErrorTreatmentFunctions } from '../../modules/treatments.module';
import { QUANTITY_DECIMAL } from 'src/app/constants/global';
import { Subject } from 'rxjs';

declare var Functions: any;

@Component({
  templateUrl: './reception-modal.html'
})
export class ReceptionModalComponent implements OnInit, OnDestroy {

  /* ***************************************************
   * *********** MODAL --> Editar receção ***********
   * ***************************************************
   * */
  destroy$: Subject<boolean> = new Subject<boolean>();
  details: Array<any> = new Array<any>();
  // 3 avaliações p/ Otis
  deliveryDateEvalList: Array<any> = new Array<any>();
  receivedQuantityEvalList: Array<any> = new Array<any>();
  productEvalList: Array<any> = new Array<any>();
  // avaliação por defeito
  evaluationEvalList: Array<number> = new Array<number>();

  receptionStates: Array<number> = new Array<number>();
  receptionNumberValidators: Array<any> = new Array<any>();
  detailModel = new OrderDetail();
  form: UntypedFormGroup;

  noError: boolean = true;
  submitted: boolean = false;

  allowEdit: boolean; // se tem permissoes de edicao
  areSelectsRequired: boolean; // se os 3 selects da modal sao obrigatorios
  isDelegation: boolean; // se os 3 selects da modal sao obrigatorios

  earliestAcceptableDate: Date;

  documentNumber: string;
  documentType: string;
  deliveryLocationIdentifier: string;
  documentState: number;
  startBy: any;
  charactersToValidate: number = 0;
  lengthOfControl: number;
  satisfiedQuantity: number;
  prevouslyReturnedQuantity: number;
  changed: boolean = false;
  guideNotMandatory: boolean = false;
  model = new OrderPod();

  // verificar form
  originalOrderProd: OrderPod = new OrderPod();

  isReceptionHasExtendedEvals: boolean = false;
  otisReceptionNumberValidation: boolean = false;
  showReturnedQuantity: boolean = false;
  hideReceptionNumber: boolean = false;

  validationMessages = {};

  constructor(public dialogRef: MatDialogRef<ReceptionModalComponent>, @Inject(MAT_DIALOG_DATA) data: any, private orderService: OrderService,
    private _errorTreat: ErrorTreatmentFunctions, private formBuilder: UntypedFormBuilder, private translateValueService: TranslateValueService) {

    this.allowEdit = data.allowEdit;
    this.model = data.orderPod;
    this.deliveryDateEvalList = data.deliveryDateEvalList;
    this.receivedQuantityEvalList = data.receivedQuantityEvalList;
    this.productEvalList = data.productEvalList;
    this.detailModel = data.detail;

    this.documentNumber = this.model.OrderStatusReportNumber;
    this.documentState = data.documentState;
    this.receptionStates = data.receptionStates;
    this.isDelegation = data.isDelegation;
    this.documentType = data.documentType;
    this.deliveryLocationIdentifier = data.deliveryLocationIdentifier;
    this.isReceptionHasExtendedEvals = data.isReceptionHasExtendedEvals;
    this.evaluationEvalList = data.evaluationEvalList;

    this.otisReceptionNumberValidation = data.otisReceptionNumberValidation;
    this.showReturnedQuantity = data.showReturnedQuantity;
    this.hideReceptionNumber = data.hideReceptionNumber;
    this.guideNotMandatory = data.guideNotMandatory;
    this.validationMessages = {
      'EvalDateID': {
        'required': 'FIELD_REQUIRED_'
      },
      'EvalQtdID': {
        'required': 'FIELD_REQUIRED_'
      },
      'EvalProdID': {
        'required': 'FIELD_REQUIRED_'
      },
      'DeliveredQuantity': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'BuyerReceptionNumber': {
        'lengthEquals': 'LENGTH_EQUALS',
        'required': 'FIELD_REQUIRED_',
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR',
        'charactersPosition': 'CHARACTRS_BEGIN'
      },
      'TransportDocumentNumber': {
        'required': 'FIELD_REQUIRED_',
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
      'Evaluation': {
        'required': 'FIELD_REQUIRED_'
      },
      'ReturnedQuantity': {
        'lengthMax': 'LENGTH_MAX',
        'numberVal': 'NUMBER_INVALID',
        'numberMin': 'NUMBER_MIN_ERROR',
        'numberOfDecimalPlaces': 'NUMBER_DECIMAL_ERROR'
      },
    }

    this.copyModel();

  }

  ngOnInit(): void {

    let that = this;
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 27) { // escape
        that.dialogRef.close(null);
      }
    });

    // a quantidade satisfeita original será a quantidade satisfeita da linha menos a quantidade desta recepção
    // GM 2021-07-19: mais a quantidade devolvida da linha
    this.satisfiedQuantity = this.detailModel.ReceivedQuantity - this.model.DeliveredQuantity;
    this.prevouslyReturnedQuantity = (this.detailModel.ReturnedQuantity ? this.detailModel.ReturnedQuantity : 0) - this.model.ReturnedQuantity;
    
    this.buildForm(this.model);


    if (this.otisReceptionNumberValidation) {

      this.receptionNumberValidators.push(ModelValidators.numberVal({ min: 0, decimalPlaces: 0 }));

      // se não for delegação
      if (!this.isDelegation) {

        if (this.form.get('BuyerReceptionNumber')) {
          let recep: string = this.form.get('BuyerReceptionNumber').value;

          /**
           * Estas validações são feitas aqui e nao no inicio porque é necessário ja existir o form
           */
          if (this.documentType === 'OrderMatStock') {
            this.receptionNumberValidators.push(Validators.required);
            this.receptionNumberValidators.push(ModelValidators.validCharacters({ startBy: 7, charactersToValidate: 0 }));
            this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 6 }));
            this.form.controls['BuyerReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
            this.form.controls['BuyerReceptionNumber'].updateValueAndValidity();
          } else if (this.documentType !== 'OrderFabUnComp') {
            this.receptionNumberValidators.push(Validators.required);
            this.receptionNumberValidators.push(ModelValidators.validCharacters({ startBy: 6, charactersToValidate: 0 }));
            this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 5 }));
            this.form.controls['BuyerReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
            this.form.controls['BuyerReceptionNumber'].updateValueAndValidity();
          } else if (this.documentType === 'OrderFabUnComp') {
            this.receptionNumberValidators.push(Validators.required);
            this.receptionNumberValidators.push(ModelValidators.validCharacters({ startBy: 9, charactersToValidate: 0 }));
            this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 4 }));
            this.form.controls['BuyerReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
            this.form.controls['BuyerReceptionNumber'].updateValueAndValidity();
          }
        }
      }
    } else {
      // GM 2018-10-11 o Numero da receção opcional (verificar necessidade de colocar como obr por setting)
      // this.receptionNumberValidators.push(Validators.required);
      this.receptionNumberValidators.push(ModelValidators.lengthVal({ max: 20 }));
      this.form.controls['BuyerReceptionNumber'].setValidators(Validators.compose(this.receptionNumberValidators));
      this.form.controls['BuyerReceptionNumber'].updateValueAndValidity();
    }

  }

  buildForm(orderPod: OrderPod): void {
    // se for delegação ignorar as validações ao nº da receção
    // let isReceptionNumberRequired = this.isDelegation === false ? Validators.required : null;
    let lengthOfControl = this.validationLengthOfControl() !== null ? ModelValidators.lengthVal({ min: this.validationLengthOfControl(), max: this.validationLengthOfControl() }) : null;
    this.receptionNumberValidators.push(lengthOfControl);

    let isSelectAccordingRequired = (!this.isReceptionHasExtendedEvals) ? Validators.required : null;

    this.form = this.formBuilder.group({
      'ID': [orderPod.ID],
      'OrderID': [orderPod.OrderID],
      'OrderStatusReportNumber': [{ value: orderPod.OrderStatusReportNumber, disabled: true }],
      'OrderStatusReportDate': [orderPod.OrderStatusReportDate],
      // *** Nº Rececao
      'BuyerReceptionNumber': [{ value: orderPod.BuyerReceptionNumber, disabled: this.isDelegation }, Validators.compose(this.receptionNumberValidators)], // nº rececao
      // 'TransportDocumentNumber': [orderPod.TransportDocumentNumber, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: 0 }), Validators.required, ModelValidators.lengthVal({ max: 20 })])], // guia
      // alteração a pedido da OTIS : 17-05-2018
      'TransportDocumentNumber': [orderPod.TransportDocumentNumber, Validators.compose([!this.guideNotMandatory ? Validators.required : Validators.nullValidator, ModelValidators.lengthVal({ max: 20 })])],
      'OriginalLineNumber': [{ value: orderPod.OriginalLineNumber, disabled: true }], // linha
      'BuyerProductCode': [{
        value: orderPod.BuyerProductCode ? orderPod.BuyerProductCode :
          orderPod.StandardProductCode ? orderPod.StandardProductCode :
            orderPod.SupplierProductCode ? orderPod.SupplierProductCode : ''
        , disabled: true
      }], // cod. artigo
      'ProductDescription': [{ value: orderPod.ProductDescription, disabled: true }], // nome produto
      'Notes': [orderPod.Notes],
      'RequestedQuantity': [{ value: orderPod.RequestedQuantity, disabled: true }], // qtd encomendada
      'ReceivedQuantityPreviously': [{ value: this.satisfiedQuantity, disabled: true }], // quantidade recebida anteriormente
      'DeliveredQuantity': [orderPod.DeliveredQuantity, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: QUANTITY_DECIMAL })])], // quantidade recebida
      // 3 avaliações Otis
      'DeliveryDateEval': [orderPod.DeliveryDateEval ? (orderPod.DeliveryDateEval).toString() : null],
      'ReceivedQuantityEval': [orderPod.ReceivedQuantityEval ? (orderPod.ReceivedQuantityEval).toString() : null],
      'ProductEval': [orderPod.ProductEval ? (orderPod.ProductEval).toString() : null],
      // avaliação genérica
      'Evaluation': [orderPod.Evaluation ? (orderPod.Evaluation).toString() : null, isSelectAccordingRequired],
      // 'ReceivedQuantity': [''], // quantidade recebida
      'ReturnedQuantity': [orderPod.ReturnedQuantity ? orderPod.ReturnedQuantity : 0, Validators.compose([ModelValidators.numberVal({ min: 0, decimalPlaces: QUANTITY_DECIMAL })])], // quantidade devolvida
      'ReturnedQuantityPreviously': [{ value: this.prevouslyReturnedQuantity, disabled: true }] // quantidade devolvida anteriormente
    });

    if (!this.allowEdit) { // colocar todos como disabled se não tem permissões de edição
      this.form.disable();
    } else if (this.detailModel.InvoiceToQuantity > 0) {
      this.form.disable();
      // GM 2018-10-11 Enable apenas ao nº da guia para permitir alterar em linhas já faturadas
      this.form.get('TransportDocumentNumber').enable();
    }

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      this.onValueChanged(value);
    }); // deteta se houve alterações no form

    this.onValueChanged(); // para apresentar mensagens de validação
  }

  /* tslint:disable:member-ordering */
  formErrors: Array<string> = new Array<string>();
  formErrorsParam: Array<string> = new Array<string>();
  
  /* tslint:enable:member-ordering */

  onValueChanged(value?: any) {
    if (!this.form) { return; }
    const form = this.form;

    // clear previous error message (if any)
    this.formErrors = new Array<string>();
    this.formErrorsParam = new Array<string>();
    for (const field in this.validationMessages) {
      if (this.validationMessages.hasOwnProperty(field)) {
        const control = form.get(field);

        if ((this.submitted && (control && !control.valid && control.enabled)) ||
          (!this.submitted && (control && control.dirty && !control.valid))
        ) {

          this.noError = false;
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (messages.hasOwnProperty(key)) {

              this.formErrors[field] = messages[key];

              let param = 'params';
              if (control.errors.hasOwnProperty(param)) {
                this.formErrorsParam[field] = JSON.parse(control.errors[param]);
              } else {
                this.formErrorsParam[field] = '';
              }
              control.markAsTouched(); // necessario porque quando submete se nao tiver passado pelo campo os md-select nao ficam a vermelho
            }
          }
        }
      }
    }
  }

  // guardar
  save() {
    // if (this.allowEdit) { // verificar se permissoes
    this.submitted = true;
    this.noError = true;
    this.onValueChanged();

    let validDetails: boolean = true;
    let validDetailsEvals: boolean = true;
    let validDetailsReceivedQuantity: boolean = true;
    let validDetailsNotes: boolean = true;
    let validDetailsNotesReturnedQuantity: boolean = true;
    let msg: string = '';

    if (this.noError) {
      // o documento está num estado passível de confirmação de recepção ?
      if (this.receptionStates.findIndex((x: any) => (+x) === (+this.documentState)) === -1) {
        msg += this.translateValueService.get('INVALID_DOCUMENT_STATE') + '<br/>';
      }

      // GM 2018-10-11 Permitir atualizar apenas o nº da guia quando a receção já foi faturada
      // Se já foi fatura não é preciso fazer estas validações por que apenas pode alterar o nº da guia
      if (!(this.detailModel.InvoiceToQuantity > 0)) {

        if (this.detailModel.InvoiceToQuantity > 0) {
          msg += this.translateValueService.get('THE_ORDER_LINE_HAS_ALREADY_BEEN_PARTIALLY_OR_FULLY_BILLED') + '<br/>';
        }

        let requestedQuantity: number = (+this.form.get('RequestedQuantity').value); // qtd encomendada
        let deliveredQuantity: number = (+this.form.get('DeliveredQuantity').value); // qtd recebida
        let returnedQuantity: number = (+this.form.get('ReturnedQuantity').value); // qtd recebida

        let notes: string = (this.form.get('Notes').value); // notas

        let evalDateID: string = (this.form.get('DeliveryDateEval').value);
        let evalQtdID: string = (this.form.get('ReceivedQuantityEval').value);
        let evalProdID: string = (this.form.get('ProductEval').value);
        let evaluation: string = (this.form.get('Evaluation').value);

        // a quantidade recebida + quantidade recebida anteriormente não pode ser superior à quantidade encomendada
        // GM 2021-07-19 ter em conta a quantidade devolvida anteriormente e a quantidade devolvida agora
        if (requestedQuantity < this.satisfiedQuantity + deliveredQuantity) {
          validDetails = false;
        }

        if (this.isReceptionHasExtendedEvals) {
          // As avals são obrigatórias
          // 2017-11-21 Validar a aval da data apenas se a quantidade recebida seja > 0
          if ((deliveredQuantity > 0 && !evalDateID) || !evalQtdID || !evalProdID) {
            validDetailsEvals = false;
          }
          // caso o produto não esteja conforme, não podem receber
          if (deliveredQuantity > 0 && (!evalProdID || evalProdID === '1' || evalProdID === '2')) {
            validDetailsReceivedQuantity = false;
          }
          if ((notes === '' || !notes) && ((!evalProdID || evalProdID === '1' || evalProdID === '2'))) {
            validDetailsNotes = false;
          }
        } else {
          if ((notes === '' || !notes) && (evaluation === 'NC')) {
            validDetailsNotes = false;
          } else if ((notes === '' || !notes) && (returnedQuantity > 0)) {
            validDetailsNotesReturnedQuantity = false;
          }
        }

        if (!validDetails) {
          msg += this.translateValueService.get('RECEIVED_QUANTITIES_CAN_NOT_EXCEED_THE_QUANTITIES_ORDERED') + '<br/>';
        }
        if (!validDetailsEvals) {
          msg += this.translateValueService.get('ALL_RECEPTION_EVALUATION_FIELDS_ARE_MANDATORY') + '<br/>';
        }
        if (!validDetailsReceivedQuantity) {
          msg += this.translateValueService.get('IF_THE_CONFORMITY_EVALUATION_OF_THE_PRODUCT_IS_<=_2_THE_QUANTITY_RECEIVED_MUST_BE_0') + '<br/>';
        }
        if (!validDetailsNotes) {
          if (this.isReceptionHasExtendedEvals) {
            msg += this.translateValueService.get('IF_THE_PRODUCT_CONFORMITY_EVALUATION_IS_<=_2_YOU_HAVE_TO_FILL_THE_OBSERVATIONS') + '<br/>';
          } else {
            msg += this.translateValueService.get('IF_THE_EVALUATION_IS_NONCONFORMING_YOU_MUST_FILL_THE_OBSERVATIONS');
          }
        } else if (!validDetailsNotesReturnedQuantity) {
          msg += this.translateValueService.get('IF_RETURNED_QUANTITY_FILLED_YOU_MUST_FILL_THE_OBSERVATIONS');
        }

      }

      // Se ocorrer erro de validação
      if (msg.length > 0) {
        Functions.gritter(msg, 'danger');
      } else {
        // Caso contrário guardar
        if (this.detailModel.InvoiceToQuantity > 0) {
          this.orderService.updateReceptionTransportNumber(this.form.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe((resp: ReturnStatusHtml) => {
            if (resp.ReturnStatus.Successfull) {
              Functions.gritter(resp.ReturnStatus.SuccessMessage, 'success');
              this.dialogRef.close(this.form.getRawValue());
            } else {
              this._errorTreat.treatErrorResponse(resp);
            }
          });
          // this.dialogRef.close(this.form.getRawValue());
        } else {
          this.orderService.updateReception(this.form.getRawValue()).pipe(takeUntil(this.destroy$)).subscribe((resp: ReturnStatusHtml) => {
            if (resp.ReturnStatus.Successfull) {
              Functions.gritter(resp.ReturnStatus.SuccessMessage, 'success');
              this.dialogRef.close(this.form.getRawValue());
            } else {
              this._errorTreat.treatErrorResponse(resp);
            }
          });
          // this.dialogRef.close(this.form.getRawValue());
        }
      }
    }
    this.submitted = false;
    // }
  }

  // validação nº caracteres
  validationLengthOfControl(): any {
    if (this.otisReceptionNumberValidation) {
      // se não for delegação
      if (!this.isDelegation) {
        // Enc. Material Stock
        if (this.documentType === 'OrderMatStock') {
          return this.lengthOfControl = 6;
          // Diferente de Enc. Fabrica Un. Completas
        } else if (this.documentType !== 'OrderFabUnComp') {
          return this.lengthOfControl = 5;
          // Enc. Fabrica Un. Completas
        } else if (this.documentType === 'OrderFabUnComp') {
          return this.lengthOfControl = 4;
        }
      }
    }
    return null;
  }

  // validação caracteres (não está a ser usado, mas manter)
  validationCharacters(): any {
    // se não for delegação
    if (!this.isDelegation) {

      if (this.documentType === 'OrderMatStock') {
        return this.startBy = 7;

      } else if (this.documentType !== 'OrderFabUnComp') {
        return this.startBy = 6;

      } else if (this.documentType === 'OrderFabUnComp') {
        return this.startBy = 9;

      }
    }
    return null;
  }

  // #region Métodos Gerais e Comuns

  // alteração avaliação data
  onSelectEvalDateID(e: any) {
    this.form.controls['DeliveryDateEval'].setValue(e);
  }

  // alteração avaliação quantidade
  onSelectEvalQtdID(e: any) {
    this.form.controls['ReceivedQuantityEval'].setValue(e);
  }

  // alteração avaliação produto
  onSelectEvalProdID(e: any) {
    this.form.controls['ProductEval'].setValue(e);
  }

  // alteração avaliação geral
  onSelectEvaluation(e: any) {
    this.form.controls['Evaluation'].setValue(e);
  }

  // alteração do valor da quantidade recebida
  receivedQuantityChange(receivedQuantity: number, positionArrayForm: number) {
    if (!isNaN(receivedQuantity)) {
      // quantidade que ainda não foi recebida
      let quantityToReceive: number = this.detailModel.OrderedQuantity - this.satisfiedQuantity;

      // verificar a avaliação da quantidade
      if (receivedQuantity < quantityToReceive * 0.85) {
        this.form.controls['ReceivedQuantityEval'].setValue('1');
      } else if (receivedQuantity < quantityToReceive * 0.95) {
        this.form.controls['ReceivedQuantityEval'].setValue('2');
      } else if (receivedQuantity < quantityToReceive * 0.99) {
        this.form.controls['ReceivedQuantityEval'].setValue('3');
      } else if (receivedQuantity > quantityToReceive) {
        this.form.controls['ReceivedQuantityEval'].setValue('4');
      } else {
        // this.form.controls['EvalQtdID'].setValue('5');
        this.form.controls['ReceivedQuantityEval'].setValue('5');
      }
    }

  }

  // para validar se dados foram alterados
  dataChanged(): void {

    if (this.originalOrderProd.BuyerReceptionNumber !== this.form.controls['BuyerReceptionNumber'].value
      || this.originalOrderProd.TransportDocumentNumber !== this.form.controls['TransportDocumentNumber'].value
      || this.originalOrderProd.OriginalLineNumber !== this.form.controls['OriginalLineNumber'].value
      || this.originalOrderProd.BuyerProductCode !== this.form.controls['BuyerProductCode'].value
      || this.originalOrderProd.ProductDescription !== this.form.controls['ProductDescription'].value
      || this.originalOrderProd.RequestedQuantity !== this.form.controls['RequestedQuantity'].value
      || this.originalOrderProd.DeliveredQuantity + '' !== this.form.controls['DeliveredQuantity'].value + ''
      || this.originalOrderProd.ReturnedQuantity + '' !== this.form.controls['ReturnedQuantity'].value + ''
      || this.originalOrderProd.DeliveryDateEval + '' !== (this.form.controls['DeliveryDateEval'].value ? this.form.controls['DeliveryDateEval'].value : '')
      || this.originalOrderProd.ReceivedQuantityEval + '' !== (this.form.controls['ReceivedQuantityEval'].value ? this.form.controls['ReceivedQuantityEval'].value : '')
      || this.originalOrderProd.ProductEval + '' !== (this.form.controls['ProductEval'].value ? this.form.controls['ProductEval'].value : '')
      || this.originalOrderProd.Evaluation + '' !== (this.form.controls['Evaluation'].value ? this.form.controls['Evaluation'].value : '')
      || (this.originalOrderProd.Notes !== this.form.controls['Notes'].value
        && (this.form.controls['Notes'].value != null))) {
      this.changed = true;
    } else {
      this.changed = false;
    }

  }

  copyModel(): void {
    this.originalOrderProd.BuyerReceptionNumber = this.model.BuyerReceptionNumber == null ? '' : this.model.BuyerReceptionNumber;
    this.originalOrderProd.TransportDocumentNumber = this.model.TransportDocumentNumber == null ? '' : this.model.TransportDocumentNumber;
    this.originalOrderProd.OriginalLineNumber = this.model.OriginalLineNumber == null ? '' : this.model.OriginalLineNumber;
    this.originalOrderProd.BuyerProductCode = this.model.BuyerProductCode == null ? '' : this.model.BuyerProductCode;
    this.originalOrderProd.ProductDescription = this.model.ProductDescription == null ? '' : this.model.ProductDescription;
    this.originalOrderProd.RequestedQuantity = this.model.RequestedQuantity == null ? 0 : this.model.RequestedQuantity;
    this.originalOrderProd.DeliveredQuantity = this.model.DeliveredQuantity == null ? 0 : this.model.DeliveredQuantity;
    this.originalOrderProd.ReturnedQuantity = this.model.ReturnedQuantity == null ? 0 : this.model.ReturnedQuantity;
    this.originalOrderProd.DeliveryDateEval = this.model.DeliveryDateEval == null ? '' : this.model.DeliveryDateEval;
    this.originalOrderProd.ReceivedQuantityEval = this.model.ReceivedQuantityEval == null ? '' : this.model.ReceivedQuantityEval;
    this.originalOrderProd.ProductEval = this.model.ProductEval == null ? '' : this.model.ProductEval;
    this.originalOrderProd.Notes = this.model.Notes == null ? '' : this.model.Notes;
  }

  resetForm() {
    // this.form.reset(this.model);
  }

  // #endregion Métodos Gerais e Comuns

  ngOnDestroy() { }
}
